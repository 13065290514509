/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useMemo, useContext, createContext } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { useLandScapeCheckHook, useMobileCheckHook } from '@/hooks/celebration';
import EditPoster from '@/components/Printables/EditTemplates';
import Layout from '@/components/Layout';
import { useGetCelebration } from '@/hooks/api';
import MobileVGBTemplateSelector from '@/components/MobileVGBTemplateSelector';
import { checkIfIdExist } from '@/lib/helper';

export const LayerContext = createContext({});

export const useLayerSelection = () => {
  const [state, dispatch] = useContext(LayerContext);
  const layer = useMemo(() => [state, dispatch], [state, dispatch]);
  return layer;
};

function LayerSelection({ children }) {
  const [selectedLayer, setSelectedLayer] = useState(null);
  const layer = useMemo(() => [selectedLayer, setSelectedLayer], [selectedLayer, setSelectedLayer]);

  return <LayerContext.Provider value={layer}>{children}</LayerContext.Provider>;
}
function PosterPage() {
  const isFromMobile = useMobileCheckHook();
  const {
    query: { id },
  } = useRouter();
  useGetCelebration(id);
  useSWR(checkIfIdExist(id) ? `/v3/celebrations/${id}/celebration_printable_templates` : null);
  useSWR(id ? `/v3/celebrations/${id}/celebration_printables` : null);

  const [showMobileVersion, setShowMobileVersion] = useState(false);
  const [mobileInnerHeight, setMobileInnerHeight] = useState(0);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isLandscape] = useLandScapeCheckHook();

  useEffect(() => {
    if (isFromMobile) setMobileInnerHeight(window.innerHeight);
  }, [isLandscape]);

  useEffect(() => {
    setShowMobileVersion(isFromMobile);
    if (isFromMobile) setMobileInnerHeight(window.innerHeight);
  }, [isFromMobile]);

  return (
    <Layout
      authenticated
      hasNav
      maxWidth="100vw"
      backgroundColor="lightGreys.3"
      isFromPrintable
      showFooter={false}
    >
      <MobileVGBTemplateSelector
        showMobileVersion={showMobileVersion}
        onClose={(templateId) => {
          setSelectedTemplateId(templateId);
          setShowMobileVersion(false);
        }}
        id={id}
      >
        <LayerSelection>
          <EditPoster
            isFromMobileScreen={isFromMobile}
            onHomePress={() => setShowMobileVersion(true)}
            mobileInnerHeight={mobileInnerHeight}
            mobileSelectedTemplateId={selectedTemplateId}
          />
        </LayerSelection>
      </MobileVGBTemplateSelector>
    </Layout>
  );
}

export default PosterPage;
