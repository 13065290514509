import React from 'react';

function Gallery({ height = '30px', width = '30px', fill = '#606060' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 37 30"
      version="1.1"
    >
      <g id="surface1">
        <path
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={fill}
          strokeOpacity={1}
          strokeMiterlimit={4}
          d="M 4.298986 1.5 L 17.949747 1.5 C 17.949747 1.5 18.999155 1.5 18.999155 2.25 L 18.999155 12 C 18.999155 12 18.999155 12.75 17.949747 12.75 L 4.298986 12.75 C 4.298986 12.75 3.249578 12.75 3.249578 12 L 3.249578 2.25 C 3.249578 2.25 3.249578 1.5 4.298986 1.5 Z M 4.298986 1.5 "
          transform="matrix(1.85,0,0,1.875,0,0)"
        />
        <path
          fillRule="nonzero"
          fill="rgb(95.294118%,96.470588%,99.215686%)"
          fillOpacity={1}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={fill}
          strokeOpacity={1}
          strokeMiterlimit={4}
          d="M 2.050253 3.75 L 15.698902 3.75 C 15.698902 3.75 16.750422 3.75 16.750422 4.5 L 16.750422 14.25 C 16.750422 14.25 16.750422 15 15.698902 15 L 2.050253 15 C 2.050253 15 1.000845 15 1.000845 14.25 L 1.000845 4.5 C 1.000845 4.5 1.000845 3.75 2.050253 3.75 Z M 2.050253 3.75 "
          transform="matrix(1.85,0,0,1.875,0,0)"
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={fill}
          fillOpacity={1}
          d="M 1.851562 28.125 L 9.453125 18.992188 L 13.394531 23.941406 L 20.449219 15.46875 L 30.988281 28.125 Z M 1.851562 28.125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={fill}
          fillOpacity={1}
          d="M 11.5625 13.359375 C 11.5625 14.523438 10.628906 15.46875 9.480469 15.46875 C 8.332031 15.46875 7.398438 14.523438 7.398438 13.359375 C 7.398438 12.195312 8.332031 11.25 9.480469 11.25 C 10.628906 11.25 11.5625 12.195312 11.5625 13.359375 Z M 11.5625 13.359375 "
        />
      </g>
    </svg>
  );
}

export default Gallery;
