import React, { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ReactSortable } from 'react-sortablejs';
import store from 'store';
import { useResourceRequest } from '@/hooks/api';
import { EntityCacheContext } from '@/lib/entities';

const checkStatusChanged = (clips, list) =>
  clips.map((clip) => clip.uploadStatus).join('') !==
  list.map((clip) => clip.uploadStatus).join('');

function SortableClips({ clips, children, ...props }) {
  const {
    query: { id },
  } = useRouter();
  const [list, setList] = useState([]);
  const { updateEntities } = useContext(EntityCacheContext);

  const [updateClipList] = useResourceRequest(
    'patch',
    `/v3/celebrations/${id}/celebration_clips/reorder`
  );

  useEffect(() => {
    setList(clips);
  }, [clips]);

  const handleSetList = (evt) => {
    if (!store.get('clipMoving') && !store.get('isDragging') && evt.oldIndex !== evt.newIndex) {
      const idToMove = evt?.item?.dataset?.id;

      const nextRequestData = [...list];

      const indexToRemove = nextRequestData.findIndex((item) => item.id === idToMove);
      const removedItem = nextRequestData.splice(indexToRemove, 1)[0];
      nextRequestData.splice(evt.newIndex, 0, removedItem);
      /* eslint-disable no-param-reassign */
      nextRequestData.forEach((item, index) => {
        item.position = index + 1;
      });

      const sortedData = nextRequestData.sort((a, b) => a.position - b.position);

      /* eslint-disable no-param-reassign */
      updateEntities((draft) => {
        sortedData.forEach((clip, index) => {
          if (clip && draft[clip.type] && draft[clip.type][clip.id]) {
            draft[clip.type][clip.id].position = index + 1;
          }
        });
      });
      setList(sortedData);
      const updatedClip = {
        id: idToMove,
        position: evt.newIndex + 1,
      };

      if (props?.isFromTimeline) updatedClip.timeline = true;

      updateClipList(null, {
        celebration_clips: sortedData,
        // celebration_clips: [
        //   {
        //     ...updatedClip,
        //   },
        // ],
      });
    }
  };

  useEffect(() => {
    if (
      (clips.length !== list.length || checkStatusChanged(clips, list)) &&
      !store.get('clipMoving') &&
      !store.get('isDragging')
    ) {
      const nextList = clips.map((clip, index) => ({
        ...clip,
        selected: false,
        chosen: false,
        position: index + 1,
      }));
      setList(nextList);
    }
  }, [clips, list]);

  return (
    <ReactSortable
      delayOnTouchStart
      delay={3}
      animation={150}
      list={list}
      setList={() => {}}
      onEnd={handleSetList}
      {...props}
    >
      {children(list)}
    </ReactSortable>
  );
}

SortableClips.defaultProps = { clips: [] };

export default SortableClips;
