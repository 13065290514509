import React from 'react';
import Slider from 'react-slider';
import { Box } from 'rebass';

function PreviewSlider({ max, bg, ...sliderProps }) {
  return (
    <Box>
      <Slider
        max={max}
        {...sliderProps}
        renderThumb={(props) => (
          <Box
            {...props}
            sx={{
              bg: 'purples.7',
              height: 20,
              width: 20,
              borderRadius: '50%',
              transform: 'translateY(-50%)',
              top: '50%',
              zIndex: 6,
              transition: 'transform 176ms',
              outline: 'none',
              '&:hover, &:active': {
                transform: 'translateY(-50%) scale(1.1)',
              },
            }}
          />
        )}
        renderTrack={(props, { index }) => (
          <Box
            {...props}
            bg={index % 2 ? bg || 'rgba(59, 63, 133, 0.102)' : 'purples.7'}
            height={12}
            sx={{
              borderRadius: 6,
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: index,
            }}
          />
        )}
      />
    </Box>
  );
}

export default PreviewSlider;
