import { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

function AutoSave({ debounceMs, children = null }) {
  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState(null);
  const debouncedSubmit = useCallback(
    debounce(() => {
      return formik.submitForm().then(() => setIsSaved(true));
    }, debounceMs),
    [formik.submitForm, debounceMs]
  );

  useEffect(() => debouncedSubmit, [debouncedSubmit, formik.values]);

  const status = useMemo(() => {
    if (formik.isSubmitting) return 'submitting';
    if (isSaved) return 'saved';
    return null;
  }, [isSaved, formik.isSubmitting]);

  if (children) return children(status);

  return null;
}

AutoSave.propTypes = { debounceMs: PropTypes.number, children: PropTypes.func };

AutoSave.defaultProps = { debounceMs: 2500, children: null };

export default AutoSave;
