import React from 'react';

function EditingSuiteSelected() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <g clipPath="url(#clip0_4595_49308)">
        <path
          d="M1.89258 10.959C1.89258 11.3168 1.96305 11.6711 2.09998 12.0016C2.2369 12.3322 2.43759 12.6326 2.6906 12.8856C2.9436 13.1386 3.24396 13.3393 3.57452 13.4762C3.90509 13.6131 4.25939 13.6836 4.61719 13.6836C4.97499 13.6836 5.32929 13.6131 5.65985 13.4762C5.99042 13.3393 6.29077 13.1386 6.54378 12.8856C6.79678 12.6326 6.99747 12.3322 7.1344 12.0016C7.27132 11.6711 7.3418 11.3168 7.3418 10.959C7.3418 10.2364 7.05474 9.54336 6.54378 9.03239C6.03281 8.52143 5.3398 8.23438 4.61719 8.23438C3.89458 8.23438 3.20156 8.52143 2.6906 9.03239C2.17963 9.54336 1.89258 10.2364 1.89258 10.959Z"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.89258 20.8838C1.89258 21.2416 1.96305 21.5959 2.09998 21.9265C2.2369 22.257 2.43759 22.5574 2.6906 22.8104C2.9436 23.0634 3.24396 23.2641 3.57452 23.401C3.90509 23.5379 4.25939 23.6084 4.61719 23.6084C4.97499 23.6084 5.32929 23.5379 5.65985 23.401C5.99042 23.2641 6.29077 23.0634 6.54378 22.8104C6.79678 22.5574 6.99747 22.257 7.1344 21.9265C7.27132 21.5959 7.3418 21.2416 7.3418 20.8838C7.3418 20.1612 7.05474 19.4682 6.54378 18.9572C6.03281 18.4462 5.3398 18.1592 4.61719 18.1592C3.89458 18.1592 3.20156 18.4462 2.6906 18.9572C2.17963 19.4682 1.89258 20.1612 1.89258 20.8838Z"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.80273 13.559L12.7528 18.8641L17.8399 21.8781"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.80273 18.2847L9.03035 15.1871"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7607 15.5V3.69336C12.7607 3.21162 12.9521 2.74961 13.2928 2.40897C13.6334 2.06832 14.0954 1.87695 14.5771 1.87695H29.1084"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1084 29.123H14.5771C14.0954 29.123 13.6334 28.9317 13.2928 28.591C12.9521 28.2504 12.7607 27.7884 12.7607 27.3066V22.7656"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.21 12.7754V1.87695"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.21 29.123V25.4902"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7607 7.32617H18.21"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1084 18.2246V1.87695"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1084 29.123V18.2246"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7607 12.7754H29.1084"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0264 18.2246H29.1084"
          stroke="#7B33FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4595_49308">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditingSuiteSelected;
