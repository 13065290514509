import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';
import Image from 'next/image';
import { FiClock, FiScissors, FiVolume2, FiRotateCw, FiType } from 'react-icons/fi';
import { FaArrowDown, FaRegSmile, FaArrowUp } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import { useWindowSize } from 'react-use';
import Grid from '@/components/app/Grid';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import TextFeature from '@/public/Images/NotSupportedFeatures/text@3x.png';
import TrimFeature from '@/public/Images/NotSupportedFeatures/Trim.gif';
import VolumeFeature from '@/public/Images/NotSupportedFeatures/Volume.gif';
import NavButton from './NavButton';
import DuplicateNavButton from './DuplicateNavButton';
import EditClipDialogButton from '../EditClipDialogButton';

const disabledButtonStyleProps = {
  background: '#FFFFFF',
  opacity: 0.33,
  border: '2px solid #E2E7F1',
  boxSizing: 'border-box',
  borderRadius: '4px',
};

function NavParent({ children, isFromMobile }) {
  if (isFromMobile)
    return (
      <Box overflowX="auto">
        <Flex width="100%" minWidth="min-content" p={1} justifyContent="space-around">
          {children}
        </Flex>
      </Box>
    );

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fit, minmax(max-content, 1fr))"
      gridAutoFlow="column"
      gridGap={1}
      pb={2}
    >
      {children}
    </Grid>
  );
}

const videoControls = {
  trim: FiScissors,
  volume: FiVolume2,
  stickers: FaRegSmile,
  rotation: FiRotateCw,
  text: FiType,
};
const imageControls = {
  duration: FiClock,
  stickers: FaRegSmile,
  volume: FiVolume2,
  rotation: FiRotateCw,
  text: FiType,
};

const images = [
  {
    key: 'text',
    value: TextFeature,
    text: `
Text allows you to add text on your clips. Please log into Celebrate on your computer to use this feature.`,
  },
  {
    key: 'trim',
    value: TrimFeature,
    text: `
Trim allows you to shorten clips to keep the best part. Please log into Celebrate on your computer to use this feature.`,
  },
  {
    key: 'volume',
    value: VolumeFeature,
    text: `
Volume allows you to adjust the sound coming from the video or the music track. Please log into Celebrate on your computer to use this feature.`,
  },
];
function Nav({
  id,
  type,
  currentControl,
  onClick,
  onRemove,
  onDuplicate,
  isFromMobile,
  moveToTimeline,
}) {
  const { width } = useWindowSize();
  const [showScroller, setShowScroller] = useState(width < 600);
  const [disabledOptions, setDisabledOptions] = useState(-1);
  const horizontalRef = useRef();
  const horizontalRightRef = useRef();

  return (
    <NavParent isFromMobile={isFromMobile}>
      {width < 600 && (
        <div id="left-tab" ref={horizontalRightRef} style={{ color: '#fff' }}>
          2
        </div>
      )}
      {width < 600 && (
        <Box
          backgroundColor="#ECF0F6"
          id="container-tabs"
          sx={{
            borderRadius: '4px',
            position: 'fixed',
            bottom: '90px',
            right: showScroller && '25px',
            left: !showScroller && '15px',
            transform: !showScroller && 'rotate(180deg)',
          }}
          onClick={() => {
            if (showScroller) horizontalRef.current.scrollIntoView({ behavior: 'smooth' });
            else horizontalRightRef.current.scrollIntoView({ behavior: 'smooth' });
            setShowScroller(!showScroller);
          }}
          size={32}
          as={BsArrowRight}
          color="#3B4084"
        />
      )}

      {type.includes('video') &&
        Object.keys(videoControls).map((control) =>
          isFromMobile &&
          (control === 'text' || control === 'volume' || control === 'stickers') ? null : (
            <NavButton
              icon={videoControls[control]}
              onClick={() => onClick(control)}
              selected={currentControl === control}
              key={`control-${control}-${id}`}
              disabledStyleProps={
                isFromMobile && (control === 'text' || control === 'volume')
                  ? disabledButtonStyleProps
                  : null
              }
              minWidth={isFromMobile && '110px'}
              mr={isFromMobile && 1}
              p={isFromMobile ? 1 : 2}
            >
              {control}
            </NavButton>
          )
        )}

      <AnimatedDialog
        contentProps={{ width: width < 500 ? '90vw' : '80vw', maxWidth: 640 }}
        isOpen={disabledOptions !== -1}
        onDismiss={() => setDisabledOptions(-1)}
      >
        {disabledOptions > -1 && (
          <Box width="100%" alignSelf="baseline" mt={-20}>
            <Image src={images[disabledOptions].value} width={3048} height={2024} />
            <Text p={2} fontWeight={400} lineHeight="24px" fontSize={16}>
              {images[disabledOptions].text}
            </Text>
          </Box>
        )}
      </AnimatedDialog>
      {type.includes('image') &&
        Object.keys(imageControls).map((control) =>
          isFromMobile &&
          (control === 'text' || control === 'volume' || control === 'stickers') ? null : (
            <NavButton
              icon={imageControls[control]}
              onClick={() => onClick(control)}
              selected={currentControl === control}
              key={`control-${control}-${id}`}
              disabledStyleProps={
                isFromMobile && (control === 'text' || control === 'volume')
                  ? disabledButtonStyleProps
                  : null
              }
              minWidth={isFromMobile && '110px'}
              mr={isFromMobile && 1}
              p={isFromMobile ? 1 : 2}
            >
              {control}
            </NavButton>
          )
        )}
      <DuplicateNavButton
        onDuplicate={onDuplicate}
        isFromMobile={isFromMobile}
        minWidth={isFromMobile && '110px'}
        mr={isFromMobile && 1}
      />
      <EditClipDialogButton
        title={moveToTimeline ? 'Move to timeline' : 'Remove clip from video?'}
        description={
          moveToTimeline
            ? 'This clip will be moved to your timeline'
            : 'This clip will be moved to your clipboard'
        }
        onConfirm={onRemove}
        confirmLabel={moveToTimeline ? 'Move Clip' : 'Remove clip'}
        cancelLabel="Keep clip"
        isFromMobile={isFromMobile}
      >
        {(onShow) => (
          <NavButton
            onClick={onShow}
            icon={moveToTimeline ? FaArrowUp : FaArrowDown}
            minWidth={isFromMobile ? '110px' : '170px'}
            p={isFromMobile ? 1 : 0}
          >
            {moveToTimeline ? 'Move to Timeline' : 'Move to clipboard'}
          </NavButton>
        )}
      </EditClipDialogButton>
      {isFromMobile ? (
        <>
          <NavButton
            icon={imageControls.stickers}
            onClick={() => setDisabledOptions(0)}
            selected={false}
            key={`control-${'stickers'}-${id}`}
            disabledStyleProps={{ ...disabledButtonStyleProps, ml: 1 }}
            minWidth={isFromMobile && '110px'}
            mr={isFromMobile && 1}
            p={isFromMobile ? 1 : 2}
          >
            stickers
          </NavButton>
          <NavButton
            icon={imageControls.text}
            onClick={() => setDisabledOptions(0)}
            selected={false}
            key={`control-${'text'}-${id}`}
            disabledStyleProps={{ ...disabledButtonStyleProps, ml: 1 }}
            minWidth={isFromMobile && '110px'}
            mr={isFromMobile && 1}
            p={isFromMobile ? 1 : 2}
          >
            text
          </NavButton>
          <NavButton
            icon={imageControls.volume}
            onClick={() => setDisabledOptions(2)}
            selected={false}
            key={`control-${'volume'}-${id}`}
            disabledStyleProps={disabledButtonStyleProps}
            minWidth={isFromMobile && '110px'}
            mr={isFromMobile && 1}
            p={isFromMobile ? 1 : 2}
          >
            volume
          </NavButton>
          {width < 600 && (
            <div id="right-tab" ref={horizontalRef} style={{ color: '#fff' }}>
              1
            </div>
          )}
        </>
      ) : null}
    </NavParent>
  );
}

Nav.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  currentControl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Nav;
