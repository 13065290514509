import React from 'react';
import { Box, Flex, Text } from 'rebass';
import Banner from '@/components/app/Banner';
import { supportedAudioTypes, supportedImageTypes, supportedVideoTypes } from '@/lib/constants';
import AnimatedDialog from './AnimatedDialog';
import Grid from './Grid';

const fieldHeading = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'left',
  letterSpacing: '0em',
};

function InvalidFileError({ errorFiles, onDismiss, supportedTypes }) {
  return (
    <AnimatedDialog
      contentProps={{ width: '80vw', maxWidth: 640 }}
      isOpen={errorFiles.length > 0}
      onDismiss={onDismiss}
    >
      <Box px={2}>
        <Banner
          color="pinks.5"
          bg="pinks.1"
          heading="Celebrate cannot open these files:"
          text={errorFiles.map((err) => (
            <Flex justifyContent="left" alignItems="center" mt={2} key={err}>
              <Box
                sx={{
                  background: '#BB3763',
                  borderRadius: '50%',
                  height: '5px',
                  width: '5px',
                  mr: 2,
                }}
              />
              <Text>{err.name}</Text>
            </Flex>
          ))}
          size="xsmall"
          mb={2}
        />
        <Text
          sx={{
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            color: '#222222',
          }}
        >
          Please check that the name of the file ends in one of the extensions we support.
        </Text>
        <Box justifyContent="center" my={2}>
          {supportedTypes.includes('image') && (
            <Box mt={2}>
              <Text sx={fieldHeading}>File extensions for images</Text>
              <Grid gridTemplateColumns="auto auto auto auto auto">
                {supportedImageTypes.map((ext) => (
                  <Text py={2} mb="0.5px" key={ext}>
                    {ext}
                  </Text>
                ))}
              </Grid>
            </Box>
          )}
          {supportedTypes.includes('video') && (
            <Box mt={2}>
              <Text sx={fieldHeading}>File extensions for videos</Text>
              <Grid gridTemplateColumns="auto auto auto auto auto">
                {' '}
                {supportedVideoTypes.map((ext) => (
                  <Text py={2} mb="0.5px" key={ext}>
                    {ext}
                  </Text>
                ))}
              </Grid>
            </Box>
          )}
          {supportedTypes.includes('audio') && (
            <Box mt={2}>
              <Text sx={fieldHeading}>File types for audio</Text>
              <Grid gridTemplateColumns="auto auto auto auto auto">
                {supportedAudioTypes.map((ext) => (
                  <Text py={2} mb="0.5px" key={ext}>
                    {ext}
                  </Text>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </AnimatedDialog>
  );
}

export default InvalidFileError;
