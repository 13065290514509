import React from 'react';

// eslint-disable-next-line react/prop-types
function UnderlineImage({ fill = '#414143' }) {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 14C10.31 14 13 11.31 13 8V0H10.5V8C10.5 9.93 8.93 11.5 7 11.5C5.07 11.5 3.5 9.93 3.5 8V0H1V8C1 11.31 3.69 14 7 14ZM0 16V18H14V16H0Z"
        fill={fill}
      />
    </svg>
  );
}

export default UnderlineImage;
