import React, { useState } from 'react';
import store from 'store';
import { useWindowSize } from 'react-use';
import PropTypes from 'prop-types';
import { Box, Text, Heading } from 'rebass';
import Clip from '@/components/Clip';
// eslint-disable-next-line import/no-cycle
import EditClip from '@/components/EditClip';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import { sortPosition } from '@/lib/helper';
import { videoGuestBookTemplateId } from '@/lib/constants';
import { useTimelineClips } from '@/hooks/clip';
import SortableClips from './SortableClips';
import { useGetSelectedTemplate } from '../CelebrationForm/hooks';
import { mobileModalCSS } from '../Printables/ReusableComponents';
import { LoadingBg, Spinner } from '../app/Loading';

export function TimelineClips({ isFromMobile, timelineClips, ...props }) {
  if (timelineClips.length > 0)
    return <ClipsList clips={timelineClips} isFromMobile={isFromMobile} {...props} />;

  return (
    <Box p={isFromMobile ? 2 : 3} textAlign="left">
      {isFromMobile ? (
        <>
          <Heading>Welcome to your Editing Suite</Heading>
          <Text mt={2} fontSize={15}>
            <b>Timeline: </b>This is your Timeline. You can change the order and edit each clip
            here.
            <br />
            <br />
            <b>Clipboard: </b>The clipboard is at the bottom. Video and pictures contributed by
            friends will show up there first, as will any video/pix you add yourself.
            <br />
            <br />
            You can move clips back and forth between the Timeline and Clipboard. Only the clips in
            your Timeline will appear in your processed Celebrate Video.
          </Text>
        </>
      ) : (
        <Box bg="lightGreys.0" p={1} sx={{ borderRadius: 'xLarge' }}>
          <Box
            sx={{
              bg: 'white',
              borderColor: 'lightGreys.1',
              borderStyle: 'dashed',
              borderRadius: 'xLarge',
              textAlign: 'center',
            }}
            p={2}
          >
            <Heading className="dm-sans" color="purples.7" fontWeight="bold" lineHeight={1.2}>
              This is the Timeline
            </Heading>
            <Text className="dm-sans" lineHeight={1.2}>
              Add video and pictures from your clipboard below. Drag and Drop to reorder. Tap a clip
              to edit.
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function ClipsList({ clips, isFromMobile, clipBoardHeight, onClickClip, clipsLoading }) {
  const selectedTemplate = useGetSelectedTemplate();
  const timelineClips = useTimelineClips(clips);
  const isFromVideoGuestBook = selectedTemplate?.id === videoGuestBookTemplateId;
  const [showEdit, setShowEdit] = useState(null);

  const { width, height } = useWindowSize();

  const clipWidth = (width / 100) * (width < 600 ? 27 : 22);
  const clipHeight = clipWidth * (9 / 16);

  return (
    <Box p={isFromMobile ? 0 : 3} pt={1} sx={{ position: 'relative' }}>
      {clipsLoading && (
        <LoadingBg
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            zIndex: 5,
          }}
        >
          <Spinner />
        </LoadingBg>
      )}
      {clips.length > 0 && !isFromMobile && (
        <Text color="purples.7" my={1}>
          <span style={{ fontWeight: 'bold' }}>Timeline: </span>Tap a clip to edit. Drag and Drop to
          reorder.
        </Text>
      )}

      <SortableClips
        clips={clips.sort(sortPosition())}
        style={{
          display: 'grid',
          gridGap: 12,
          gridTemplateColumns: isFromMobile
            ? `repeat(auto-fill, ${clipWidth}px)`
            : 'repeat(auto-fill, 153px)',
        }}
        handle={isFromMobile && '.handle'}
        isFromTimeline
      >
        {(list) =>
          list.map(
            (clip, index) =>
              clip && (
                <Clip
                  index={index}
                  id={clip.id}
                  isFromVideoGuestBook={isFromVideoGuestBook}
                  type={clip.type}
                  height={isFromMobile ? clipHeight : 82}
                  width={isFromMobile ? clipWidth : 153}
                  minWidth={isFromMobile && clipWidth}
                  isFromMobile={isFromMobile}
                  onDragStart={(ev, isFromTouch) => {
                    if (store.get('ClipPlaying')) store.remove('ClipPlaying');
                    onClickClip();
                    store.set('isDragging', true);
                    if (isFromTouch) {
                      const touch = ev.changedTouches[0];
                      if (touch.screenY > height - clipBoardHeight)
                        store.set(
                          'TimeLine clip',
                          JSON.stringify({ id: clip.id, type: clip.type })
                        );
                    } else
                      ev.dataTransfer.setData(
                        'TimeLine clip',
                        JSON.stringify({ id: clip.id, type: clip.type })
                      );
                  }}
                  key={clip.id}
                  showName
                  onClick={() => {
                    setShowEdit({
                      ...clip,
                      ...timelineClips.filter(({ id }) => id === clip.id)[0],
                    });
                    if (store.get('ClipPlaying')) store.remove('ClipPlaying');
                    onClickClip();
                  }}
                />
              )
          )
        }
      </SortableClips>
      <AnimatedDialog
        isOpen={!!showEdit}
        onDismiss={() => {
          if (store.get('PreviewClipPlaying')) store.remove('PreviewClipPlaying');
          setShowEdit(null);
        }}
        title="Edit Submission Dialog"
        contentCSS={isFromMobile && mobileModalCSS()}
        contentProps={{
          width: '100%',
          maxWidth: !isFromMobile && 900,
        }}
        mt={isFromMobile ? 0 : undefined}
        isFullScreenModal={isFromMobile}
        isFromMobile={isFromMobile}
      >
        <EditClip
          id={showEdit && showEdit.id}
          type={showEdit && showEdit.type}
          clipDuration={showEdit && { startAt: showEdit.startAt, endAt: showEdit.endAt }}
          onDismiss={() => setShowEdit(null)}
          isFromMobile={isFromMobile}
        />
      </AnimatedDialog>
    </Box>
  );
}

ClipsList.propTypes = {
  clips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      position: PropTypes.number,
    })
  ).isRequired,
  onClickClip: PropTypes.func.isRequired,
};

export default ClipsList;
