/* eslint-disable import/no-cycle */
import React, { useState, useRef } from 'react';
import { Box, Flex, Text } from 'rebass';
import useOnClickOutside from 'use-onclickoutside';
import { FiAlignLeft, FiAlignCenter, FiAlignRight } from 'react-icons/fi';
import FontStyleChanges from './FontStyleChanges';
import ShowBottomSheet from '../app/ShowBottomSheet';

function AlignmentEditorValues({ name }) {
  return (
    <Flex
      py={2}
      px={1}
      width={150}
      justifyContent="space-around"
      textAlign="center"
      alignItems="center"
    >
      <FontStyleChanges
        width="unset"
        name={name}
        defaultValue="left"
        label={<FiAlignLeft color="#414143" size={24} />}
      />
      <FontStyleChanges
        width="unset"
        name={name}
        defaultValue="center"
        label={<FiAlignCenter color="#414143" size={24} />}
      />
      <FontStyleChanges
        width="unset"
        name={name}
        defaultValue="right"
        label={<FiAlignRight color="#414143" size={24} />}
      />
    </Flex>
  );
}

function AlignmentEditor({ name, width, isFromMobileScreen }) {
  const ref = useRef();
  const [isOpen, setOpen] = useState(false);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      setOpen(false);
    }
  });

  return (
    <Box
      ml={1}
      width={width}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => setOpen(!isOpen)}
      >
        <FiAlignLeft color="#414143" size={24} />
        <Text className="posterEditiorNavText">Alignment</Text>
      </Flex>

      {isFromMobileScreen ? (
        <ShowBottomSheet
          heading="Text Alignment"
          ref={ref}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        >
          <AlignmentEditorValues name={name} />
        </ShowBottomSheet>
      ) : (
        <Box
          ref={ref}
          py={1}
          bg="white"
          ml="-65px"
          sx={{
            position: 'absolute',
            display: isOpen ? 'block' : 'none',
            mt: '10px',
            boxShadow: 'medium',
            borderRadius: 'medium',
            borderSize: '3px',
            borderStyle: 'solid',
            borderColor: 'greys.2',
            zIndex: 10,
          }}
        >
          <AlignmentEditorValues name={name} />
        </Box>
      )}
    </Box>
  );
}

export default AlignmentEditor;
