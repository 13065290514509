import React from 'react';
import Head from 'next/head';

function TextClipFonts() {
  return (
    <Head>
      <link
        href="https://fonts.googleapis.com/css2?family=Dancing+Script&family=Lobster&family=Merriweather&family=Montserrat&family=Open+Sans&family=Permanent+Marker&family=Roboto&family=Rock+Salt&family=Rowdies&family=Shadows+Into+Light&display=swap"
        crossOrigin="anonymous"
        rel="stylesheet"
      />
    </Head>
  );
}

export default TextClipFonts;

export const fontOptions = [
  { label: 'Monospace', value: 'monospace !important' },
  { label: 'Serif', value: 'serif !important' },
  { label: 'Sans-serif', value: 'sans-serif !important' },
  { label: 'Roboto', value: "'Roboto', sans-serif" },
  { label: 'Open Sans', value: "'Open Sans', sans-serif" },
  { label: 'Montserrat', value: 'Montserrat !important' },
  { label: 'Rowdies', value: 'Rowdies !important' },
  { label: 'Merriweather', value: 'Merriweather !important' },
  { label: 'Dancing Script', value: 'Dancing Script !important' },
  { label: 'Lobster', value: 'Lobster !important' },
  { label: 'Shadows Into Light', value: 'Shadows Into Light !important' },
  { label: 'Permanent Marker', value: 'Permanent Marker !important' },
  { label: 'Rock Salt', value: 'Rock Salt !important' },
];
