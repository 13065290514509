/* eslint-disable import/no-cycle, no-param-reassign, no-unused-vars */
import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import ColorField from '../app/formik/ColorField';
import FontSizeField from '../app/formik/FontSizeField';
import SelectField from '../app/formik/SelectField';
import AlignmentEditor from './AlignmentEditor';
import FontStyleChanges from './FontStyleChanges';
import BoldImage from '../app/Svgs/BoldImage';
import ItalicImage from '../app/Svgs/ItalicImage';
import UnderlineImage from '../app/Svgs/UnderlineImage';

function FormikTextEditor({
  showEditor,
  initialValues,
  handleSave,
  options,
  isFromMobileScreen,
  isCursorInsideEditor,
}) {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSave} enableReinitialize>
      {({ values }) => (
        <Form>
          {showEditor && (
            <Flex
              alignItems="center"
              marginRight={1}
              data-tut="CHANGE_COLOUR"
              onMouseEnter={() => {
                isCursorInsideEditor = true;
              }}
              onMouseLeave={() => {
                isCursorInsideEditor = false;
              }}
            >
              <ColorField name="text-color" label="Text Color" mr={1} pb={0} isFromSubmitForm />
              <FontSizeField
                name="font-size"
                label="Font size"
                mr={1}
                pb={0}
                isFromMobileScreen={isFromMobileScreen}
                isFromSubmitForm
              />
              <SelectField
                name="font"
                label="Font Family"
                maxWidth={360}
                width={160}
                pb={0}
                background="white"
                options={options}
                selectProps={{
                  menuPlacement: 'bottom',
                  styles: {
                    option: (provided, state) => ({
                      ...provided,
                      fontFamily: state.value,
                      color: state.isSelected ? '#16161D' : 'black',
                    }),
                  },
                }}
                isFromSubmitForm
              />
              {values['font-weight'] !== undefined && (
                <FontStyleChanges
                  ml={1}
                  name="font-weight"
                  defaultValue="bold"
                  label={<BoldImage fill="#414143" />}
                />
              )}
              {values['font-style'] !== undefined && (
                <FontStyleChanges
                  ml={1}
                  name="font-style"
                  defaultValue="italic"
                  label={<ItalicImage fill="#414143" />}
                />
              )}
              {values['font-decoration'] !== undefined && (
                <FontStyleChanges
                  ml={1}
                  name="font-decoration"
                  defaultValue="underline"
                  label={<UnderlineImage fill="#414143" />}
                />
              )}
              {values['font-alignment'] !== undefined && (
                <AlignmentEditor name="font-alignment" currentValue={values['font-alignment']} />
              )}
            </Flex>
          )}
        </Form>
      )}
    </Formik>
  );
}

FormikTextEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  showEditor: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
};

FormikTextEditor.defaultProps = {
  showEditor: null,
};

export default FormikTextEditor;
