import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Image } from 'rebass';
import { useClipTextClip } from '@/hooks/clip';
import { useImage } from '@/hooks/image';

function RenderImage({ clip, size, ...props }) {
  const image = useImage({ id: get(clip, 'image.id'), size });

  if (!image) return false;

  return <Image src={image} {...props} />;
}

function TextClipImage({ id, size, ...props }) {
  const clips = useClipTextClip({ id });

  if (!clips.length) return false;

  return clips.map((cli) => <RenderImage key={cli.id} clip={cli} size={size} {...props} />);
}

TextClipImage.defaultProps = { size: 1 };
TextClipImage.propTypes = { size: PropTypes.number };

export default TextClipImage;
