/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import { Text, Flex, Box } from 'rebass';
import { Input } from '@rebass/forms';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import TextareaAutosize from 'react-textarea-autosize';
import { checkIsIpadUser } from '@/lib/helper';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import BufferingOverlay from '../VideoPreview/BufferingOverlay';

function TextLayer({
  isCurrentLayerSelected,
  onClickOutside,
  zoom,
  isCursorInsideEditor,
  allSupportedFontsList,
  onFlipDeletion,
  setIsEditing,
  isDeleteIconOpen,
  setIsDeleIconOpen,
  ...props
}) {
  const ref = useRef();
  const inputRef = useRef();
  const [selectedLayer, setSelectedLayer] = useLayerSelection();

  const [isDeletingFlipSide, setIsDeletingFlipSide] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [fontAlignment, setFontAlignment] = useState(null);
  const [textStyleValue, setTextStyleValue] = useState(null);

  useEffect(() => {
    if (props.text) setTextValue(props.enabled ? props.text : '');
  }, [props.text]);

  useEffect(() => {
    setFontAlignment(props['font-alignment']);
  }, [props['font-alignment']]);

  useEffect(() => {
    if (!isCurrentLayerSelected && inputRef?.current) inputRef?.current?.blur();

    let fontStyle = 'normal';
    if (isCurrentLayerSelected && selectedLayer?.supportsItalic)
      fontStyle = selectedLayer?.['font-style'];
    else fontStyle = props?.['font-style'];

    setTextStyleValue({
      fontWeight: `${(isCurrentLayerSelected ? selectedLayer : props)['font-weight']}`,
      fontSize: `${(isCurrentLayerSelected ? selectedLayer : props)['font-size']}`,
      fontFamily: (isCurrentLayerSelected ? selectedLayer : props)['font-name'],
      color: (isCurrentLayerSelected ? selectedLayer : props)['font-colour'],
      fontStyle,
      'font-decoration': (isCurrentLayerSelected ? selectedLayer : props)['font-decoration'],
    });
  }, [isCurrentLayerSelected]);

  function getFontSize() {
    if (selectedLayer?.['font-size'] && selectedLayer?.['font-size']?.includes('px'))
      return selectedLayer?.['font-size'].replaceAll('px', '');
    return selectedLayer?.['font-size'];
  }

  const isIpadUser = checkIsIpadUser();
  const fontSize = isCurrentLayerSelected
    ? getFontSize()
    : textStyleValue?.fontSize?.replaceAll('px', '');

  const isForFlipForIdeas = props?.name.includes('flip');
  const isDeleteIconVisible = isDeleteIconOpen && isForFlipForIdeas;

  const deleteTextLayer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeletingFlipSide(true);
    await onFlipDeletion();
    setIsDeletingFlipSide(false);
  };

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const selectedFontDetails = allSupportedFontsList.find(
        (list) => list.label === props['font-name']
      );
      const { supportsBold, supportsItalic } = selectedFontDetails || {
        supportsBold: '',
        supportsItalic: '',
      };

      const shouldEdit = selectedLayer?.name === props?.name;

      inputRef.current[shouldEdit ? 'focus' : 'blur']();
      setIsEditing(shouldEdit);

      setIsDeleIconOpen(false);

      setSelectedLayer({ ...props, text: e.target.value, supportsBold, supportsItalic });
    },
    [
      allSupportedFontsList,
      isDeleteIconVisible,
      isForFlipForIdeas,
      selectedLayer,
      setIsDeleIconOpen,
    ]
  );

  return (
    <Text
      sx={{
        position: 'absolute',
        writingMode: 'horizontal-tb',
        fontFeatureSettings: `"kern" 0,"calt" 0,"liga" 0,"clig" 0,"dlig" 0,"hlig" 0`,
        WebkitFontSmoothing: 'subpixel-antialiased',
        MozOsxFontSmoothing: 'grayscale',
        WebkitTextSizeAdjust: 'auto',
        MozTextSizeAdjust: 'auto',
        msTextSizeAdjust: 'auto',
        textSizeAdjust: 'auto',
        contain: 'style',
        direction: 'ltr',
        WebkitFontKerning: 'none',
        fontKerning: 'none',
        fontSynthesis: 'none',
        WebkitFontVariantLigatures: 'none',
        fontVariantLigatures: 'none',
        letterSpacing: '0',
        overflow: 'visible',
        overflowWrap: 'break-word',
        padding: '0px !important',
        margin: '0px !important',
        MozTabSize: '4',
        OTabSize: '4',
        tabSize: '4',
        textTransform: 'none',
        fontSize: isIpadUser ? `${(fontSize / 100) * zoom}px` : `${fontSize}px`,
        fontFamily: `${textStyleValue?.fontFamily}  !important`,
        fontWeight: textStyleValue?.fontWeight,
        textAlign: fontAlignment,
        fontStyle: textStyleValue?.fontStyle,
        textDecoration: textStyleValue?.['font-decoration'] || 'none',
        color: textStyleValue?.color,
        width: `${props.width}px`,
        height: `${props.height}px`,
        verticalAlign: props['vertical-alignment'],
        zIndex: isForFlipForIdeas ? props['render-order'] + 2 : props['render-order'],
        // lineHeight: `${props['font-line-height']}px`,
        wordBreak: 'break-word',
        outline: 'none',
        left: `${props.xpos}px`,
        top: `${props.ypos}px`,
        touchAction: 'pan-x pan-y pinch-zoom',
      }}
    >
      <Box
        ref={ref}
        py={1}
        bg="#fff"
        sx={{
          position: 'absolute',
          display: isDeleteIconVisible ? 'block' : 'none',
          boxShadow: '0 0 0 1px rgba(64,87,109,0.07),0 2px 12px rgba(53,71,90,0.2)',
          borderRadius: '12px',
          pointerEvents: 'auto',
          transform: 'translate(100%, -145%)',
        }}
      >
        <BufferingOverlay isBuffering={isDeletingFlipSide} sx={{ mb: 2 }}>
          <Flex py={4} px={4} textAlign="center" alignItems="center">
            <Text
              size={250}
              color="black"
              as={RiDeleteBin5Fill}
              sx={{ cursor: isDeletingFlipSide ? 'not-allowed' : 'pointer' }}
              onClick={!isDeletingFlipSide && deleteTextLayer}
            />
          </Flex>
        </BufferingOverlay>
      </Box>
      <Input
        as={TextareaAutosize}
        ref={inputRef}
        value={textValue || ''}
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
        onBlur={(e) => {
          let updatedLayer = null;
          if (isCurrentLayerSelected) {
            updatedLayer = { ...selectedLayer, text: e.target.value };
            setSelectedLayer(updatedLayer);
          }
          if (!isCursorInsideEditor) onClickOutside(e, updatedLayer);
        }}
        onClick={handleClick}
        minHeight={`${props.height}px !important`}
        sx={{
          border: isCurrentLayerSelected || isDeleteIconVisible ? 'solid #A259FF' : 0,
          fontSize: 'inherit',
          color: 'inherit',
          fontFamily: 'inherit',
          fontStyle: 'inherit',
          fontWeight: 'inherit',
          textAlign: 'inherit',
          textTransform: 'inherit',
          textDecoration: 'inherit',
          verticalAlign: props['vertical-alignment'],
          width: 'inherit',
          height: 'inherit !important',
          padding: '0px !important',
          margin: '0px !important',
          maxWidth: '100%',
          resize: 'none',
          bg: 'inherit',
          overflow: 'hidden',
          outline: 'none',
          writingMode: 'horizontal-tb',
        }}
      />
    </Text>
  );
}

export default memo(TextLayer);
