import React, { useState, useCallback } from 'react';
import { Heading, Box, Button, Text, Flex } from 'rebass';
import useSWR, { mutate } from 'swr';
import { FiCheckCircle } from 'react-icons/fi';
// import RangeField from '@/components/app/formik/RangeField';
import { Form, Formik } from 'formik';
import { MdEdit } from 'react-icons/md';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import { useResourceRequest } from '@/hooks/api';
import ConfirmDialog from '@/components/app/ConfirmDialog';
import { useSoundTrack, useSoundTrackLockVersion } from '@/hooks/soundTracks';
import { useRemoveEntityReference } from '@/lib/entities';
import { useMobileCheckHook } from '@/hooks/celebration';
import { ax } from '@/lib/api';
import { checkIfIdExist } from '@/lib/helper';
import SoundTrackPreview from './SoundTrackPreview';
// eslint-disable-next-line import/no-cycle
import { RenderForm } from '../CelebrationDashboard/CelebrationLinks';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';

function EditTrack({ id, name, artistName, onDismiss }) {
  const {
    query: { id: celebrationId },
  } = useRouter();
  const { mutate: revalidateCelebration } = useSWR(
    checkIfIdExist(celebrationId) ? `/v3/celebrations/${celebrationId}?shallow=true` : null
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const isFromMobile = useMobileCheckHook();
  const track = useSoundTrack(id);
  const [isEditing, setIsEdit] = useState(isFromMobile);
  const createConfirmation = useCreateConfirmation();
  const lockVersion = useSoundTrackLockVersion(id);
  const removeEntityReference = useRemoveEntityReference({
    type: 'celebrations',
    id: celebrationId,
    reference: { type: 'sound-tracks', id },
  });
  const [updateTrack] = useResourceRequest(
    'patch',
    `/v3/celebrations/${celebrationId}/sound_tracks/${id}`
  );
  const [deleteTrack, { loading }] = useResourceRequest(
    'delete',
    `/v3/celebrations/${celebrationId}/sound_tracks/${id}`
  );

  const handleSubmit = useCallback(async ({ name: updatedTitle, artist }, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await ax().patch(`/v2/media/${track?.['playable-id']}`, {
        data: {
          attributes: {
            title: updatedTitle,
            'lock-version': lockVersion,
            artist,
          },
        },
      });
      revalidateCelebration();
      onDismiss();
      createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
      setSubmitting(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Box p={4} width={800} maxWidth="100%">
      {!isFromMobile && track?.['playable-type'] !== 'Song' && <Heading>Edit Track Name</Heading>}
      {isEditing || isFromMobile ? (
        <RenderForm
          isFromMobile={isFromMobile}
          celebrationId={celebrationId}
          name={name}
          artistName={artistName || ''}
          handleSubmit={handleSubmit}
          onDismiss={() => (isFromMobile ? onDismiss() : setIsEdit(false))}
        />
      ) : (
        <Flex py={4} width="100%">
          <Box width="100%">
            <SoundTrackPreview id={id} />
          </Box>
          {track?.['playable-type'] !== 'Song' && (
            <Button
              ml={2}
              variant="primary"
              fontSize={16}
              lineHeight="24px"
              fontWeight={400}
              bg="pinks.4"
              px={2}
              height={40}
              alignSelf="center"
              py={1}
              onClick={() => setIsEdit(true)}
            >
              <Text as={MdEdit} size={24} />
            </Button>
          )}
        </Flex>
      )}
      {!isFromMobile && (
        <Formik
          initialValues={{ volume: track?.volume || 0.2 }}
          validationSchema={Yup.object().shape({ volume: Yup.number().min(0).max(1).required() })}
          onSubmit={async (values) => {
            await updateTrack(values);
            mutate(`/v3/celebrations/${celebrationId}/sound_tracks`);
            onDismiss();
          }}
        >
          <Form>
            {/* <Box maxWidth={320}>          // Not Needed for now will add in future
            <RangeField
              step={0.01}
              min={0}
              max={1}
              name="volume"
              icon={IoIosVolumeLow}
              label="overall volume"
            />
          </Box> */}
            <Flex>
              <Button
                variant="outline"
                type="button"
                onClick={() => setShowConfirmDelete(true)}
                mr={2}
              >
                Delete track
              </Button>
              {/* <Button type="submit" variant="special">
              Save Changes
            </Button> */}
            </Flex>
          </Form>
        </Formik>
      )}
      <ConfirmDialog
        loading={loading}
        isOpen={showConfirmDelete}
        onConfirm={async () => {
          await deleteTrack();
          removeEntityReference();
          mutate(`/v3/celebrations/${celebrationId}/sound_tracks`);
          setShowConfirmDelete(false);
          onDismiss();
        }}
        onDismiss={() => setShowConfirmDelete(false)}
        title="Confirm Delete"
        body="Are you sure you want to delete this track?"
      />
    </Box>
  );
}

export default EditTrack;
