import React from 'react';

function HelpIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
        stroke="#606060"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6348 13.4999C13.9874 12.4974 14.6835 11.652 15.5997 11.1136C16.5159 10.5751 17.5931 10.3783 18.6405 10.5579C19.688 10.7376 20.638 11.2822 21.3224 12.0952C22.0068 12.9082 22.3813 13.9372 22.3798 14.9999C22.3798 17.9999 17.8798 19.4999 17.8798 19.4999"
        stroke="#606060"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 25.5H18.015"
        stroke="#606060"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HelpIcon;
