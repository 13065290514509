import React from 'react';

function PrintIcon({ width = '34px', height = '25px', fill = '#606060', fillOpacity = 1 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 34 25"
      version="1.1"
    >
      <g id="surface1">
        <path
          stroke="none"
          fillRule="evenodd"
          fill={fill}
          fillOpacity={fillOpacity}
          d="M 3.375 3.71875 C 3.003906 4.316406 2.746094 4.996094 2.632812 5.734375 L 0.832031 17.808594 C 0.359375 20.992188 2.808594 23.855469 6.007812 23.855469 L 27.492188 23.855469 C 30.691406 23.855469 33.140625 20.992188 32.664062 17.808594 L 30.863281 5.734375 C 30.480469 3.160156 28.28125 1.253906 25.691406 1.253906 L 7.808594 1.253906 C 5.984375 1.253906 4.355469 2.195312 3.417969 3.65625 L 3.371094 3.660156 Z M 4.207031 12.917969 L 3.417969 18.199219 C 3.238281 19.410156 3.90625 20.53125 4.9375 20.996094 Z M 7.585938 21.222656 L 27.492188 21.222656 C 29.089844 21.222656 30.316406 19.792969 30.078125 18.199219 L 28.277344 6.125 C 28.085938 4.835938 26.984375 3.882812 25.691406 3.882812 L 7.808594 3.882812 C 7.148438 3.882812 6.542969 4.132812 6.078125 4.542969 Z M 7.585938 21.222656 "
        />
        <path
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="miter"
          stroke={fill}
          strokeOpacity={fillOpacity}
          strokeMiterlimit={4}
          d="M 9.275046 11.497969 L 19.180377 11.497969 "
          transform="matrix(1.307692,0,0,1.315789,0,0)"
        />
        <path
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="miter"
          stroke={fill}
          strokeOpacity={fillOpacity}
          strokeMiterlimit={4}
          d="M 10.720818 13.769062 L 17.940717 13.769062 "
          transform="matrix(1.307692,0,0,1.315789,0,0)"
        />
        <path
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={fill}
          strokeOpacity={fillOpacity}
          strokeMiterlimit={4}
          d="M 16.480009 4.928125 C 16.342601 4.788594 16.175322 4.675781 15.993107 4.598594 C 15.813879 4.521406 15.616728 4.482812 15.422564 4.482812 C 15.225414 4.482812 15.028263 4.521406 14.849035 4.598594 C 14.66682 4.675781 14.502528 4.788594 14.362132 4.928125 L 14.072381 5.222031 L 13.785616 4.928125 C 13.504825 4.643125 13.122472 4.482812 12.725184 4.482812 C 12.327895 4.482812 11.948529 4.643125 11.667739 4.928125 C 11.386949 5.216094 11.228631 5.605 11.228631 6.00875 C 11.228631 6.4125 11.386949 6.801406 11.667739 7.089375 L 11.954504 7.383281 L 14.072381 9.541562 L 16.193244 7.383281 L 16.480009 7.089375 C 16.620404 6.946875 16.730928 6.777656 16.805607 6.593594 C 16.880285 6.406562 16.919118 6.207656 16.919118 6.00875 C 16.919118 5.806875 16.880285 5.610937 16.805607 5.423906 C 16.730928 5.239844 16.620404 5.070625 16.480009 4.928125 Z M 16.480009 4.928125 "
          transform="matrix(1.307692,0,0,1.315789,0,0)"
        />
      </g>
    </svg>
  );
}

export default PrintIcon;
