import React, { useState } from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { useRouter } from 'next/router';
import { useCelebrationRoute } from '@/hooks/routes';
import { sendTrackingEvent } from '@/lib/helper';
import { useCelebration } from '@/hooks/celebration';
import { VIDEO_GUEST_BOOK } from '@/lib/constants';
import PaymentModal from '../CelebrationHomeScreen/common/PaymentModal';

function VideoEditorHeader() {
  const {
    push,
    query: { id },
  } = useRouter();
  const bigDayRouteParams = useCelebrationRoute(id, 'publish-and-pay');
  const [showPaymentModal, setPaymentModal] = useState(false);
  const celebration = useCelebration(id);

  return (
    <Box pb={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading className="gradientHeading">The Editing Suite</Heading>
        <PaymentModal id={id} isOpen={showPaymentModal} onDismiss={() => setPaymentModal(false)} />

        <Button
          variant="special"
          className="dmSansNormal"
          type="button"
          onClick={() => {
            if (!celebration?.paid && celebration?.theme === VIDEO_GUEST_BOOK)
              setPaymentModal(true);
            else {
              sendTrackingEvent(`member_visit`, {
                context: {
                  url_text: 'Go to Last Stage',
                  url: bigDayRouteParams.as,
                },
              });
              push(bigDayRouteParams.as);
            }
          }}
        >
          Go to Last Stage
        </Button>
      </Flex>
    </Box>
  );
}

export default VideoEditorHeader;
