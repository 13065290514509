import React, { useState, useEffect, useRef } from 'react';
import { Text, Flex, Box, Button } from 'rebass';
import useSWR from 'swr';
import { useEntity } from '@/lib/entities';
import {
  useCelebration,
  usePrintableEntities,
  usePrintableTemplateEntities,
} from '@/hooks/celebration';
import { capitalizeFirstLetter } from '@/lib/helper';
import { ax } from '@/lib/api';
import VGBExampleModal from './VGBExampleModal';
import AnimatedDialog from './app/AnimatedDialog';
import TemplateRenderer from './Printables/TemplateRenderer';

function selectPrintables(tagNames, printableObjects, selectedPrintableObject) {
  let tagRelatedPrintables = tagNames.reduce((acc, curr) => {
    acc[curr] = [];
    return acc;
  }, {});

  const printableArray = Object.values(printableObjects);

  const activeTemplates = [];

  printableArray.forEach((print) => {
    const printable = { ...print, active: false };
    const printableTagNames = printable['tag-names'].map((tag) => tag.toLowerCase());

    const selectedObjects = Object.keys(selectedPrintableObject);
    printable.active =
      selectedObjects.length > 0
        ? selectedObjects.some(
            (printKey) =>
              selectedPrintableObject[printKey]?.['celebration-printable-template']?.id ===
              printable.id
          ) && printable.enabled
        : false;

    if (printable.active && printable.page === 'front') {
      activeTemplates.push({ ...printable });
    } else {
      printable.back = printableArray.find(
        (pri) => pri.id === printable['related-printable-template'].id
      );

      Object.keys(tagRelatedPrintables).forEach((tag) => {
        if (printableTagNames.includes(tag.toLowerCase()) && printable.page === 'front') {
          tagRelatedPrintables[tag].push(printable);
        }
      });
    }
  });

  if (activeTemplates.length > 0)
    tagRelatedPrintables = {
      'Currently Editing': activeTemplates,
      ...tagRelatedPrintables,
    };

  return tagRelatedPrintables;
}

function MobileTemplateSelector({ id, onClose }) {
  const { mutate: revalidatePrintables } = useSWR(
    id ? `/v3/celebrations/${id}/celebration_printables` : null
  );
  const scrollToItemRef = useRef(null);

  const celebration = useCelebration(id);

  const templateDetails = useEntity(celebration?.['celebration-template']);
  const printableTemplates = usePrintableTemplateEntities();
  const [, , printables] = usePrintableEntities();
  const [normalizedPritables, setNormalizedPrintables] = useState([]);
  const [templateToSelect, setTemplateToSelect] = useState(null);
  const [seeExample, setSeeExample] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPrintable, setSelectedPrintable] = useState(null);

  useEffect(() => {
    if (templateDetails && printableTemplates)
      setNormalizedPrintables(
        selectPrintables(templateDetails?.['tag-names'], printableTemplates, printables || {})
      );
  }, [templateDetails?.['tag-names'], printableTemplates, printables]);

  useEffect(() => {
    if (printables)
      setSelectedPrintable(
        printables[Object.keys(printables).filter((print) => printables[print].page === 'front')[0]]
      );
  }, [printables]);

  useEffect(() => {
    if (scrollToItemRef.current) {
      scrollToItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [scrollToItemRef?.current]);

  async function onTemplateSelection() {
    setIsSubmitting(true);
    if (selectedPrintable) {
      await ax().patch(`/v3/celebrations/${id}/celebration_printables/${selectedPrintable.id}`, {
        data: {
          attributes: {
            'celebration-printable-template-id': templateToSelect.id,
          },
        },
      });
    } else {
      await ax().post(`/v3/celebrations/${id}/celebration_printables`, {
        data: {
          attributes: {
            'celebration-printable-template-id': templateToSelect.id,
          },
        },
      });
    }
    setIsSubmitting(false);
    revalidatePrintables();
    onClose(templateToSelect.id);
  }

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" mb="60px">
      <Text
        sx={{
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '23px',
          letterSpacing: '-0.022em',
          textAlign: 'center',
          color: '#16161D',
          mt: 2,
        }}
      >
        Create your printables!
      </Text>
      <AnimatedDialog
        isOpen={!!templateToSelect}
        contentProps={{ width: '90vw', maxWidth: '900px' }}
        onDismiss={() => setTemplateToSelect(null)}
        allowPinchAndZoom
      >
        <Flex justifyContent="center" alignItems="center" flexDirection="column">
          <Text
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '23px',
              letterSpacing: '-0.022em',
              textAlign: 'center',
            }}
          >
            {templateToSelect?.name.split('-')[0]}
          </Text>
          <Flex justifyContent="space-around" my={4}>
            <Box>
              <TemplateRenderer isFromPreview isSubmitting={isSubmitting} {...templateToSelect} />
              <Text sx={{ fontSize: '15px', fontWeight: 700, textAlign: 'center' }}>
                {capitalizeFirstLetter(templateToSelect?.page)}
              </Text>
            </Box>
            <Box>
              <TemplateRenderer
                isFromPreview
                isSubmitting={isSubmitting}
                {...templateToSelect?.back}
              />
              <Text sx={{ fontSize: '15px', fontWeight: 700, textAlign: 'center' }}>
                {capitalizeFirstLetter(templateToSelect?.back.page)}
              </Text>
            </Box>
          </Flex>
          <Button variant="special" onClick={onTemplateSelection} margin="auto" mb={3}>
            Select this template
          </Button>
        </Flex>
      </AnimatedDialog>
      <VGBExampleModal
        isOpen={seeExample}
        contentProps={{ width: '90vw', maxWidth: '900px' }}
        onDismiss={() => setSeeExample(false)}
        onDone={() => setSeeExample(false)}
      />
      <Text
        textAlign="center"
        fontWeight={400}
        fontSize="15px"
        lineHeight="140%"
        p={2}
        color="#8E8E8E"
      >
        We recommend doing this at least 1-2 weeks before the event to account for printing time.{' '}
        <br />
        <Text
          as="span"
          color="#7B33FB"
          onClick={() => setSeeExample(true)}
          sx={{ textDecorationLine: 'underline' }}
        >
          See an example
        </Text>
      </Text>
      {Object.keys(normalizedPritables).map((normalizeProduct) => (
        <Box mr="auto" key={normalizeProduct}>
          <Text fontWeight={700} fontSize="20px" lineHeight="140%" p={2}>
            {capitalizeFirstLetter(normalizeProduct)}
          </Text>
          <Flex
            sx={{
              display: 'grid !important',
              gridAutoFlow: 'column',
              flexGrow: 1,
              width: '100vw',
              overflowX: 'auto',
            }}
          >
            {normalizedPritables[normalizeProduct].map((template) => (
              <Box width="120px" ref={template?.active ? scrollToItemRef : null}>
                <TemplateRenderer
                  {...template}
                  key={template.id}
                  onTemplateSelection={() => {
                    if (template?.active) onClose(template.id);
                    else setTemplateToSelect(template);
                  }}
                  isSelected={template?.active}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      ))}
    </Flex>
  );
}

function MobileVGBTemplateSelector({ showMobileVersion, children, id, onClose }) {
  if (showMobileVersion) return <MobileTemplateSelector id={id} onClose={onClose} />;

  return children;
}

MobileVGBTemplateSelector.propTypes = {};
MobileVGBTemplateSelector.defaultProps = {};

export default MobileVGBTemplateSelector;
