import React, { useState } from 'react';
import { Heading, Box, Button, Text } from 'rebass';
import Grid from '@/components/app/Grid';
import AnimatedDialog from '@/components/app/AnimatedDialog';

function EditClipDialogButton({
  title,
  description,
  onConfirm,
  cancelLabel,
  confirmLabel,
  children,
  loading,
  isFromMobile,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <AnimatedDialog
        onDismiss={() => setIsOpen(false)}
        isOpen={isOpen}
        contentCSS={isFromMobile && 'width: 100%;'}
      >
        <Box p={isFromMobile ? 3 : 7} pt={0} width={isFromMobile ? 'auto' : 800}>
          <Heading pb={4} variant="headings.h2">
            {title}
          </Heading>
          <Text fontSize={2} pb={5}>
            {description}
          </Text>
          <Grid
            gridTemplateColumns={isFromMobile ? 'auto auto' : 'max-content max-content'}
            gridAutoFlow="column"
            gridGap={2}
          >
            <Button
              type="button"
              disabled={loading}
              variant="outline"
              onClick={() => setIsOpen(false)}
              p={isFromMobile && 1}
            >
              {cancelLabel || 'Cancel'}
            </Button>
            <Button
              disabled={loading}
              type="button"
              onClick={async () => {
                await onConfirm();
                setIsOpen(false);
              }}
              p={isFromMobile && 1}
            >
              {confirmLabel || 'Confirm'}
            </Button>
          </Grid>
        </Box>
      </AnimatedDialog>
      {children(async () => {
        await onConfirm();
      })}
    </>
  );
}

export default EditClipDialogButton;
