import PropTypes from 'prop-types';
import React from 'react';
import { Text, Flex } from 'rebass';

function UnorderedList({ items = [], marginLeft = '30px' }) {
  return (
    <ol style={{ listStyle: 'none', marginLeft }}>
      {items.map((item, index) => (
        <li key={item}>
          <Flex>
            {index + 1}.&nbsp;<Text ml="5px">{item}</Text>
          </Flex>
        </li>
      ))}
    </ol>
  );
}

UnorderedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UnorderedList;
