import React, { useState, useMemo, useEffect } from 'react';
import useSWR from 'swr';
import normalize from 'jsonapi-normalizer';
import { FiCheckCircle, FiMusic } from 'react-icons/fi';
import { Flex, Button, Box, Heading, Text } from 'rebass';
import { useRouter } from 'next/router';
import { useEntity, useReceivePayload, useRemoveEntityReference } from '@/lib/entities';
import Grid from '@/components/app/Grid';
import { useResourceRequest } from '@/hooks/api';
import AudioPlayer from '@/components/app/AudioPlayer';

import { checkIfIdExist } from '@/lib/helper';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';

function Song({ id, isFromMobile, soundTracks, isPlaying, setIsPlaying }) {
  const {
    query: { id: celebrationId },
  } = useRouter();
  const song = useEntity({ id, type: 'songs' });
  const audio = useEntity(song.audio);
  const receivePayload = useReceivePayload();
  const createConfirmation = useCreateConfirmation();
  const [isSelected, setIsSelected] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);

  const { mutate: revalidateSongs } = useSWR(`/v3/celebrations/${celebrationId}/sound_tracks`);
  const { mutate: revalidateCelebration } = useSWR(
    checkIfIdExist(celebrationId) ? `/v3/celebrations/${celebrationId}?shallow=true` : null
  );

  const removeEntityReference = useRemoveEntityReference({
    type: 'celebrations',
    id: celebrationId,
    reference: { type: 'sound-tracks', id },
  });

  const [deleteTrack] = useResourceRequest(
    'delete',
    `/v3/celebrations/${celebrationId}/sound_tracks/${selectedSong && selectedSong.id}`
  );

  const [createSoundTrack, { data }] = useResourceRequest(
    'post',
    `/v3/celebrations/${celebrationId}/sound_tracks`
  );

  useEffect(() => {
    if (soundTracks) {
      const selectedSongIndex = soundTracks.findIndex((track) => track?.['playable-id'] === id);
      setIsSelected(selectedSongIndex > -1);
      setSelectedSong(soundTracks[selectedSongIndex]);
    }
  }, [soundTracks]);

  async function deleteSong() {
    setActionLoading(true);
    await deleteTrack();
    removeEntityReference();
    revalidateSongs();
    revalidateCelebration();
    createConfirmation({ icon: FiCheckCircle, message: 'Song Deleted' });
    setActionLoading(false);
  }

  const addSoundtrack = async () => {
    setActionLoading(true);
    const track = await createSoundTrack({
      'playable-type': 'Song',
      'playable-id': id,
      position: soundTracks.length === 0 ? 0 : soundTracks[0].position - 1,
    });
    receivePayload(track);
    revalidateSongs();
    revalidateCelebration();
    setActionLoading(false);
    // onFinished();
  };

  useEffect(() => {
    if (data) {
      createConfirmation({ message: 'Track Added', icon: FiMusic });
    }
  }, [data]);

  return (
    <Box pb={isFromMobile ? 0 : 2} pt={isFromMobile && 2}>
      <Grid alignItems="center" gridTemplateColumns="max-content max-content" gridGap={2} pb={2}>
        <Heading variant="headings.h5">{song.title}</Heading>
      </Grid>
      <AudioPlayer
        id={id}
        src={audio?.['asset-url']}
        isFromMobile={isFromMobile}
        addSong={addSoundtrack}
        deleteSong={deleteSong}
        isSelected={isSelected}
        uploading={actionLoading}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />
    </Box>
  );
}

const SongCategoryName = ({ id }) => {
  const category = useEntity({ id, type: 'categories' });
  if (!category) return false;
  return category ? category.name : '';
};

function SongsLibrary({ isFromMobile, soundTracks, onFinished }) {
  const { data } = useSWR('/v3/songs');
  const [currentCategoryId, setCurrentCategoryId] = useState('');
  const entitySong = useEntity({ id: data ? data.data.data[0].id : '', type: 'songs' });

  const [isPlaying, setIsPlaying] = useState(null);

  const normalized = useMemo(() => {
    if (!data) return undefined;
    const normalizedData = normalize(data.data);
    if (!currentCategoryId)
      setCurrentCategoryId(
        Object.keys(normalizedData.entities.categories).filter(
          (category) => normalizedData.entities.categories[category].name === 'Happy'
        )[0]
      );

    return normalizedData;
  }, [data]);

  const currentSongs = useMemo(() => {
    if (!normalized) return [];
    return Object.values(normalized.entities.songs).filter((song) =>
      (song.categories || []).find((category) => category.id === currentCategoryId)
    );
  }, [normalized, currentCategoryId]);

  if (!entitySong) return false;

  return (
    <Box p={isFromMobile ? 2 : 4} sx={{ zIndex: 2 }} pt={0} alignSelf={isFromMobile && 'baseline'}>
      {isFromMobile ? (
        <Box overflowX="auto">
          <Flex width="min-content" pb={1}>
            {Object.keys(normalized.entities.categories).map((id) => (
              <Button
                variant={id === currentCategoryId ? 'primary' : 'outline'}
                fontSize={1}
                px={2}
                py={1}
                width="max-content"
                type="button"
                mr="10px"
                onClick={() => setCurrentCategoryId(id)}
              >
                <SongCategoryName id={id} />
              </Button>
            ))}
          </Flex>
        </Box>
      ) : (
        <Grid gridTemplateColumns="repeat(5, 1fr)" gridGap={1} pb={4}>
          {Object.keys(normalized.entities.categories).map((id) => (
            <Button
              variant={id === currentCategoryId ? 'primary' : 'outline'}
              fontSize={1}
              px={2}
              py={1}
              type="button"
              onClick={() => setCurrentCategoryId(id)}
            >
              <SongCategoryName id={id} />
            </Button>
          ))}
        </Grid>
      )}
      <Box pb={isFromMobile && 2}>
        {!isFromMobile && (
          <>
            <Heading variant="headings.h4" pb={1}>
              All <SongCategoryName id={currentCategoryId} /> songs
            </Heading>
            <Text pb={4}>Select a song to add it to your celebration’s song list.</Text>
          </>
        )}
        {currentSongs.map((song) => (
          <Song
            key={song.id}
            id={song.id}
            isFromMobile={isFromMobile}
            soundTracks={soundTracks}
            onFinished={onFinished}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        ))}
      </Box>
    </Box>
  );
}

export default SongsLibrary;
