import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosPlay, IoIosPause } from 'react-icons/io';
import { FiDownload } from 'react-icons/fi';
import { Button, Text, Flex, Box } from 'rebass';
import { useWindowSize } from 'react-use';
import Grid from '@/components/app/Grid';
import { useEntity } from '@/lib/entities';
import { postPrivateEventDetails } from '@/lib/api';
import { useCelebrationInRoute } from '@/hooks/celebration';
import PreviewSlider from './PreviewSlider';
import VideoPreviewTime from './VideoPreviewTime';
import PaymentModal from '../CelebrationHomeScreen/common/PaymentModal';

export function CircleButton(props) {
  return (
    <Button
      p={0}
      type="button"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        borderRadius: '50%',
        outline: 'none',
      }}
      bg="purples.7"
      {...props}
    />
  );
}

function PreviewControls({
  isPlaying,
  currentSeconds,
  endSeconds,
  onPlayClick,
  onPauseClick,
  onSliderChange,
  disabled,
  showDownload,
  clip,
  individualClip,
}) {
  const [showPayment, setShowPaymentModal] = useState(false);

  const clipDetails = clip?.video || clip?.image;
  const mediaDetails = clipDetails ? useEntity(clipDetails) : null;
  const celebration = useCelebrationInRoute();
  const { width } = useWindowSize();
  const isMobile = width < 500;
  return (
    <Grid
      gridTemplateColumns={isMobile ? 'max-content 1fr max-content' : 'max-content 1fr max-content'}
      bg={individualClip && 'white'}
      justifyContent="center"
      height={48}
      alignItems="center"
      px={isMobile ? 1 : 3}
    >
      <PaymentModal
        isOpen={showPayment}
        onDismiss={() => setShowPaymentModal(false)}
        id={celebration?.id}
        celebrationDetail={celebration}
        individualClip={individualClip}
      />
      <Flex alignItems="center" justifyContent="center" alignSelf="end" mr={isMobile ? 1 : 3}>
        {isPlaying ? (
          <CircleButton onClick={onPauseClick}>
            <Text color="white" size={18} as={IoIosPause} />
          </CircleButton>
        ) : (
          <CircleButton onClick={onPlayClick} type="button" disabled={disabled}>
            <Text color="white" size={18} as={IoIosPlay} />
          </CircleButton>
        )}
      </Flex>
      <Box>
        <Flex alignItems="center" justifyContent="space-between" mb="10px" mt="5px">
          <VideoPreviewTime seconds={currentSeconds} isMobile={isMobile} />
          <VideoPreviewTime seconds={endSeconds} isMobile={isMobile} />
        </Flex>
        <PreviewSlider
          value={currentSeconds}
          min={0}
          max={endSeconds}
          step={0.016}
          onChange={onSliderChange}
          onBeforeChange={onPauseClick}
          disabled={disabled}
        />
      </Box>
      {celebration?.paid && showDownload && mediaDetails && !disabled && (
        <a
          href={`${mediaDetails['unprocessed-url']}?download=true`}
          onClick={() => {
            postPrivateEventDetails('celebration_clip_media_downloaded', {
              'subject-type': 'CelebrationClip',
              'subject-id': clip?.id,
            });
          }}
          download
        >
          <CircleButton alignSelf="end" ml={1} type="button">
            <Text color="white" size={18} as={FiDownload} />
          </CircleButton>
        </a>
      )}
      {!celebration?.paid && showDownload && mediaDetails && (
        <CircleButton
          opacity={0.5}
          onClick={() => {
            postPrivateEventDetails('celebration_tapped_pay', {
              'subject-type': 'CelebrationClip',
              'subject-id': celebration?.id,
            });
            setShowPaymentModal(true);
          }}
          alignSelf="end"
          ml={1}
          type="button"
        >
          <Text color="white" size={18} as={FiDownload} />
        </CircleButton>
      )}
    </Grid>
  );
}
PreviewControls.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  currentSeconds: PropTypes.number.isRequired,
  endSeconds: PropTypes.number.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  onPauseClick: PropTypes.func.isRequired,
  onSliderChange: PropTypes.func.isRequired,
};

export default PreviewControls;
