import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { useRouter } from 'next/router';
import RouterLink from '@/components/RouterLink';
import CelebrateLogo from '@/components/app/CelebrateLogo';
import { useMobileCheckHook } from '@/hooks/celebration';

function Header({ children, isAuthenticated }) {
  const { pathname } = useRouter();
  const isFromMobile = useMobileCheckHook();

  const [height, setHeight] = useState(96);

  useEffect(() => {
    if (isFromMobile) setHeight(60);
    else setHeight(96);
  }, [isFromMobile]);

  return (
    <Box
      as="header"
      bg="white"
      px={height === 60 ? '5px' : 4}
      sx={{
        zIndex: 3,
        borderBottom: '1px solid',
        borderColor: 'border',
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        height={height}
        sx={
          height === 60 && {
            position: 'fixed',
            width: '100%',
            backgroundColor: 'white',
            borderBottom: '1px solid',
            borderColor: '#E2E8F0',
            left: 0,
          }
        }
      >
        {height === 60 && pathname !== '/' && isAuthenticated ? (
          <Box marginLeft="auto">
            <CelebrateLogo isBlack />
          </Box>
        ) : (
          <RouterLink
            href="/"
            mr={height === 60 ? 0 : 3}
            ml={height === 60 && 'auto'}
            sx={
              !isFromMobile && {
                zoom: isFromMobile ? '15%' : '25%',
                MozTransform: `scale(${isFromMobile ? 15 : 25}%)`,
                MozTransformOrigin: 'left',
              }
            }
          >
            <CelebrateLogo isUnderLink isBlack={isFromMobile} />
          </RouterLink>
        )}
        {children}
      </Flex>
    </Box>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
