/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text, Heading } from 'rebass';
import { IoIosPlayCircle, IoIosMenu } from 'react-icons/io';
// import Footer from '@/components/Footer';
import store from 'store';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import UAParser from 'ua-parser-js';
import Header from '@/components/Header';
import RouterLink from '@/components/RouterLink';
import Grid from '@/components/app/Grid';
import DevTools from '@/components/DevTools';
import { FlashMessagesBanner } from '@/components/FlashMessages';
import { useIsCurrentUserHasUsernamePassword } from '@/hooks/auth';
import { useClearEntityCache } from '@/lib/entities';
import { viewChangePixels } from '@/lib/constants';
import VideoPreview from '@/components/VideoPreview';
import {
  useCelebrationClips,
  useCelebrationSoundTracks,
  useMobileCheckHook,
} from '@/hooks/celebration';
import { useTimelineClips } from '@/hooks/clip';
import { clearCelebrationId, getAspectRatio, sendTrackingEvent, sortPosition } from '@/lib/helper';
// eslint-disable-next-line import/no-cycle
import { LearnMoreAboutProcessing } from '@/pages/dashboard/[id]/video/index';
import { AuthContext, useAuth, useSetPostLoginRedirect } from './Auth/index';
// eslint-disable-next-line import/no-cycle
import CelebrationNav from './CelebrationDashboard/CelebrationNav';
// import { useCheckIfVideoGuestBook } from './CelebrationForm/hooks';

function VideoPreviewModal({ onDismiss, id }) {
  const c = useCelebrationClips(id);
  const clips = useTimelineClips(c);
  const soundTracks = useCelebrationSoundTracks(id);
  const { width } = useWindowSize();
  const vidHeight = getAspectRatio(width);
  const parser = new UAParser();
  const isIphoneUser = parser.getDevice().model?.toLowerCase()?.includes('iphone');

  return (
    <Box
      sx={{
        borderRadius: '0px',
        overFlowY: 'auto',
        position: 'fixed',
        height: '100vh',
        width: '100%',
        left: 0,
        top: 0,
        paddingTop: '10px',
        bg: 'white',
        zIndex: 1000,
      }}
    >
      <Flex
        px={[2, 3]}
        minHeight={[60, 80]}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ position: 'relative', zIndex: 2 }}
        width="100%"
      >
        <Text
          color="purples.7"
          onClick={onDismiss}
          sx={{
            fontWeight: 'bold',
            textDecorationLine: 'underline',
          }}
        >
          Done
        </Text>
      </Flex>
      <Heading variant="headings.h1" fontWeight={600} fontSize={5} p={2} pt={0}>
        Video Preview
      </Heading>
      <Box mt="40px">
        <LearnMoreAboutProcessing id={id} />
        <Box sx={{ objectFit: 'contain', borderRadius: 'medium', boxShadow: 'small' }}>
          <VideoPreview
            clips={clips}
            soundTracks={soundTracks
              .map((track) => ({ ...track, type: 'sound-tracks' }))
              .sort(sortPosition())}
            height={vidHeight + 130}
            width="100%"
            isFromMobile
          />
        </Box>
        {isIphoneUser && (
          <Flex py={1} px={1} textAlign="left" alignItems="center">
            <Text color="black">
              All timeline clips are shown here in order. This preview will pause before each video
              clip. Press play button to continue.
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
}

function UserNav({ pathname, id, setCloseSidebar, closeSidebar }) {
  const { width } = useWindowSize();
  const [showNav, setShowNav] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  if (width <= viewChangePixels)
    return (
      <>
        <Flex>
          {pathname === '/dashboard/[id]/video' && (
            <>
              {isPlay && (
                <VideoPreviewModal
                  onDismiss={() => {
                    setIsPlay(false);
                    store.remove('ClipPlaying');
                  }}
                  id={id}
                />
              )}
              <Flex alignItems="center">
                {/* <Text as={IoIosHome} color="purples.6" fontSize={28} mr={2} onClick={() => push('/')} /> */}
                <Flex
                  onClick={() => {
                    setIsPlay(true);
                    store.set('ClipPlaying', true);
                  }}
                  alignItems="center"
                >
                  <Text color="purples.6" mr={1}>
                    {' '}
                    Play
                  </Text>
                  <Text as={IoIosPlayCircle} color="purples.6" fontSize={28} mr={2} />
                </Flex>
              </Flex>
            </>
          )}
          <Box
            sx={{
              background: '#FFFFFF',
              cursor: 'pointer',
            }}
            as={IoIosMenu}
            pr={2}
            size={48}
            color="#342E57"
            onClick={() => setShowNav(true)}
          />
        </Flex>
        <Box
          height="100vh"
          width="100vw"
          sx={{
            position: 'fixed',
            top: 0,
            backgroundColor: showNav && 'hsla(0, 0%, 0%, 0.33)',
            left: showNav ? 0 : '100vw',
            zIndex: showNav ? 9999 : -10,
          }}
        >
          <Box
            height="100vh"
            width="100vw"
            sx={{
              position: 'fixed',
              left: 0,
              top: 0,
              transition: 'transform .6s',
              transform: showNav ? 'translateX(0px)' : 'translateX(100vw)',
              zIndex: showNav ? 9999 : -10,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowNav(false);
            }}
          >
            <CelebrationNav
              isFromSmalDevice
              setCloseSidebar={setCloseSidebar}
              closeSidebar={closeSidebar}
            />
          </Box>
        </Box>
      </>
    );

  return (
    <Grid gridTemplateColumns="max-content max-content max-content" gridGap={4} ml="auto">
      <RouterLink
        alignSelf="center"
        href="/"
        sx={{ textDecoration: 'none', lineHeight: 1.2 }}
        fontSize="18px"
        color="#000"
      >
        Home
      </RouterLink>
      <RouterLink
        alignSelf="center"
        href="/profile"
        sx={{ textDecoration: 'none', lineHeight: 1.2 }}
        fontSize="18px"
        color="#000"
      >
        Profile
      </RouterLink>
      <RouterLink
        alignSelf="center"
        href="/log-out"
        sx={{ textDecoration: 'none', lineHeight: 1.2, color: 'pinks.4' }}
        fontSize="18px"
      >
        Log Out
      </RouterLink>
    </Grid>
  );
}

function PublicNav() {
  const { asPath, query } = useRouter();
  return (
    <Grid gridAutoColumns="auto-fit" gridAutoFlow="column" gridGap={4} ml="auto">
      {asPath !== '/sign-in' && !(query.firstName || query.lastName) && (
        <RouterLink
          alignSelf="center"
          href={`/sign-in${
            query?.['private-code'] ? `?private-code=${query?.['private-code']}` : ''
          }`}
          mr="6px"
        >
          Sign In
        </RouterLink>
      )}
    </Grid>
  );
}

function Layout({
  children,
  authenticated,
  maxWidth,
  hasNav,
  isFromNew,
  isFromHome = false,
  // showFooter,
  backgroundColor,
  backgroundImageSx = undefined,
  margin,
  // isFromPrintable,
  marginLeft,
  isVGBEvent,
  showHeader = true,
  p,
}) {
  const {
    pathname,
    push,
    replace,
    query: { id, 'private-code': privateCode },
  } = useRouter();
  const { isAuthenticated } = useAuth();
  // const isVideoGuestBookTemplate = useCheckIfVideoGuestBook();
  const isValidUser = useIsCurrentUserHasUsernamePassword();
  const showContent = authenticated ? isAuthenticated : true;
  const isMobileScreen = useMobileCheckHook();
  // const [isFromVideoGuestBook, setIsFromVideoGuestBook] = useState(false);

  const [isFromMobile, setIsfromMobile] = useState(false);

  // useEffect(() => {
  //   setIsFromVideoGuestBook(isFromPrintable || isVideoGuestBookTemplate);
  // }, [isFromPrintable, isVideoGuestBookTemplate]);

  useEffect(() => {
    setIsfromMobile(isMobileScreen);
  }, [isMobileScreen]);

  const setRedirect = useSetPostLoginRedirect();
  const [, dispatch] = useContext(AuthContext);
  const clearCache = useClearEntityCache();

  useEffect(() => {
    // once authenticated, the user will want to return to their initial destination
    if (pathname && authenticated && !isAuthenticated && !isVGBEvent) {
      if (pathname === '/new' || pathname === '/event') {
        clearCelebrationId();
        if (pathname !== '/event') {
          const url = `/create-account${privateCode ? `?private-code=${privateCode}` : ''}${
            window?.location?.search
          }`;
          sendTrackingEvent(`member_visit`, {
            context: {
              url_text: 'Logout',
              url,
            },
          });
          push(url);
        }
      } else {
        setRedirect(pathname);
        dispatch({ type: 'logout' });
        store.remove('token');
        store.remove('selectedDeviceID');
        store.remove('selectedAudioDeviceID');
        // store.remove('isProducer');
        clearCache();
        push('/sign-in');
      }
    }
  }, [pathname, authenticated, isAuthenticated, push, setRedirect, isVGBEvent]);

  function getMarginLeft() {
    if (marginLeft) return marginLeft;
    if (!isFromHome) {
      if (isFromMobile) return 0;
      return '100px';
    }
    return undefined;
  }

  function getPx() {
    if (
      pathname === '/dashboard/[id]' ||
      pathname === '/dashboard/[id]/gallery' ||
      backgroundColor ||
      backgroundImageSx
    )
      return 0;
    if (isFromHome) return 1;
    return 2;
  }
  let padding;
  if (p !== undefined) {
    padding = p;
  } else if (backgroundColor || isFromMobile) {
    padding = 0;
  } else {
    padding = 4;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-start"
      minHeight="100vh"
      sx={{
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'left 40px',
        backgroundColor,
        ...backgroundImageSx,
      }}
    >
      {showHeader && (
        <Header isAuthenticated={isAuthenticated}>
          <Box ml="auto">
            {(!isAuthenticated || isValidUser === false) && !isVGBEvent && <PublicNav />}
            {isAuthenticated && isValidUser && (
              <UserNav pathname={pathname} push={push} replace={replace} id={id} />
            )}
          </Box>
        </Header>
      )}
      <Box m={margin} mt={isFromMobile && showHeader && 60} sx={{ zIndex: 2 }}>
        <FlashMessagesBanner maxWidth={maxWidth} mb={4} mx={4} />
        {showContent && (
          <Box
            as="main"
            alignSelf={isFromHome && 'center'}
            p={padding}
            sx={{
              marginLeft: getMarginLeft(),
              margin: isFromHome && 'auto',
              transition: 'all 0.5s ease-in-out',
              '@media (max-width: 1024px)': {
                marginLeft: 0,
                px: getPx(),
              },
            }}
            width={isFromHome ? '100%' : 'auto'}
            maxWidth={maxWidth}
          >
            {hasNav && <CelebrationNav isFromNew={isFromNew} />}
            {children}
          </Box>
        )}
        <DevTools isEnabled />
      </Box>
      {/* {showFooter && !isFromMobile && !isFromVideoGuestBook && <Footer />} */}
    </Flex>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // showFooter: PropTypes.bool,
};
Layout.defaultProps = {
  authenticated: false,
  maxWidth: 922,
  // showFooter: true,
};

export default Layout;
