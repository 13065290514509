import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Box } from 'rebass';
import { useGetCelebration } from '@/hooks/api';
import { useSetFlashMessage } from '@/components/FlashMessages';
import { sendTrackingEvent } from '@/lib/helper';
// eslint-disable-next-line import/no-cycle
import Layout from './Layout';

function DashboardLayout({ children, authenticated, hasNav, ...props }) {
  const {
    query: { id },
    push,
  } = useRouter();
  const { data, error } = useGetCelebration(id);
  const setFlashMessage = useSetFlashMessage();

  if (error && error.response) {
    if (error.response.status === 410 || error.response.status === 401) {
      const url = `/${window.location.search}`;
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: '410 or 401 Error',
          url,
        },
      });
      push(url);
      if (error.response.status === 410)
        setFlashMessage({ type: 'error', text: `Celebration ${id} not found`, size: 'small' });
    }
  }

  if (data)
    return (
      <Layout authenticated={authenticated} maxWidth={1200} showFooter hasNav={hasNav} {...props}>
        {children}
      </Layout>
    );

  return <Box />;
}

DashboardLayout.propTypes = {
  hasNav: PropTypes.bool,
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool,
};
DashboardLayout.defaultProps = { hasNav: true, authenticated: false };

export default DashboardLayout;
