import React, { forwardRef, useMemo } from 'react';
import Slider from 'react-slider';
import { Box } from 'rebass';

const TrimThumb = forwardRef((props, ref) => (
  <Box {...props} p={2} mt="-17px" ml="-17px">
    <Box
      ref={ref}
      bg="greys.4"
      width={12}
      height={28}
      sx={{
        outline: 'none',
        borderRadius: 2,
        boxShadow: 'medium',
        transform: 'scale(1, 1.5) translateX(-4px)',
        transformOrigin: 'center',
      }}
    />
  </Box>
));

const PlaybackThumb = forwardRef((props, ref) => (
  <Box
    {...props}
    ref={ref}
    bg="purples.4"
    width={25}
    height={25}
    sx={{
      outline: 'none',
      borderRadius: 12,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'white',
      boxShadow: 'medium',
      transform: 'translateX(-12px)',
      transformOrigin: 'center',
      zIndex: '10 !important',
    }}
  />
));

const useTrackBg = (index) =>
  useMemo(() => {
    switch (index) {
      case 0:
      case 3:
        return 'greys.3';
      case 1:
        return 'purples.3';
      case 2:
      default:
        return 'white';
    }
  }, [index]);

const Track = forwardRef(({ index, ...props }, ref) => {
  const bg = useTrackBg(index);
  return <Box height={25} ref={ref} bg={bg} {...props} />;
});

function TrimSlider(sliderProps) {
  return (
    <Slider
      style={{ height: 50 }}
      renderThumb={(props, { index }) => {
        if (index === 1) return <PlaybackThumb {...props} index={index} />;
        return <TrimThumb {...props} index={index} />;
      }}
      renderTrack={(props, { index }) => <Track {...props} index={index} />}
      {...sliderProps}
    />
  );
}

export default TrimSlider;
