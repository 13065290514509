/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, Box, Heading, Button, Link, Flex } from 'rebass';
// import PropTypes from 'prop-types';
import store from 'store';
import UAParser from 'ua-parser-js';
import { useWindowSize } from 'react-use';
import { RiVideoUploadFill, RiGiftFill } from 'react-icons/ri';
import { FaCloudUploadAlt, FaClipboardList, FaVideo } from 'react-icons/fa';
import { AiOutlineFileGif } from 'react-icons/ai';
import { MdLibraryMusic } from 'react-icons/md';
import { getMetadata } from 'video-metadata-thumbnails';
import { v4 } from 'uuid';
import { useRouter } from 'next/router';
import Uppy from '@uppy/core';
import Axios from 'axios';
import { StatusBar, useUppy } from '@uppy/react';
import {
  useCelebration,
  useCelebrationUploadErrorEmailMailto,
  useIsCustomRecorderEnabled,
} from '@/hooks/celebration';
import { useDeleteCanceledClip, useMoveClip } from '@/hooks/mutations';
import { useCurrentMember } from '@/hooks/member';
import { UploadInProgressTrigger } from '@/lib/constants';
import {
  cancelUppyUpload,
  getMediaIdFromUploadKey,
  retryUppyUpload,
  setFileName,
  setFileType,
  useHasMediaRecorder,
  getIDFromKey,
  sendTrackingEvent,
  clearLocalStorageItems,
} from '@/lib/helper';
import { ax, postPrivateEventDetails } from '@/lib/api';
import { EntityCacheContext } from '@/lib/entities';
import { useGETClipSWR } from '@/hooks/api';
import { useAddClipToEntities } from '@/hooks/clip';
import { useCurrentMemberId } from '@/hooks/auth';
import Grid from '../app/Grid';
import AddGif from './AddGif';
import AnimatedDialog from '../app/AnimatedDialog';
import VideoPreview from '../WebcamRecorder/VideoPreview';
import RecordVideo from '../WebcamRecorder/RecordVideo';
import SubmissionClipsList from './SubmissionClipsList';
import UploadClipFile from './UploadClipFile';
import AddCardClip from './AddCardClip';
import SortableClips from './SoundSortableClips';
import SongsLibrary from './SongsLibrary';
import UploadSong from './UploadSong';
import EditCredits from './EditCredits';
// eslint-disable-next-line import/no-cycle
import EditTrack from './EditTrack';
import SoundTrackPreview from './SoundTrackPreview';
import ConfirmDialog from '../app/ConfirmDialog';
import Progress from '../app/Progress';
import UserCapture from '../WebcamRecorder/UserCapture';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';

import InternetSpeedTracker from '../InternetSpeedTracker/index';
import ProgressTracker from '../app/ProgressTracker';
import { useHeightAndWidthOfRecorder } from '../CelebrationForm/hooks';
import UppyComponent from '../UppyComponent';
import { useCreateNewAccount } from '../Auth/index';

export function RenderMediaAction({ Icon, text, onClick }) {
  return (
    <Box textAlign="center" width="80px" onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Flex
        mb={1}
        height="80px"
        bg="pinks.4"
        boxShadow="0px 0px 34px 0px rgba(0, 0, 0, 0.1)"
        sx={{ borderRadius: '11px' }}
        justifyContent="center"
        alignItems="center"
      >
        {/* <Image src={Icon} /> */}
        {Icon}
      </Flex>
      <Text className="dm-sans" fontWeight={600} color="purples.7">
        {text}
      </Text>
    </Box>
  );
}

const baseURL = process.env.NEXT_PUBLIC_API_BASE.replace('/api', '/');

function RenderUploader({
  children,
  isFromMobile,
  celebrationId,
  mainProgress,
  uploadError,
  tryAgain,
  loading,
}) {
  const memberId = useCurrentMemberId();
  const uploadFailedMailto = useCelebrationUploadErrorEmailMailto(celebrationId, memberId);

  if (uploadError)
    return (
      <Box p={4} pt={0} maxWidth="100vw">
        <Heading variant="headings.h1" fontSize={4} pb={4}>
          Oops. That didn’t work 🙁.
        </Heading>
        <Heading variant="headings.h6" fontSize={4}>
          Would you please try again? If it fails a second time, please email us at{' '}
          <Button as={Link} variant="link" target="_blank" href={uploadFailedMailto(uploadError)}>
            help@celebrate.buzz
          </Button>{' '}
          and we will help you with this issue.
        </Heading>
        {children}
        <Button as="primary" mt={4} onClick={tryAgain}>
          Try Again
        </Button>
      </Box>
    );

  if (loading)
    return (
      <Box p={4} pt={0} textAlign="center" width="100%" alignSelf={isFromMobile && 'baseline'}>
        <Heading textAlign="left" variant="headings.h3" pb={2}>
          Uploading Clip
        </Heading>
        <Text textAlign="left">Please don&apos;t close this window</Text>
        <ProgressTracker progress={mainProgress} />
        <Progress percentComplete={mainProgress} />
        {children}
      </Box>
    );

  return null;
}

function UploadFile({
  file: newFile,
  onFinished,
  celebrationId,
  name,
  isFromMobile,
  isFromVideoGuestBookInvitation = false,
}) {
  const [uploadError, setuploadError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainProgress, setProgress] = useState(0);
  const { asPath } = useRouter();
  const createNewUser = useCreateNewAccount();
  const isPublic = asPath.includes('/invitations/');

  const [uploadingMediaId, setUploadingMediaId] = useState(null);
  const [failedUploadFile, setFailedUploadFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(null);
  const { data: updatedData } = useGETClipSWR(uploadingFile);
  const [addCliptoEntity] = useAddClipToEntities();

  useEffect(() => {
    if (updatedData) {
      const {
        data: {
          data: { type, id },
        },
      } = updatedData;
      addCliptoEntity(type, id, celebrationId);
    }
  }, [updatedData]);

  useEffect(() => {
    const interval = setInterval(async function () {
      // method to be executed;
      if (uploadingMediaId) await ax().patch(`/v3/media/${uploadingMediaId}/touch`);
    }, UploadInProgressTrigger);
    if (uploadingMediaId === null) {
      return clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [uploadingMediaId]);

  const uppy = useUppy(() => {
    return new Uppy({
      meta: {},
      allowMultipleUploads: false,
      restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['video/*'] },
      autoProceed: true,
    });
  });

  /* eslint-disable no-param-reassign */
  async function createMultipartUpload(uploadedFile) {
    uploadedFile.type = setFileType(uploadedFile.type);
    const filename = setFileName(uploadedFile.name, uploadedFile.type);
    store.set('Media Uploading', true);
    let token = store.get('token');
    if (!token) {
      token = await createNewUser();
    } else if (token.includes('token=')) token = token.replace('token=', '');

    const clipAttributes = {
      filename,
      metadata: { name: filename, type: uploadedFile.type },
      type: uploadedFile.type,
      token,
      celebration_id: celebrationId,
      // 'clip_name': name,
      position_beginning: !isFromVideoGuestBookInvitation,
    };
    try {
      const { duration } = await getMetadata(uploadedFile.data);
      clipAttributes.duration = duration * 1000;
    } catch (err) {
      console.log(err);
    }
    if (isPublic) clipAttributes.source = 'invitation';
    else clipAttributes.source = 'video_editor';

    const tokenResponse = await Axios.post(`${baseURL}s3/multipart`, clipAttributes, {
      headers: {
        'X-LIFETALES-CLIENT': 'web-celebrate-client',
        'X-CELEBRATE-REFERER': window?.location?.href,
      },
    });
    const { status, data, error } = tokenResponse;
    if (status === 200) {
      const mediaId = getMediaIdFromUploadKey(data.key);
      setUploadingMediaId(mediaId);
      const {
        data: {
          data: { attributes },
        },
      } = await ax().get(`/v2/media/${mediaId}`);
      if (isFromVideoGuestBookInvitation) getIDFromKey(attributes['owner-id']);
      else getIDFromKey(data.key);

      setUploadingFile({
        id: attributes['owner-id'],
        type: `celebration-${attributes.type.toLowerCase()}-clips`,
      });
      sendTrackingEvent(`${uploadedFile.type.split('/')[0]}_upload_started`);
      store.set('file-type', uploadedFile.type.split('/')[0]);
      return data;
    }

    store.remove('Media Uploading');
    store.set('sentryError', `${error} /VideoEditorAddBar Component After Multipart Request`);
    return null;
  }

  async function uploadSuccess(file) {
    sendTrackingEvent(`${file.meta.type.split('/')[0]}_upload_completed`);
    setUploadingFile(null);
    setUploadingMediaId(null);
    setLoading(false);
    onFinished({
      id: store.get('uploadingID'),
      type: `celebration_${store.get('file-type')}_clips`,
    });
    clearLocalStorageItems();
    uppy.close();
  }

  async function uploadProgress(file, progress) {
    const currProgress = (progress.bytesUploaded / progress.bytesTotal) * 100;
    setProgress(currProgress);
    sendTrackingEvent(`${file.meta.type.split('/')[0]}_part_uploaded`, {
      context: `upload progrees ${currProgress}`,
    });
  }

  async function uppyUploadError(file, error) {
    setuploadError(true);
    setLoading(false);
    setUploadingMediaId(null);
    setUploadingFile(null);
    setFailedUploadFile(file);
    store.set('sentryError', `${error} /VideoEditorAddBar Component and in upload-error function`);
  }

  useEffect(() => {
    if (loading) {
      store.set('Media Uploading', true);
    } else {
      store.remove('Media Uploading');
    }
  }, [loading]);

  useEffect(() => {
    if (newFile) {
      try {
        uppy.addFile({
          name,
          type: newFile.type,
          data: newFile, // changed blob -> data
        });
        setLoading(true);
      } catch (error) {
        store.set('sentryError', `${error} /VideoEditorAddBar Component in add-file function`);
      }
    }
  }, [newFile]);

  const tryAgain = () => {
    setLoading(true);
    setProgress(0);
    setuploadError(null);
    uppy.cancelAll();
    retryUppyUpload();
    sendTrackingEvent(`${failedUploadFile.type.split('/')[0]}_upload_retry`);
    uppy.addFile({
      name: failedUploadFile.name,
      type: failedUploadFile.type,
      data: failedUploadFile.data,
    });
  };

  return (
    <Box width="100%">
      <UppyComponent
        uppy={uppy}
        createMultipartUpload={createMultipartUpload}
        uploadSuccess={uploadSuccess}
        uploadProgress={uploadProgress}
        uploadError={uppyUploadError}
        tryAgain={uploadError ? tryAgain : undefined}
      >
        <RenderUploader
          isFromMobile={isFromMobile}
          celebrationId={celebrationId}
          uploadError={uploadError}
          mainProgress={mainProgress}
          loading={loading}
          tryAgain={tryAgain}
        >
          <StatusBar uppy={uppy} hideUploadButton hideAfterFinish={false} />
        </RenderUploader>
      </UppyComponent>
    </Box>
  );
}

export function AddWebcamVideo({
  onFinished,
  celebrationId,
  isFromMobile,
  isFromVideoGuestBookInvitation,
}) {
  const [file, setFile] = useState(null);
  const [uploadClip, setUploadClip] = useState(null);
  const { width, height } = useHeightAndWidthOfRecorder();
  const isCustomRecorderEnabled = useIsCustomRecorderEnabled();
  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  const osName = parser.getResult().os.name;

  const isMobileUser =
    (['Android'].includes(osName) ||
      ['ipad', 'iphone'].includes(parser.getDevice().model?.toLowerCase())) &&
    !isCustomRecorderEnabled;

  const hasMediaRecorder = useHasMediaRecorder();

  const member = useCurrentMember() || {};

  const setRecordedClip = async (recordedChunks) => {
    try {
      const isSafari = browserName?.includes('Safari');
      const containerType = isSafari ? 'mp4' : 'webm';
      const blob = new Blob([recordedChunks], { type: `video/${containerType}` });
      blob.name = `${v4()}.${containerType}`;
      setUploadClip(blob);
    } catch (err) {
      store.set(
        'sentryError',
        `${err} /VideoEditorAddBar Component and in setRecordedClip function`
      );
    }
  };

  if (uploadClip)
    return (
      <UploadFile
        file={uploadClip}
        onFinished={onFinished}
        celebrationId={celebrationId}
        name={member ? `${member['full-name']}` : ''}
        isFromMobile={isFromMobile}
        isFromVideoGuestBookInvitation={isFromVideoGuestBookInvitation}
      />
    );

  if (file)
    return (
      <Box width="100%" alignSelf={isFromMobile && 'baseline'}>
        <VideoPreview
          height="auto"
          minHeight={360}
          blob={file}
          onReject={() => setFile(undefined)}
          onAccept={async () => setUploadClip(file)}
          mt={-80}
          celebrationId={celebrationId}
        />
      </Box>
    );
  if (hasMediaRecorder && !isMobileUser)
    return (
      <RecordVideo
        height={height}
        isFromMobile={isFromMobile}
        width={width}
        onEnded={setFile}
        onDismiss={onFinished}
      />
    );

  return (
    <UserCapture
      isFromMobile={isFromMobile}
      onEnded={setRecordedClip}
      celebrationId={celebrationId}
    />
  );
}

function VideoEditorAddBar({
  soundTracks,
  setClipBoardHeight,
  clips,
  timelineClips,
  clipsLoading,
}) {
  const {
    query: { id },
  } = useRouter();
  const [currentModal, setCurrentModal] = useState(false);
  const [editTrack, setEditTrack] = useState('');
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [clipPlaying, setClipPlaying] = useState(false);
  const deleteCanceledClip = useDeleteCanceledClip(id);

  const { width } = useWindowSize();
  // const soundTracks = useCelebrationSoundTracks(id);
  const closeModal = useCallback(() => setCurrentModal(''), [setCurrentModal]);
  const [draggedVideo, setDraggedVideo] = useState(null);
  const celebration = useCelebration(id);

  const addToClip = useMoveClip({
    id: draggedVideo ? draggedVideo.id : null,
    type: draggedVideo ? draggedVideo.type : null,
    celebrationId: id,
    state: 'in_clipboard',
  });

  async function addNewClip() {
    await addToClip();
  }

  function cssHandling() {
    if (currentModal === 'credits') {
      if (width < 600) return 'width: 100%; border-radius:0px; height: 100%; overflow-y: auto;';
      return 'width: 90vw;';
    }

    return 'width: 90vw;';
  }

  useEffect(() => {
    if (draggedVideo) {
      addNewClip();
    }
  }, [draggedVideo]);

  const onDismiss = () => {
    if (store.get('Media Uploading')) {
      setShowConfirmClose(true);
    } else {
      if (showConfirmClose) {
        setShowConfirmClose(false);
      }
      setCurrentModal('');
      setEditTrack('');
    }
  };
  const { updateEntities } = useContext(EntityCacheContext);

  const onMoveClipToTimeline = async () => {
    await ax().patch(`/v3/celebrations/${id}/move_clips_to_timeline`);
    /* eslint-disable no-param-reassign, no-unsafe-optional-chaining */
    updateEntities((draft) => {
      let clipMoved = 0;
      if (timelineClips?.[timelineClips?.length - 1]?.position)
        clipMoved = timelineClips[timelineClips?.length - 1].position;
      clips.forEach((clip) => {
        if (clip) {
          if (
            draft[clip.type] &&
            draft[clip.type][clip.id] &&
            draft?.[clip.type]?.[clip.id]?.state === 'in_clipboard'
          ) {
            clipMoved += 1;
            draft[clip.type][clip.id].state = 'in_timeline';
            draft[clip.type][clip.id].position = clipMoved;
          }
        }
      });
    });
  };

  return (
    <Box>
      <Box pb={1}>
        <SortableClips
          style={{
            display: 'grid',
            gridGap: 16,
            gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
          }}
          clips={soundTracks}
        >
          {(list) =>
            list.map(
              (musicClip) =>
                musicClip?.id && (
                  <SoundTrackPreview
                    key={`track-preview-${musicClip.id}`}
                    id={musicClip.id}
                    onClick={(name, artistName) =>
                      setEditTrack({ id: musicClip.id, name, artistName })
                    }
                    clipPlaying={clipPlaying}
                    onPlayClip={(clipId) => setClipPlaying(clipId)}
                  />
                )
            )
          }
        </SortableClips>
      </Box>
      <Box
        p={2}
        bg="pinks.4"
        onDrop={(ev) => {
          store.remove('isDragging');
          if (ev.dataTransfer.getData('TimeLine clip')) {
            store.set('clipMoving', true);
            setDraggedVideo(JSON.parse(ev.dataTransfer.getData('TimeLine clip')));
          }
        }}
        onDragOver={(ev) => ev.preventDefault()}
        sx={{ borderRadius: 'xLarge', boxShadow: 'large' }}
        ref={(el) => {
          if (!el) return;
          setClipBoardHeight(el.getBoundingClientRect().height);
        }}
        maxWidth="1200px"
      >
        {clips.length > 0 && (
          <Flex justifyContent="space-between" alignItems="center">
            <Text className="dm-sans" color="white">
              <span style={{ fontWeight: 'bold' }}>Clipboard: </span>Tap clips to preview. Drag
              clips to your timeline to add to your video
            </Text>
            <Text
              className="dm-sans"
              color="white"
              p={1}
              sx={{ border: '2px solid white', borderRadius: '6px', cursor: 'pointer' }}
              onClick={onMoveClipToTimeline}
            >
              Move all to timeline
            </Text>
          </Flex>
        )}
        <SubmissionClipsList clips={clips} clipsLoading={clipsLoading} />
      </Box>
      <Grid
        gridTemplateColumns="repeat(auto-fill, minmax(120px, 1fr))"
        gridTemplateFlow="column"
        gridGap={3}
        py={4}
      >
        <RenderMediaAction
          Icon={<FaVideo color="white" size={42} />}
          text="Record Video"
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Record Video',
              },
            });
            setCurrentModal('webcam');
          }}
        />

        <RenderMediaAction
          Icon={<RiVideoUploadFill color="white" size={42} />}
          text="Upload Media"
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Upload Media',
              },
            });
            setCurrentModal('video');
          }}
        />

        {/* <RenderMediaAction
          Icon={<MdPhotoLibrary color="white" size={42} />}
          text="Upload Photos"
          onClick={() => setCurrentModal('image')}
        /> */}

        <RenderMediaAction
          Icon={<RiGiftFill color="white" size={42} />}
          text="Add Card"
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Add Card',
              },
            });
            setCurrentModal('card');
          }}
        />

        <RenderMediaAction
          Icon={<AiOutlineFileGif color="white" size={42} />}
          text="Add GIF"
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Add GIF',
              },
            });
            setCurrentModal('gif');
          }}
        />

        <RenderMediaAction
          Icon={<MdLibraryMusic color="white" size={42} />}
          text="Browse Song Library"
          onClick={() => {
            postPrivateEventDetails(`celebration_tapped_browse_song_library`, {
              'subject-type': 'Celebration',
              'subject-id': id,
            });
            setCurrentModal('library');
          }}
        />

        <RenderMediaAction
          Icon={<FaCloudUploadAlt color="white" size={42} />}
          text="Upload Song"
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Upload Song',
              },
            });
            setCurrentModal('upload');
          }}
        />

        <RenderMediaAction
          Icon={<FaClipboardList color="white" size={42} />}
          text="Edit Credits"
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Edit Credits',
              },
            });
            setCurrentModal('credits');
          }}
        />
      </Grid>
      <AnimatedDialog
        title="Add Media Modal"
        isOpen={!!currentModal || !!editTrack}
        contentCSS={
          currentModal === 'gif' || currentModal === 'credits' ? cssHandling() : 'max-width: 800px;'
        }
        onDismiss={onDismiss}
        contentProps={{ width: '80vw' }}
        isFullScreenModal={currentModal === 'credits'}
      >
        <InternetSpeedTracker onDismiss={onDismiss} />
        <ConfirmDialog
          isOpen={showConfirmClose}
          onConfirm={() => {
            cancelUppyUpload();
            if (store.get('Media Uploading')) {
              if (store.get('file-type')) {
                sendTrackingEvent(`${store.get('file-type')}_upload_cancelled`, {
                  context: 'Canceled Upload',
                });
                store.remove('file-type');
              }
              deleteCanceledClip(store.get('uploadingID'));
              store.remove('Media Uploading');
            }
            setShowConfirmClose(false);
            setCurrentModal('');
            setEditTrack('');
          }}
          onDismiss={() => setShowConfirmClose(false)}
          title="Cancel upload?"
          body="Your file(s) will not be uploaded."
          cancelTitle="Nevermind"
          confirmTitle="Cancel upload"
          confirmVariant="destructive"
        />
        {currentModal === 'webcam' && <AddWebcamVideo celebrationId={id} onFinished={closeModal} />}
        {currentModal === 'video' && (
          <UploadClipFile
            isFromVideoGuestBookInvitation
            type="Add Media"
            celebrationId={id}
            onFinished={closeModal}
          />
        )}
        {currentModal === 'image' && (
          <UploadClipFile type="image" celebrationId={id} onFinished={closeModal} />
        )}
        {currentModal === 'card' && <AddCardClip type="image" onFinished={closeModal} />}
        {currentModal === 'gif' && (
          <AddGif
            onFinished={closeModal}
            occasion={celebration ? celebration['occasion-description-long'] : null}
          />
        )}
        {currentModal === 'library' && <SongsLibrary soundTracks={soundTracks} />}
        {currentModal === 'upload' && <UploadSong onDismiss={() => setCurrentModal('')} />}
        {currentModal === 'credits' && <EditCredits onFinished={() => setCurrentModal('')} />}
        {!!editTrack && <EditTrack {...editTrack} onDismiss={() => setEditTrack('')} />}
      </AnimatedDialog>
    </Box>
  );
}

export default VideoEditorAddBar;
