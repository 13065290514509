import React from 'react';

function Lock({ color = '#EF2482', width = '10', height = '12' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50008 3.33329H6.08341V2.49996C6.08341 1.34996 5.15008 0.416626 4.00008 0.416626C2.85008 0.416626 1.91675 1.34996 1.91675 2.49996V3.33329H1.50008C1.04175 3.33329 0.666748 3.70829 0.666748 4.16663V8.33329C0.666748 8.79163 1.04175 9.16663 1.50008 9.16663H6.50008C6.95841 9.16663 7.33341 8.79163 7.33341 8.33329V4.16663C7.33341 3.70829 6.95841 3.33329 6.50008 3.33329ZM4.00008 7.08329C3.54175 7.08329 3.16675 6.70829 3.16675 6.24996C3.16675 5.79163 3.54175 5.41663 4.00008 5.41663C4.45841 5.41663 4.83341 5.79163 4.83341 6.24996C4.83341 6.70829 4.45841 7.08329 4.00008 7.08329ZM5.29175 3.33329H2.70841V2.49996C2.70841 1.78746 3.28758 1.20829 4.00008 1.20829C4.71258 1.20829 5.29175 1.78746 5.29175 2.49996V3.33329Z"
        fill={color}
      />
    </svg>
  );
}

export default Lock;
