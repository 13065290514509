import React from 'react';

function VideoIconSelected() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <g clipPath="url(#clip0_4595_48699)">
        <path
          d="M3.57422 1.81641H26.4258C26.4258 1.81641 28.1836 1.81641 28.1836 3.57422V26.4258C28.1836 26.4258 28.1836 28.1836 26.4258 28.1836H3.57422C3.57422 28.1836 1.81641 28.1836 1.81641 26.4258V3.57422C1.81641 3.57422 1.81641 1.81641 3.57422 1.81641Z"
          stroke="#7B33FB"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9352 19.289C12.7822 19.3653 12.6123 19.4012 12.4416 19.3934C12.2709 19.3856 12.105 19.3343 11.9597 19.2444C11.8144 19.1544 11.6944 19.0289 11.6112 18.8796C11.528 18.7303 11.4844 18.5623 11.4844 18.3914V11.6086C11.4844 11.4377 11.528 11.2696 11.6112 11.1203C11.6944 10.971 11.8144 10.8455 11.9597 10.7556C12.105 10.6656 12.2709 10.6143 12.4416 10.6065C12.6123 10.5987 12.7822 10.6346 12.9352 10.7109L19.7191 14.1035C19.8857 14.1866 20.0259 14.3146 20.1238 14.4729C20.2217 14.6313 20.2736 14.8138 20.2736 15C20.2736 15.1862 20.2217 15.3687 20.1238 15.527C20.0259 15.6854 19.8857 15.8133 19.7191 15.8964L12.9352 19.289Z"
          stroke="#7B33FB"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4595_48699">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VideoIconSelected;
