import React from 'react';
import { Button } from 'rebass';

const LabelButtonForFileInput = React.forwardRef((props, ref) => {
  return (
    <Button
      as="label"
      ref={ref}
      sx={{
        position: 'relative',
        '& input': { position: 'absolute', left: '-9999em' },
      }}
      {...props}
    />
  );
});

export default LabelButtonForFileInput;
