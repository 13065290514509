/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import store from 'store';
import { Box, Button, Text, Flex } from 'rebass';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useWindowSize } from 'react-use';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { FiHome } from 'react-icons/fi';
import useSWR from 'swr';
import { useTheme } from 'emotion-theming';
import Select from 'react-select';
import Grid from '@/components/app/Grid';
import {
  useCelebration,
  usePrintableEntities,
  usePrintableTemplateEntities,
} from '@/hooks/celebration';
import { ax } from '@/lib/api';
import InlineEditing from '@/components/inline-editing';
import { useEntities, useEntity } from '@/lib/entities';
import { capitalizeFirstLetter, checkIfIdExist, sendTrackingEvent } from '@/lib/helper';
import PaymentModal from '@/components/CelebrationHomeScreen/common/PaymentModal';
import PrintablePreviewModal from './PrintablePreviewModal';
import BufferingOverlay from '../VideoPreview/BufferingOverlay';
import { Tour } from '../Tour';
import PrintableScanPreviewModal from './PrintableScanPreviewModal';
import OutlineButton from '../app/OutlineButton';
import { CelebrationLayoutFooter } from '../CreateCelebrationLayout';
import TemplateRenderer from './TemplateRenderer';
import { CelebrationFormList } from '../CelebrationForm/CelebrationFormContext';
import { usePDFProcessor } from '../PDFProcessor/index';

let allSupportedFontsList = [];

function getTagStyles(isSelected, lastIndex) {
  return {
    fontStyle: 'normal',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '14px',
    lineheight: '21px',
    color: isSelected ? '#252627' : '#808080',
    mx: '10px',
    textDecoration: isSelected ? 'underline' : 'none',
    textAlign: 'center',
    textShadow: lastIndex && '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 2,
  };
}

function RenderHeader({
  onHomePress,
  isSubmitting,
  selectedSide,
  setSelectedSide,
  setHeaderHeight,
}) {
  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background: 'linear-gradient(90deg, #6FE1F7 0%, #74DBBA 105.43%)',
        p: '14px',
      }}
      ref={(el) => {
        if (!el) return;
        setHeaderHeight(el.getBoundingClientRect().height);
      }}
    >
      <FiHome size={20} onClick={onHomePress} />
      {/* <ZoomSlider
        id="zoom_control"
        type="range"
        zoom={zoom}
        onChange={(e) => setZoom(e.target.value)}
        max={100}
        min={10}
        step="1"
        style={{ padding: 0 }}
      /> */}
      <Flex>
        <Button
          disabled={isSubmitting}
          bg={selectedSide === 'front' ? 'white' : 'transparent'}
          sx={{
            py: '4px',
            borderRadius: '100px',
            border: selectedSide === 'front' ? '0.5px solid #fff' : '0.5px solid #000',
            color: '#000',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: selectedSide === 'front' ? 700 : 400,
            lineHeight: '120%',
            letterSpacing: '-0.33px',
            mr: '12px',
          }}
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Front',
                url: 'Front Side of Poster',
              },
            });
            setSelectedSide('front');
          }}
        >
          Front
        </Button>
        <Button
          disabled={isSubmitting}
          bg={selectedSide === 'back' ? 'white' : 'transparent'}
          sx={{
            py: '4px',
            borderRadius: '100px',
            border: selectedSide === 'back' ? '0.5px solid #fff' : '0.5px solid #000',
            color: '#000',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: selectedSide === 'back' ? 700 : 400,
            lineHeight: '120%',
            letterSpacing: '-0.33px',
          }}
          onClick={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Back',
                url: 'Back Side of Poster',
              },
            });
            setSelectedSide('back');
          }}
        >
          Back
        </Button>
      </Flex>
    </Flex>
  );
}

const tourConfig = [
  {
    selector: '[data-tut="CHOOSE_TEMPLATE"]',
    content: ({ close }) => (
      <Flex flexDirection="column">
        <Text fontWeight="700" fontSize="20px" lineHeight="21px" color="white" mb={1}>
          Choose a template
        </Text>
        <Text fontWeight=" 400" fontSize="16px" lineHeight="21px" color="white">
          Pick one of our professionally designed templates by scrolling through this sidebar
        </Text>
        <Button mt={1} bg="purples.7" onClick={close} ml="auto">
          GOT IT
        </Button>
      </Flex>
    ),
    style: {
      backgroundColor: '#6369EF',
    },
    stepInteraction: false,
  },
];

function EditPosterPage({
  isFromMobileScreen,
  onHomePress,
  mobileInnerHeight,
  mobileSelectedTemplateId = null,
}) {
  const {
    push,
    query: { id, isFromNew },
    replace,
  } = useRouter();
  const { generatePDF } = usePDFProcessor();

  const { colors, fontSizes } = useTheme();
  const celebration = useCelebration(id);
  const templateDetails = useEntity(celebration?.['celebration-template']);
  const [showPaymentModal, setPaymentModal] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isScanPreviewOpen, setScanPreviewOpen] = useState(false);
  const [newSelectedTemplate, setNewSelectedTemplate] = useState(mobileSelectedTemplateId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [closeTemplateSelector, setCloseTemplateSelector] = useState(false);

  const [showTour, setTour] = useState(null);
  const [selectedPrintable, setSelectedPrintable] = useState(null);
  const [editorValue, setEditorValue] = useState(null);
  const [mobileFooterHeight, setMobileFooterHeight] = useState(0);
  const [mobileHeaderHeight, setMobileHeaderHeight] = useState(0);

  const [zoom, setZoom] = useState(10);
  const [selectedSide, setSelectedSide] = useState('front');
  const [selectedTag, setSelectedTag] = useState('All');
  const [frontPrintables, setFrontPrintables] = useState([]);
  const [backPrintles, setBackPrintables] = useState([]);

  const { width } = useWindowSize();

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  useSWR(checkIfIdExist(id) ? `/v3/celebrations/${id}/celebration_printable_templates` : null);
  const { mutate: revalidatePrintables } = useSWR(
    id ? `/v3/celebrations/${id}/celebration_printables` : null
  );

  const printableTemplates = usePrintableTemplateEntities();
  const [, , printables] = usePrintableEntities();

  useEffect(() => {
    if (isFromMobileScreen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // This makes the scrolling smooth, you can also use 'auto' or 'instant'
      });
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isFromMobileScreen]);

  useEffect(() => {
    if (
      Object.keys(printableTemplates).length > 0 &&
      !store.get('posterGuidedTour') &&
      !isPreviewOpen &&
      !isFromMobileScreen
    ) {
      setTour(true);
    }
    setFrontPrintables(
      Object.keys(printableTemplates)
        .filter(
          (tem) => printableTemplates[tem].page === 'front' && printableTemplates[tem].enabled
        )
        .map((tem) => printableTemplates[tem])
        .sort((a, b) => a.name.localeCompare(b.name))
    );
    setBackPrintables(
      Object.keys(printableTemplates)
        .filter((tem) => printableTemplates[tem].page === 'back' && printableTemplates[tem].enabled)
        .map((tem) => printableTemplates[tem])
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [printableTemplates, isPreviewOpen, isFromMobileScreen]);

  useEffect(() => {
    if (isFromNew && isFromMobileScreen === false) {
      replace(`/dashboard/${id}/poster`);
      setPreviewOpen(true);
    }
  }, [isFromMobileScreen]);

  useEffect(() => {
    if (
      selectedPrintable &&
      !isFromMobileScreen &&
      store.get('posterGuidedTour') &&
      !store.get('posterEditorGuidedTour')
    )
      setEditorValue('title');
    else setEditorValue(null);
  }, [selectedPrintable]);

  useEffect(() => {
    if (newSelectedTemplate !== selectedPrintable?.['celebration-printable-template']?.id)
      setNewSelectedTemplate(selectedPrintable?.['celebration-printable-template']?.id);
  }, [selectedPrintable?.['celebration-printable-template']?.id]);

  useEffect(() => {
    if (
      newSelectedTemplate &&
      newSelectedTemplate !== selectedPrintable?.['celebration-printable-template']?.id
    )
      setIsSubmitting(true);
    else setIsSubmitting(false);
  }, [selectedPrintable, newSelectedTemplate]);

  useEffect(() => {
    if (printables)
      setSelectedPrintable(
        printables[
          Object.keys(printables).filter((print) => printables[print].page === selectedSide)[0]
        ]
      );
  }, [printables, selectedSide]);

  const printableLayers =
    selectedPrintable?.['celebration-printable-layers'] &&
    selectedPrintable?.['celebration-printable-layers'].length
      ? selectedPrintable?.['celebration-printable-layers'].map(
          // eslint-disable-next-line react-hooks/rules-of-hooks
          ({ id: printableId }) =>
            useEntities({ type: 'celebration-printable-layers' })[printableId]
        )
      : [];

  async function loadFonts() {
    const {
      data: { data: fontsList },
    } = await ax().get(`/v3/fonts`);
    allSupportedFontsList = fontsList.map(
      ({
        attributes: {
          name,
          'supports-bold': supportsBold,
          'supports-italic': supportsItalic,
          'download-url': downloadUrl,
        },
      }) => ({ label: name, value: name, supportsBold, supportsItalic, downloadUrl })
    );
  }

  useEffect(() => {
    loadFonts();
  }, []);

  const toggleInnerTour = () => {
    if (showTour && !store.get('posterGuidedTour')) store.set('posterGuidedTour', true);

    setTour(!showTour);
  };

  async function onTemplateSelection(template) {
    if (template?.id !== selectedPrintable?.['celebration-printable-template']?.id) {
      setIsSubmitting(true);
      setEditorValue(null);
      setNewSelectedTemplate(template.id);
      if (selectedPrintable) {
        await ax().patch(`/v3/celebrations/${id}/celebration_printables/${selectedPrintable.id}`, {
          data: {
            attributes: {
              'celebration-printable-template-id': template.id,
            },
          },
        });
      } else {
        await ax().post(`/v3/celebrations/${id}/celebration_printables`, {
          data: {
            attributes: {
              'celebration-printable-template-id': template.id,
            },
          },
        });
      }
      revalidatePrintables();
      setIsSubmitting(false);
    }
  }

  let height = 'calc(100vh - 235px)';
  if (!celebration) return false;
  const smallWidth = width < 1150;

  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderTagSearch() {
    if (width < 1231)
      return (
        <Select
          fontSize={fontSizes[1]}
          options={[
            { value: 'All', label: 'All' },
            ...templateDetails['tag-names'].map((tag) => ({
              value: tag,
              label: capitalizeFirstLetter(tag),
            })),
          ]}
          styles={{
            option: (provided) => ({ ...provided, fontSize: fontSizes[1] }),
            control: (provided) => ({
              ...provided,
              fontSize: fontSizes[1],
              paddingTop: 8,
              paddingBottom: 8,
              width: smallWidth ? '120px' : '240px',
              background: 'white',
            }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral20: colors.border,
              primary: colors.primary,
              neutral90: colors.body,
              neutral0: colors.lightGreys[3],
            },
          })}
          value={{ value: selectedTag, label: capitalizeFirstLetter(selectedTag) }}
          onChange={(opt) => setSelectedTag(opt.value)}
        />
      );
    return null;
  }

  function getMl() {
    if (closeTemplateSelector) return '-10px';
    if (smallWidth) return '125px';
    return '250px';
  }

  function getWidth() {
    if (closeTemplateSelector) return 0;
    if (smallWidth) return '130px';
    return '260px';
  }

  // eslint-disable-next-line  react/no-unstable-nested-components
  function RenderTemplateSelector(isFromModal = false) {
    function renderGridTemplateColumns() {
      if (isFromModal) return `${width / 3 - 10}px ${width / 3 - 10}px ${width / 3 - 10}px`;
      if (smallWidth) return '120px';
      return '120px 120px';
    }

    return (
      <BufferingOverlay isBuffering={isFromModal && isSubmitting}>
        <Box height={isFromModal && '50vh'} overflowY="auto">
          <Grid gridTemplateColumns={renderGridTemplateColumns()} gridGap={1}>
            {(selectedSide === 'front' ? frontPrintables : backPrintles)
              .filter((template) =>
                selectedTag === 'All'
                  ? true
                  : template?.['tag-names'].includes(capitalizeFirstLetter(selectedTag))
              )
              .map((template) => (
                <TemplateRenderer
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...template}
                  key={template.id}
                  onTemplateSelection={() => {
                    onTemplateSelection(template);
                  }}
                  isSubmitting={isSubmitting}
                  isSelected={
                    selectedPrintable
                      ? (newSelectedTemplate ||
                          printables[selectedPrintable.id]?.['celebration-printable-template']
                            ?.id) === template.id
                      : false
                  }
                />
              ))}
          </Grid>
        </Box>
      </BufferingOverlay>
    );
  }

  const onContinuePress = (e, onClickOutside) => {
    const url = `/dashboard/${id}/event`;
    if (selectedSide === 'front') {
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: 'Continue',
          url: 'back poster',
        },
      });
      setSelectedSide('back');
    } else if (selectedSide === 'back') {
      generatePDF();
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: 'Continue',
          url,
        },
      });
      push(url);
    }

    if (onClickOutside) onClickOutside(e);
  };

  // eslint-disable-next-line  react/no-unstable-nested-components
  function RenderTemplateTagSelector(isFromModal = false) {
    if (width > 1230 || isFromModal)
      return (
        <Flex
          height="21px"
          mb={isFromModal ? '10px' : '26px'}
          sx={{
            mt: !isFromModal && '-35px',
            position: !isFromModal && 'absolute',
          }}
        >
          <Text sx={getTagStyles(selectedTag === 'All')} onClick={() => setSelectedTag('All')}>
            All
          </Text>
          {templateDetails['tag-names'].map((tag, index) => (
            <Text
              key={tag}
              sx={getTagStyles(
                selectedTag === tag,
                index === templateDetails['tag-names'].length - 1
              )}
              onClick={() => setSelectedTag(tag)}
            >
              {capitalizeFirstLetter(tag)}
            </Text>
          ))}
        </Flex>
      );

    return null;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderFooter(onClickOutside) {
    if (isFromMobileScreen)
      return (
        <CelebrationFormList>
          <CelebrationLayoutFooter
            onBack={() => {
              if (selectedSide === 'front') onHomePress();
              else if (selectedSide === 'back') setSelectedSide('front');
            }}
            onNextPress={onContinuePress}
            nextButtonText={selectedSide === 'back' ? 'Save & Finish' : 'Continue'}
            sx={{ pb: 1 }}
          />
        </CelebrationFormList>
      );

    return (
      <Flex width="100%" justifyContent="center">
        <OutlineButton
          mb={0}
          onClick={(e) => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Save a qr code',
                url: `/dashboard/${id}/print?downloadQrCode=true`,
              },
            });
            push(`/dashboard/${id}/print?downloadQrCode=true`);
            onClickOutside(e);
          }}
        >
          Save a qr code
        </OutlineButton>
        {selectedPrintable && (
          <Button
            ml={5}
            variant="special"
            disabled={!selectedPrintable}
            onClick={(e) => onContinuePress(e, onClickOutside)}
          >
            Continue
          </Button>
        )}
      </Flex>
    );
  }
  const switchSides = (side) => {
    setSelectedSide(side);
    setEditorValue(null);
  };

  if (!isFromMobileScreen)
    return (
      <>
        <Grid
          gridTemplateColumns={`${getWidth()} 2fr`}
          pt="65px"
          bg="#F7F7F7"
          style={{
            transition: 'width 0.5s ease-in-out',
          }}
        >
          <Box
            height={height}
            overflowY="auto"
            width={getWidth()}
            data-tut="CHOOSE_TEMPLATE"
            sx={{
              transition: 'width 0.5s ease-in-out',
            }}
          >
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              height="100px"
              width="30px"
              bg="#F7F7F7"
              ml={getMl()}
              mt="200px"
              sx={{
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
                cursor: 'pointer',
                position: 'fixed',
                zIndex: 2,
                transform: 'matrix(-1, 0, 0, 1, 0, 0)',
                transition: 'width 0.5s ease-in-out',
              }}
              onClick={() => setCloseTemplateSelector(!closeTemplateSelector)}
            >
              {closeTemplateSelector ? (
                <FaChevronLeft color="black" size={30} />
              ) : (
                <FaChevronRight color="black" size={30} />
              )}
            </Flex>
            {RenderTemplateTagSelector()}
            {RenderTemplateSelector()}
            {/* } */}
          </Box>
          <BufferingOverlay isBuffering={isSubmitting}>
            {!isSubmitting && (
              <Box
                sx={{ position: 'relative', overflow: 'auto' }}
                height={height}
                bg="mobileEditingSuiteScreen"
              >
                <InlineEditing
                  containerHeight={height}
                  isLoading={isSubmitting}
                  celebrationId={id}
                  templateDetails={selectedPrintable}
                  printableLayers={printableLayers}
                  revalidatePrintables={revalidatePrintables}
                  editorValue={editorValue}
                  switchSides={switchSides}
                  selectedSide={selectedSide}
                  closeTemplateSelector={closeTemplateSelector}
                  zoom={zoom}
                  setZoom={setZoom}
                  tags={templateDetails['tag-names']}
                  RenderTagSearch={RenderTagSearch}
                  RenderFooter={RenderFooter}
                  allSupportedFontsList={allSupportedFontsList}
                />
              </Box>
            )}
          </BufferingOverlay>
        </Grid>
        <PrintablePreviewModal isOpen={isPreviewOpen} onDismiss={() => setPreviewOpen(false)} />
        <PrintableScanPreviewModal
          isOpen={isScanPreviewOpen}
          onDismiss={() => setScanPreviewOpen(false)}
        />
        {showPaymentModal ? (
          <PaymentModal
            id={celebration?.id}
            isOpen={showPaymentModal}
            onDismiss={() => setPaymentModal(false)}
          />
        ) : null}
        <Tour
          onRequestClose={toggleInnerTour}
          steps={tourConfig}
          disableInteraction
          isOpen={showTour || false}
          maskClassName="mask"
          className="helper"
          rounded={8}
          startAt={0}
          accentColor="purples.9"
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          maskSpace={8}
          showNavigation={false}
          showNumber={false}
          showCloseButton={false}
          showButtons={false}
        />
      </>
    );
  height = `${mobileInnerHeight - (mobileFooterHeight + mobileHeaderHeight + 60)}px`; // 70 is the height of header 60 is fixed height of header
  return (
    <Box sx={{ position: 'fixed', zIndex: 7, width: '100vw' }}>
      <RenderHeader
        selectedPrintable={selectedPrintable}
        selectedSide={selectedSide}
        setSelectedSide={setSelectedSide}
        onHomePress={onHomePress}
        zoom={zoom}
        isSubmitting={isSubmitting}
        setZoom={setZoom}
        setHeaderHeight={(headerHeight) => setMobileHeaderHeight(headerHeight)}
      />
      <BufferingOverlay isBuffering={isSubmitting} sx={{ height }}>
        {!isSubmitting && (
          <Box
            sx={{ position: 'relative', overflow: 'auto' }}
            height={height}
            bg="mobileEditingSuiteScreen"
          >
            <InlineEditing
              containerHeight={height}
              isLoading={isSubmitting}
              celebrationId={id}
              templateDetails={selectedPrintable}
              printableLayers={printableLayers}
              revalidatePrintables={revalidatePrintables}
              editorValue={editorValue}
              switchSides={switchSides}
              selectedSide={selectedSide}
              closeTemplateSelector={closeTemplateSelector}
              zoom={zoom}
              isFromMobileScreen
              setZoom={setZoom}
              tags={templateDetails['tag-names']}
              RenderTagSearch={RenderTagSearch}
              allSupportedFontsList={allSupportedFontsList}
              setMobileFooterHeight={setMobileFooterHeight}
              onContinuePress={onContinuePress}
              RenderFooter={RenderFooter}
            />
          </Box>
        )}
      </BufferingOverlay>

      <PrintablePreviewModal
        isFromMobileScreen
        isOpen={isPreviewOpen}
        onDismiss={() => setPreviewOpen(false)}
      />
      <PrintableScanPreviewModal
        isOpen={isScanPreviewOpen}
        onDismiss={() => setScanPreviewOpen(false)}
        isFromMobileScreen
      />
      {showPaymentModal ? (
        <PaymentModal
          id={celebration?.id}
          isOpen={showPaymentModal}
          onDismiss={() => setPaymentModal(false)}
        />
      ) : null}
    </Box>
  );
}

export default EditPosterPage;
