import { useRouter } from 'next/router';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import { FiXCircle } from 'react-icons/fi';
import { Flex, Box, Button, Text } from 'rebass';
import { useWindowSize } from 'react-use';
import axios from 'axios';
import Grid from '@/components/app/Grid';
import FileDropper from '@/components/app/FileDropper';
import {
  calculateAspectRatioFit,
  extensionRegex,
  getAspectRatio,
  renderPositionForVGBImage,
} from '@/lib/helper';
import InvalidFileError from '@/components/app/InvalidFileError';
import { supportedImageTypes } from '@/lib/constants';
import ImageFromFileOrSrc from '@/components/app/ImageFromFileOrSrc';
import ZoomSlider from '@/components/VideoPreview/ZoomSlider';
import { useMobileCheckHook } from '@/hooks/celebration';
import BufferingOverlay from '@/components/VideoPreview/BufferingOverlay';

export function scalePosition(position) {
  if (position < 3) return (position / 3) * 100;

  return position;
}
function isFileOrBlob(variable) {
  return variable instanceof File || variable instanceof Blob;
}

async function imageUrlToBlob(imageUrl) {
  try {
    const response = await axios.get('/api/proxy', {
      params: {
        imageUrl,
      },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error converting image to Blob:', error);
    return null;
  }
}

function Editor({ image, onClear, onAccept, posterAttributes = null, setFile }) {
  const editorRef = useRef();
  const { asPath } = useRouter();
  const [zoom, setZoom] = useState(posterAttributes ? scalePosition(posterAttributes?.scale) : 1.2);
  const [position, setPositions] = useState(posterAttributes ? posterAttributes.position : null);
  const [imageInfo, setImageInfo] = useState(null);
  const [newImageObj, setNewImageObj] = useState(null);
  const [boxWidth, setBoxWidth] = useState(null);
  const { width } = useWindowSize();

  const { width: AvatarEditorWidth, height: AvatarEditorHeight } = calculateAspectRatioFit(
    posterAttributes?.aspectRatio?.width || 400,
    posterAttributes?.aspectRatio?.height || 400,
    width > 500 ? 400 : width,
    width > 500 ? 400 : width
  );

  const handleChange = async () => {
    try {
      const blob = await imageUrlToBlob(image);
      setNewImageObj(URL.createObjectURL(blob));
    } catch (err) {
      setNewImageObj(image);
    }
  };

  useEffect(() => {
    if (image && !isFileOrBlob(image) && image.indexOf(window.location.origin) === -1) {
      handleChange();
    } else setNewImageObj(image);
  }, [image]);

  const handleDone = useCallback(() => {
    const perform = async () => {
      try {
        const canvas = editorRef.current.getImage().toDataURL();
        const blob = await fetch(canvas).then((res) => res.blob());
        if (posterAttributes) {
          onAccept({
            croppedPostion: editorRef.current.getCroppingRect(),
            imageInfo,
            scale: zoom,
            position,
            croppedBlob: blob,
          });
        } else onAccept(blob);
      } catch (err) {
        console.log(err);
      }
    };

    perform();
  });

  let height = AvatarEditorHeight;
  let imageWidth = AvatarEditorWidth;
  if (asPath.includes('/event')) height = 200;
  if (width < 500) {
    if (!posterAttributes) {
      height = getAspectRatio(AvatarEditorWidth);
      if (boxWidth) imageWidth = boxWidth - 50;
    } else {
      height = AvatarEditorHeight - 50;
      imageWidth = AvatarEditorWidth - 50;
    }
  }

  const handleZoom = (e) => {
    e.preventDefault();
    if (posterAttributes) {
      const scaleDelta = e.deltaY > 0 ? -1 : 1;
      const newScale = zoom + scaleDelta;
      if (newScale <= 100 && newScale >= 5) setZoom(newScale);
    }
  };
  const isBuffering = !newImageObj;

  return (
    <Box
      ref={(el) => {
        if (!el) return;
        setBoxWidth(el.getBoundingClientRect().width);
      }}
    >
      {posterAttributes && (
        <ImageFromFileOrSrc
          sx={{ display: 'none' }}
          file={image}
          src={image}
          onLoad={(img) =>
            setImageInfo({ width: img.target.naturalWidth, height: img.target.naturalHeight })
          }
        />
      )}
      <Flex flexDirection="column" maxWidth={width > 480 ? 480 : width}>
        <BufferingOverlay isBuffering={isBuffering}>
          <ReactAvatarEditor
            image={newImageObj}
            height={height}
            width={imageWidth}
            ref={editorRef}
            scale={posterAttributes ? (zoom * 3) / 100 : zoom}
            position={renderPositionForVGBImage(position)}
            onPositionChange={(pos) => (posterAttributes ? setPositions(pos) : {})}
            onWheel={handleZoom}
            crossOrigin=""
          />
        </BufferingOverlay>
        <Grid
          alignItems="center"
          gridTemplateColumns={width < 500 ? 'block' : '1fr max-content max-content'}
          gridGap={[1, 4]}
          pt={[1, 4]}
        >
          <Box>
            <ZoomSlider
              id="zoom_control"
              zoom={zoom}
              onChange={(e) => setZoom(e.target.value)}
              max={posterAttributes ? 100 : 3}
              min={posterAttributes ? 5 : 1}
              step={posterAttributes ? '1' : '.1'}
              width="80px"
              showActualValue={!posterAttributes}
              showSign={posterAttributes ? '%' : ''}
              style={{ width: '100%' }}
            />
          </Box>
          {width < 500 ? (
            <Flex justifyContent="center">
              <Button
                width={posterAttributes ? ['80%', '100%', '100%'] : '50%'}
                type="button"
                fontSize={1}
                disabled={isBuffering}
                onClick={handleDone}
              >
                Accept
              </Button>
              {!posterAttributes && (
                <Button
                  width="50%"
                  ml={1}
                  type="button"
                  fontSize={1}
                  variant="muted"
                  onClick={onClear}
                >
                  Clear
                </Button>
              )}
            </Flex>
          ) : (
            <>
              <Button
                type="button"
                disabled={isBuffering}
                fontSize={1}
                py={[1, 2]}
                onClick={handleDone}
              >
                ACCEPT
              </Button>
              {!posterAttributes ||
                (setFile && (
                  <Button py={[1, 2]} type="button" fontSize={1} variant="muted" onClick={onClear}>
                    CLEAR
                  </Button>
                ))}
            </>
          )}
        </Grid>
      </Flex>
    </Box>
  );
}
Editor.propTypes = { image: PropTypes.string.isRequired, onClear: PropTypes.func.isRequired };

// eslint-disable-next-line react/prop-types
function AvatarEditor({ src, onDismiss, posterAttributes = null, onAccept, setFile }) {
  const [fileSrc, setFileSrc] = useState(src);
  const [dropError, setDropError] = useState([]);
  const isFromMobile = useMobileCheckHook();

  useEffect(() => {
    setFileSrc(src);
    if (!src) {
      URL.revokeObjectURL(fileSrc);
    }
  }, [src]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(fileSrc);
    };
  });

  return (
    <Box
      pt={posterAttributes ? [0, 2] : 2}
      sx={{
        background: 'white',
        zIndex: 3,
        position: 'relative',
      }}
    >
      {onDismiss && (
        <Flex justifyContent="flex-end">
          <Button
            type="button"
            sx={{ cursor: 'pointer' }}
            variant="transparent"
            onClick={onDismiss}
          >
            <Text color="greys.4" as={FiXCircle} size={24} />
          </Button>
        </Flex>
      )}
      {fileSrc && (
        <Editor
          image={fileSrc}
          onClear={() => setFileSrc(null)}
          posterAttributes={posterAttributes}
          onAccept={onAccept}
          setFile={setFile}
        />
      )}
      {!fileSrc && (
        <>
          <FileDropper
            accept="image/*"
            multiple={false}
            onDrop={(files) => {
              if (
                files &&
                files[0].type.includes('image') &&
                supportedImageTypes.includes(extensionRegex.exec(files[0].name)[0].toLowerCase())
              ) {
                setDropError([]);
                if (setFile) setFile(files[0]);
                setFileSrc(URL.createObjectURL(files[0]));
              } else {
                setDropError([files[0]]);
              }
            }}
          >
            {isFromMobile && (
              <Text color="greys.5">Drag an image, or click to select an image</Text>
            )}
          </FileDropper>
          <InvalidFileError
            errorFiles={dropError}
            onDismiss={() => setDropError([])}
            supportedTypes={['image']}
          />
        </>
      )}
    </Box>
  );
}

export default AvatarEditor;
