import React from 'react';
import { Button } from 'rebass';
import { useFormikContext } from 'formik';

function SubmitButton({ children, ...props }) {
  const formik = useFormikContext();
  return (
    <Button type="submit" disabled={formik.isSubmitting} {...props}>
      {children}
    </Button>
  );
}

export default SubmitButton;
