/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Text, Box, Flex, Button, Link } from 'rebass';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useWindowSize } from 'react-use';
import { useRelatedEntity } from '@/lib/entities';
import ResponsiveReactPlayer from '@/components/app/ResponsiveReactPlayer';
import DashboardLayout from '@/components/DashboardLayout';
import { useCelebrationInRoute } from '@/hooks/celebration';

import { fetcher } from '@/lib/api';
import { sendTrackingEvent } from '@/lib/helper';
import CopyVideoLink from '@/components/Invitations/ShareInvitations/CopyVideoLink';

let timer = 0;
export function FinalVideoPreviewPage({ focusTimer }) {
  const {
    query: { id },
  } = useRouter();
  const { width } = useWindowSize();
  const video = useRelatedEntity({ id, type: 'celebrations', relation: 'final-video' });
  const celebration = useCelebrationInRoute();
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [videoState, setVideoState] = useState(null);
  const [playerHeight, setPlayerHeight] = useState(null);
  const [playerWidth, setPlayerWidth] = useState(null);
  useSWR(id ? `/v3/celebrations/${id}?shallow=true` : null, fetcher, { refreshInterval });

  useEffect(() => {
    if (
      celebration?.state === 'creating_video' ||
      celebration?.state === 'submitted_to_create_video'
    ) {
      setRefreshInterval(5000);
    }
    // TODO: works for happy path. needs error handling
    if (refreshInterval !== null && celebration?.state === 'completed') {
      setRefreshInterval(null);
    }
  }, [celebration?.state, refreshInterval]);

  useEffect(() => {
    setVideoState({ state: video?.state, time: video?.['updated-at'] });
  }, [video?.state]);

  if (
    (video?.state === 'finished' && videoState?.state === 'uploaded') ||
    video?.['updated-at'] !== videoState?.time
  ) {
    timer += 1;
    setVideoState({ state: video?.state, time: video?.['updated-at'] });
  }
  const smallMobileWidth = width < 460;
  if (video?.state === 'finished' || video?.state === 'uploaded')
    return (
      <Flex mb={2} flexDirection={['column', 'column', 'row']}>
        <Flex
          width={['100%', '100%', '80%']}
          flexDirection="column"
          justifyContent="space-between"
          mb={[2, 2, 0]}
          mr={[0, 0, 2]}
        >
          {video.state === 'finished' && videoState?.state === 'uploaded' ? (
            <Box backgroundColor="black" height={playerHeight} width={playerWidth} />
          ) : (
            <Box
              ref={(el) => {
                if (!el) return;
                setPlayerHeight(el.getBoundingClientRect().height);
                setPlayerWidth(el.getBoundingClientRect().width);
              }}
            >
              <ResponsiveReactPlayer
                key={`${video.state}${focusTimer}${timer}`}
                url={video.state === 'finished' ? video?.['asset-url'] : video?.mp4}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nofullscreen',
                    },
                  },
                }}
                style={{ borderRadius: '10px' }}
              />
            </Box>
          )}
          <Button
            as={Link}
            href={`${video.mp4}?download=true`}
            download
            backgroundColor="#7B33FB"
            sx={{ py: '10px', mx: 'auto', my: 2, textTransform: 'uppercase', borderRadius: '40px' }}
            height="fit-content"
            width={['100%', '100%', '70%']}
            minWidth="180px"
            // p={smallMobileWidth && 1}
            fontSize={smallMobileWidth && 16}
            fontWeight={smallMobileWidth && 500}
            onClick={() => {
              sendTrackingEvent(`member_visit`, {
                context: {
                  url_text: 'Download',
                  url: `${video.mp4}?download=true`,
                },
              });
            }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              Download <Text as={FaCloudDownloadAlt} ml={1} />
            </Flex>
          </Button>
        </Flex>
        <CopyVideoLink pt={[1, 0]} newDesign />
      </Flex>
    );

  return <Box />;
}

function FinalVideoPage() {
  return (
    <DashboardLayout hasNav={false} showFooter>
      <FinalVideoPreviewPage />
    </DashboardLayout>
  );
}

export default FinalVideoPage;
