import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import Form from './Form';

function TextControl({ id, type, children, onDismiss }) {
  return (
    <Form id={id} type={type} onDismiss={onDismiss}>
      {children}
    </Form>
  );
}

TextControl.propTypes = {
  children: PropTypes.string.isRequired,
  id: PropTypes.string,
};

TextControl.defaultProps = { id: null };

export default TextControl;
