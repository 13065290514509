import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js';
import { saveAs } from 'file-saver';
import { Box, Heading, Text, Flex, Image } from 'rebass';
import { useCelebration } from '@/hooks/celebration';
import { useEntity } from '@/lib/entities';
import { checkIsIpadUser } from '@/lib/helper';
import LandscapeMobile from '@/public/Images/NewDesignImages/LandscapeMobile.png';
import SafariEnableMediaRecorder1 from '@/public/Images/SafariEnableMediaRecorder1.png';
import SafariEnableMediaRecorder2 from '@/public/Images/SafariEnableMediaRecorder2.png';
import SafariEnableMediaRecorder3 from '@/public/Images/SafariEnableMediaRecorder3.png';
import SafariEnableMediaRecorder4 from '@/public/Images/SafariEnableMediaRecorder4.png';
import { useCheckIsIosUser } from '@/hooks/clip';
import { useIsFireFoxUser, useIsSafariUser } from '@/hooks/user';
import AnimatedDialog from '../app/AnimatedDialog';
import LabelButtonForFileInput from '../app/LabelButtonForFileInput';
import ResponsiveReactPlayer from '../app/ResponsiveReactPlayer';

const ImageStyles = { borderRadius: 'medium', boxShadow: 'large', objectFit: 'contain' };

function DisabledForBrowser() {
  return (
    <Box className="disabledVideoContentContainer" textAlign="left" mt={0}>
      <Heading variant="headings.h1" color="#ED2300" fontSize={30}>
        To record a video, your browser needs access to your microphone and camera.
        <br />
        <br />
        Please refresh this page and grant permission. If that doesn&apos;t work, go to your
        phone&apos;s browser settings to provide permission.
      </Heading>
    </Box>
  );
}

function DisabledForInstaAndFB() {
  return (
    <Box className="disabledVideoContentContainer" m={32} mt={0}>
      <Text pb={3} fontWeight={500}>
        This looks like your browser but isn’t. It’s created by Facebook and can be incompatible.
        Please open this in your browser. Here’s how
      </Text>

      <ResponsiveReactPlayer
        url="https://player.vimeo.com/video/928863872"
        playerWrapper={{
          paddingTop: '125.25%',
        }}
        config={{
          file: {
            attributes: {
              controlsList: 'nofullscreen',
            },
          },
        }}
        style={{ borderRadius: '10px' }}
      />
    </Box>
  );
}

function UserCapture({ onEnded, isFromMobile, template, celebrationId }) {
  const rafRef = useRef();
  const isIOSUser = useCheckIsIosUser();
  const isSafariUser = useIsSafariUser();
  const isFFUser = useIsFireFoxUser();

  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsCamerDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const parser = new UAParser();
  const osName = parser.getResult().os.name;
  const isMobileUser =
    ['iOS', 'Android'].includes(osName) || parser.getDevice().model === 'iPad' || checkIsIpadUser();
  const browserName = parser.getResult().browser.name;
  const celebration = useCelebration(celebrationId);
  const templateDetails = useEntity(celebration?.['celebration-template']);

  const images = [
    SafariEnableMediaRecorder1,
    SafariEnableMediaRecorder2,
    SafariEnableMediaRecorder3,
    SafariEnableMediaRecorder4,
  ].map((src) => {
    const image = new window.Image();
    image.src = src;
    return image.src;
  });

  const [maxTouchPoints, setMaxTouchPoints] = useState(null);

  const isDesktopSafariUser = browserName?.includes('Safari') && maxTouchPoints === 0;

  useEffect(() => {
    setMaxTouchPoints(navigator.maxTouchPoints);
  }, [setMaxTouchPoints]);

  useEffect(() => {
    if (
      browserName.toLowerCase()?.includes('chrome') &&
      !osName.toLowerCase().includes('android')
    ) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (stream) {
          console.log('Microphone access is allowed. ', stream);
          setIsLoading(false);
          stream.getTracks().forEach((track) => {
            track.stop();
          });
          stream.getAudioTracks().forEach((track) => {
            track.stop();
          });
          stream.getVideoTracks().forEach((track) => {
            track.stop();
          });
        })
        .catch(function (err) {
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            console.log('Microphone access is denied.', err);
            setIsCamerDisabled(true);
            setIsLoading(false);
          } else {
            console.error('Error accessing microphone:', err);
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [browserName, osName]);

  if (isDesktopSafariUser)
    return (
      <Box pb={4} sx={{ textAlign: 'center' }}>
        <Box mx={5} mr="10px">
          <Heading color="purples.7" textAlign="left">
            Sorry, {browserName} doesn’t support video recording
          </Heading>
          <Heading variant="headings.h1" pt={3} textAlign="left">
            3 things you can do
          </Heading>
          <Text pb={2} pt={2} textAlign="left">
            <span className="numberItems" style={{ marginRight: '4px' }}>
              1.
            </span>{' '}
            Use{' '}
            <a href="https://www.google.com/intl/en_pk/chrome/" className="link">
              Google Chrome
            </a>
            ,{' '}
            <a href="https://www.mozilla.org/en-US/firefox/new/" className="link">
              Firefox
            </a>{' '}
            or{' '}
            <a href="https://www.microsoft.com/en-us/edge" className="link">
              Microsoft Edge
            </a>
          </Text>
          <Text pb={2} textAlign="left">
            <span className="numberItems">2.</span> Use{' '}
            <span style={{ fontWeight: 'bold' }}>Quicktime Player</span> to record your video and
            then upload it.
          </Text>
          <Flex pb={2} textAlign="left">
            <span className="numberItems">3.</span>
            <Text textAlign="left">
              {' '}
              Be the coolest kid on the block and turn on Apple’s experimental record feature.
              That’s what Developers use. It’s really pretty easy 😊{' '}
              <span className="link" onClick={() => setIsOpen(true)}>
                Show Me
              </span>
            </Text>
          </Flex>
        </Box>

        <AnimatedDialog
          isOpen={isOpen}
          contentProps={{ width: '100%', maxWidth: '800px' }}
          onDismiss={() => setIsOpen(false)}
        >
          <Box mx={5} mr="10px">
            <Heading variant="headings.h1" pt={0} textAlign="left">
              How to record using Safari
            </Heading>
            <Box pb={5} pt={2}>
              <Text textAlign="left" pb={2}>
                <span className="numberItems" style={{ marginRight: '4px' }}>
                  1.
                </span>{' '}
                Safari {'>'} Preferences
              </Text>
              <Image src={images[0]} sx={ImageStyles} />
            </Box>
            <Box pb={5}>
              <Text textAlign="left">
                <span className="numberItems">2. </span>Select advanced and check “show Develop menu
                in menu bar”
              </Text>
              <Text color="#747474" ml="15px" pt={1} pb={2}>
                *Be sure to close this window afterwards{' '}
              </Text>
              <Image src={images[1]} sx={ImageStyles} />
            </Box>
            <Box pb={5}>
              <Text textAlign="left" pb={2}>
                <span className="numberItems">3.</span> Safari {'>'} Develop {'>'} Experimental
                Features{' '}
              </Text>
              <Image src={images[2]} sx={ImageStyles} />
            </Box>
            <Box pb={5}>
              <Text textAlign="left" pb={2}>
                <span className="numberItems">4.</span> Check off Media Recorder{' '}
              </Text>
              <Image src={images[3]} sx={ImageStyles} />
            </Box>
            <Box pb={5}>
              <Text textAlign="left" pb={2}>
                <span className="numberItems">5.</span> Refresh this page to record your video 😊.
              </Text>
            </Box>
          </Box>
        </AnimatedDialog>
      </Box>
    );
  const isQnATemplate = template === 'question_and_answer';
  const isClassicTemplate = template === 'normal';
  if (isDisabled) {
    return (
      <Box
        p={!isQnATemplate && 4}
        sx={{ textAlign: 'center' }}
        alignSelf={isFromMobile && 'baseline'}
        width="100%"
      >
        <DisabledForBrowser />
      </Box>
    );
  }

  if (!isLoading)
    return (
      <Box
        p={!isQnATemplate && 4}
        sx={{ textAlign: 'center' }}
        alignSelf={isFromMobile && 'baseline'}
        width="100%"
      >
        {isMobileUser && templateDetails?.['suggest-landscape-recording'] && (
          <Flex flexDirection="column">
            <Text pb={3}>For best results, please rotate your phone to landscape to record</Text>
            <Image pb={3} src={LandscapeMobile} maxWidth={350} m="auto" />
          </Flex>
        )}
        {(browserName.toLowerCase().includes('instagram') ||
          browserName.toLowerCase().includes('facebook')) &&
          (['Android'].includes(osName) ? (
            <Text pb={3} fontWeight={500}>
              This browser looks like Chrome, but isn’t. Please tap the 3 dots in the top right
              corner and select “Open in Chrome”
            </Text>
          ) : (
            <DisabledForInstaAndFB />
          ))}
        <LabelButtonForFileInput
          variant="special"
          htmlFor="user_capture"
          maxWidth={isQnATemplate && '226px'}
          fontSize={isQnATemplate && ['16px', '20px']}
        >
          <input
            ref={rafRef}
            id="user_capture"
            name="user_capture"
            type="file"
            onChange={(e) => {
              if (e.target.files) {
                if (
                  templateDetails?.['download-video-clip'] &&
                  !(isIOSUser && isSafariUser) &&
                  !isFFUser
                ) {
                  saveAs(e.target.files[0], 'your_celebration_video');
                  setTimeout(() => {
                    onEnded(e.target.files[0]);
                  }, 3000);
                } else {
                  onEnded(e.target.files[0]);
                }
              }
            }}
            capture={isClassicTemplate || isQnATemplate ? 'user' : 'environment'}
            accept="video/*"
          />
          {isQnATemplate ? 'Record Response!' : `Record Now`}
        </LabelButtonForFileInput>
      </Box>
    );

  return null;
}
UserCapture.propTypes = { onEnded: PropTypes.func.isRequired };

export default UserCapture;
