/* eslint-disable import/no-cycle */
import React, { useState, useRef, useEffect } from 'react';
import { Box, Flex, Button } from 'rebass';
import { Formik, Form } from 'formik';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import * as html2canvas from 'html2canvas';
import { FiCheckCircle } from 'react-icons/fi';
import Grid from '@/components/app/Grid';
import { useClipTextClip } from '@/hooks/clip';
import { ax } from '@/lib/api';
import { useCreateMedium } from '@/hooks/medium';
import { useCelebrationPath, useCelebrationResourceRequest, useGETClipSWR } from '@/hooks/api';
import ColorField from '@/components/app/formik/ColorField';
import FontSizeField from '@/components/app/formik/FontSizeField';
import SelectField from '@/components/app/formik/SelectField';
import { useCreateConfirmation } from '@/components/app/ConfirmationOverlay';
import { useCelebration, useMobileCheckHook } from '@/hooks/celebration';
import FormControlsWell from '../FormControlsWell';
import defaultValues from './defaultValues';
import EditableTextClip from './EditableTextClip';
import { fontOptions } from '../TextClipFonts';
import { EditClipActionButton } from '../index';

const checkStatusChanged = (clips, list) =>
  clips.map((clip) => clip.id).join('') !== list.map((clip) => clip.id).join('');

function TextControlForm({ id, type, children, onDismiss }) {
  const {
    query: { id: celebrationId },
  } = useRouter();
  const { mutate } = useGETClipSWR({ id, type });
  const { width } = useWindowSize();

  const isFromMobile = useMobileCheckHook();
  const celebration = useCelebration(celebrationId);
  const celebrationPath = useCelebrationPath();
  const textClips = useClipTextClip({ id });

  // you can access the elements with itemsRef.current[n]
  const itemsRef = useRef([]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [savePressed, setSavePressed] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [initialValues, setInitialValues] = useState(
    textClips.length > 0
      ? textClips.map((textClip) => ({
          id: textClip?.id,
          font: textClip?.font || celebration?.['font-family'],
          'text-color': textClip['text-color'] || celebration?.['font-text-colour'],
          'background-color':
            textClip['background-color'] || celebration?.['font-background-colour'],
          'font-size': textClip['font-size'] || celebration?.['font-size'],
          text: textClip.text,
          x: textClip.x,
          y: textClip.y,
          width: textClip.width,
          height: textClip.height,
          transform: textClip.transform,
          'rotation-angle': textClip['rotation-angle'],
        }))
      : []
  );

  useEffect(() => {
    if (checkStatusChanged(initialValues, textClips)) {
      setInitialValues(
        textClips.map((textClip) => ({
          id: textClip?.id,
          font: textClip?.font || celebration?.['font-family'],
          'text-color': textClip['text-color'] || celebration?.['font-text-colour'],
          'background-color':
            textClip['background-color'] || celebration?.['font-background-colour'],
          'font-size': textClip['font-size'] || celebration?.['font-size'],
          text: textClip.text,
          x: textClip.x,
          y: textClip.y,
          width: textClip.width,
          height: textClip.height,
          transform: textClip.transform,
          'rotation-angle': textClip['rotation-angle'],
        }))
      );
    }
  }, [textClips]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, initialValues.length);
  }, [initialValues]);

  const [createMedium] = useCreateMedium({ type: 'Image' });
  const [createTextClip] = useCelebrationResourceRequest('post', `celebration_text_clips`);

  const createConfirmation = useCreateConfirmation();

  const handleSave = async (values) => {
    try {
      setSubmitting(true);
      await Promise.all(
        values.map(async (value, index) => {
          const ref = itemsRef.current[index];
          const { height, width: imageWidth } = ref.getBoundingClientRect();
          const canvas = await html2canvas(ref, {
            scale: 1080 / height,
            width: imageWidth,
            backgroundColor: null,
          });
          // eslint-disable-next-line no-promise-executor-return
          const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
          blob.name = 'text-clip-image.png';
          let textClipResponse;
          if (value?.id) {
            const { id: textCliId, ...textClip } = value;
            textClipResponse = await ax().patch(
              `${celebrationPath}/celebration_text_clips/${textCliId}`,
              {
                data: { attributes: { ...textClip } },
              }
            );
            // updateTextClip(value);
          } else {
            textClipResponse = await createTextClip({ ...value, 'parent-clip-id': id });
          }

          const { id: textClipId } = textClipResponse.data.data;
          await createMedium({
            file: blob,
            attributes: { 'owner-type': 'CelebrationClip', 'owner-id': textClipId },
          });
        })
      );
      mutate();
      createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
      onDismiss();
    } catch (err) {
      console.log(err, ' err');
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (savePressed && selectedIndex === -1) handleSave(savePressed);
    setSavePressed(null);
  }, [savePressed, selectedIndex]);

  const SubmitForm = (values) => {
    if (selectedIndex === -1) handleSave(values);
    else {
      setSelectedIndex(-1);
      setSavePressed(values);
    }
  };

  const handleDelete = async (e, textClip) => {
    e.preventDefault();
    if (textClip) {
      if (textClip?.id)
        await ax().delete(`${celebrationPath}/celebration_text_clips/${textClip?.id}`);
      // setSelectedIndex(-1)
      mutate();
    }
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={SubmitForm}>
      {({ values, setValues }) => (
        <Form>
          <Flex justifyContent="space-between" mb={1}>
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault();
                const updatedArray = [
                  ...values,
                  {
                    ...defaultValues,
                    font: celebration?.['font-family'] || 'sans-serif !important',
                    'text-color': celebration?.['font-text-colour'] || '#ffffff',
                    'background-color': celebration?.['font-background-colour'] || '#6269F0',
                    'font-size': celebration?.['font-size'] || 25,
                  },
                ];
                setValues(updatedArray);
                // setSelectedIndex(updatedArray.length - 1)
              }}
            >
              Add Text Clip
            </Button>
            {isFromMobile ? (
              <EditClipActionButton disabled={isSubmitting} width={width} />
            ) : (
              <Button variant="special" disabled={isSubmitting || values.length === 0}>
                Save Changes to this clip
              </Button>
            )}
          </Flex>

          {selectedIndex !== -1 && (
            <FormControlsWell mb={1} p={width < 650 ? 1 : 2}>
              <Grid
                gridTemplateColumns={
                  width < 650 ? '45% 45%' : 'max-content max-content max-content 1fr'
                }
                gridGap={2}
              >
                <ColorField
                  name="text-color"
                  celebrationAttrName="font-text-colour"
                  label="Text Color"
                  pb={0}
                  valueIndex={selectedIndex}
                  width={width < 650 && '100%'}
                  fontSize={width < 650 && 15}
                />
                <ColorField
                  name="background-color"
                  celebrationAttrName="font-background-colour"
                  label="Background Color"
                  pb={0}
                  valueIndex={selectedIndex}
                  fontSize={width < 650 && 15}
                  width={width < 650 && '100%'}
                />
                <FontSizeField
                  name="font-size"
                  celebrationAttrName="font-size"
                  label="Font size"
                  mr={2}
                  pb={0}
                  valueIndex={selectedIndex}
                  width={width < 650 && '100%'}
                  fontSize={width < 650 && 15}
                />
                <Box width={width < 650 && '100%'}>
                  <SelectField
                    name="font"
                    celebrationAttrName="font-family"
                    label="Font Family"
                    maxWidth={360}
                    width={width < 650 && '100%'}
                    pb={0}
                    options={fontOptions}
                    valueIndex={selectedIndex}
                    fontSize={width < 650 && 15}
                    selectProps={{
                      menuPlacement: 'top',
                      styles: {
                        option: (provided, state) => ({
                          ...provided,
                          fontFamily: state.value,
                        }),
                      },
                    }}
                  />
                </Box>
              </Grid>
            </FormControlsWell>
          )}

          <Grid>
            <Box sx={{ gridArea: '1 / 1 / 2 / 2' }}>{children}</Box>
            {values.length > 0 &&
              values.map((item, i) => (
                <Box
                  key={item?.text}
                  sx={{ zIndex: selectedIndex === i ? 1 : 0, gridArea: '1 / 1 / 2 / 2 ' }}
                >
                  <EditableTextClip
                    index={i}
                    selectedIndex={selectedIndex}
                    currentValue={item}
                    ref={function (el) {
                      itemsRef.current[i] = el;
                    }}
                    onDelete={handleDelete}
                    isFromMobile={isFromMobile}
                    onSelectIndex={(index) => setSelectedIndex(index)}
                  >
                    {children}
                  </EditableTextClip>
                </Box>
              ))}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default TextControlForm;
