import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { Box, Text, Flex } from 'rebass';
import { Label } from '@rebass/forms';
import byteSize from 'byte-size';
import { capitalizeFirstLetter, extensionRegex } from '@/lib/helper';
// import { useMobileCheckHook } from '@/hooks/celebration';
import { supportedAudioTypes, supportedImageTypes, supportedVideoTypes } from '@/lib/constants';
import FileDropper from '../FileDropper';
import InvalidFileError from '../InvalidFileError';

function FilePreview({ file }) {
  return (
    <Flex justifyContent="space-between" py={1} maxWidth="480px">
      <Text fontSize={14} maxWidth="70%">
        {file.name || file.map((fil) => fil.name).join(', ')}
      </Text>
      <Text fontSize={14} color="greys.5" ml={2}>
        {file?.size
          ? `${byteSize(file.size)}`
          : `${byteSize(file.reduce((total, obj) => obj.size + total, 0))}`}
      </Text>
    </Flex>
  );
}

function FileDropperField({ name, onDrop, label, multiple, placeholder, accept, ...props }) {
  const [field, , helper] = useField(name);
  const formik = useFormikContext();
  const [dropError, setDropError] = useState([]);

  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  // const isFromMobile = useMobileCheckHook();
  useEffect(() => {
    if (field?.value?.length > 0) formik.submitForm();
  }, [field]);

  return (
    <Box pb={5} {...props}>
      {label && label !== 'audio' && (
        <Label htmlFor={name} pb={2}>
          {capitalizeFirstLetter(label)}
        </Label>
      )}
      {field.value && <FilePreview file={field.value} />}
      <FileDropper
        accept={browserName?.includes('Safari') && label === 'video' ? '' : accept}
        label={label}
        multiple={multiple}
        onDrop={(val) => {
          setDropError([]);
          let filteredResp = val.filter((f) => {
            const fileExtensions = extensionRegex.exec(f.name.toLowerCase());
            if (label.toLowerCase() === 'video or image' || label.toLowerCase() === 'add media')
              return (
                (f.type.includes('video') &&
                  supportedVideoTypes.includes(fileExtensions[0].toLowerCase())) ||
                (f.type.includes('image') &&
                  supportedImageTypes.includes(fileExtensions[0].toLowerCase())) ||
                fileExtensions[1] === 'webm'
              );

            return (
              (f.type.includes(label) &&
                (label === 'video'
                  ? supportedVideoTypes.includes(fileExtensions[0].toLowerCase())
                  : supportedImageTypes.includes(fileExtensions[0].toLowerCase()))) ||
              fileExtensions[1] === 'webm'
            );
          });

          if (label === 'audio')
            filteredResp = val.filter(
              (f) =>
                f.type.includes(label) &&
                supportedAudioTypes.includes(extensionRegex.exec(f.name)[0].toLowerCase()) &&
                extensionRegex.exec(f.name)[1] !== 'm3u8'
            );

          if (filteredResp.length === val.length) {
            // eslint-disable-next-line no-unused-expressions
            if (multiple) helper.setValue(val);
            else helper.setValue(val[0]);
          } else {
            const b = new Set(filteredResp);
            const differenceOfArray = [...val].filter((x) => !b.has(x));
            setDropError(differenceOfArray);
          }
        }}
      >
        {placeholder && <Text color="greys.4">{placeholder}</Text>}
      </FileDropper>
      <InvalidFileError
        errorFiles={dropError}
        onDismiss={() => setDropError([])}
        supportedTypes={[label]}
      />
      <ErrorMessage name={name}>
        {(message) => (
          <Text color="error" p={1} pt={2} fontSize={1}>
            {message}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
}

FileDropperField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  onDrop: PropTypes.func,
};

FileDropperField.defaultProps = { placeholder: '', multiple: false, onDrop: () => {} };

export default FileDropperField;
