import React, { useState, useEffect } from 'react';
import { Text } from 'rebass';

function RotatingText(props) {
  const texts = ['Processing', 'Still Processing', 'Will be ready shortly'];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return <Text {...props}>{props?.text || texts[currentTextIndex]}</Text>;
}

export default RotatingText;
