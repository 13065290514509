import React, { useState } from 'react';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import UAParser from 'ua-parser-js';
import { FiCheckCircle } from 'react-icons/fi';
import { usePatchClip } from '@/hooks/api';
import { useClip } from '@/hooks/clip';
import VolumeField from '@/components/app/formik/VolumeField';
import { useUpdateEntity } from '@/lib/entities';
import { useCelebration } from '@/hooks/celebration';
import { relativeVideoVolume } from '@/lib/helper';
import FormControlsWell from './FormControlsWell';
import AutoSave from '../app/formik/AutoSave';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';
import AnimatedDialog from '../app/AnimatedDialog';
import CopyForTextNormalization from '../app/Copy-for-text-normalization';

function VolumeControl({ id, type, isFromMobile }) {
  const clip = useClip({ type, id });
  const [showNormalizedDetail, setShowNormalizedDetail] = useState(false);

  const {
    query: { id: celebrationId },
  } = useRouter();
  const celebration = useCelebration(celebrationId);
  const createConfirmation = useCreateConfirmation();

  const parser = new UAParser();
  const isSafari = parser.getResult().browser.name === 'Safari';

  const [updateClip] = usePatchClip({ id, type });
  const updateClipEntity = useUpdateEntity({ id, type });
  const soundTrackVolume =
    typeof clip['soundtrack-volume'] !== 'undefined'
      ? clip['soundtrack-volume']
      : (clip.video && celebration?.['video-clip-music-volume']) ||
        celebration?.['image-clip-music-volume'];
  return (
    <Box>
      {isSafari && (
        <Text mb={2}>
          <i>
            * Raising the clip volume to 300 is in beta for Safari. To increase the clip volume past
            100, please use another browser (Chrome, Firefox, or Edge).
          </i>
        </Text>
      )}
      <AnimatedDialog
        contentProps={{ width: '100vw', maxWidth: 500 }}
        isOpen={showNormalizedDetail}
        onDismiss={() => setShowNormalizedDetail(false)}
      >
        <CopyForTextNormalization />
      </AnimatedDialog>
      <Formik
        initialValues={{
          volume: relativeVideoVolume(clip.volume, true),
          'soundtrack-volume': soundTrackVolume,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (
            (values.volume >= 0 && values.volume !== relativeVideoVolume(clip.volume, true)) ||
            values['soundtrack-volume'] !== soundTrackVolume
          ) {
            const relativeVolume = relativeVideoVolume(values.volume, false);
            const changedValues = { ...values, volume: relativeVolume };
            updateClipEntity(changedValues);
            await updateClip(changedValues);
            setSubmitting(false);
            createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
          }
        }}
      >
        <Flex justifyContent="space-between">
          {!clip.video ? (
            <FormControlsWell mb={isFromMobile ? 1 : 3} p={2} pb={0} width="100%">
              <VolumeField
                name="soundtrack-volume"
                label="Music Volume"
                isFromMobile={isFromMobile}
              />
              <AutoSave />
            </FormControlsWell>
          ) : (
            <>
              <FormControlsWell mb={isFromMobile ? 1 : 3} p={2} pb={0} width="100%" mr={1}>
                {celebration?.['normalize-audio'] ? (
                  <Text fontSize="16px" lineHeight="24px" fontWeight={600} color="purples.7">
                    All video clip volumes will be automatically adjusted in the final version of
                    the video.
                    <br />
                    <br />
                    Music will be turned off during video clips
                  </Text>
                ) : (
                  <>
                    {clip.video && (
                      <VolumeField name="volume" label="Clip Volume" isFromMobile={isFromMobile} />
                    )}
                    <VolumeField
                      name="soundtrack-volume"
                      label="Music Volume"
                      isFromMobile={isFromMobile}
                    />
                    <AutoSave />
                  </>
                )}
              </FormControlsWell>
              {/* <Box
                bg="greys.1"
                p={2}
                sx={{ borderRadius: 'medium' }}
                mb={isFromMobile ? 1 : 3}
                p={2}
                pb={0}
                width="25%"
              >
                <Flex justifyContent="center" my={2}>
                  <Switch
                    className="switch normalize"
                    checked={!!celebration?.['normalize-audio']}
                    disabled={submitting}
                    onClick={SubmitChanges}
                  />
                </Flex>
                <Text
                  textAlign="center"
                  sx={{
                    justifySelf: 'center',
                    fontSize: '16px',
                    lineHeight: '150%',
                  }}
                  color="purples.7"
                  mb={1}
                >
                  <b> Normalize clip volume (In beta)</b>
                  <br />
                  <span
                    onClick={() => setShowNormalizedDetail(true)}
                    style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
                  >
                    {' '}
                    Whats this?
                  </span>
                </Text>
              </Box> */}
            </>
          )}
        </Flex>
      </Formik>
    </Box>
  );
}

VolumeControl.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isFromMobile: PropTypes.string,
};

VolumeControl.defaultProps = {
  isFromMobile: false,
};

export default VolumeControl;
