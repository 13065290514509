import React, { memo, useEffect, useState } from 'react';
import { Box, Image } from 'rebass';
import Placeholder from '@/public/Images/Placeholder.jpeg';

function RenderImageLayer({ onClick, onImageReady, image, secondUrl, height, width }) {
  const [fileSrc, setFileSrc] = useState(image);

  useEffect(() => {
    setFileSrc(image);
  }, [image]);

  return (
    <Box height="inherit" width="inherit" overflow="hidden" onClick={onClick}>
      <Image
        src={fileSrc || secondUrl || Placeholder}
        id="myImage"
        sx={{
          objectFit: 'cover',
        }}
        onLoad={onImageReady}
        height={height}
        width={width}
        loading="lazy"
        onError={(e) => {
          if (fileSrc === image) setFileSrc(secondUrl);
          const { target } = e; // The image element that triggered the error
          if (target.naturalWidth === 0) {
            console.log('Image not found or failed to load.');
          } else {
            console.log('Error loading image:', e);
          }
        }}
      />
    </Box>
  );
}

export default memo(RenderImageLayer);
