import React from 'react';
import { Box, Button, Flex, Image } from 'rebass';
import PrintablePreview1 from '@/public/Images/VideoGuestBook/PrintablePreview1.png';
import PrintablePreview2 from '@/public/Images/VideoGuestBook/PrintablePreview2.png';
import AnimatedDialog from '../app/AnimatedDialog';
import OnboardSection from '../OnboardSection';
import { StepHeading, SubTitleHeading } from './ReusableComponents';

function PrintablePreviewModal({ isOpen, onDismiss }) {
  return (
    <AnimatedDialog contentProps={{ width: '90vw' }} isOpen={isOpen} onDismiss={onDismiss}>
      <Box m="auto">
        <OnboardSection
          step={<StepHeading>STEP 2</StepHeading>}
          subTitle={<SubTitleHeading>CREATE PRINTABLES</SubTitleHeading>}
          support={
            <StepHeading px={2}>
              Use our templates and tools to create your posters and table cards, then print them
              yourself, print online or send them off to a local printer shop (we provide
              suggestions).
            </StepHeading>
          }
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={['column', 'column', 'row']}
          >
            <Box sx={{ position: 'relative' }} mr={[0, 0, 3]}>
              <Image src={PrintablePreview2} />
            </Box>
            <Box sx={{ position: 'relative' }}>
              <Image src={PrintablePreview1} />
            </Box>
          </Flex>
          <Flex m="auto" justifyContent="center" flexDirection="column" mt={2}>
            <Button bg="purples.5" variant="primary" m="auto" onClick={onDismiss}>
              GET STARTED
            </Button>
          </Flex>
        </OnboardSection>
      </Box>
    </AnimatedDialog>
  );
}

export default PrintablePreviewModal;
