import React, { useMemo, useState, useEffect } from 'react';
import { Flex, Text, Box, Image } from 'rebass';
import Tappable from 'react-tappable/lib/Tappable';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import store from 'store';
import { MdCheckCircle, MdOutlineCircle } from 'react-icons/md';
import { fetcher, postPrivateEventDetails } from '@/lib/api';
import {
  useClipName,
  useClipThumbnail,
  useClipDuration,
  useVideoClipUploadStatus,
  useClipStickerClip,
} from '@/hooks/clip';
import { useEntity } from '@/lib/entities';
import Grid from '@/components/app/Grid';
import { LoadingBg } from '@/components/app/Loading';
import { ReactComponent as HandleIconLarge } from '@/components/app/handle_icon_svg.svg';
import { useCelebration, useCelebrationIdFromRoute } from '@/hooks/celebration';
import { useCurrentMemberName } from '@/hooks/member';
import { useUpdateClip } from '@/hooks/mutations';
import { checkIfIdExist, showLockIcon } from '@/lib/helper';
import TextClipImage from './VideoPreview/TextClipImage';
import StickerClipImage from './VideoPreview/StickerClipImage';
import RotatingText from './app/RotatingText';
import Lock from './app/Svgs/Lock';
import PaymentModal from './CelebrationHomeScreen/common/PaymentModal';

export function emailRender() {
  return (
    <span>
      <br />
      <a
        style={{ color: 'white', cursor: 'pointer' }}
        href="mailto:help@celebrate.buzz?subject=Error uploading a clip"
      >
        help@celebrate.buzz
      </a>
    </span>
  );
}

function TimeBadge({ duration }) {
  const formattedDuration = useMemo(() => {
    if (!duration) return 0;
    return duration.toFixed();
  }, [duration]);

  return (
    <Text color="white" fontSize={0} sx={{ lineHeight: 1 }}>
      {formattedDuration}s
    </Text>
  );
}

function Clip({
  id,
  type,
  height,
  width,
  showName,
  isFromMobile,
  onClick,
  onSelect,
  minWidth,
  lastClip,
  onDragStart,
  isFromVideoGuestBook,
  selectionEnabled = false,
  isFromGallery = false,
  downloadUrl = undefined,
  index,
  ...props
}) {
  const {
    query: { id: celebId },
  } = useRouter();
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [showPaymentModal, setPaymentModal] = useState(false);
  const authorName = useClipName({ id, type });
  const memberName = useCurrentMemberName() || '';
  const thumbnail = useClipThumbnail({ id, type });
  const duration = useClipDuration({ id, type });
  const uploadStatus = useVideoClipUploadStatus(id, type === 'celebration-image-clips' && true);
  const celebrationId = useCelebrationIdFromRoute(celebId);
  const updateClip = useUpdateClip({ id, type });
  const stickerClips = useClipStickerClip({ id });
  const clip = useEntity({ id, type });
  const celebration = useCelebration(celebId);
  const showLock = isFromGallery ? false : showLockIcon(celebration, clip, index);

  const mainVideo = useEntity(clip?.video);
  const mainImage = useEntity(clip?.image);
  const isSelected = !!downloadUrl;

  const rotation = clip?.['rotation-angle'];
  const cursor =
    uploadStatus === 'finished' ||
    uploadStatus === false ||
    uploadStatus === 'stalled_upload' ||
    uploadStatus === 'error'
      ? 'pointer'
      : 'not-allowed';

  useSWR(
    refreshInterval && type && checkIfIdExist(celebrationId)
      ? `/v3/celebrations/${celebrationId}/${type.replaceAll('-', '_')}/${id}`
      : null,
    fetcher,
    { refreshInterval }
  );

  useEffect(() => {
    if (
      uploadStatus === 'finished' &&
      mainVideo &&
      mainVideo.type === 'Video' &&
      mainVideo['duration-in-seconds'] &&
      !duration
    ) {
      // Updating clip to add clip duration as trim-end. Check for mpg upload
      updateClip({ 'trim-end': mainVideo.duration / 1000 });
    }
  }, [uploadStatus]);

  useEffect(() => {
    if (
      uploadStatus === 'pending_upload' ||
      uploadStatus === 'uploaded' ||
      uploadStatus === 'processing'
    ) {
      setRefreshInterval(5000);
    }
    // TODO: works for happy path. needs error handling
    if (refreshInterval !== null && uploadStatus === 'finished') {
      setRefreshInterval(0);
    }
  }, [uploadStatus, setRefreshInterval]);

  const onSelectInternal = () => {
    onSelect(
      `${
        mainVideo?.['unprocessed-url'] || mainImage?.['unprocessed-url'] || clip?.['giphy-gif-url']
      }?download=true`
    );
  };

  const onClickPress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (cursor === 'pointer' && !store.get('isDragging')) {
      if (showLock) {
        postPrivateEventDetails('celebration_tapped_pay', {
          'subject-type': 'Celebration',
          'subject-id': celebId,
        });
        setPaymentModal(true);
      } else if (selectionEnabled) onSelectInternal();
      else onClick();
    }
  };

  function RenderErrorMessage() {
    if (isFromMobile) return 'Error';
    if (authorName)
      return `This file by ${authorName} cannot be processed. Please ask them to upload a different picture or video. If you need help, please contact `;
    return `File cannot be processed. Please upload a different picture or video. If you need help, please contact `;
  }

  let fontSize = '16px';
  if (isFromMobile) fontSize = '24px';

  if ((uploadStatus === 'stalled_upload' || uploadStatus === 'error') && isFromVideoGuestBook)
    return null;

  if (showPaymentModal)
    return (
      <PaymentModal
        id={celebrationId}
        isOpen={showPaymentModal}
        onDismiss={() => setPaymentModal(!showPaymentModal)}
      />
    );
  let finalHeight;
  if (isFromGallery) {
    finalHeight = undefined;
  } else {
    finalHeight = !showName ? height + 30 : height;
  }
  return (
    <Tappable
      onClick={onClickPress}
      style={{
        overflow: 'hidden',
        position: 'relative',
        zIndex: 3,
        cursor: cursor || 'pointer',
        minWidth,
        marginRight: lastClip ? '20px' : showName && isFromMobile && '10px',
        height: finalHeight,
        borderRadius: isFromGallery ? '3px' : '6px',
        border: clip['question-colour'] ? `4px solid ${clip['question-colour']}` : undefined,
      }}
      onDragStart={onDragStart || undefined}
      onTouchMove={onDragStart ? (e) => onDragStart(e, true) : undefined}
      onTouchEnd={() =>
        store.get('isDragging') && isFromMobile ? store.remove('isDragging') : null
      }
      {...props}
      onTap={isFromMobile ? onClickPress : undefined}
    >
      {clip?.['question-position'] !== undefined && clip?.['question-position'] !== null && (
        <Text
          mb="-31px"
          textAlign="right"
          p="5px 10px"
          color="#fff"
          sx={{ position: 'relative', zIndex: 1, textShadow: '0 0 5px rgba(0, 0, 0, 1)' }}
        >
          {clip?.['question-position']}
        </Text>
      )}
      {!showName && isFromMobile && (
        <Text
          color="black"
          mb="5px"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {authorName}
        </Text>
      )}
      <Grid
        overflow="hidden"
        style={{
          position:
            !isFromGallery &&
            stickerClips.length > 0 &&
            (uploadStatus === 'finished' || uploadStatus === false) &&
            'absolute',
        }}
      >
        {(uploadStatus === 'processing' ||
          uploadStatus === 'pending_upload' ||
          uploadStatus === 'uploaded') && (
          <LoadingBg
            sx={{
              pointerEvents: 'none',
              gridArea: '1 / 1 / 2 / 2',
              height,
            }}
          >
            <RotatingText
              className="loading"
              fontSize="16px"
              color="white"
              textAlign="center"
              mt={isFromMobile ? '15%' : '30%'}
            />
          </LoadingBg>
        )}
        {uploadStatus === 'stalled_upload' && (
          <LoadingBg
            sx={{
              pointerEvents: 'none',
              gridArea: '1 / 1 / 2 / 2',
              height,
              overflowY: 'auto',
            }}
          >
            <Text color="white" fontSize={fontSize} textAlign="center" m="10px">
              {isFromMobile
                ? 'Error'
                : `${authorName || memberName} tried to ${
                    type === 'celebration-image-clips'
                      ? 'upload a picture'
                      : 'upload/record a video'
                  }, but it did not reach us. Please ask them to try again`}
            </Text>
          </LoadingBg>
        )}
        {uploadStatus === 'error' ? (
          <LoadingBg
            sx={{
              pointerEvents: 'none',
              gridArea: '1 / 1 / 2 / 2',
              height,
            }}
          >
            <Text color="white" fontSize={fontSize} textAlign="center" m="10px">
              {RenderErrorMessage()}
              {!isFromMobile && emailRender()}
            </Text>
          </LoadingBg>
        ) : (
          <>
            {showLock ? (
              <LoadingBg
                sx={{
                  pointerEvents: 'none',
                  gridArea: '1 / 1 / 2 / 2',
                  height,
                }}
              >
                <Flex height="inherit" justifyContent="center" alignItems="center">
                  <Lock color="#fff" width={40} height={40} />
                </Flex>
              </LoadingBg>
            ) : (
              (uploadStatus === 'finished' || uploadStatus === false) && (
                <>
                  <Image
                    height={height}
                    width={width}
                    src={thumbnail}
                    sx={{
                      pointerEvents: 'none',
                      gridArea: '1 / 1 / 2 / 2',
                      objectFit: 'cover',
                      borderRadius: isFromGallery && '3px',
                      transform: `rotate(${rotation}deg)`,
                      zIndex: (rotation || isFromMobile || showLock) && -1,
                      opacity: isSelected ? 0.7 : 1,
                    }}
                  />
                  {selectionEnabled && (
                    <Text
                      as={Tappable}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onSelectInternal();
                      }}
                      onTap={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onSelectInternal();
                      }}
                      sx={{
                        cursor: 'pointer',
                        ml: 'auto',
                        mt: '-35px',
                        mr: '10px',
                        bg: '#fff',
                        borderRadius: '100%',
                        zIndex: 10,
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      {isSelected ? (
                        <MdCheckCircle color="#3866F4" size={30} />
                      ) : (
                        <MdOutlineCircle color="#3866F4" size={30} />
                      )}
                    </Text>
                  )}
                </>
              )
            )}
            <TextClipImage
              id={id}
              height={height}
              width={width}
              sx={{
                pointerEvents: 'none',
                gridArea: '1 / 1 / 2 / 2',
                objectFit: 'cover',
                zIndex: showLock ? -1 : 2,
              }}
            />
            <StickerClipImage
              id={id}
              height={height}
              width={width}
              stickerClips={stickerClips}
              sx={{ pointerEvents: 'none', gridArea: '1 / 1 / 2 / 2', objectFit: 'cover' }}
              zIndex={isFromMobile || showLock ? -1 : 10}
            />
          </>
        )}
        {(uploadStatus === 'finished' || uploadStatus === 'uploaded' || uploadStatus === false) && (
          <Flex
            height="100%"
            width={width}
            alignItems="flex-end"
            p={1}
            justifyContent="flex-start"
            sx={{
              background: 'linear-gradient(to top, rgba(0,0,0,.1), rgba(0,0,0,0))',
              gridArea: '1 / 1 / 2 / 2',
              border: isFromMobile && '2px solid black',
            }}
          >
            <Box>
              {isFromMobile && !isFromGallery && (
                <Box
                  className="handle"
                  sx={{
                    objectFit: 'contain',
                    marginTop: `-${7}px`,
                    position: 'relative',
                    zIndex: 9,
                  }}
                >
                  <HandleIconLarge />
                </Box>
              )}
              {showName && !isFromMobile && !isFromGallery && (
                <Text color="white">{authorName}</Text>
              )}
              <TimeBadge
                duration={
                  (showName || isFromMobile) && mainVideo
                    ? mainVideo['duration-in-seconds']
                    : duration
                }
              />
            </Box>
          </Flex>
        )}
        {isFromGallery && (
          <Text
            color="black"
            title={authorName}
            sx={{
              fontSize: '16px',
              lineHeight: 1.3,
              ml: '5px',
              letterSpacing: '-0.21px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {authorName}
          </Text>
        )}
      </Grid>
    </Tappable>
  );
}

export default Clip;
