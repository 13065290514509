import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { FiRotateCcw, FiRotateCw } from 'react-icons/fi';
import { useUpdateClip } from '@/hooks/mutations';
import FormControlsWell from './FormControlsWell';
import NavButton from './Nav/NavButton';

function RotateControl({ isCurrent, rotation, id, type, isFromMobile }) {
  // const [updateClip, { loading }] = usePatchClip({ id, type });
  const updateClip = useUpdateClip({ id, type });

  if (isCurrent)
    return (
      <FormControlsWell mb={isFromMobile ? 1 : 3} mt={isFromMobile && 1} p={isFromMobile ? 2 : 4}>
        <Flex justifyContent="center">
          <NavButton
            icon={FiRotateCcw}
            onClick={() => {
              const nextState = rotation - 90;
              const attrs = { 'rotation-angle': nextState === -360 ? 0 : nextState };
              updateClip(attrs);
            }}
            mr={1}
          >
            Rotate Left
          </NavButton>
          <NavButton
            icon={FiRotateCw}
            onClick={() => {
              const nextState = rotation + 90;
              const attrs = { 'rotation-angle': nextState === 360 ? 0 : nextState };
              updateClip(attrs);
            }}
            ml={1}
          >
            Rotate Right
          </NavButton>
        </Flex>
      </FormControlsWell>
    );

  return null;
}

RotateControl.propTypes = {
  rotation: PropTypes.number,
  isCurrent: PropTypes.bool.isRequired,
};

RotateControl.defaultProps = {
  rotation: 0,
};

export default RotateControl;
