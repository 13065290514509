import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import { useOnlineStatus } from '@/hooks/useOnlineStatus';

function ProgressTracker({ progress }) {
  const isOnline = useOnlineStatus();

  if (!isOnline)
    return (
      <Text textAlign="right" fontStyle="italic" color="purples.7" pb={1}>
        we&apos;ve lost your Internet connection and your Upload is currently stalled. It will begin
        again once your connection is strong enough. Please do not kill the app or it will end the
        upload.
      </Text>
    );

  if (progress === 100)
    return (
      <Text textAlign="right" fontStyle="italic" color="purples.7" pb={1}>
        Almost done. Now processing…
      </Text>
    );

  return <Box height="24px" />;
}
ProgressTracker.defaultProps = { progress: 0 };
ProgressTracker.propTypes = { progress: PropTypes.number };

export default ProgressTracker;
