import React, { useRef } from 'react';
import { Label } from '@rebass/forms';
import { Flex, Box } from 'rebass';

function ZoomSlider({
  max,
  min,
  step,
  zoom = 0,
  width = '55px',
  onChange,
  showFullPercentage,
  showSign = '%',
  parentWidth,
  showActualValue = false,
  ...props
}) {
  const inputRef = useRef();

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    const rect = event.target.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const { width: rectWidth } = rect;
    const value = (x / rectWidth) * max;

    if (value <= max && value >= min) onChange({ target: { value } });
  };

  return (
    <Flex alignItems="center" marginRight={1} minWidth={180} width={parentWidth}>
      <Label htmlFor="zoom_control" color="#0d1216" width={width} alignItems="center">
        <Box
          as="input"
          type="input"
          value={
            showActualValue
              ? (+zoom).toFixed(1)
              : parseInt(zoom * (showFullPercentage ? 100 : 1), 10)
          }
          onChange={onChange}
          p={1}
          pr={0}
          width={50}
          sx={{
            border: 'none',
            bg: 'transparent',
            outline: 'none',
            fontSize: 1,
            textAlign: 'right',
          }}
        />
        &nbsp;
        {showSign}
      </Label>
      <input
        ref={inputRef}
        id="zoom_control"
        type="range"
        value={zoom || 0}
        onChange={onChange}
        max={max}
        min={min}
        step={step}
        onTouchMove={handleTouchMove}
        {...props}
      />
    </Flex>
  );
}

export default ZoomSlider;
