import { useRef, useState, useReducer, useEffect } from 'react';
import store from 'store';

const timerReducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return { ...state, isRunning: true };
    case 'stop':
      return { ...state, isRunning: false };
    case 'setSeconds':
      return {
        ...state,
        ended: false,
        seconds: store.get('currentSeconds') ? store.get('currentSeconds') : action.seconds,
      };
    case 'addSeconds':
      return { ...state, seconds: state.seconds + action.delta };
    case 'ended':
      return { ...state, isRunning: false, seconds: 0 };
    case 'updateEndSeconds':
      return { ...state, endSeconds: action.endSeconds };
    default:
      return state;
  }
};

export default function useTimerTo(initialEndSeconds = 0) {
  const endSeconds = initialEndSeconds ? Math.ceil(initialEndSeconds / 0.016) * 0.016 : 0;
  const [state, dispatch] = useReducer(timerReducer, { seconds: 0, isRunning: false, endSeconds });

  useEffect(() => {
    const nextEndSeconds = initialEndSeconds ? Math.ceil(initialEndSeconds / 0.016) * 0.016 : 0;
    dispatch({ type: 'updateEndSeconds', endSeconds: nextEndSeconds });
  }, [initialEndSeconds]);

  return [state, dispatch];
}

export function useFrameNow(isActive) {
  const [now, setNow] = useState(null);
  const requestRef = useRef();

  const animate = (time) => {
    setNow(time);
    // The 'state' will always be the initial value here
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (isActive) {
      requestRef.current = requestAnimationFrame(animate);
    }
    if (!isActive) {
      cancelAnimationFrame(requestRef.current);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [isActive]); // Make sure the effect runs only once

  return now;
}
