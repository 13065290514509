import React, { useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { Box, Text, Flex, Image, Button } from 'rebass';
import useOnClickOutside from 'use-onclickoutside';
import { AiOutlineWarning } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { useWindowSize } from 'react-use';
import { useCelebration, useMobileCheckHook } from '@/hooks/celebration';
import { useCelebrationRoute } from '@/hooks/routes';
import { CELEBRATE_HELP_PAGE, VIDEO_GUEST_BOOK } from '@/lib/constants';
// eslint-disable-next-line import/no-cycle
import PaymentModal from '@/components/CelebrationHomeScreen/common/PaymentModal';
import { sendTrackingEvent } from '@/lib/helper';
import { postPrivateEventDetails } from '@/lib/api';
import RouterLink from '../RouterLink';
import Event from '../app/Svgs/NewDesign/SideBar-Icons/EditCelebration';
import EventSelected from '../app/Svgs/NewDesign/SideBar-Icons/EditCelebrationSelected';
import PrintIcon from '../app/Svgs/NewDesign/SideBar-Icons/Print';
import QRCode from '../app/Svgs/NewDesign/SideBar-Icons/QRCode';
import QRCodeSelected from '../app/Svgs/NewDesign/SideBar-Icons/QRCodeSelected';
import EditingSuite from '../app/Svgs/NewDesign/SideBar-Icons/EditingSuite';
import EditingSuiteSelected from '../app/Svgs/NewDesign/SideBar-Icons/EditingSuiteSelected';
import HelpIcon from '../app/Svgs/NewDesign/SideBar-Icons/HelpIcon';
import HelpIconSelected from '../app/Svgs/NewDesign/SideBar-Icons/HelpIconSelected';
import HomeIcon from '../app/Svgs/NewDesign/SideBar-Icons/home';
import Lock from '../app/Svgs/Lock';
import VideoIconSelected from '../app/Svgs/NewDesign/SideBar-Icons/VideoSelected';
import VideoIcon from '../app/Svgs/NewDesign/SideBar-Icons/Video';
import Gallery from '../app/Svgs/NewDesign/SideBar-Icons/Gallery';

function trackingEvent(urlText, url) {
  sendTrackingEvent(`member_visit`, {
    context: {
      url_text: urlText,
      url,
    },
  });
}

function LockImage({ position = 'absolute', marginLeft = '14px', marginTop = '7px' }) {
  return (
    <Box
      sx={{
        borderRadius: '50%',
        border: '3px solid #606060',
        width: 25,
        height: 25,
        paddingLeft: '5px',
        background: 'white',
        position,
        marginLeft,
        marginTop,
      }}
    >
      <Lock />
    </Box>
  );
}

const textStyle = {
  fontSize: '18px',
  minWidth: '110px',
  fontWeight: 700,
  lineHeight: '130%',
  letterSpacing: '-0.022em',
  textAlign: 'center',
  // lineHeight: 3.75,
};

function GetSidebarIcon(title, isCurrent, disabled) {
  // const childrenText = title.toLowerCase()
  // eslint-disable-next-line react/destructuring-assignment
  switch (title.toLowerCase()) {
    case 'home':
      return <HomeIcon />;
    case 'event':
    case 'edit':
      return isCurrent ? <EventSelected /> : <Event />;
    case 'gallery':
      return <Gallery fill={isCurrent ? '#7B33FB' : '#606060'} />;
    case 'poster':
      return (
        <Flex>
          <PrintIcon fill={isCurrent ? '#7B33FB' : '#606060'} />
          {disabled && <LockImage />}
        </Flex>
      );
    case 'help':
      return isCurrent ? <HelpIconSelected /> : <HelpIcon />;
    case 'print':
      return <Flex>{isCurrent ? <QRCodeSelected /> : <QRCode />}</Flex>;
    case 'video':
      return (
        <Flex>
          {isCurrent ? <EditingSuiteSelected /> : <EditingSuite />}
          {disabled && <LockImage />}
        </Flex>
      );
    case 'publish-and-pay':
      return (
        <Flex>
          {isCurrent ? <VideoIconSelected /> : <VideoIcon />}
          {disabled && <LockImage />}
        </Flex>
      );
    default:
  }
}

const GetImageAndBg = (isCurrent) => {
  const imageUrl = '';
  const bg = isCurrent ? '#7B33FB' : 'white';

  return {
    imageUrl,
    bg,
  };
};

function ShowWarningMessage({
  children,
  showMessage,
  celebrationRouteParams,
  disabled,
  isFromMobile,
  onClick,
}) {
  const { asPath } = useRouter();
  const isFromNew = asPath.includes('/new');
  const ref = useRef();
  const [isOpen, setOpen] = useState(false);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      setOpen(false);
    }
  });

  if (showMessage)
    return (
      <Flex justifyContent={['flex-start', 'center']}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(() => !isOpen);
          }}
        >
          {children}
        </Box>
        <Box
          ref={ref}
          py={1}
          bg="white"
          sx={{
            position: 'fixed',
            display: isOpen ? 'block' : 'none',
            mt: isFromMobile ? '-160px' : ['40px', '25px'],
            ml: isFromMobile ? '-75px' : ['-25px', '225px'],
            boxShadow: 'medium',
            borderRadius: 'medium',
            borderSize: '3px',
            borderStyle: 'solid',
            borderColor: 'greys.2',
            zIndex: 10,
          }}
        >
          <Flex py={1} px={1} width={190} textAlign="center" alignItems="center">
            {!isFromNew && <Text size={48} mr={2} as={AiOutlineWarning} />}
            <Text>
              {isFromNew
                ? `Please create the celebration first.`
                : `Your video is currently processing. Further editing can be done when processing is
      complete.`}
            </Text>
          </Flex>
        </Box>
      </Flex>
    );

  return (
    <LinkWrapper
      onClick={onClick}
      disabled={disabled}
      celebrationRouteParams={celebrationRouteParams}
    >
      {children}
    </LinkWrapper>
  );
}

function CelebrationRoute({
  children,
  isCurrent,
  isFromSmalDevice,
  isFromMobile,
  disabled = false,
}) {
  const { imageUrl, bg } = GetImageAndBg(isCurrent);

  let textColor = 'rgb(39, 41, 41)';
  if (isCurrent) textColor = '#fff';
  else if (isFromSmalDevice) textColor = '#FFFFFF';

  return (
    <Box
      my={!(isFromSmalDevice || isFromMobile) && 2}
      mb={isFromMobile && '6px'}
      sx={{
        // eslint-disable-next-line no-nested-ternary
        transition: 'all 1s ease-in-out',
      }}
    >
      <Flex
        alignItems="center"
        transition="all 0.5s ease-in-out"
        flexDirection="column"
        sx={{
          height: '60px',
          background: isCurrent ? 'rgba(123, 51, 251, 0.1)' : 'purples.8',
          py: '10px',
          px: isFromSmalDevice ? '0px' : '10px',
          borderRadius: '5px',
          m: isFromSmalDevice ? 0 : 'auto',
          width: 'max-content',
        }}
      >
        {imageUrl && (
          <Image
            src={imageUrl}
            size={60}
            sx={{
              background: bg,
              p: 1,
              borderRadius: '10px',
              mr: 2,
              boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
              cursor: 'pointer',
            }}
          />
        )}
        <Flex alignItems="center">
          <Text
            sx={typeof children !== 'object' && textStyle}
            textAlign={isFromSmalDevice && 'left'}
            color={textColor}
          >
            {children}
          </Text>
          {disabled && <LockImage position="relative" marginLeft="5px" marginTop="0px" />}
        </Flex>
        {isFromSmalDevice && isCurrent && (
          <Box sx={{ borderBottom: '2px solid #FFFFFF', width: '47px', mr: 'auto' }} />
        )}
      </Flex>
    </Box>
  );
}

function LinkWrapper({ children, disabled, celebrationRouteParams, onClick }) {
  const {
    query: { id },
  } = useRouter();
  const [showPaymentModal, setPaymentModal] = useState(false);
  return (
    <Box>
      {disabled ? (
        <Box
          onClick={() => {
            if (celebrationRouteParams?.href?.includes('publish-and-pay'))
              postPrivateEventDetails(`celebration_tapped_publish_and_pay_icon`, {
                'subject-type': 'Celebration',
                'subject-id': id,
              });
            else {
              postPrivateEventDetails('celebration_tapped_pay', {
                'subject-type': 'Celebration',
                'subject-id': id,
              });
            }
            setPaymentModal(true);
          }}
        >
          {children}
        </Box>
      ) : (
        <RouterLink
          onClick={onClick}
          {...celebrationRouteParams}
          sx={{
            textDecoration: 'none',
            textAlign: 'left',
          }}
        >
          {children}
        </RouterLink>
      )}
      <PaymentModal id={id} isOpen={showPaymentModal} onDismiss={() => setPaymentModal(false)} />
    </Box>
  );
}

function SectionHeading({ title }) {
  return (
    <Text
      sx={{
        fontWeight: 600,
        fontSize: '14px !important',
        lineHeight: '0.2em',
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.7)',
        opacity: '0.8',
        mb: 1,
      }}
    >
      {title}
    </Text>
  );
}

function VideoGuestBookNavLink({
  page,
  isFromSmalDevice,
  disabled,
  isFromPrintable,
  isProcessing = false,
}) {
  const {
    query: { id },
    asPath,
  } = useRouter();
  const celebrationRouteParams = useCelebrationRoute(id, page);
  let isCurrent = asPath.includes(celebrationRouteParams.as);
  const isFromMobile = useMobileCheckHook();

  if (page === 'home') isCurrent = false;

  return (
    <ShowWarningMessage
      showMessage={isProcessing}
      isFromMobile={isFromMobile}
      justifyContent="center"
      disabled={disabled}
      celebrationRouteParams={celebrationRouteParams}
      onClick={() => {
        trackingEvent(page, celebrationRouteParams.href);
      }}
    >
      <CelebrationRoute
        isFromPrintable={isFromPrintable}
        isCurrent={isCurrent}
        isFromSmalDevice={isFromSmalDevice}
        isFromMobile={isFromMobile}
      >
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {GetSidebarIcon(page, isCurrent, disabled)}
          {isFromMobile && isCurrent && (
            <Box
              bg={isCurrent ? '#7B33FB' : '#16161D'}
              height="7px"
              width="7px"
              mt={5}
              sx={{ borderRadius: '50%', position: 'absolute' }}
            />
          )}
        </Flex>
      </CelebrationRoute>
    </ShowWarningMessage>
  );
}

function CelebrationNav({ isFromSmalDevice, closeSidebar }) {
  const {
    query: { id, videoguestbook },
    asPath,
  } = useRouter();
  const isFromMobile = useMobileCheckHook();
  const celebration = useCelebration(id);
  const { width } = useWindowSize();
  const [showNav, setShowNav] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    if (showNav) {
      setShowNav(false);
    }
  });

  const isFromPrintable = celebration?.theme === VIDEO_GUEST_BOOK;
  const isProcessing =
    celebration?.state === 'creating_video' || celebration?.state === 'submitted_to_create_video';

  function onHelpClick() {
    trackingEvent('help', CELEBRATE_HELP_PAGE);
    window.open(CELEBRATE_HELP_PAGE);
  }

  if (asPath.includes('/event') && (videoguestbook || !id) && !isFromSmalDevice) return null; // donot show sidebar on event and edit event screen

  if (isFromMobile && !isFromSmalDevice)
    return (
      <Flex
        bg="white"
        height="65px"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          zIndex: 5,
          width: '100vw',
          transition: 'width 0.5s',
          border: '0.5px solid #16161D',
        }}
      >
        <VideoGuestBookNavLink page="home" />
        {id && (
          <>
            <VideoGuestBookNavLink page="event" />
            {isFromPrintable && <VideoGuestBookNavLink page="gallery" />}
            {isFromPrintable && width > 767 && <VideoGuestBookNavLink page="print" />}
            {isFromPrintable && width > 767 && <VideoGuestBookNavLink page="poster" />}
            <VideoGuestBookNavLink page="video" isProcessing={isProcessing} />
            <VideoGuestBookNavLink
              disabled={isFromPrintable && !celebration?.paid}
              page="publish-and-pay"
            />
            {width < 768 && (
              <Box
                sx={{
                  background: '#FFFFFF',
                  cursor: 'pointer',
                }}
                as={BsThreeDots}
                size={35}
                color="#342E57"
                onClick={() => setShowNav(true)}
              />
            )}
            <Box
              ref={ref}
              sx={{
                position: 'fixed',
                display: showNav ? 'block' : 'none',
                right: '10px',
                bottom: '50px',
                p: 2,
                boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: 'medium',
                borderSize: '3px',
                borderStyle: 'solid',
                borderColor: 'greys.2',
                background: '#F3F6FD',
                zIndex: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowNav(false);
              }}
            >
              <LinkWrapper
                celebrationRouteParams={{ href: `/dashboard/${id}/print` }}
                onClick={() => trackingEvent('QR Code', `/dashboard/${id}/print`)}
              >
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  mb={asPath.includes('print') ? 0 : 1}
                >
                  {GetSidebarIcon('print', false, false)}
                  <Text
                    ml={2}
                    sx={{
                      letterSpacing: '0.352px',
                      lineHeight: '150%',
                      color: '#000',
                      fontSize: '16px',
                    }}
                  >
                    QR Code
                  </Text>
                </Flex>
                {asPath.includes('print') && (
                  <Box sx={{ borderBottom: '2px solid #000', width: '70%', mb: 1, ml: 'auto' }} />
                )}
              </LinkWrapper>
              <LinkWrapper
                celebrationRouteParams={{ href: `/dashboard/${id}/poster` }}
                onClick={() => trackingEvent('Printables', `/dashboard/${id}/poster`)}
              >
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  my={1}
                  mb={asPath.includes('poster') && 0}
                >
                  {GetSidebarIcon('poster', false, false)}
                  <Text
                    ml={1}
                    sx={{
                      letterSpacing: '0.352px',
                      lineHeight: '150%',
                      color: '#000',
                      fontSize: '16px',
                    }}
                  >
                    Printables
                  </Text>
                </Flex>
                {asPath.includes('poster') && (
                  <Box sx={{ borderBottom: '2px solid #000', width: '70%', mb: 1, ml: 'auto' }} />
                )}
              </LinkWrapper>
              <Flex justifyContent="flex-start" alignItems="center" mt={1} onClick={onHelpClick}>
                {GetSidebarIcon('help', false, false)}
                <Text
                  ml={1}
                  sx={{
                    letterSpacing: '0.352px',
                    lineHeight: '150%',
                    color: '#000',
                    fontSize: '16px',
                  }}
                >
                  Get Help
                </Text>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    );

  const smallDeviceLinkProps = {
    alignSelf: 'center',
    fontWeight: 600,
    sx: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'white',
      textAlign: 'left',
    },
  };

  return (
    <Box
      // bg="purples.7"
      height="100%"
      sx={{
        position: 'fixed',
        left: isFromSmalDevice ? `calc(100vw - 185px)` : 0,
        width: isFromSmalDevice ? '184px' : '92px',
        transition: 'width 0.5s',
        top: 0,
        padding: isFromSmalDevice && 2,
        paddingTop: isFromSmalDevice ? '32px' : '105px',
        borderRight: '6px #F7F7F7 solid',
        overflowX: !closeSidebar && 'hidden',
        bg: isFromSmalDevice ? '#7B33FB' : 'white',
        zIndex: 1,
        // overflowY: 'hidden',
        '@media (max-width: 1024px)': {
          display: !isFromSmalDevice && 'none',
        },
      }}
    >
      {isFromSmalDevice ? (
        <>
          <SectionHeading title="Your Account" />
          <RouterLink
            onClick={() => trackingEvent('Home', '/')}
            alignSelf="center"
            href="/"
            fontWeight={600}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: asPath === '/profile' ? 'purples.7' : 'white',
              textAlign: 'left',
            }}
          >
            <CelebrationRoute isCurrent={asPath === '/'} isFromSmalDevice={isFromSmalDevice}>
              Home
            </CelebrationRoute>
          </RouterLink>
          <RouterLink
            onClick={() => trackingEvent('Profile', '/profile')}
            alignSelf="center"
            href="/profile"
            fontWeight={600}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: asPath === '/profile' ? 'purples.7' : 'white',
              textAlign: 'left',
            }}
          >
            <CelebrationRoute isCurrent={asPath === '/profile'} isFromSmalDevice={isFromSmalDevice}>
              Profile
            </CelebrationRoute>
          </RouterLink>
          <RouterLink
            onClick={() => trackingEvent('Log Out', '/log-out')}
            alignSelf="center"
            href="/log-out"
            fontWeight={600}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: asPath === '/log-out' ? 'purples.7' : 'white',
              textAlign: 'left',
            }}
          >
            <CelebrationRoute isCurrent={asPath === '/log-out'} isFromSmalDevice={isFromSmalDevice}>
              Log Out
            </CelebrationRoute>
          </RouterLink>
          <Box mt={2}>
            {id && (
              <>
                <SectionHeading title="This Celebration" />
                <LinkWrapper
                  celebrationRouteParams={{
                    ...smallDeviceLinkProps,
                    href: `/dashboard/${id}/event`,
                  }}
                  onClick={() => trackingEvent('Edit Event', `/dashboard/${id}/event`)}
                >
                  <CelebrationRoute
                    isCurrent={asPath.includes('/event')}
                    isFromSmalDevice={isFromSmalDevice}
                  >
                    Edit Event
                  </CelebrationRoute>
                </LinkWrapper>
                {isFromPrintable && (
                  <LinkWrapper
                    celebrationRouteParams={{
                      ...smallDeviceLinkProps,
                      href: `/dashboard/${id}/gallery`,
                    }}
                    onClick={() => trackingEvent('Gallery', `/dashboard/${id}/gallery`)}
                  >
                    <CelebrationRoute
                      isCurrent={asPath.includes('/gallery')}
                      isFromSmalDevice={isFromSmalDevice}
                    >
                      Gallery
                    </CelebrationRoute>
                  </LinkWrapper>
                )}
                {isFromPrintable && (
                  <LinkWrapper
                    celebrationRouteParams={{
                      ...smallDeviceLinkProps,
                      href: `/dashboard/${id}/print`,
                    }}
                    onClick={() => trackingEvent('QR Code', `/dashboard/${id}/print`)}
                  >
                    <CelebrationRoute
                      isCurrent={asPath.includes('/print')}
                      isFromSmalDevice={isFromSmalDevice}
                    >
                      QR Code
                    </CelebrationRoute>
                  </LinkWrapper>
                )}
                {isFromPrintable && (
                  <LinkWrapper
                    celebrationRouteParams={{
                      ...smallDeviceLinkProps,
                      href: `/dashboard/${id}/poster`,
                    }}
                    onClick={() => trackingEvent('Create Poster', `/dashboard/${id}/poster`)}
                  >
                    <CelebrationRoute
                      isCurrent={asPath.includes('/poster')}
                      isFromSmalDevice={isFromSmalDevice}
                    >
                      Create Poster
                    </CelebrationRoute>
                  </LinkWrapper>
                )}
                <ShowWarningMessage
                  showMessage={isProcessing}
                  justifyContent="center"
                  celebrationRouteParams={{
                    ...smallDeviceLinkProps,
                    href: `/dashboard/${id}/video`,
                  }}
                  onClick={() => trackingEvent('Edit Video', `/dashboard/${id}/video`)}
                >
                  <CelebrationRoute
                    isCurrent={asPath.includes('/video')}
                    isFromSmalDevice={isFromSmalDevice}
                  >
                    Edit Video
                  </CelebrationRoute>
                </ShowWarningMessage>
                <LinkWrapper
                  celebrationRouteParams={{
                    ...smallDeviceLinkProps,
                    href: `/dashboard/${id}/publish-and-pay`,
                  }}
                  disabled={isFromPrintable && !celebration?.paid}
                  onClick={() =>
                    trackingEvent('Publish and Pay', `/dashboard/${id}/publish-and-pay`)
                  }
                >
                  <CelebrationRoute
                    isCurrent={asPath.includes('/publish-and-pay')}
                    isFromSmalDevice={isFromSmalDevice}
                    disabled={isFromPrintable && !celebration?.paid}
                  >
                    Publish and Pay
                  </CelebrationRoute>
                </LinkWrapper>
              </>
            )}
            <Box mt={2}>
              <SectionHeading title="Support" />
              <Button
                alignSelf="center"
                variant="transparent"
                fontWeight={600}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'white',
                  textAlign: 'left',
                  p: 0,
                }}
                onClick={() => {
                  trackingEvent('FAQs / Tips', CELEBRATE_HELP_PAGE);
                  window.open(CELEBRATE_HELP_PAGE);
                }}
              >
                <CelebrationRoute isFromSmalDevice={isFromSmalDevice}>FAQs / Tips</CelebrationRoute>
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <VideoGuestBookNavLink page="home" isFromSmalDevice={isFromSmalDevice} />
          {id && (
            <>
              <VideoGuestBookNavLink page="event" isFromSmalDevice={isFromSmalDevice} />
              {isFromPrintable && (
                <VideoGuestBookNavLink page="print" isFromSmalDevice={isFromSmalDevice} />
              )}
              {isFromPrintable && (
                <VideoGuestBookNavLink page="poster" isFromSmalDevice={isFromSmalDevice} />
              )}
              {isFromPrintable && (
                <VideoGuestBookNavLink
                  page="gallery"
                  isProcessing={isProcessing}
                  isFromSmalDevice={isFromSmalDevice}
                />
              )}
              <VideoGuestBookNavLink
                page="video"
                isProcessing={isProcessing}
                isFromSmalDevice={isFromSmalDevice}
              />
              <VideoGuestBookNavLink
                disabled={isFromPrintable && !celebration?.paid}
                page="publish-and-pay"
                isFromSmalDevice={isFromSmalDevice}
              />
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  pt: 3,
                }}
                onClick={onHelpClick}
                target="_blank"
              >
                {GetSidebarIcon('help', false, false)}
              </Flex>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default CelebrationNav;
