import React, { useState, useEffect } from 'react';
import { Heading, Box, Text, Button, Link } from 'rebass';
import { Formik, Form } from 'formik';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import Uppy from '@uppy/core';
import Axios from 'axios';
import store from 'store';
import { getMetadata } from 'video-metadata-thumbnails';
import { StatusBar, useUppy } from '@uppy/react';
import { useCelebrationUploadErrorEmailMailto } from '@/hooks/celebration';
import {
  extensionRegex,
  getMediaIdFromUploadKey,
  retryUppyUpload,
  setFileType,
  getIDFromKey,
  checkIfIdExist,
  clearLocalStorageItems,
} from '@/lib/helper';
import { UploadInProgressTrigger } from '@/lib/constants';
import { ax } from '@/lib/api';
import { useCurrentMemberId } from '@/hooks/auth';
import Progress from '../app/Progress';
import FileDropperField from '../app/formik/FileDropperField';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import ProgressTracker from '../app/ProgressTracker';
import UppyComponent from '../UppyComponent';
import InternetSpeedTracker from '../InternetSpeedTracker/index';
import { useCreateNewAccount } from '../Auth/index';

const baseURL = process.env.NEXT_PUBLIC_API_BASE.replace('/api', '/');

function RenderUploader({
  children,
  onSuccess,
  uploadError,
  loading,
  isFromMobile,
  onDismiss,
  id,
  uploadProgress,
  tryAgain,
}) {
  const memberId = useCurrentMemberId();
  const uploadFailedMailto = useCelebrationUploadErrorEmailMailto(id, memberId);

  if (onSuccess)
    return (
      <Box
        p={isFromMobile ? 2 : 4}
        width={800}
        maxWidth="100%"
        alignSelf={isFromMobile && 'baseline'}
        pt={isFromMobile && 0}
      >
        <Heading variant="headings.h3" pb={2}>
          Your Song has been Uploaded
        </Heading>
        <Text pb={3}>You may now close this window</Text>
        <Button type="button" onClick={onDismiss}>
          Close
        </Button>
      </Box>
    );

  if (uploadError)
    return (
      <Box
        p={isFromMobile ? 2 : 4}
        width="100%"
        alignSelf={isFromMobile && 'baseline'}
        pt={isFromMobile && 0}
      >
        <Heading variant="headings.h1" fontSize={4} pb={4}>
          Oops. That didn’t work 🙁.
        </Heading>
        <Heading variant="headings.h6" fontSize={4} pb={4}>
          Would you please try again? If it fails a second time, please email us at{' '}
          <Button as={Link} variant="link" target="_blank" href={uploadFailedMailto(uploadError)}>
            help@celebrate.buzz
          </Button>{' '}
          and we will help you with this issue.
        </Heading>
        {children}
        <Button as="primary" mt={4} onClick={tryAgain}>
          Try Again
        </Button>
      </Box>
    );

  if (loading)
    return (
      <Box
        width="100%"
        p={isFromMobile ? 2 : 4}
        alignSelf={isFromMobile && 'baseline'}
        pt={isFromMobile && 0}
        textAlign="center"
      >
        <Heading textAlign="left" variant="headings.h3" pb={2}>
          Uploading Song
        </Heading>
        <Text textAlign="left">Please don&apos;t close this window</Text>
        <ProgressTracker progress={uploadProgress} />
        <Progress percentComplete={uploadProgress} />
        {children}
      </Box>
    );

  return null;
}

function UploadFile({ file: newFile, onDismiss, isFromMobile }) {
  const {
    query: { id },
  } = useRouter();
  const { mutate: revalidateSongs } = useSWR(
    checkIfIdExist(id) ? `/v3/celebrations/${id}/sound_tracks` : null
  );
  const { mutate: revalidateCelebration } = useSWR(
    checkIfIdExist(id) ? `/v3/celebrations/${id}?shallow=true` : null
  );

  const createNewUser = useCreateNewAccount();
  const [uploadError, setuploadError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setProgress] = useState(0);
  const [onSuccess, setSuccess] = useState(false);

  const [uploadingMediaId, setUploadingMediaId] = useState(null);
  const [failedUploadFile, setFailedUploadFile] = useState(null);

  useEffect(() => {
    const interval = setInterval(async function () {
      // method to be executed;
      if (uploadingMediaId) await ax().patch(`/v3/media/${uploadingMediaId}/touch`);
    }, UploadInProgressTrigger);
    if (!uploadingMediaId) return clearInterval(interval);

    return () => clearInterval(interval);
  }, [uploadingMediaId]);

  const uppy = useUppy(() => {
    return new Uppy({
      meta: {},
      allowMultipleUploads: true,
      restrictions: { maxNumberOfFiles: null, allowedFileTypes: [`audio/*`] },
      autoProceed: true,
    });
  });

  /* eslint-disable no-param-reassign */
  async function createMultipartUpload(file) {
    file.type = setFileType(file.type);
    let artist;
    let title;
    try {
      const { jsmediatags } = window;
      const mediaTags = await new Promise((resolve, reject) => {
        new jsmediatags.Reader(file.data).read({
          onSuccess: (tag) => resolve(tag),
          onError: (error) => reject(error),
        });
      });
      artist = mediaTags?.tags.artist;
      title = mediaTags?.tags.title || file.name;
    } catch (err) {
      console.log(err);
    }
    const filename = extensionRegex.exec(file.name)[0]
      ? file.data.name.replace(/\s/g, '')
      : `${file.name.replace(/\s/g, '')}.${file.type.split('/')[1]}`;

    store.set('Media Uploading', true);
    let token = store.get('token');
    if (!token) {
      token = await createNewUser();
    } else if (token && token.includes('token=')) token = token.replace('token=', '');

    const clipAttributes = {
      filename,
      metadata: { name: filename, type: file.type },
      type: file.type,
      token,
      celebration_id: id,
      position_beginning: true,
      title,
      artist,
    };
    try {
      const { duration } = await getMetadata(file.data);
      clipAttributes.duration = duration * 1000;
    } catch (err) {
      console.log(err);
    }
    const tokenResponse = await Axios.post(`${baseURL}s3/multipart`, clipAttributes, {
      headers: {
        'X-LIFETALES-CLIENT': 'web-celebrate-client',
        'X-CELEBRATE-REFERER': window?.location?.href,
      },
    });
    const { status, data, error } = tokenResponse;
    if (status === 200) {
      getIDFromKey(data.key);
      const mediaId = getMediaIdFromUploadKey(data.key);
      setUploadingMediaId(mediaId);
      store.set('file-type', file.type.split('/')[0]);
      return data;
    }

    store.remove('Media Uploading');
    store.set('sentryError', `${error} /UploadSong Component After Multipart Request`);
    return null;
  }

  async function uploadSuccess() {
    revalidateCelebration();
    setUploadingMediaId(null);
    setSuccess(true);
    setLoading(false);
    revalidateSongs();
    clearLocalStorageItems();
    uppy.close();
  }

  async function uppyUploadProgress(_, progress) {
    const currProgress = (progress.bytesUploaded / progress.bytesTotal) * 100;
    setProgress(currProgress);
  }

  async function uppyUploadError(file, error) {
    setUploadingMediaId(null);
    setSuccess(false);
    setuploadError(true);
    setLoading(false);
    setFailedUploadFile(file);
    store.set('sentryError', `${error} /UploadSong Component in upload-error function`);
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      uppy.addFile({
        name: newFile.name,
        type: newFile.type,
        data: newFile, // changed blob -> data
      });
    } catch (error) {
      store.set('sentryError', `${error} /UploadSong Component and in handleSubmit function`);
    }
  };

  useEffect(() => {
    if (newFile) handleSubmit();
  }, [newFile]);

  const tryAgain = () => {
    setLoading(true);
    setProgress(0);
    setuploadError(null);
    uppy.cancelAll();
    retryUppyUpload();
    uppy.addFile({
      name: failedUploadFile.name,
      type: failedUploadFile.type,
      data: failedUploadFile.data,
    });
  };

  return (
    <Box width="100%">
      <UppyComponent
        uppy={uppy}
        createMultipartUpload={createMultipartUpload}
        uploadSuccess={uploadSuccess}
        uploadProgress={uppyUploadProgress}
        uploadError={uppyUploadError}
        tryAgain={uploadError ? tryAgain : undefined}
      >
        <RenderUploader
          onSuccess={onSuccess}
          uploadError={uploadError}
          loading={loading}
          isFromMobile={isFromMobile}
          onDismiss={onDismiss}
          uploadProgress={uploadProgress}
          uppy={uppy}
          id={id}
          tryAgain={tryAgain}
        >
          <StatusBar uppy={uppy} hideUploadButton hideAfterFinish={false} />
        </RenderUploader>
      </UppyComponent>
    </Box>
  );
}

function UploadSong({ onDismiss, isFromMobile }) {
  const [fileToUpload, setFile] = useState(null);

  if (fileToUpload)
    return <UploadFile file={fileToUpload} onDismiss={onDismiss} isFromMobile={isFromMobile} />;

  return (
    <Box
      p={isFromMobile ? 2 : 4}
      width="100%"
      alignSelf={isFromMobile && 'baseline'}
      pt={isFromMobile && 0}
      maxWidth="800px"
    >
      <InternetSpeedTracker onDismiss={onDismiss} />
      <Formik initialValues={{ file: null }} onSubmit={({ file }) => setFile(file)}>
        {(formikBag) => (
          <Box>
            <Heading variant="headings.h3" pb={2}>
              Upload your own music file
            </Heading>
            <Text pb={4}>
              {isFromMobile
                ? 'Tap to upload a song from your device.'
                : 'Drag and drop a music file to upload it to your songs.'}
            </Text>
            <Form>
              <FileDropperField
                accept="audio/*"
                label="audio"
                name="file"
                placeholder={
                  isFromMobile ? 'Tap to upload from device.' : 'Click or drag a file to upload'
                }
                onDrop={formikBag.handleSubmit}
              />
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
}

export default UploadSong;
