import React, { forwardRef, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Flex, Text } from 'rebass';
import { Span } from '../CelebrationHomeScreen/common/ReusableComponents';

const ShowBottomSheet = forwardRef(({ children, heading, isOpen, height = 0, onClose }, ref) => {
  const [parentHeight, setParentHeight] = useState(0);
  useEffect(() => {
    if (isOpen) setParentHeight(height || '117px');
    else setParentHeight(0);
  }, [height, isOpen]);

  return (
    <Flex
      ref={ref}
      py={1}
      bg="white"
      flexDirection="column"
      sx={{
        display: isOpen ? 'block' : 'none !important',
        position: 'fixed',
        width: '100vw',
        bottom: 0,
        left: 0,
        right: 0,
        height: parentHeight,
        boxShadow: 'medium',
        borderRadius: 'medium',
        borderSize: '3px',
        borderStyle: 'solid',
        borderColor: 'greys.2',
        zIndex: 10,
        transition: 'height 0.5s ease-in-out',
      }}
    >
      <Flex
        justifyContent="center"
        sx={{
          borderBottom: '0.5px solid #16161D',
        }}
      >
        <Span
          sx={{
            color: '#000',
            textAlign: 'center',
            fontWeight: 700,
            lineHeight: '130%' /* 23.4px */,
            ml: 'auto',
          }}
        >
          {heading}
        </Span>
        <Text ml="auto" size={24} as={IoMdClose} onClick={onClose} />
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        {children}
      </Flex>
    </Flex>
  );
});

export default ShowBottomSheet;
