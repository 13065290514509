import React, { createRef, useReducer, useCallback, useState } from 'react';
import { Box, Flex, Heading, Button } from 'rebass';
import { useWindowSize } from 'react-use';
import { useSWRConfig } from 'swr';
import { useRouter } from 'next/router';
import { useClip, useClipStickerClip, useTimelineClips } from '@/hooks/clip';
// eslint-disable-next-line import/no-cycle
import VideoPreview from '@/components/VideoPreview';
import { useMoveClip, useDuplicateClip, useDeleteAndRemoveClip } from '@/hooks/mutations';
import { useCelebration, useCelebrationSoundTracks } from '@/hooks/celebration';
import { getAspectRatio, sortPosition } from '@/lib/helper';
import { viewChangePixels } from '@/lib/constants';
import Nav from './Nav';
// eslint-disable-next-line import/no-cycle
import ClipControl from './ClipControl';
import TrimControl from './TrimControl';
import AddGif from '../VideoEditor/AddGif';
// eslint-disable-next-line import/no-cycle
import StickerControl from './StickerControl/index';
import ConfirmDialog from '../app/ConfirmDialog';

// eslint-disable-next-line react/no-unstable-nested-components
function RenderStickers({
  clips,
  width,
  state,
  clip,
  id,
  type,
  vidHeight,
  clipWidth,
  celebrationId,
  isFromMobile,
  soundTracks,
  dispatch,
}) {
  const celebration = useCelebration(celebrationId);

  const stickerClips = useClipStickerClip({ id });
  const [images, setImages] = useState(stickerClips || []);

  const addStickerToPanel = ({ src, width: stickerWidth, x, y, height }) => {
    setImages((currentImages) => [
      ...currentImages,
      {
        width: stickerWidth,
        height,
        x,
        y,
        src,
        resetButtonRef: createRef(),
      },
    ]);
  };

  if (state.control === 'stickers')
    return (
      <StickerControl
        id={id}
        type={type}
        onClear={() => dispatch({ type: 'showControl', control: 'more stickers' })}
        images={images}
        isVideoClip={!!clip.video}
        textClip={stickerClips}
        setImages={setImages}
        height={isFromMobile ? vidHeight + 100 : 394}
        width={isFromMobile ? clipWidth : 700}
      >
        <VideoPreview
          clips={clips}
          soundTracks={soundTracks
            .map((track) => ({ ...track, type: 'sound-tracks' }))
            .sort(sortPosition())}
          height={isFromMobile ? vidHeight + 100 : 394}
          width={isFromMobile ? clipWidth : 700}
          showTextClip={state.control !== 'text'}
          hasFadeTransition={false}
          isForEdit
          isForStickerEdit
          showDownload
        />
      </StickerControl>
    );

  return (
    <Box
      sx={
        isFromMobile && {
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          backgroundColor: 'white',
          zIndex: 10001,
          left: 0,
        }
      }
    >
      <AddGif
        // onFinished={closeModal}
        onSelectionOfSticker={(selectedSticker) => {
          addStickerToPanel({
            src: selectedSticker?.gifUrl,
            width: selectedSticker?.width,
            height: selectedSticker?.height,
            x: 100,
            y: width < 500 ? 50 : 100,
          });
          dispatch({ type: 'showControl', control: 'stickers' });
        }}
        occasion={celebration ? celebration['occasion-description-long'] : null}
        isFromMobile={isFromMobile}
      />
    </Box>
  );
}

export function EditClipActionButton({ disabled, width, value, onClick = null }) {
  let top = 50;
  if (width < viewChangePixels)
    if (width < 500) top = 21;
    else top = 31;

  return (
    <Button
      type="submit"
      variant="transparent"
      disabled={disabled}
      color="purples.7"
      sx={{
        fontWeight: 'bold',
        opacity: disabled && 0.5,
        cursor: disabled ? 'not-allowed' : 'pointer',
        position: 'fixed',
        right: 16,
        top,
        zIndex: '3',
      }}
      onClick={onClick}
    >
      {value}
    </Button>
  );
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'showControl': {
      return {
        ...state,
        control: action.control,
      };
    }
    default:
      return state;
  }
};

function EditClip({ id, type, onDismiss, isFromMobile, clipDuration, moveToTimeline }) {
  // useGETClipSWR({ id, type });
  const {
    query: { id: celebrationId },
  } = useRouter();
  const soundTracks = useCelebrationSoundTracks(celebrationId);
  const clip = useClip({ id, type });
  const [clipWidth, setClipWidth] = useState(0);
  const [showRemove, setShowRemove] = useState(false);
  const { mutate } = useSWRConfig();

  const [deleteAndRemoveClip, { loading }] = useDeleteAndRemoveClip({ id, type, celebrationId });
  const clips = useTimelineClips([{ ...clip, type }], false);
  const [state, dispatch] = useReducer(reducer, { control: 'name', trimmer: false });
  const moveClip = useMoveClip({
    state: moveToTimeline ? 'in_timeline' : 'in_clipboard',
    id,
    type,
    celebrationId,
  });
  const removeClip = useCallback(async () => {
    await moveClip();
    onDismiss();
  }, [onDismiss, moveClip]);

  const duplicateClip = useDuplicateClip({ id, celebrationId });
  const showTrimmer = state.control === 'trim';
  const { width } = useWindowSize();
  const vidHeight = getAspectRatio(width);

  let componentsHeight;
  if (isFromMobile) {
    componentsHeight = 'calc(100vh - 160px)';
  }
  if (width < 500) componentsHeight = 'calc(100vh - 210px)';

  return (
    <>
      <Flex
        height={componentsHeight}
        overflowY={isFromMobile && 'auto'}
        m={isFromMobile ? 2 : 5}
        mt={0}
        flexDirection="column"
        width={isFromMobile ? '100vw' : 'auto'}
        alignSelf={isFromMobile && 'baseline'}
        ref={(el) => {
          if (!el) return;
          setClipWidth(el.getBoundingClientRect().width);
        }}
      >
        <Heading
          pb={isFromMobile ? 1 : 3}
          variant={isFromMobile ? 'headings.h1' : 'headings.h2'}
          color={isFromMobile && 'special'}
        >
          {isFromMobile ? 'The editing suite' : 'Edit this Clip'}
        </Heading>
        {!isFromMobile && (
          <Nav
            id={id}
            type={type}
            moveToTimeline={moveToTimeline}
            currentControl={state.control}
            onRemove={removeClip}
            onDuplicate={duplicateClip}
            onClick={(control) => {
              // if (control === 'stickers')
              //   control = state.control === 'stickers' ? 'more stickers' : 'stickers';

              dispatch({
                type: 'showControl',
                control: control === state.control ? 'name' : control,
              });
            }}
          />
        )}
        {state.control.includes('stickers') ? (
          <RenderStickers
            clips={clips}
            clipWidth={clipWidth}
            clip={clip}
            state={state}
            id={id}
            type={type}
            celebrationId={celebrationId}
            vidHeight={vidHeight}
            isFromMobile={isFromMobile}
            width={width}
            dispatch={dispatch}
            soundTracks={soundTracks}
          />
        ) : (
          (!showTrimmer || isFromMobile) && (
            <ClipControl
              currentControl={state.control}
              id={id}
              type={type}
              isFromMobile={isFromMobile}
              onDismiss={onDismiss}
            >
              {!showTrimmer && (
                <Box width={isFromMobile ? '100%' : 700} mx="auto">
                  <VideoPreview
                    clips={clips}
                    soundTracks={soundTracks
                      .map((track) => ({ ...track, type: 'sound-tracks' }))
                      .sort(sortPosition())}
                    height={isFromMobile ? vidHeight + 100 : 394}
                    width={isFromMobile ? clipWidth : 700}
                    showTextClip={state.control !== 'text'}
                    hasFadeTransition={false}
                    isForEdit
                    clipDuration={clipDuration}
                    showDownload
                  />
                </Box>
              )}
            </ClipControl>
          )
        )}
        {showTrimmer && !isFromMobile && <TrimControl clip={clip} />}
        <ConfirmDialog
          title="Delete clip?"
          body="Clip will be removed from the celebration"
          onConfirm={async () => {
            await deleteAndRemoveClip(true);
            mutate(`/v3/celebrations/${celebrationId}?shallow=true`);
            onDismiss();
          }}
          isOpen={showRemove}
          onDismiss={() => setShowRemove(false)}
          zIndex={1001}
          loading={loading}
        />
        {state.control !== 'more stickers' && (
          <Button
            type="button"
            color="greys.5"
            fontSize={0}
            variant="link"
            onClick={() => setShowRemove(true)}
            mt={3}
          >
            Permanently Delete Clip
          </Button>
        )}
      </Flex>
      {isFromMobile && (
        <Box
          sx={{ position: 'fixed', bottom: 0, left: 0, zIndex: '1' }}
          bg="white"
          width="100%"
          p={1}
          overflowX="auto"
        >
          <Nav
            id={id}
            type={type}
            currentControl={state.control}
            moveToTimeline={moveToTimeline}
            onRemove={removeClip}
            onDuplicate={duplicateClip}
            isFromMobile={isFromMobile}
            onClick={(control) => {
              // if (control === 'stickers')
              //   control = state.control === 'stickers' ? 'more stickers' : 'stickers';

              dispatch({
                type: 'showControl',
                control: control === state.control ? 'name' : control,
              });
            }}
          />
        </Box>
      )}
    </>
  );
}

export default EditClip;
