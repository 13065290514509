import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useSWR, { mutate } from 'swr';
import { FaMinus } from 'react-icons/fa';
import Tappable from 'react-tappable/lib/Tappable';
import { FiCheckCircle } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { Text, Button, Box } from 'rebass';
import { IoIosPause, IoIosPlay } from 'react-icons/io';
import { fetcher } from '@/lib/api';
import Grid from '@/components/app/Grid';
import { useSoundTrackTitle, useSoundTrackAudio, useSoundTrackArtist } from '@/hooks/soundTracks';
import { useResourceRequest } from '@/hooks/api';
import { useRemoveEntityReference } from '@/lib/entities';
import FormattedTime from '../FormattedTime';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';
import { CircleButton } from '../VideoPreview/PreviewControls';

function SoundTrackPreview({ id, onClick, isFromMobile, clipPlaying, onPlayClip }) {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(undefined);
  const [textBoxWidth, setWidth] = useState(0);
  const createConfirmation = useCreateConfirmation();

  const audio = useSoundTrackAudio(id);
  const title = useSoundTrackTitle(id);
  const artist = useSoundTrackArtist(id);
  const artistName = artist && typeof artist === 'string' ? ` by ${artist}` : '';
  const audioRef = useRef(null);
  const {
    query: { id: celebrationID },
  } = useRouter();
  const [refreshInterval, setRefreshInterval] = useState(null);

  useSWR(refreshInterval ? `/v3/celebrations/${celebrationID}/sound_tracks` : null, fetcher, {
    refreshInterval,
  });

  const removeEntityReference = useRemoveEntityReference({
    type: 'celebrations',
    id: celebrationID,
    reference: { type: 'sound-tracks', id },
  });

  const [deleteTrack, { loading }] = useResourceRequest(
    'delete',
    `/v3/celebrations/${celebrationID}/sound_tracks/${id}`
  );

  async function deleteSong() {
    await deleteTrack();
    removeEntityReference();
    mutate(`/v3/celebrations/${celebrationID}/sound_tracks`);
    createConfirmation({ icon: FiCheckCircle, message: 'Song Deleted' });
  }

  useEffect(() => {
    if (audio.state === 'pending_upload') {
      setRefreshInterval(3000);
    }
    // TODO: works for happy path. needs error handling
    if (refreshInterval !== null && audio.state === 'finished') {
      setRefreshInterval(null);
    }
  }, [audio, refreshInterval]);

  useEffect(() => {
    if (playing) {
      audioRef.current.volume = 0.2;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      // if (clipPlaying) audioRef.current.currentTime = 0;
    }
  }, [playing]);

  useEffect(() => {
    if (clipPlaying === id) {
      setPlaying(true);
    } else setPlaying(false);
  }, [clipPlaying]);

  function onPlayPress(e) {
    e.preventDefault();
    if (onPlayClip) {
      if (clipPlaying && clipPlaying === id) onPlayClip(null);
      else onPlayClip(id);
    }
    setPlaying(!playing);
  }

  function onTitleClick() {
    if (isFromMobile && typeof artist === 'object' && artist !== null) return null;
    onClick(title, artist);
    return null;
  }

  return (
    <Box py={1} mt={isFromMobile && 3} px={2} sx={{ borderRadius: 'small' }} bg="purples.7">
      <audio
        onLoadedMetadata={(e) => setDuration(e.target.duration)}
        src={audio?.['asset-url']}
        ref={audioRef}
      />
      {audio.state === 'pending_upload' ? (
        <Text className="loading" color="white" fontSize={16}>
          Processing
        </Text>
      ) : (
        <Grid
          gridTemplateColumns={isFromMobile ? 'max-content 1fr max-content' : '1fr max-content'}
          gridGap={1}
          ref={(el) => {
            if (!el) return;
            setWidth(el.getBoundingClientRect().width);
          }}
        >
          {isFromMobile && (
            <Tappable onClick={onPlayPress} onTap={onPlayPress}>
              <Button
                variant="transparent"
                type="button"
                onClick={onPlayPress}
                color="white"
                height={24}
              >
                <Text as={playing ? IoIosPause : IoIosPlay} size={24} />
              </Button>
            </Tappable>
          )}
          <Tappable
            onClick={onTitleClick}
            onTap={onTitleClick}
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <Text color="white" mr={1}>
              <FormattedTime seconds={audio.duration ? audio.duration / 1000 : duration} />
            </Text>
            <Text
              color="white"
              title={`${title + artistName}`}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: textBoxWidth !== 0 && `${textBoxWidth - (isFromMobile ? 110 : 60)}px`,
                textOverflow: 'ellipsis',
              }}
            >
              {title + artistName}
            </Text>
          </Tappable>
          {isFromMobile ? (
            <Tappable onClick={deleteSong} onTap={deleteSong}>
              <CircleButton disabled={loading} onClick={deleteSong} bg="white">
                <Text color="purples.7" as={FaMinus} />
              </CircleButton>
            </Tappable>
          ) : (
            <Button
              variant="transparent"
              type="button"
              onClick={onPlayPress}
              color="white"
              height={24}
            >
              <Text as={playing ? IoIosPause : IoIosPlay} size={24} />
            </Button>
          )}
        </Grid>
      )}
    </Box>
  );
}

SoundTrackPreview.propTypes = { id: PropTypes.string.isRequired, onClick: PropTypes.func };
SoundTrackPreview.defaultProps = { onClick: () => {} };

export default SoundTrackPreview;
