import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import { Image } from 'rebass';
import { get } from 'lodash';
import { useEntity } from '@/lib/entities';
import { useImage } from '@/hooks/image';

const timeArray = [];
function ImageNode({
  shouldPlay,
  dispatch,
  nodeInRange,
  onPause,
  onPlay,
  gifUrl,
  id,
  height,
  width,
  isBuffering,
  setBuffering,
  clipDetail,
}) {
  const entity = clipDetail || useEntity({ id, type: 'celebration-image-clips' });
  const rotation = (entity && entity['rotation-angle']) || 0;
  const src = useImage({ id: get(entity, 'image.id', ''), size: 2 });

  if (store.get('sliderChanged')) store.remove('sliderChanged');

  useEffect(() => {
    if (isBuffering) setBuffering(false);
  }, []);

  const prevTimeRef = useRef();
  const rafRef = useRef();

  const animateTime = (time) => {
    if (
      prevTimeRef.current &&
      (store.get('PreviewClipPlaying') || store.get('ClipPlaying')) &&
      shouldPlay &&
      store.get('nodeInRange') &&
      time > prevTimeRef.current
    ) {
      const changeTime = time - prevTimeRef.current;
      if (!timeArray.includes(time)) {
        timeArray.push(time);
        const delta = changeTime / 1000;
        if (store.get('currentSeconds')) {
          store.remove('currentSeconds');
        }
        if (store.get('PreviewClipPlaying') || store.get('ClipPlaying'))
          dispatch({ type: 'addSeconds', delta });
      }
    }
    if (store.get('PreviewClipPlaying') || (store.get('ClipPlaying') && store.get('nodeInRange'))) {
      prevTimeRef.current = time;
      rafRef.current = requestAnimationFrame(animateTime);
    }
  };

  // video nodes use callbacks for these
  useEffect(() => {
    if (!shouldPlay && prevTimeRef.current) {
      onPause();
      cancelAnimationFrame(rafRef.current);
      prevTimeRef.current = undefined;
    }
    if (shouldPlay && nodeInRange) {
      rafRef.current = requestAnimationFrame(animateTime);
      onPlay();
    }
    if (nodeInRange) {
      store.set('nodeInRange', true);
    }
    return () => {
      store.remove('nodeInRange');
      cancelAnimationFrame(rafRef.current);
      prevTimeRef.current = undefined;
    };
  }, [onPause, onPlay, shouldPlay, nodeInRange]);

  return (
    <Image
      sx={{
        objectFit: 'contain',
        gridArea: '1 / 1 / 2 / 2 ',
        objectPosition: 'center',
        transform: `rotate(${rotation}deg)`,
      }}
      height={height}
      width={width}
      src={gifUrl || src}
    />
  );
}

ImageNode.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ImageNode.defaultProps = {
  height: 'inherit',
  width: 'inherit',
};

export default ImageNode;
