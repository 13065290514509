import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';

function AsyncButton({ onClick, disabled, children, sx, ...props }) {
  const [isLoading, setLoading] = useState(false);
  return (
    <Button
      type="button"
      disabled={disabled || isLoading}
      onClick={async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
      }}
      sx={{ ...sx }}
      {...props}
    >
      {children}
    </Button>
  );
}

AsyncButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default AsyncButton;
