import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Flex, Box, Button } from 'rebass';
import { Layer, Stage } from 'react-konva';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import { FiCheckCircle } from 'react-icons/fi';
import store from 'store';
import { useMobileCheckHook } from '@/hooks/celebration';
import { useCelebrationResourceRequest, useCelebrationPath, useGETClipSWR } from '@/hooks/api';
import useCreateMedium from '@/hooks/medium';
import { ax } from '@/lib/api';
import { useCreateConfirmation } from '@/components/app/ConfirmationOverlay';
import { checkIfIdExist } from '@/lib/helper';
// eslint-disable-next-line import/no-cycle
import { EditClipActionButton } from '../index';
import IndividualSticker from './IndividualSticker';

/* eslint-disable no-await-in-loop */
function StickerControl({ id, type, images, setImages, onClear, children, height, isVideoClip }) {
  const {
    query: { id: celebrationId },
  } = useRouter();
  const { mutate: clipMutate } = useGETClipSWR({ id, type });
  const [createTextClip] = useCelebrationResourceRequest('post', `celebration_sticker_clips`);
  const celebrationPath = useCelebrationPath();
  const stageRef = useRef(null);
  const createConfirmation = useCreateConfirmation();
  const { mutate } = useSWR(
    checkIfIdExist(celebrationId) ? `/v3/celebrations/${celebrationId}?shallow=true` : null
  );

  const [selectedId, selectShape] = useState(null);
  const [onSave, setOnSave] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [createMedium] = useCreateMedium({ type: 'Image' });
  const { width } = useWindowSize();
  const resetAllButtons = useCallback(() => {
    images.forEach((image) => {
      if (image.resetButtonRef.current) {
        image.resetButtonRef.current();
      }
    });
  }, [images]);

  const handleCanvasClick = useCallback(
    (event) => {
      if (event.target.attrs.id === 'backgroundImage') {
        resetAllButtons();
      }
      if (event.target.attrs.container) {
        selectShape(null);
      }
    },
    [resetAllButtons]
  );
  const isFromMobile = useMobileCheckHook();
  const containerWidth = width < 600 ? width - 40 : 700;

  const updateCanvas = async () => {
    try {
      const uri = stageRef.current.toDataURL();
      const response = await fetch(uri);
      const blob = await response.blob();
      blob.name = 'canvas-clip-image.gif';
      const textCanvasClipResponse = await createMedium({
        file: blob,
        attributes: { 'owner-type': 'Celebration', 'owner-id': celebrationId },
      });

      const { id: textCanvasClipId } = textCanvasClipResponse.data.data;

      await ax().patch(
        `${celebrationPath}/${
          isVideoClip ? 'celebration_video_clips' : 'celebration_image_clips'
        }/${id}`,
        { data: { attributes: { canvas_image_id: textCanvasClipId } } }
      );
    } catch (err) {
      store.set('sentryError', `${err} /Sticker Control updateCanvas function`);
    }
  };

  const handleSave = async () => {
    try {
      setSubmitting(true);
      let errors = [];
      const updatedImages = [];
      for (let i = 0; i < images.length; i += 1) {
        const { x, y, width: imageWidth, height: imageHeight, src } = images[i];
        try {
          const values = {
            x,
            y,
            width: imageWidth,
            height: imageHeight,
          };
          let textClipResponse;
          if (images[i].id) {
            textClipResponse = await ax().patch(
              `${celebrationPath}/celebration_sticker_clips/${images[i].id}`,
              { data: { attributes: { ...values } } }
            );
          } else {
            textClipResponse = await createTextClip({ ...values, 'parent-clip-id': id });
          }

          const { id: textClipId } = textClipResponse.data.data;
          updatedImages.push({ ...images[i], id: textClipId });
          const response = await fetch(images[i].src);
          const blob = await response.blob();
          blob.name = 'sticker-clip-image.gif';
          await createMedium({
            file: blob,
            attributes: { 'owner-type': 'CelebrationClip', 'owner-id': textClipId },
          });
        } catch (err) {
          errors.push(src);
        }
      }
      if (errors.length === 0) {
        await updateCanvas();
        mutate();
        createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
        setImages(updatedImages);
      } else {
        const noOfStickersFailed = errors.length;
        setImages(images.filter((img) => !errors.includes(img.src)));
        createConfirmation({
          message: `${noOfStickersFailed} ${
            noOfStickersFailed > 1 ? 'stickers' : 'sticker'
          } could not be added`,
        });
        errors = [];
      }
      setOnSave(false);
      clipMutate();
      setSubmitting(false);
    } catch (err) {
      store.set('sentryError', `${err} /Sticker Control Index, in handleSave function`);
    }
  };

  const onSavePress = () => {
    selectShape(null);
    setOnSave(true);
  };

  useEffect(() => {
    if (onSave && selectedId === null) {
      handleSave();
    }
    return () => mutate();
  }, [onSave, selectedId]);

  return (
    <>
      <Flex justifyContent="space-between" mb={1}>
        {/* {images.length === 0 &&  */}
        <Button variant="outline" onClick={onClear}>
          Add Sticker Clip
        </Button>
        {/* } */}
        {isFromMobile ? (
          <EditClipActionButton
            width={width}
            value="Save"
            disabled={isSubmitting || images.length === 0}
            onClick={onSavePress}
          />
        ) : (
          <Button
            disabled={isSubmitting || images.length === 0}
            variant="special"
            onClick={onSavePress}
          >
            Save Changes to this clip
          </Button>
        )}
      </Flex>
      <Box marginBottom={`-${height}px`} sx={{ zIndex: '3 !important' }}>
        <Stage
          width={containerWidth}
          height={height}
          style={{
            margin: 'auto',
            width: containerWidth,
          }}
          onClick={handleCanvasClick}
          onTap={handleCanvasClick}
          ref={stageRef}
        >
          <Layer>
            {images.map((image, i) => {
              return (
                <IndividualSticker
                  onDelete={async () => {
                    try {
                      selectShape(null);
                      const newImages = [...images];
                      newImages.splice(i, 1);
                      setImages(newImages);
                      if (images[i].id) {
                        await ax().delete(
                          `${celebrationPath}/celebration_sticker_clips/${images[i].id}`
                        );
                        await updateCanvas();
                        clipMutate();
                        createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
                      }
                    } catch (err) {
                      store.set(
                        'sentryError',
                        `${err} /Sticker Control Index, in onDelete function`
                      );
                    }
                  }}
                  isSelected={i === selectedId}
                  onDragEnd={(event) => {
                    setImages((currentImages) =>
                      currentImages.map((currentImage, index) => ({
                        ...currentImage,
                        x: index === i ? event.target.x() : currentImage.x,
                        y: index === i ? event.target.y() : currentImage.y,
                      }))
                    );
                  }}
                  containerWidth={containerWidth}
                  containerHeight={height}
                  onWidthChange={(event) => {
                    if (event.width <= containerWidth && event.height <= height) {
                      setImages((currentImages) =>
                        currentImages.map((currentImage, index) => ({
                          ...currentImage,
                          width: index === i ? event.width : currentImage.width,
                          height: index === i ? event.height : currentImage.height,
                        }))
                      );
                    }
                  }}
                  onSelect={() => selectShape(i)}
                  key={image.id}
                  image={image}
                />
              );
            })}
          </Layer>
        </Stage>
      </Box>
      {children}
    </>
  );
}

export default StickerControl;
