import React from 'react';
import SoundTrackPlayback from './SoundTrackPlayback';

function determineAudioTrack(currentClip, AudioTracks, isForEdit) {
  if (!isForEdit) return undefined;

  const track = AudioTracks.find((t) => {
    return currentClip?.startAt >= t.startAt && currentClip?.startAt <= t.endAt;
  });

  if (track) {
    const startSecond = currentClip.startAt - track.startAt;
    return { audioToPlay: track, startSecond };
  }
  return undefined;
}

const PreviewSoundTracks = ({
  clipSoundtrackVolume,
  tracks,
  isForEdit,
  isBuffering,
  isPlaying,
  currentTime,
  clipDuration,
}) => {
  const audioTracktoPlay = determineAudioTrack(clipDuration, tracks, isForEdit);
  return tracks.map((track) => (
    <SoundTrackPlayback
      key={`${track?.id}${track?.duration}`}
      {...track}
      isPlaying={isPlaying}
      currentTime={currentTime}
      audioTracktoPlay={audioTracktoPlay}
      clipSoundtrackVolume={clipSoundtrackVolume}
      isBuffering={isBuffering}
      isForEdit={isForEdit}
      clipDuration={clipDuration}
    />
  ));
};

export default PreviewSoundTracks;
