/* eslint-disable import/no-cycle */
import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Box } from 'rebass';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';

function FontStyleChanges({ name, label, defaultValue, ml, width = '30px' }) {
  const [values, setSelectedLayer] = useLayerSelection();
  const formik = useFormikContext();
  /* eslint-disable-next-line no-sparse-arrays */
  const [field, , helper] = formik ? useField(name) : [{}, , {}];

  let currentValue = values ? values[name] : '';
  if (formik) currentValue = field.value;

  return (
    <Box
      width={width}
      sx={{
        borderRadius: '4px',
        p: 1,
        cursor: 'pointer',
        background:
          (currentValue || '').toLowerCase() === defaultValue ? 'rgba(123, 51, 251, 0.1)' : '',
      }}
      ml={ml}
    >
      <Box
        background="transparent"
        onClick={() => {
          if (formik) {
            helper.setValue(currentValue === defaultValue ? '' : defaultValue);
            formik.submitForm();
          } else
            setSelectedLayer((lay) => ({
              ...lay,
              [name]: currentValue === defaultValue ? '' : defaultValue,
            }));
        }}
      >
        {label}
      </Box>
    </Box>
  );
}

export default FontStyleChanges;
