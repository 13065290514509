import React from 'react';
import { Flex, Box, Text } from 'rebass';
import OnboardSection from '../OnboardSection';
import { StepHeading, SubTitleHeading } from '../Printables/ReusableComponents';
import EmptyState from '../app/Svgs/EmptyState';

// eslint-disable-next-line react/prop-types
function VideoEmptyState({ children, isClipExist, AllDoneText, NextStepText }) {
  return (
    <Box>
      {isClipExist ? (
        children
      ) : (
        <OnboardSection
          step={<StepHeading>STEP 5</StepHeading>}
          subTitle={<SubTitleHeading>Compose your video</SubTitleHeading>}
          m="auto"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              border: '5px dashed #999999',
              borderRadius: '32px',
            }}
            p={3}
          >
            <EmptyState />
            <Text
              sx={{
                fontWeight: 500,
                fontSize: '35px',
                lineHeight: '34px',
                textAlign: 'center',
              }}
              mt={5}
              mb={3}
            >
              {AllDoneText}
            </Text>
            <StepHeading color="#000000" maxWidth="700px">
              {NextStepText}
            </StepHeading>
          </Flex>
        </OnboardSection>
      )}
    </Box>
  );
}

export default VideoEmptyState;
