import React, { useState, useContext, createContext, useMemo } from 'react';

const EditingSuiteContext = createContext({});

export const useEditingSuiteSelection = () => {
  const [state, dispatch] = useContext(EditingSuiteContext);
  const layer = useMemo(() => [state, dispatch], [state, dispatch]);
  return layer;
};

function EditingSuiteContextProvider({ children }) {
  const [duplicateClipPosition, setDuplicateClipPosition] = useState(null);
  const layer = useMemo(
    () => [duplicateClipPosition, setDuplicateClipPosition],
    [duplicateClipPosition, setDuplicateClipPosition]
  );

  return <EditingSuiteContext.Provider value={layer}>{children}</EditingSuiteContext.Provider>;
}

export default EditingSuiteContextProvider;
