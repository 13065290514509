import React, { useEffect } from 'react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { checkInternetFailureError, clearLocalStorageItems, sendTrackingEvent } from '@/lib/helper';
import InternetConnectivityTracker from './app/InternetConnectivityTracker';

const baseURL = process.env.NEXT_PUBLIC_API_BASE.replace('/api', '/');

function UppyComponent({
  children,
  uppy,
  uploadSuccess,
  uploadProgress,
  uploadError,
  createMultipartUpload,
  tryAgain,
}) {
  useEffect(() => {
    uppy
      .use(AwsS3Multipart, {
        companionUrl: baseURL,
        limit: 3,
        retryDelays: [0, 1000, 5000, 10000, 20000, 40000, 60000, 120000],
        createMultipartUpload,
      })
      .on('upload-success', uploadSuccess)
      .on('upload-progress', uploadProgress)
      .on('upload-error', async (file, error) => {
        sendTrackingEvent(`${file.meta.type.split('/')[0]}_upload_error_occurred`, {
          context: error,
        });
        checkInternetFailureError(error, () => {
          clearLocalStorageItems();
          uploadError(file, error);
        });
      })
      .on('upload-retry', () => {
        checkInternetFailureError({ message: 'Retried' });
      });

    // Clean up event listener
    return () => uppy.close();
  }, [uppy]);

  return (
    <>
      <InternetConnectivityTracker tryAgain={tryAgain} />
      {children}
    </>
  );
}

export default UppyComponent;
