import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import { DateTime } from 'luxon';
import { truncate } from 'lodash';

function VideoPreviewTime({ seconds, isMobile, ...props }) {
  const minutesSeconds = useMemo(
    () => DateTime.fromISO('2020-05-15').plus({ seconds }).toFormat('mm:ss'),
    [seconds]
  );
  const mils = useMemo(
    () =>
      truncate(DateTime.fromISO('2020-05-15').plus({ seconds }).toFormat(':SSS'), {
        length: 2,
        omission: '',
      }),
    [seconds]
  );
  return (
    <Text className="dm-sans" fontSize={isMobile ? '12px' : 0} color="purples.7" {...props}>
      <Text as="span" fontWeight={500}>
        {minutesSeconds}
      </Text>
      {!isMobile && (
        <Text as="span" sx={{ opacity: 0.8 }}>
          {mils}
        </Text>
      )}
    </Text>
  );
}

VideoPreviewTime.propTypes = { seconds: PropTypes.number.isRequired, isMobile: PropTypes.bool };
VideoPreviewTime.defaultProps = { isMobile: false };

export default VideoPreviewTime;
