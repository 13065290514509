import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import store from 'store';
import useSWR from 'swr';
import { BsArrowUp } from 'react-icons/bs';
import { useWindowSize } from 'react-use';
import { Text, Box, Flex, Heading, Button, Link } from 'rebass';
import { RiVideoUploadFill } from 'react-icons/ri';
import { FaVideo, FaCloudUploadAlt } from 'react-icons/fa';
import { MdLibraryMusic } from 'react-icons/md';
import { useTheme } from 'emotion-theming';
import { ax, postPrivateEventDetails } from '@/lib/api';
import VideoEditorHeader from '@/components/VideoEditor/VideoEditorHeader';
// eslint-disable-next-line import/no-cycle
import VideoEditorAddBar, {
  RenderMediaAction,
  AddWebcamVideo,
} from '@/components/VideoEditor/VideoEditorAddBar';
import { useDeleteCanceledClip, useMoveClip } from '@/hooks/mutations';
import VideoPreview from '@/components/VideoPreview';
import {
  useCelebration,
  useCelebrationClips,
  useCelebrationSoundTracks,
  useMobileCheckHook,
} from '@/hooks/celebration';
import { useTimelineClips } from '@/hooks/clip';
import { ReactComponent as LearnMore } from '@/components/app/LearnMore.svg';
import DashboardLayout from '@/components/DashboardLayout';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import { cancelUppyUpload, sendTrackingEvent, sortPosition } from '@/lib/helper';
import { TimelineClips } from '@/components/VideoEditor/ClipsList';
import SubmissionClipsList from '@/components/VideoEditor/SubmissionClipsList';
import EditCredits from '@/components/VideoEditor/EditCredits';
import AddCardClip from '@/components/VideoEditor/AddCardClip';
import AddGif from '@/components/VideoEditor/AddGif';
import ConfirmDialog from '@/components/app/ConfirmDialog';
import UploadClipFile from '@/components/VideoEditor/UploadClipFile';
import SongsLibrary from '@/components/VideoEditor/SongsLibrary';
import UploadSong from '@/components/VideoEditor/UploadSong';
import SoundSortableClips from '@/components/VideoEditor/SoundSortableClips';
import SoundTrackPreview from '@/components/VideoEditor/SoundTrackPreview';
import InternetSpeedTracker from '@/components/InternetSpeedTracker/index';
import { EntityCacheContext } from '@/lib/entities';
import EditTrack from '@/components/VideoEditor/EditTrack';
import VideoEmptyState from '@/components/VideoEditor/VideoEmptyState';
import { useCheckIfVideoGuestBook } from '@/components/CelebrationForm/hooks';
import EditingSuiteContextProvider, {
  useEditingSuiteSelection,
} from '@/components/VideoEditor/EditingSuiteContext';

export function LearnMoreAboutProcessing({ id }) {
  const [isOpen, setOpen] = useState(false);
  const isFromMobile = useMobileCheckHook();

  return (
    <>
      <AnimatedDialog
        contentProps={{
          width: '90vw',
          maxWidth: 640,
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
        isOpen={isOpen}
        onDismiss={() => setOpen(false)}
      >
        <Box textAlign="left" p={2} pt={0}>
          <Text color="#000000" fontSize="16px" fontWeight="normal" lineHeight="150%">
            Your processed video will differ in three ways:
            <br />
            1. It will be in HD
            <br />
            2. The “draft” watermark will be gone
            <br />
            3. Vertical videos have black bars on the sides in this preview. We will convert that to
            a blurred background like the one shown below during processing. You will have the
            option to turn off this feature if you prefer.
          </Text>
          <br />
          <Flex justifyContent="center" alignItems="center">
            <LearnMore />
          </Flex>
        </Box>
      </AnimatedDialog>
      {isFromMobile ? (
        <Text color="#595959" textAlign="center" fontSize="16px" pb={1}>
          This is a draft version{' '}
          <span
            onClick={() => {
              postPrivateEventDetails(`celebration_tapped_learn_more_editing_suite`, {
                'subject-type': 'Celebration',
                'subject-id': id,
              });
              setOpen('Sharing with contributors');
            }}
            style={{
              textDecorationLine: 'underline',
              color: '#3B3F85',
              cursor: 'pointer',
            }}
          >
            Learn More
          </span>
        </Text>
      ) : (
        <Box textAlign="center" mb={3}>
          <Heading color="purples.7" className="dm-sans" fontWeight="bold" lineHeight={1.2}>
            Create And Edit The Group Video
          </Heading>

          <Text
            color="greys.9"
            textAlign="center"
            className="dm-sans"
            lineHeight={1.2}
            fontSize="16px"
            py={1}
          >
            This is a draft version
          </Text>
          <Button
            className="dm-sans"
            type="submit"
            variant="primary"
            bg="pinks.4"
            p={0}
            width="141px"
            height="37px"
            onClick={() => {
              postPrivateEventDetails(`celebration_tapped_learn_more_editing_suite`, {
                'subject-type': 'Celebration',
                'subject-id': id,
              });
              setOpen(true);
            }}
            fontWeight={400}
            lineHeight={1.2}
          >
            Learn More
          </Button>
        </Box>
      )}
    </>
  );
}

// eslint-disable-next-line react/prop-types
function MediaActionItem({ title, showSeperator, onClick }) {
  return (
    <Text
      width="20%"
      pt="7px"
      onClick={onClick}
      textAlign="center"
      color="purples.7"
      sx={
        showSeperator && {
          borderRight: '2px solid #E2E8F0',
          height: '30px',
          borderRadius: '2px',
          cursor: 'pointer',
        }
      }
      fontWeight={500}
    >
      {title}
    </Text>
  );
}

function TimelinePreview(props) {
  return (
    <Box>
      <Text fontSize={15}>VIDEO TIMELINE</Text>
      <TimelineClips
        isFromMobile
        onClickClip={() => {
          store.remove('ClipPlaying');
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Box>
  );
}

function cssHandling(isFromMobile) {
  if (isFromMobile)
    return `border-radius:0px; 
     overflow-y: auto !important;
    position: fixed;
    height:  100vh;
    width: 100%;
    left: 0;
    top: 0;
    margin-top:0px;
    padding-top:10px`;

  return 'width: 90vw;';
}

const itemsPerPage = 30;

function EditingSuiteChild() {
  const {
    query: { id },
  } = useRouter();
  const [duplicateClipPosition, setDuplicateClipPosition] = useEditingSuiteSelection();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [editTrack, setEditTrack] = useState('');

  useEffect(() => {
    if (duplicateClipPosition) {
      setPage(Math.ceil(duplicateClipPosition / itemsPerPage));
      setDuplicateClipPosition(null);
    }
  }, [duplicateClipPosition]);

  useSWR('/v3/celebrate_settings');
  useSWR(id && loading ? `/v3/celebrations/${id}?page=${page}&per=${itemsPerPage}` : null);
  const isVideoGuestBookTemplate = useCheckIfVideoGuestBook();
  const deleteCanceledClip = useDeleteCanceledClip(id);
  const { updateEntities } = useContext(EntityCacheContext);

  const { width, height: windowHeight } = useWindowSize();
  const [currentModal, setCurrentModal] = useState(false);
  const celebration = useCelebration(id);
  const c = useCelebrationClips(id);
  const clips = useTimelineClips(c);
  const clipboardClips = useCelebrationClips(id, { state: 'in_clipboard' });
  const timelineClips = useCelebrationClips(id, { state: 'in_timeline' });
  const [isClipExist, setIsClipExist] = useState(false);

  useEffect(() => {
    if (
      celebration?.['visible-clips-count'] &&
      celebration?.['visible-clips-count'] !== celebration?.['visible-clips']?.length
    ) {
      setLoading(true);
    }
    setIsClipExist(celebration?.['visible-clips-count']);
  }, [celebration?.['visible-clips-count']]);

  useEffect(() => {
    if (page < celebration?.['visible-clips-page-count']) setPage(page + 1);

    if (
      celebration?.['visible-clips-count'] === celebration?.['visible-clips']?.length &&
      (page === celebration?.['visible-clips-page-count'] ||
        celebration?.['visible-clips-page-count'] === 0)
    ) {
      setLoading(false);
    }
  }, [celebration?.['visible-clips'], setLoading]);

  const isEditingBlocked =
    celebration?.state === 'creating_video' || celebration?.state === 'submitted_to_create_video';

  const [draggedVideo, setDraggedVideo] = useState(null);
  const [draggedVideoToClipBoard, setDraggedVideoToClipBoard] = useState(null);
  const [timelineHeight, setTimelineHeight] = useState(0);
  const [clipBoardHeight, setClipBoardHeight] = useState(0);
  const soundTracks = useCelebrationSoundTracks(id);
  const sortedSoundTracks = soundTracks
    .map((track) => ({ ...track, type: 'sound-tracks' }))
    .sort(sortPosition());

  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const isFromMobile = useMobileCheckHook();

  const addClipToClipboard = useMoveClip({
    id: draggedVideoToClipBoard ? draggedVideoToClipBoard.id : null,
    type: draggedVideoToClipBoard ? draggedVideoToClipBoard.type : null,
    celebrationId: id,
    state: 'in_clipboard',
  });
  const { colors } = useTheme();

  const addToClip = useMoveClip({
    id: draggedVideo ? draggedVideo.id : null,
    type: draggedVideo ? draggedVideo.type : null,
    celebrationId: id,
    state: 'in_timeline',
  });
  const closeModal = useCallback(() => setCurrentModal(''), [setCurrentModal]);

  const closeMediaModal = useCallback(() => {
    if (currentModal === 'webcam' || currentModal === 'video' || currentModal === 'image')
      setCurrentModal('media');
    else if (currentModal === 'library' || currentModal === 'upload') setCurrentModal('music');
    else setCurrentModal('');
  }, [setCurrentModal, currentModal]);

  async function addNewClip() {
    await addToClip();
  }

  async function addNewClipToClipboard() {
    await addClipToClipboard();
  }

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      if (store.get('ClipPlaying')) store.remove('ClipPlaying');
      if (store.get('PreviewClipPlaying')) store.remove('PreviewClipPlaying');
      if (store.get('nodeInRange')) store.remove('nodeInRange');
      if (store.get('sliderChanged')) store.remove('sliderChanged');
      if (store.get('isDragging')) store.remove('isDragging');
      if (store.get('clipMoving')) store.remove('clipMoving');
      // store.remove('selectedDeviceID');
      // store.remove('selectedAudioDeviceID');
      if (typeof store.get('currentSeconds') !== 'undefined') store.remove('currentSeconds');
      if (typeof store.get('audioSorted') !== 'undefined') store.remove('audioSorted');
    }
    if (draggedVideo) addNewClip();

    if (draggedVideoToClipBoard) addNewClipToClipboard();
  }, [draggedVideo, draggedVideoToClipBoard]);

  const [clipPlaying, setClipPlaying] = useState(false);

  const onDismiss = () => {
    setClipPlaying(null);
    if (store.get('Media Uploading')) setShowConfirmClose(true);
    else {
      if (showConfirmClose) setShowConfirmClose(false);

      closeMediaModal();
    }
    setEditTrack('');
  };

  const onMoveClipToTimeline = async () => {
    await ax().patch(`/v3/celebrations/${id}/move_clips_to_timeline`);
    /* eslint no-param-reassign: "error" */
    updateEntities((draft) => {
      const doesClipExist = clips?.length;
      let clipMoved = 0;
      if (doesClipExist) clipMoved = clips[clips.length - 1]?.position || 0;
      clipboardClips.forEach((clip) => {
        if (clip) {
          if (
            draft[clip.type] &&
            draft[clip.type][clip.id] &&
            draft[clip.type][clip.id].state === 'in_clipboard'
          ) {
            clipMoved += 1;
            draft[clip.type][clip.id].state = 'in_timeline';
            draft[clip.type][clip.id].position = clipMoved;
          }
        }
      });
    });
  };

  return (
    <div
      onTouchEnd={() => {
        if (store.get('Submission clip')) {
          setDraggedVideoToClipBoard(null);
          setDraggedVideo(JSON.parse(store.get('Submission clip')));
          store.remove('Submission clip');
        }
        if (store.get('TimeLine clip')) {
          setDraggedVideo(null);
          setDraggedVideoToClipBoard(JSON.parse(store.get('TimeLine clip')));
          store.remove('TimeLine clip');
        }
      }}
    >
      <AnimatedDialog isOpen={isEditingBlocked} contentCSS="min-width: 350px; margin-top:25%;">
        <Flex flexDirection="column" p={4} m="auto">
          <Heading mb={3}>Video Processing</Heading>
          <Text sx={{ maxWidth: '325px', lineHeight: '1.5' }}>
            Unfortunately, Editing is unavailable while a video is processing. We’ll email you just
            as soon as processing is complete.
          </Text>
        </Flex>
      </AnimatedDialog>
      <DashboardLayout
        maxWidth={1200}
        hasNav
        backgroundColor={isFromMobile && colors.mobileEditingSuiteScreen}
      >
        {isFromMobile ? (
          <>
            <AnimatedDialog
              isOpen={!!currentModal}
              isFullScreenModal
              contentCSS={cssHandling(isFromMobile)}
              onDismiss={onDismiss}
              mt={0}
              isFromMobile
            >
              <InternetSpeedTracker onDismiss={onDismiss} />
              <ConfirmDialog
                isOpen={showConfirmClose}
                onConfirm={() => {
                  cancelUppyUpload();
                  if (store.get('Media Uploading')) {
                    if (store.get('file-type')) {
                      store.remove('file-type');
                      sendTrackingEvent(`${store.get('file-type')}_upload_cancelled`, {
                        context: 'Canceled Upload',
                      });
                    }
                    deleteCanceledClip(store.get('uploadingID'));
                    store.remove('Media Uploading');
                  }
                  setShowConfirmClose(false);
                  closeMediaModal();
                }}
                onDismiss={() => setShowConfirmClose(false)}
                title="Cancel upload?"
                body="Your file(s) will not be uploaded."
                cancelTitle="Nevermind"
                confirmTitle="Cancel upload"
                confirmVariant="destructive"
              />
              {currentModal === 'credits' && <EditCredits isFromMobile onFinished={closeModal} />}
              {currentModal === 'card' && (
                <AddCardClip isFromMobile type="image" onFinished={closeModal} />
              )}
              {currentModal === 'gif' && (
                <AddGif
                  onFinished={closeModal}
                  occasion={celebration ? celebration['occasion-description-long'] : null}
                  isFromMobile
                />
              )}
              {currentModal === 'webcam' && (
                <AddWebcamVideo isFromMobile celebrationId={id} onFinished={closeModal} />
              )}
              {currentModal === 'video' && (
                <UploadClipFile
                  isFromMobile
                  isFromVideoGuestBookInvitation
                  type="Add Media"
                  celebrationId={id}
                  onFinished={closeModal}
                />
              )}
              {currentModal === 'image' && (
                <UploadClipFile
                  isFromMobile
                  type="image"
                  celebrationId={id}
                  onFinished={closeModal}
                />
              )}
              {currentModal === 'library' && (
                <SongsLibrary
                  isFromMobile
                  onFinished={closeModal}
                  soundTracks={sortedSoundTracks}
                />
              )}
              {currentModal === 'upload' && <UploadSong isFromMobile onDismiss={closeModal} />}

              {(currentModal === 'media' || currentModal === 'music') && (
                <Flex alignSelf="baseline" justifyContent="space-around" width="100%">
                  {currentModal === 'media' ? (
                    <>
                      <RenderMediaAction
                        Icon={<FaVideo color="white" size={42} />}
                        text="Record Video"
                        onClick={() => {
                          sendTrackingEvent(`member_visit`, {
                            context: {
                              url_text: 'Record Video',
                            },
                          });
                          setCurrentModal('webcam');
                        }}
                      />

                      <RenderMediaAction
                        Icon={<RiVideoUploadFill color="white" size={42} />}
                        text="Upload Media"
                        onClick={() => {
                          sendTrackingEvent(`member_visit`, {
                            context: {
                              url_text: 'Upload Media',
                            },
                          });
                          setCurrentModal('video');
                        }}
                      />
                    </>
                  ) : (
                    <Box width="100%">
                      <Flex alignSelf="baseline" justifyContent="space-around" mb="20px">
                        <RenderMediaAction
                          Icon={<MdLibraryMusic color="white" size={42} />}
                          text="Browse Song Library"
                          onClick={() => {
                            postPrivateEventDetails(`celebration_tapped_browse_song_library`, {
                              'subject-type': 'Celebration',
                              'subject-id': celebration?.id,
                            });
                            setCurrentModal('library');
                          }}
                        />
                        <RenderMediaAction
                          Icon={<FaCloudUploadAlt color="white" size={42} />}
                          text="Upload Song"
                          onClick={() => {
                            sendTrackingEvent(`member_visit`, {
                              context: {
                                url_text: 'Upload Song',
                              },
                            });
                            setCurrentModal('upload');
                          }}
                        />
                      </Flex>
                      {editTrack ? (
                        <EditTrack {...editTrack} onDismiss={() => setEditTrack('')} />
                      ) : (
                        <Box px={2}>
                          {sortedSoundTracks.length > 0 && (
                            <Heading variant="headings.h1">Songs in your video</Heading>
                          )}
                          <SoundSortableClips clips={sortedSoundTracks}>
                            {(list) =>
                              list.map(
                                (musicClip) =>
                                  musicClip?.id && (
                                    <SoundTrackPreview
                                      key={`track-preview-${musicClip.id}`}
                                      id={musicClip.id}
                                      isFromMobile
                                      clipPlaying={clipPlaying}
                                      onPlayClip={(clipId) => setClipPlaying(clipId)}
                                      onClick={(name, artistName) =>
                                        setEditTrack({ id: musicClip.id, name, artistName })
                                      }
                                    />
                                  )
                              )
                            }
                          </SoundSortableClips>
                          <Text lineHeight="140%" fontSize="16px" mt={2}>
                            The feature used to adjust the volume of the songs in your video is only
                            available on the desktop version of Celebrate. To use it, please log
                            into Celebrate from your computer.
                          </Text>
                        </Box>
                      )}
                    </Box>
                  )}
                </Flex>
              )}
            </AnimatedDialog>
            <Box
              p={2}
              pt={0} // Mobilre view starts
              ref={(el) => {
                if (!el) return;
                setTimelineHeight(el.getBoundingClientRect().height);
              }}
              height={`calc(100vh - 120px - ${clipBoardHeight}px)`}
              overflowY="scroll"
            >
              <Box
                onDragOver={(ev) => ev.preventDefault()}
                onDrop={(ev) => {
                  ev.preventDefault();
                  store.remove('isDragging');
                  if (ev.dataTransfer.getData('Submission clip')) {
                    store.set('clipMoving', true);
                    setDraggedVideoToClipBoard(null);
                    setDraggedVideo(JSON.parse(ev.dataTransfer.getData('Submission clip')));
                  }
                }}
              >
                <Heading variant="headings.h1" pb={2}>
                  Editing Suite
                </Heading>
                <TimelinePreview
                  timelineHeight={timelineHeight}
                  clipBoardHeight={clipBoardHeight}
                  timelineClips={timelineClips}
                  clipsLoading={loading}
                />
              </Box>
              <Box
                onDrop={(ev) => {
                  ev.preventDefault();
                  store.remove('isDragging');
                  if (ev.dataTransfer.getData('TimeLine clip')) {
                    store.set('clipMoving', true);
                    setDraggedVideo(null);
                    setDraggedVideoToClipBoard(
                      JSON.parse(ev.dataTransfer.getData('TimeLine clip'))
                    );
                  }
                }}
                onDragOver={(ev) => ev.preventDefault()}
                sx={{ position: 'fixed', bottom: 0, left: 0, zIndex: '4' }}
                bg="white"
                width="100%"
                minHeight="20%"
                maxHeight="300px"
                mb="40px"
                py={1}
                ref={(el) => {
                  if (!el) return;
                  setClipBoardHeight(el.getBoundingClientRect().height);
                }}
              >
                <Box>
                  <Flex px={2} mb={0.5} justifyContent="space-between" alignItems="center">
                    <Text fontSize={15}>CLIPBOARD</Text>
                    {clipboardClips.length > 0 && (
                      <Flex alignItems="center" onClick={onMoveClipToTimeline}>
                        <Text as={BsArrowUp} color="#3B3F85" />
                        <Text fontSize={15} ml={1} color="#3B3F85">
                          Move all to timeline
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Box pl={2} bg="#e3e8ef" minHeight={windowHeight * 0.08}>
                    <SubmissionClipsList
                      isFromMobile
                      clips={clipboardClips}
                      clipBoardHeight={clipBoardHeight}
                      clipsLoading={loading}
                    />
                  </Box>
                  <Flex justifyContent="space-between" mt={1} mb={3}>
                    <MediaActionItem
                      title="Cards"
                      showSeperator
                      onClick={() => setCurrentModal('card')}
                    />
                    <MediaActionItem
                      title="GIFs"
                      showSeperator
                      onClick={() => {
                        sendTrackingEvent(`member_visit`, {
                          context: {
                            url_text: 'Add GIF',
                          },
                        });
                        setCurrentModal('gif');
                      }}
                    />
                    <MediaActionItem
                      title="Media"
                      showSeperator
                      onClick={() => setCurrentModal('media')}
                    />
                    <MediaActionItem
                      title="Music"
                      showSeperator
                      onClick={() => setCurrentModal('music')}
                    />
                    <MediaActionItem
                      title="Credits"
                      onClick={() => {
                        sendTrackingEvent(`member_visit`, {
                          context: {
                            url_text: 'Edit Credits',
                          },
                        });
                        setCurrentModal('credits');
                      }}
                    />
                  </Flex>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <VideoEmptyState
            isClipExist={isClipExist}
            AllDoneText={
              isVideoGuestBookTemplate
                ? 'All done for now! We’ll see you after your event.'
                : 'All done for now!'
            }
            NextStepText={
              // isVideoGuestBookTemplate ? (
              //   'Check back here after your special day to see and edit together all the videos your loved ones will submit.'
              // ) : (
              <>
                Check back here later when your friends and family send in their videos.
                <br />
                <br />
                <Button
                  as={Link}
                  sx={{
                    color: 'pinks.4',
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: '21px',
                    bg: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  Feel like exploring?{' '}
                  <Text
                    as="span"
                    sx={{ textDecorationLine: 'underline' }}
                    onClick={() => setIsClipExist(true)}
                  >
                    Click here.
                  </Text>
                </Button>
              </>
              // )
            }
          >
            <Box width="100%">
              <VideoEditorHeader />
              <LearnMoreAboutProcessing id={id} />
            </Box>

            <Box
              width="100%"
              maxWidth={1200}
              sx={{
                boxShadow: '0px 0px 36px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: '20px',
              }}
              onDrop={(ev) => {
                store.remove('isDragging');
                if (ev.dataTransfer.getData('Submission clip')) {
                  store.set('clipMoving', true);
                  setDraggedVideo(JSON.parse(ev.dataTransfer.getData('Submission clip')));
                }
              }}
              onDragOver={(ev) => ev.preventDefault()}
              ref={(el) => {
                if (!el) return;
                setTimelineHeight(el.getBoundingClientRect().height);
              }}
            >
              <VideoPreview
                clips={clips.sort(sortPosition())}
                // loading={loading}
                soundTracks={sortedSoundTracks}
                height={540}
                width={width < 1227 ? 960 - (1227 - width) : 960}
                hasTimeline
                timelineClips={timelineClips.sort(sortPosition())}
                clipsLoading={loading}
              />
            </Box>
            <Box width="100%" mx="auto">
              <Box my={1}>
                <VideoEditorAddBar
                  setClipBoardHeight={(height) => setClipBoardHeight(height)}
                  soundTracks={sortedSoundTracks}
                  clips={clipboardClips}
                  timelineClips={timelineClips.sort(sortPosition())}
                  clipsLoading={loading}
                />
              </Box>
            </Box>
          </VideoEmptyState>
        )}
      </DashboardLayout>
    </div>
  );
}

function EditingSuite() {
  return (
    <EditingSuiteContextProvider>
      <EditingSuiteChild />
    </EditingSuiteContextProvider>
  );
}

export default EditingSuite;
