import React, { Image } from 'rebass';
import useImage from '@/hooks/image';

function TemplateRenderer({
  example,
  onTemplateSelection,
  isFromPreview = false,
  isSubmitting,
  isSelected,
}) {
  const src = useImage({ id: example?.id, size: isFromPreview ? 5 : 9 });
  return (
    <Image
      src={src}
      disabled={isSubmitting}
      mb={1}
      sx={{
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        background: isSelected
          ? 'linear-gradient( 110deg,#ED2365 0%,#ED2365 0%,#C8368C 35%,#A846AF 65%,#6269F9 100%) 0% 0% no-repeat padding-box'
          : '',
        p: '5px',
        cursor: 'pointer',
      }}
      onClick={onTemplateSelection}
      loading="lazy"
      decoding="async"
    />
  );
}

export default TemplateRenderer;
