import React, { useContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import { Grid, SearchBar, SearchContext, SearchContextManager } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Flex, Box, Heading, Button, Text, Image } from 'rebass';
import { useSubmitClip } from '@/hooks/clip';
import { useCelebrationClips } from '@/hooks/celebration';
import { LoadingBg } from '@/components/app/Loading';
import { checkIfIdExist } from '@/lib/helper';
import Progress from '../app/Progress';
import AnimatedDialog from '../app/AnimatedDialog';
import ProgressTracker from '../app/ProgressTracker';
import { mobileModalCSS } from '../Printables/ReusableComponents';

const gf = new GiphyFetch(process.env.NEXT_PUBLIC_GIPHY_API_KEY);

// TODO: replace API key from codesandbox with authentic one
function AddGif({ onFinished, isFromMobile, occasion, onSelectionOfSticker }) {
  return (
    <Box
      alignSelf={isFromMobile && 'baseline'}
      p={isFromMobile ? 1 : 4}
      pt={0}
      width="100%"
      backgroundColor="white"
    >
      <SearchContextManager apiKey={process.env.NEXT_PUBLIC_GIPHY_API_KEY} initialTerm={occasion}>
        <Components
          onFinished={onFinished}
          isFromMobile={isFromMobile}
          onSelectionOfSticker={onSelectionOfSticker}
        />
      </SearchContextManager>
    </Box>
  );
}

// TODO: handle mp4 upload to API
// potentially gif.images.looping.mp4
function Components({ onFinished, isFromMobile, onSelectionOfSticker }) {
  const {
    query: { id },
  } = useRouter();

  const { width } = useWindowSize();

  function getPercentage() {
    if (width > 1350) return (width / 100) * 70;
    if (width > 700) return (width / 100) * 65;
    return (width / 100) * 60;
  }

  const [boxWidth, setWidth] = useState(getPercentage());

  const [selected, setSelected] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagesWidth, setSelectedImagesWidth] = useState(0);
  const [selectedImagesHeight, setSelectedImagesHeight] = useState(0);
  const [imageLoading, setImageLoading] = useState(null);

  const { fetchGifs, term, channelSearch, activeChannel } = useContext(SearchContext);
  // TODO: revisit submitting an mp4 link
  const [submitClip, { loading, error, data, progress }] = useSubmitClip({ type: 'image' });
  const { mutate: revalidateCelebration } = useSWR(
    checkIfIdExist(id) ? `/v3/celebrations/${id}?shallow=true` : null
  );
  const clips = useCelebrationClips(id, { state: 'in_clipboard' });

  function getColoumns() {
    if (width > 1500) {
      return 3;
    }
    if (width > 1000) {
      return 2;
    }
    if (width > 600) {
      return 1;
    }
    return 5;
  }

  useEffect(() => {
    if (data) {
      revalidateCelebration();
      onFinished();
    }
  }, [data, revalidateCelebration, onFinished]);

  useEffect(() => {
    if (selected) {
      const img = new window.Image();
      setImageLoading(true);
      img.src = selected.gifUrl;
      img.onload = () => {
        setImageLoading(false);
        setSelectedImage(img.src);
        setSelectedImagesWidth(img.width);
        setSelectedImagesHeight(img.height);
      };
    } else {
      setSelectedImage(null);
    }
  }, [selected]);

  useEffect(() => {
    document.getElementsByClassName('add_gif')[0].tabIndex = -1;
  }, []);
  if (error) return <Box p={4}>there was an error</Box>;

  if (loading)
    return (
      <Box p={4} textAlign="left">
        <Heading variant="headings.h3" pb={2}>
          Uploading Clip
        </Heading>
        <Text>Please don&apos;t close this window</Text>
        <ProgressTracker progress={progress} />
        <Progress percentComplete={progress} />
      </Box>
    );

  const fetchStickers = (offset) => gf.search(term, { type: 'stickers', offset, limit: 10 });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
      // mt={!isFromMobile && "-70px"}
      ref={(el) => {
        if (!el) return;
        setWidth(el.getBoundingClientRect().width);
      }}
    >
      <Heading variant="headings.h3" pb={2}>
        Add a {onSelectionOfSticker ? 'Sticker' : 'GIF'}
      </Heading>

      {isFromMobile ? (
        <AnimatedDialog
          isOpen={imageLoading || selectedImage}
          isFullScreenModal
          contentCSS={mobileModalCSS()}
          onDismiss={() => {
            setImageLoading(false);
            setSelectedImage(null);
          }}
          // isFromMobile
        >
          <Box alignSelf="baseline" maxWidth="300px" m="0px auto">
            {imageLoading ? (
              <LoadingBg
                height={isFromMobile ? 160 : 130}
                width="90vw"
                maxHeight="300px"
                display="table-cell"
              >
                <Text color="white" className="loading" textAlign="center" mt="50px">
                  Loading
                </Text>
              </LoadingBg>
            ) : (
              selectedImage && (
                <>
                  <Image src={selectedImage} alt={selected.title} width="90vw" />
                  <Flex justifyContent="center" mt={2}>
                    <Button
                      height="45px"
                      p={1}
                      onClick={() => {
                        if (onSelectionOfSticker)
                          onSelectionOfSticker({
                            ...selected,
                            width: selectedImagesWidth,
                            height: selectedImagesHeight,
                          });
                        else submitClip({ name: '', selectedGif: selected });
                        setImageLoading(false);
                        setSelectedImage(null);
                      }}
                    >
                      Select {onSelectionOfSticker ? 'Sticker' : 'GIF'}
                    </Button>
                  </Flex>
                </>
              )
            )}
          </Box>
        </AnimatedDialog>
      ) : (
        <Box>
          {imageLoading ? (
            <LoadingBg height={130} width={200}>
              <Text color="white" className="loading" textAlign="center" mt="50px">
                Loading
              </Text>
            </LoadingBg>
          ) : (
            selectedImage && (
              <Image src={selectedImage} alt={selected.title} height={130} width={200} />
            )
          )}
        </Box>
      )}
      <Flex my={2} alignItems="center">
        <Box sx={{ borderColor: 'greys.3', borderWidth: '1px', borderStyle: 'solid' }}>
          <SearchBar className="add_gif" />
        </Box>
        {selected && !isFromMobile && (
          <Button
            ml={isFromMobile ? 1 : 2}
            height={isFromMobile && '42px'}
            p={isFromMobile && 1}
            onClick={() =>
              onSelectionOfSticker
                ? onSelectionOfSticker({
                    ...selected,
                    width: selectedImagesWidth,
                    height: selectedImagesHeight,
                  })
                : submitClip({ name: '', selectedGif: selected })
            }
          >
            Add {onSelectionOfSticker ? 'Sticker' : 'GIF'}
          </Button>
        )}
      </Flex>
      <Box height="calc(100vh - 300px)" overflow="auto">
        <Grid
          key={`${channelSearch} ${term} ${activeChannel?.user.username}`}
          columns={getColoumns()}
          width={boxWidth}
          fetchGifs={onSelectionOfSticker ? fetchStickers : fetchGifs}
          noLink
          gutter={2}
          hideAttribution
          onGifClick={(gif) => {
            setSelected({
              gifUrl: gif.images.fixed_height.url,
              gifID: gif.id,
              title: gif.title,
              videoUrl: gif.images.looping.mp4,
              position: clips.length === 0 ? 0 : (clips[0].position || 0) - 1,
            });
          }}
        />
      </Box>
    </Flex>
  );
}

export default AddGif;
