import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { Box, Flex, Button, Text } from 'rebass';
import { Label } from '@rebass/forms';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { useSubmitCelebration } from '@/components/CelebrationForm/hooks';

export function FontSizePicker({ value, onChange, width, isFromSubmitForm }) {
  return (
    <Flex
      alignItems="center"
      width={width || 92}
      sx={{
        bg: 'white',
        border: '2px solid',
        borderColor: 'border',
        borderRadius: 'medium',
        overflow: 'hidden',
      }}
      py="4px"
    >
      <Box
        as="input"
        type="input"
        value={value}
        onChange={(e) => onChange(Number(e.target.value), true)}
        onBlur={(e) => onChange(Number(e.target.value))}
        p={1}
        pr={0}
        width={isFromSubmitForm ? 65 : 32}
        sx={{ border: 'none', outline: 'none', fontSize: 1, textAlign: 'right' }}
      />
      <Text as="span" fontSize={1}>
        px
      </Text>
      <Flex flexDirection="column" px={1} ml="auto">
        <Button
          flex={1}
          type="button"
          variant="transparent"
          onClick={() => onChange(Number(value) + 1, false)}
        >
          <Text as={FiChevronUp} />
        </Button>
        <Button
          flex={1}
          type="button"
          variant="transparent"
          onClick={() => onChange(Number(value) - 1, false)}
        >
          <Text as={FiChevronDown} />
        </Button>
      </Flex>
    </Flex>
  );
}

FontSizePicker.propTypes = { value: PropTypes.number, onChange: PropTypes.func.isRequired };
FontSizePicker.defaultProps = { value: 14 };

function FontSizeField({
  name,
  fontSize,
  celebrationAttrName,
  label,
  placeholder,
  isFromSubmitForm,
  valueIndex,
  ...props
}) {
  const {
    query: { id },
  } = useRouter();
  const [field, , helper] = useField(valueIndex !== undefined ? `${valueIndex}.${name}` : name);
  const labelFontSize = useMemo(() => Math.max(0, fontSize - 1), [fontSize]);
  const submitCelebration = useSubmitCelebration();
  const formik = useFormikContext();

  return (
    <Box {...props}>
      {label && !isFromSubmitForm && (
        <Label htmlFor={name} fontSize={labelFontSize} pb={labelFontSize === 14 ? 1 : 2}>
          {label}
        </Label>
      )}
      <FontSizePicker
        value={+field.value}
        isFromSubmitForm={isFromSubmitForm}
        onChange={async (e, isBlur) => {
          helper.setValue(e);
          if (celebrationAttrName) await submitCelebration({ [celebrationAttrName]: e }, id);
          if (isFromSubmitForm)
            if (isBlur) {
              setTimeout(() => {
                formik.submitForm();
              }, 2000);
            } else formik.submitForm();
        }}
        width={props.width}
      />
      <ErrorMessage name={name}>
        {(message) => (
          <Text color="error" p={1} pt={fontSize} fontSize={1}>
            {message}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
}

FontSizeField.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

FontSizeField.defaultProps = { placeholder: '', fontSize: 2 };

export default FontSizeField;
