import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useUpdateEntity } from '@/lib/entities';
import { useClip } from '@/hooks/clip';
import VolumeControl from './VolumeControl';
import TrimControl from './TrimControl';
import RotateControl from './RotateControl';
// eslint-disable-next-line import/no-cycle
import TextControl from './TextControl';
import ClipOverview from './ClipOverview';
import ImageTrimmer from './ImageTrimmer';

function ClipControl({ currentControl, type, id, children, isFromMobile, onDismiss }) {
  const clip = useClip({ type, id });
  // const medium = useEntity(clip.video || clip.image);
  // || (isFromMobile && currentControl !== 'text' && currentControl !== 'trim'))
  const updateClip = useUpdateEntity({ type, id });
  const isControlRenderingChildren = ['duration', 'stickers', 'text'].includes(currentControl);

  return (
    <Box>
      {currentControl === 'name' && (
        <ClipOverview id={id} type={type} isFromMobile={isFromMobile} />
      )}
      {currentControl === 'trim' && <TrimControl clip={clip} isFromMobile={isFromMobile} />}
      <RotateControl
        isCurrent={currentControl === 'rotation'}
        rotation={clip['rotation-angle']}
        onUpdatePreview={updateClip}
        id={id}
        type={type}
        isFromMobile={isFromMobile}
      />
      {currentControl === 'volume' && (
        <VolumeControl id={id} type={type} isFromMobile={isFromMobile} />
      )}
      {currentControl === 'duration' && (
        <ImageTrimmer id={id} isFromMobile={isFromMobile}>
          {children}
        </ImageTrimmer>
      )}
      {currentControl === 'text' && !isFromMobile && (
        <TextControl id={id} type={type} isFromMobile={isFromMobile} onDismiss={onDismiss}>
          {children}
        </TextControl>
      )}
      {!isControlRenderingChildren && children}
    </Box>
  );
}

ClipControl.propTypes = {
  currentControl: PropTypes.string,
  id: PropTypes.string.isRequired,
};

ClipControl.defaultProps = {
  currentControl: '',
};

export default ClipControl;
