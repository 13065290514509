import React from 'react';

function EditingSuite({ stroke = '#606060', height = '30', width = '30' }) {
  if (height === '30')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
      >
        <g clipPath="url(#clip0_4595_48722)">
          <path
            d="M1.83105 10.6055C1.83105 10.9517 1.89926 11.2946 2.03176 11.6145C2.16427 11.9344 2.35849 12.2251 2.60333 12.4699C2.84817 12.7148 3.13884 12.909 3.45874 13.0415C3.77865 13.174 4.12151 13.2422 4.46777 13.2422C4.81403 13.2422 5.1569 13.174 5.4768 13.0415C5.7967 12.909 6.08737 12.7148 6.33222 12.4699C6.57706 12.2251 6.77128 11.9344 6.90378 11.6145C7.03629 11.2946 7.10449 10.9517 7.10449 10.6055C7.10449 9.90617 6.8267 9.23551 6.33222 8.74103C5.83773 8.24655 5.16707 7.96875 4.46777 7.96875C3.76847 7.96875 3.09781 8.24655 2.60333 8.74103C2.10885 9.23551 1.83105 9.90617 1.83105 10.6055Z"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.83105 20.21C1.83105 20.5562 1.89926 20.8991 2.03176 21.219C2.16427 21.5389 2.35849 21.8296 2.60333 22.0744C2.84817 22.3192 3.13884 22.5135 3.45874 22.646C3.77865 22.7785 4.12151 22.8467 4.46777 22.8467C4.81403 22.8467 5.1569 22.7785 5.4768 22.646C5.7967 22.5135 6.08737 22.3192 6.33222 22.0744C6.57706 21.8296 6.77128 21.5389 6.90378 21.219C7.03629 20.8991 7.10449 20.5562 7.10449 20.21C7.10449 19.5107 6.8267 18.84 6.33222 18.3455C5.83773 17.851 5.16707 17.5732 4.46777 17.5732C3.76847 17.5732 3.09781 17.851 2.60333 18.3455C2.10885 18.84 1.83105 19.5107 1.83105 20.21Z"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.67969 13.1221L12.341 18.2561L17.2641 21.1729"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.67969 17.6949L8.73867 14.6973"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3486 14C12.3486 12 12.3486 9.28711 12.3486 9.28711V3.57422C12.3486 3.10802 12.5338 2.66091 12.8635 2.33126C13.1931 2.0016 13.6402 1.81641 14.1064 1.81641C14.1064 1.81641 24.332 1.81641 28.0005 1.81641"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.5005 28.1836H14.1064C13.6402 28.1836 13.1931 27.9984 12.8635 27.6687C12.5338 27.3391 12.3486 26.892 12.3486 26.4258V22.0312"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6221 11.5V1.81641"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6221 28.1836V24.668"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3486 7.08984H17.6221"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.1689 17.6367V1.81641"
            stroke={stroke}
            strokeWidth="2.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.1689 28.1836V17.6367"
            stroke={stroke}
            strokeWidth="2.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 12L27 12"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 18H27"
            stroke={stroke}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4595_48722">
            <rect width={width} height={height} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_4595_48741)">
        <path
          d="M1.09863 6.36328C1.09863 6.57104 1.13955 6.77676 1.21906 6.9687C1.29856 7.16064 1.41509 7.33504 1.562 7.48195C1.7089 7.62885 1.88331 7.74538 2.07525 7.82489C2.26719 7.90439 2.47291 7.94531 2.68066 7.94531C2.88842 7.94531 3.09414 7.90439 3.28608 7.82489C3.47802 7.74538 3.65242 7.62885 3.79933 7.48195C3.94623 7.33504 4.06277 7.16064 4.14227 6.9687C4.22177 6.77676 4.2627 6.57104 4.2627 6.36328C4.2627 5.9437 4.09602 5.5413 3.79933 5.24462C3.50264 4.94793 3.10024 4.78125 2.68066 4.78125C2.26108 4.78125 1.85869 4.94793 1.562 5.24462C1.26531 5.5413 1.09863 5.9437 1.09863 6.36328Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.09863 12.1261C1.09863 12.3339 1.13955 12.5396 1.21906 12.7315C1.29856 12.9235 1.41509 13.0979 1.562 13.2448C1.7089 13.3917 1.88331 13.5082 2.07525 13.5877C2.26719 13.6672 2.47291 13.7081 2.68066 13.7081C2.88842 13.7081 3.09414 13.6672 3.28608 13.5877C3.47802 13.5082 3.65242 13.3917 3.79933 13.2448C3.94623 13.0979 4.06277 12.9235 4.14227 12.7315C4.22177 12.5396 4.2627 12.3339 4.2627 12.1261C4.2627 11.7065 4.09602 11.3041 3.79933 11.0074C3.50264 10.7107 3.10024 10.5441 2.68066 10.5441C2.26108 10.5441 1.85869 10.7107 1.562 11.0074C1.26531 11.3041 1.09863 11.7065 1.09863 12.1261Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.20801 7.87289L7.4048 10.9533L10.3586 12.7034"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.20801 10.617L5.2434 8.81836"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.40918 9V2.14453C7.40918 1.86481 7.5203 1.59655 7.71809 1.39875C7.91588 1.20096 8.18415 1.08984 8.46387 1.08984H16.9014"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9014 16.9102H8.46387C8.18415 16.9102 7.91588 16.799 7.71809 16.6012C7.5203 16.4035 7.40918 16.1352 7.40918 15.8555V13.2188"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5732 7.41797V1.08984"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5732 16.9102V14.8008"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.40918 4.25391H10.5732"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9014 10.582V1.08984"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9014 16.9102V10.582"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.40918 7.41797H16.9014"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6279 10.582H16.9014"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4595_48741">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditingSuite;
