import React, { useState } from 'react';
import { Image, Box, Heading, Button, Flex } from 'rebass';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import produce from 'immer';
import { useEntity, useUpdateEntity, useReceivePayload } from '@/lib/entities';
import Grid from '@/components/app/Grid';
import { useImage } from '@/hooks/image';
import { ax } from '@/lib/api';
import { useRequestReducer, useStockImages, useGetCelebration } from '@/hooks/api';
import { useCelebration } from '@/hooks/celebration';

function StockImagePreview({ id, type, onClick, selected }) {
  const stockImage = useEntity({ id, type }) || {};
  const src = useImage(stockImage.image || {});
  // const image = useEntity(stockImage.image);
  // const src = image && image['asset-url'];
  if (!stockImage) return false;
  return (
    <Button
      type="button"
      onClick={onClick}
      variant="transparent"
      p="3px"
      sx={{
        borderRadius: 'small',
        border: '3px solid',
        borderColor: selected ? 'purples.4' : 'transparent',
      }}
    >
      <Image
        src={src}
        sx={{
          display: 'block',
          objectFit: 'cover',
          gridArea: '1 / 1 / 2 / 2',
        }}
      />
    </Button>
  );
}

function AddCardClip({ onFinished, isFromMobile }) {
  const {
    query: { id },
  } = useRouter();
  const { mutate } = useGetCelebration(id);
  const celebration = useCelebration(id);
  const updateEntity = useUpdateEntity({ id, type: 'celebrations' });
  const receivePayload = useReceivePayload();
  const { data } = useStockImages();
  const [selected, setSelected] = useState('');
  const [{ loading }, dispatch] = useRequestReducer();
  const selectedImage = useEntity({ type: 'stock-photos', id: selected });
  const { width } = useWindowSize();

  const handleSubmit = async () => {
    const oldMediumId = selectedImage.image.id;
    dispatch({ type: 'loading' });
    try {
      const newMedium = await ax().patch(`/v3/media/${oldMediumId}/duplicate`);
      const { id: mediumId } = newMedium.data.data;
      const { 'lock-version': lockVersion } = newMedium.data.data.attributes;

      const clipResponse = await ax().post(`/v3/celebrations/${id}/celebration_image_clips`, {
        data: {
          attributes: {
            state: 'in_clipboard',
            'stock-image-id': oldMediumId,
          },
        },
      });
      const { id: clipId } = clipResponse.data.data;

      updateEntity({
        'image-clips': [
          ...celebration['image-clips'],
          { type: 'celebration-image-clips', id: clipId },
        ],
      });

      const clipMedium = await ax().patch(`/v2/media/${mediumId}`, {
        data: {
          attributes: {
            'owner-type': 'CelebrationClip',
            'owner-id': clipId,
            'lock-version': lockVersion,
          },
        },
      });
      receivePayload(clipMedium);
      const newClip = produce(clipResponse, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.data.data.relationships.image.data = { type: 'images', id: clipMedium.data.data.id };
      });
      receivePayload(newClip);
      mutate();
    } catch (err) {
      dispatch({ type: 'error', error: err.response });
    }
    dispatch({ type: 'success', data: {} });
  };

  return (
    <Box p={isFromMobile ? 2 : 4} pt={isFromMobile ? 2 : 0} pb={isFromMobile && 4} width="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={1}>
        {!isFromMobile && (
          <Heading variant="headings.h3" pb={2}>
            Add a Card
          </Heading>
        )}
        {isFromMobile && (
          <Button
            disabled={!selected || loading}
            variant="transparent"
            type="button"
            // mt={-120}
            onClick={async () => {
              await handleSubmit();
              onFinished();
            }}
            sx={{
              zIndex: 3,
              fontWeight: 'bold',
              opacity: (!selected || loading) && 0.5,
              cursor: !selected || loading ? 'not-allowed' : 'pointer',
              position: 'fixed',
              right: 16,
              top: width < 500 ? 21 : 31,
              color: 'purples.7',
            }}
          >
            Add Card
          </Button>
        )}
      </Flex>
      <Box
        height={isFromMobile ? 'calc(100vh - 160px)' : '50vh'}
        sx={{ overflowY: 'auto', width: isFromMobile && '100%' }}
        mb={4}
      >
        <Grid
          role="button"
          gridTemplateColumns={`repeat(auto-fill, minmax(${width < 500 ? '40%' : '30%'}, 1fr))`}
          gridGap={1}
        >
          {data &&
            data.data.data.map((stock) => (
              <StockImagePreview
                id={stock.id}
                type={stock.type}
                key={`stock-image-${stock.id}`}
                selected={stock.id === selected}
                onClick={() => setSelected(stock.id === selected ? null : stock.id)}
              />
            ))}
        </Grid>
      </Box>
      {!isFromMobile && (
        <Button
          disabled={!selected || loading}
          variant="special"
          type="button"
          onClick={async () => {
            await handleSubmit();
            onFinished();
          }}
        >
          Add Card
        </Button>
      )}
    </Box>
  );
}

export default AddCardClip;
