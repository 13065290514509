import React, { useMemo, useRef, useEffect } from 'react';
// import UAParser from 'ua-parser-js';
import { inRange } from 'lodash';
import store from 'store';
import { useEntity } from '@/lib/entities';
import { useCheckIsIosUser } from '@/hooks/clip';

// const parser = new UAParser();
// const browserName = parser.getResult().browser.name;

function Playback({
  clipSoundtrackVolume,
  id,
  type,
  nodeInRange,
  startAt,
  isPlaying,
  currentTime,
  isBuffering,
  editCurrentTimeStartSeconds,
  isForEdit,
}) {
  const audioRef = useRef();
  const track = useEntity({ id, type });
  const audio = useEntity(track?.audio);
  const isIOSUser = useCheckIsIosUser();
  // controlled play/pause
  useEffect(() => {
    if (
      nodeInRange &&
      (isForEdit ? store.get('PreviewClipPlaying') : store.get('ClipPlaying')) &&
      !isBuffering
    ) {
      audioRef.current.play();
    }
    if ((isForEdit ? !store.get('PreviewClipPlaying') : !store.get('ClipPlaying')) || isBuffering) {
      audioRef.current.pause();
    }
  }, [
    nodeInRange,
    isBuffering,
    !store.get('ClipPlaying'),
    !store.get('PreviewClipPlaying'),
    isForEdit,
  ]);

  // slider scrubbing
  useEffect(() => {
    if (
      !isPlaying &&
      (isForEdit ? !store.get('PreviewClipPlaying') : !store.get('ClipPlaying')) &&
      nodeInRange
    ) {
      if (editCurrentTimeStartSeconds) audioRef.current.currentTime = editCurrentTimeStartSeconds;
      else audioRef.current.currentTime = isForEdit ? startAt : currentTime - startAt;
    }
  }, [
    isPlaying,
    currentTime,
    startAt,
    store.get('ClipPlaying'),
    store.get('PreviewClipPlaying'),
    isForEdit,
    nodeInRange,
    editCurrentTimeStartSeconds,
  ]);

  useEffect(() => {
    if (typeof clipSoundtrackVolume !== 'undefined') audioRef.current.volume = clipSoundtrackVolume;
    if (!nodeInRange) {
      if (isIOSUser) audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.volume = 0;
    }
  }, [clipSoundtrackVolume, nodeInRange]);

  return (
    <audio
      autoPlay={isForEdit ? store.get('PreviewClipPlaying') : store.get('ClipPlaying')}
      preload="true"
      ref={audioRef}
      src={audio?.['asset-url']}
      onCanPlay={() => {
        // if (browserName?.includes('Safari')) {
        //   audioRef.current.currentTime = currentTime - startAt;
        // }
      }}
    />
  );
}

function SoundTrackPlayback({
  startAt,
  endAt,
  isBuffering,
  isForEdit,
  currentTime,
  clipDuration,
  audioTracktoPlay,
  ...props
}) {
  const nodeInRange = useMemo(() => {
    if (isForEdit) {
      return inRange((clipDuration?.startAt ?? 0) + currentTime, startAt, endAt);
    }

    return inRange(currentTime, startAt, endAt);
  }, [currentTime, isForEdit, startAt, endAt, audioTracktoPlay]);

  return (
    <Playback
      {...{
        startAt: clipDuration ? clipDuration.startAt : startAt,
        endAt: clipDuration ? clipDuration.endAt : endAt,
        currentTime,
        nodeInRange,
        isForEdit,
        isBuffering,
        editCurrentTimeStartSeconds: nodeInRange ? audioTracktoPlay?.startSecond : undefined,
      }}
      {...props}
    />
  );
}

export default SoundTrackPlayback;
