/* eslint-disable import/no-cycle, no-param-reassign, no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { Box, Text, Flex } from 'rebass';
import { RiFontSize } from 'react-icons/ri';
import { FaBold } from 'react-icons/fa';
import { isEqual, uniq } from 'lodash';
import useOnClickOutside from 'use-onclickoutside';
import { useDebounce } from 'use-debounce';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import ColorField from '../app/formik/ColorField';
import ShowBottomSheet from '../app/ShowBottomSheet';
import ZoomSlider from '../VideoPreview/ZoomSlider';
import AlignmentEditor from './AlignmentEditor';
import SelectField from '../app/formik/SelectField';
import FontStyleChanges from './FontStyleChanges';
import BoldImage from '../app/Svgs/BoldImage';
import ItalicImage from '../app/Svgs/ItalicImage';
import UnderlineImage from '../app/Svgs/UnderlineImage';

const getUpdatedKey = (oldData, newData) => {
  const data = uniq([...Object.keys(oldData), ...Object.keys(newData)]);

  let updatedKey = null;

  data.some((key) => {
    if (!isEqual(oldData[key], newData[key])) {
      updatedKey = key;
      return true; // Stop iteration
    }
    return false;
  });

  return updatedKey;
};

function FontSizeChange({ name }) {
  const ref = useRef();
  const [showPicker, setShowPicker] = useState(false);
  const [values, setValues] = useLayerSelection();

  const fontSize =
    values?.[name] &&
    (values[name].includes('px') ? +values[name].replaceAll('px', '') : +values[name]);

  useOnClickOutside(ref, () => {
    if (showPicker) setShowPicker(false);
  });

  return (
    <Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => setShowPicker(true)}
      >
        <RiFontSize color="#414143" size={24} />
        <Text className="posterEditiorNavText">Font Size</Text>
      </Flex>

      <ShowBottomSheet
        heading="Font Size"
        onClose={() => setShowPicker(false)}
        isOpen={showPicker}
        ref={ref}
      >
        <Flex justifyContent="center">
          <ZoomSlider
            id="zoom_control"
            type="range"
            showSign="px"
            zoom={fontSize}
            onChange={(e) => {
              setValues((val) => ({ ...val, [name]: `${e.target.value}px` }));
              // setFontSize(Number())
            }}
            max={1000}
            min={10}
            step="10"
            width="75px"
            parentWidth="100vw"
            style={{ width: '100%' }}
          />
        </Flex>
      </ShowBottomSheet>
    </Box>
  );
}

function TextStyleChange({ options }) {
  const ref = useRef();
  const [showPicker, setShowPicker] = useState(false);
  const [selectedLayer] = useLayerSelection();
  useOnClickOutside(ref, () => {
    if (showPicker) {
      setShowPicker(false);
    }
  });

  let supportsBold = false;
  let supportsItalic = false;
  const fontFound = options.find((list) => list.label === selectedLayer['font-name']);
  if (fontFound) {
    supportsBold = fontFound.supportsBold;
    supportsItalic = fontFound.supportsItalic;
  }
  return (
    <Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => setShowPicker(true)}
      >
        <FaBold color="#414143" size={24} />
        <Text className="posterEditiorNavText">Text style</Text>
      </Flex>
      <ShowBottomSheet
        heading="Text style"
        onClose={() => setShowPicker(false)}
        isOpen={showPicker}
        ref={ref}
      >
        <Flex justifyContent="space-around" alignItems="center" py={2}>
          {selectedLayer['font-weight'] !== undefined && supportsBold && (
            <FontStyleChanges
              name="font-weight"
              defaultValue="bold"
              label={<BoldImage fill="#414143" />}
            />
          )}
          {selectedLayer['font-style'] !== undefined && supportsItalic && (
            <FontStyleChanges
              ml={1}
              name="font-style"
              defaultValue="italic"
              label={<ItalicImage fill="#414143" />}
            />
          )}
          {selectedLayer['font-decoration'] !== undefined && (
            <FontStyleChanges
              ml={1}
              name="font-decoration"
              defaultValue="underline"
              label={<UnderlineImage fill="#414143" />}
            />
          )}
        </Flex>
      </ShowBottomSheet>
    </Box>
  );
}

function MobileTextEditor({ handleSave, options, isCursorInsideEditor }) {
  const [prevState, setPrevState] = useState(null);
  const [values] = useLayerSelection();
  const [debouncedValues] = useDebounce(values, 500);

  useEffect(() => {
    const updatedKeys = getUpdatedKey(prevState || {}, values);
    if (updatedKeys && updatedKeys !== 'text' && updatedKeys !== 'id') handleSave();

    setPrevState(debouncedValues);
  }, [debouncedValues]);

  return (
    <Flex
      alignItems="center"
      data-tut="CHANGE_COLOUR"
      sx={{
        justifyContent: 'space-around',
        overflowX: 'auto',
        height: '75px',
        width: '100vw',
      }}
      onMouseEnter={() => {
        isCursorInsideEditor = true;
      }}
      onMouseLeave={() => {
        isCursorInsideEditor = false;
      }}
    >
      {values['font-alignment'] !== undefined && (
        <AlignmentEditor name="font-alignment" isFromMobileScreen />
      )}
      <ColorField
        name="text-color"
        label="Text Color"
        mr={1}
        pb={0}
        isFromMobileScreen
        isFromSubmitForm
      />
      <FontSizeChange name="font-size" />
      <SelectField
        name="font"
        label="Font Family"
        maxWidth={460}
        width="100%"
        pb={0}
        background="white"
        options={options}
        isFromMobileScreen
        selectProps={{
          menuPlacement: 'bottom',
          styles: {
            option: (provided, state) => ({
              ...provided,
              fontFamily: state.value,
              color: state.isSelected ? '#16161D' : 'black',
            }),
            menu: (provided) => ({
              ...provided,
              height: 120,
              overflow: 'auto',
            }),
          },
        }}
        isFromSubmitForm
      />
      <TextStyleChange options={options} />
    </Flex>
  );
}

function RenderEditiorForMobile({
  setMobileFooterHeight,
  showEditor,
  handleSave,
  fontFamilyOptions,
  RenderFooter,
}) {
  const [selectedLayer] = useLayerSelection();
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      width="100%"
      ref={(el) => {
        if (!el) return;
        setMobileFooterHeight(el.getBoundingClientRect().height || 76); // 76px is the height of CelebrationLayoutFooter
      }}
      sx={{
        position: 'fixed',
        width: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 7,
      }}
    >
      {selectedLayer && showEditor ? (
        <Flex
          sx={{
            width: '100%',
            flex: 1,
            position: 'relative',
          }}
        >
          <MobileTextEditor handleSave={handleSave} options={fontFamilyOptions} />
        </Flex>
      ) : (
        RenderFooter()
      )}
    </Flex>
  );
}

export default RenderEditiorForMobile;
