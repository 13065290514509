export default {
  'font-size': 25,
  'text-color': '#ffffff',
  'background-color': '#6269F0',
  font: 'sans-serif',
  text: 'new text',
  transform: 'translate(300px, 200px) rotate(0deg)',
  x: 300,
  y: 200,
};
