import React, { useEffect, createContext } from 'react';
import { useImmer } from 'use-immer';
import { useCheckIfQnAIdea } from '../CelebrationForm/hooks';

export const VideoPreviewContext = createContext();

const getClipSoundtrackVolume = (clip) => {
  if (typeof clip['soundtrack-volume'] !== 'undefined') return clip['soundtrack-volume'];
  if (clip.image) return 1;
  return 0.01;
};

export function VideoPreviewContextProvider({
  hasFadeTransition = true,
  currentSeconds,
  clips,
  children,
}) {
  const [value, produce] = useImmer({
    currentClipId: clips && clips.length > 0 ? clips[0].id : null,
    currentOpacity: hasFadeTransition ? 0 : 1,
  });
  const isQATemplate = useCheckIfQnAIdea();

  useEffect(() => {
    if (clips && clips.length > 0) {
      const currentClip = clips.find(
        (clip) => currentSeconds >= clip.startAt && currentSeconds < clip.endAt
      );
      if (!currentClip) return;

      // fade in 1 second, fade out 1 second
      let currentOpacity;
      if (hasFadeTransition) {
        if (currentClip.position === 0 || isQATemplate) {
          currentOpacity = 1;
        } else {
          if (currentSeconds - currentClip.startAt < 1)
            currentOpacity = currentSeconds - currentClip.startAt;
          if (currentClip.endAt - currentSeconds < 1)
            currentOpacity = currentClip.endAt - currentSeconds;
        }
      }

      let currentVolume = currentClip.volume || 1 * currentOpacity || 1;
      if (currentClip.volume === 0) {
        currentVolume = currentClip.volume;
      }

      // fade transition of soundtrack volume
      const currentClipIndex = clips.findIndex((clip) => clip.id === currentClip.id);
      const nextClip = clips[currentClipIndex + 1];

      let soundtrackVolume = getClipSoundtrackVolume(currentClip);
      if (nextClip) {
        // start transitioning at 2.5 seconds from the end of the current clip
        if (currentSeconds >= currentClip.endAt - 2.5) {
          const currentStVolume = getClipSoundtrackVolume(currentClip);
          const nextStVolume = getClipSoundtrackVolume(nextClip);
          if (currentStVolume === nextStVolume) {
            soundtrackVolume = currentStVolume;
          }
          // multiply the difference in volumes by the percentage through the 2.5 second transition

          const transitionDifference = nextStVolume - currentStVolume;
          const transitionPercentage = (currentClip.endAt - currentSeconds) / 2.5;
          const transitionPercentageComplete = 1 - transitionPercentage;

          soundtrackVolume = currentStVolume + transitionDifference * transitionPercentageComplete;
        }
      }
      /* eslint-disable no-param-reassign */
      produce((draft) => {
        draft.currentVolume = currentVolume;
        draft.currentClipId = currentClip.id;
        draft.currentOpacity = currentOpacity;
        draft.soundtrackVolume = soundtrackVolume;
      });
    }
  }, [currentSeconds, clips]);

  return <VideoPreviewContext.Provider value={value}>{children}</VideoPreviewContext.Provider>;
}

export default VideoPreviewContext;
