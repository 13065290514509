/* eslint-disable import/no-cycle */
import React, { useState, useRef, memo, useCallback } from 'react';
import { Text, Flex, Box } from 'rebass';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { checkIsIpadUser } from '@/lib/helper';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import RenderImage from './RenderImage';
import BufferingOverlay from '../VideoPreview/BufferingOverlay';

function SystemLayer({
  index,
  zoom,
  isDeleteIconOpen,
  setIsDeleIconOpen,
  onFlipDeletion,
  setIsPreviewOpenForChildren,
  ...props
}) {
  const ref = useRef();
  const [isDeletingFlipSide, setIsDeletingFlipSide] = useState(false);

  const {
    height,
    width,
    xpos,
    ypos,
    photo,
    text,
    'render-order': renderOrder,
    enabled,
    ...attr
  } = props;
  const isIpadUser = checkIsIpadUser();
  const isForFlipForIdeas = props?.name.includes('flip');
  const [selectedLayer, setSelectedLayer] = useLayerSelection();

  const isDeleteIconVisible = isDeleteIconOpen && isForFlipForIdeas;
  const deleteTextLayer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeletingFlipSide(true);
    await onFlipDeletion();
    setIsDeletingFlipSide(false);
  };

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (selectedLayer) setSelectedLayer(null);
      else setIsDeleIconOpen(true);
    },
    [isForFlipForIdeas, setIsDeleIconOpen, selectedLayer]
  );

  if (text)
    return (
      <Text
        width={`${width}px`}
        height={`${height}px`}
        sx={{
          position: 'absolute',
          fontSize: isIpadUser ? `${(attr['font-size'] / 100) * zoom}px` : `${attr['font-size']}px`,
          fontFamily: `${attr['font-name']} !important`,
          fontWeight: attr['font-weight'],
          textAlign: attr['font-alignment'],
          verticalAlign: attr['vertical-alignment'],
          color: attr['font-colour'],
          cursor: 'pointer',
          zIndex: renderOrder,
          wordBreak: 'break-word',
          outline: 'none',
          writingMode: 'horizontal-tb',
          fontFeatureSettings: `"kern" 0,"calt" 0,"liga" 0,"clig" 0,"dlig" 0,"hlig" 0`,
          WebkitFontSmoothing: 'subpixel-antialiased',
          MozOsxFontSmoothing: 'grayscale',
          WebkitTextSizeAdjust: 'auto',
          MozTextSizeAdjust: 'auto',
          msTextSizeAdjust: 'auto',
          textSizeAdjust: 'auto',
          WebkitFontKerning: 'none',
          fontKerning: 'none',
          fontSynthesis: 'none',
          WebkitFontVariantLigatures: 'none',
          fontVariantLigatures: 'none',
          letterSpacing: '0',
          overflow: 'visible',
          overflowWrap: 'break-word',
          padding: '0px !important',
          margin: '0px !important',
          MozTabSize: '4',
          OTabSize: '4',
          tabSize: '4',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          left: `${xpos}px`,
          top: `${ypos}px`,
          fontStyle: attr?.['font-style'],
          textDecoration: attr?.['font-decoration'],
          touchAction: 'pan-x pan-y pinch-zoom',
          lineHeight: `${attr['font-line-height']}px`,
        }}
      >
        {text}
      </Text>
    );

  return (
    <Box
      width={`${width}px`}
      height={`${height}px`}
      sx={{
        position: 'absolute',
        left: `${xpos}px`,
        top: `${ypos}px`,
        border: isDeleteIconVisible ? 'solid #A259FF' : 0,
        zIndex: renderOrder,
        writingMode: 'horizontal-tb',
        touchAction: 'pan-x pan-y pinch-zoom',
      }}
    >
      <Box
        ref={ref}
        py={1}
        bg="#fff"
        sx={{
          position: 'absolute',
          display: isDeleteIconVisible ? 'block' : 'none',
          boxShadow: '0 0 0 1px rgba(64,87,109,0.07),0 2px 12px rgba(53,71,90,0.2)',
          borderRadius: '12px',
          pointerEvents: 'auto',
          transform: 'translate(100%, -145%)',
        }}
      >
        <BufferingOverlay isBuffering={isDeletingFlipSide} sx={{ mb: 2 }}>
          <Flex py={4} px={4} textAlign="center" alignItems="center">
            <Text
              size={250}
              color="black"
              as={RiDeleteBin5Fill}
              sx={{ cursor: isDeletingFlipSide ? 'not-allowed' : 'pointer' }}
              onClick={!isDeletingFlipSide && deleteTextLayer}
            />
          </Flex>
        </BufferingOverlay>
      </Box>
      <RenderImage
        index={index}
        photo={photo}
        text={text}
        enabled={enabled}
        handleClick={isForFlipForIdeas ? handleClick : undefined}
        {...attr}
        setIsPreviewOpenForChildren={setIsPreviewOpenForChildren}
      />
    </Box>
  );
}

export default memo(SystemLayer);
