import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { useRouter } from 'next/router';
import {
  useClipName,
  useClipStickerClip,
  useVideoableClip,
  useVideoClipUploadStatus,
} from '@/hooks/clip';
import { useEntity } from '@/lib/entities';
import { useCelebration } from '@/hooks/celebration';
import { showLockIcon } from '@/lib/helper';
import ImageNode from './ImageNode';
import TextClipImage from './TextClipImage';
import { LoadingBg } from '../app/Loading';
import { VideoPreviewContext } from './VideoPreviewContext';
import SafariVideoNode from './SafariVideoNode';
import { emailRender } from '../Clip';
import StickerClipImage from './StickerClipImage';

function PreviewNode({
  currentTime,
  shouldPlay,
  onProgress,
  onPlay,
  onPause,
  onEnded,
  startAt,
  endAt,
  volume,
  id,
  showTextClip,
  height,
  width,
  setClipSoundtrackVolume,
  dispatch,
  setBuffering,
  isBuffering,
  isForEdit = false,
  originalVolume,
  clipDetail,
  handlePlayClick,
  handlePauseClick,
  isForStickerEdit,
  index,
}) {
  const {
    query: { id: celebId },
  } = useRouter();
  const { currentClipId, currentOpacity } = useContext(VideoPreviewContext);
  const clip = clipDetail || useVideoableClip(id) || {};
  const uploadStatus = useVideoClipUploadStatus(id, clip.image && true);
  // const [opacity, setOpacity] = useState(0);
  const authorName = useClipName({ id, type: clip.video ? 'video' : 'Image' });
  const stickerClips = useClipStickerClip({ id });
  const celebration = useCelebration(celebId);

  const showLock = showLockIcon(celebration, clip);

  const normalizedVideo = useEntity(clip?.['normalized-media']);
  const nodeInRange = currentClipId === id;

  useEffect(() => {
    if (showLock) handlePauseClick();
  }, [showLock]);

  useEffect(() => {
    if (nodeInRange) {
      setClipSoundtrackVolume(
        typeof clip['soundtrack-volume'] !== 'undefined'
          ? clip['soundtrack-volume']
          : (clip.video && 0.05) || 1
      );
    }
  }, [nodeInRange, setClipSoundtrackVolume, clip]);

  if (uploadStatus === 'pending_upload')
    return (
      <LoadingBg sx={{ gridArea: '1 / 1 / 2 / 2' }}>
        <Text className="loading" color="white" textAlign="center" mt="30%">
          Processing
        </Text>
      </LoadingBg>
    );

  if (uploadStatus === 'stalled_upload' && nodeInRange)
    return (
      <LoadingBg sx={{ gridArea: '1 / 1 / 2 / 2' }}>
        <Text color="white" textAlign="center" m="10%">
          {authorName} tried to {clip.video ? 'upload/record a video' : 'upload a picture'}, but it
          did not reach us. Please ask them to try again
        </Text>
      </LoadingBg>
    );

  if (uploadStatus === 'error' && nodeInRange)
    return (
      <LoadingBg sx={{ pointerEvents: 'none', gridArea: '1 / 1 / 2 / 2', height }}>
        <Text color="white" fontSize="16px" textAlign="center" m="10%">
          {authorName
            ? `This file by ${authorName} cannot be processed. Please ask them to upload a different picture or video. If you need help, please contact 
          `
            : `File cannot be processed. Please upload a different picture or video. If you need help, please contact `}
          {emailRender()}
        </Text>
      </LoadingBg>
    );

  if (showLock)
    return (
      <LoadingBg sx={{ gridArea: '1 / 1 / 2 / 2' }}>
        <Flex
          height="100%"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(0, 0, 0, 0.7)"
        >
          <Text color="white" textAlign="center" m="10%" fontSize="30px">
            PayNow to see the preview
          </Text>
        </Flex>
      </LoadingBg>
    );

  const notNormalized = !normalizedVideo?.['hls-np'] || isForEdit;

  function getOpacity() {
    if (isForEdit || index === 1) return 1;
    if (nodeInRange) return currentOpacity;
    return 0;
  }

  return (
    <div
      style={{
        display: 'grid',
        backgroundColor: '#272929',
        gridArea: '1 / 1 / 2 / 2 ',
        height: 'inherit',
        width: stickerClips.length > 0 ? width : 'inherit',
        opacity: getOpacity(),
        overflowY: 'hidden',
        position: stickerClips.length > 0 && 'absolute',
      }}
    >
      {clip?.['text-clips'].length > 0 && notNormalized && showTextClip && (
        <TextClipImage
          id={id}
          size={6}
          sx={{ gridArea: '1 / 1 / 2 / 2', width: '100%', zIndex: 2 }}
        />
      )}
      {!isForStickerEdit && notNormalized && (
        <StickerClipImage
          id={id}
          size={6}
          height={height}
          width={width}
          stickerClips={stickerClips}
          sx={{ gridArea: '1 / 1 / 2 / 2', zIndex: 1, width: '100%' }}
        />
      )}
      <Flex
        alignItems="stretch"
        justifyContent="center"
        sx={{ gridArea: '1 / 1 / 2 / 2', overflow: 'hidden' }}
      >
        {clip.video && (
          <SafariVideoNode
            id={clip.id}
            notNormalized={notNormalized}
            normalizedVideo={normalizedVideo}
            index={index}
            clipDetail={clipDetail}
            currentTime={nodeInRange ? currentTime - startAt : 0}
            nodeInRange={nodeInRange}
            onPlay={onPlay}
            onPause={onPause}
            onStalled={onPause}
            volume={volume}
            onEnded={onEnded}
            shouldPlay={shouldPlay}
            onProgress={onProgress}
            startAt={startAt}
            originalVolume={originalVolume}
            endAt={endAt}
            isBuffering={isBuffering}
            setBuffering={setBuffering}
            isForEdit={isForEdit}
            handlePauseClick={handlePauseClick}
            handlePlayClick={handlePlayClick}
          />
        )}
        {clip.image || clip['giphy-id'] ? (
          <ImageNode
            id={clip.id}
            clipDetail={clipDetail}
            shouldPlay={shouldPlay}
            nodeInRange={nodeInRange}
            gifUrl={clip['giphy-gif-url']}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            height={height}
            width={width}
            dispatch={dispatch}
            currentTime={currentTime - startAt}
            setBuffering={setBuffering}
            isBuffering={isBuffering}
            isForEdit={isForEdit}
          />
        ) : null}
      </Flex>
    </div>
  );
}
PreviewNode.propTypes = {
  currentTime: PropTypes.number.isRequired,
  onPlay: PropTypes.func.isRequired,
  onEnded: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  endAt: PropTypes.number.isRequired,
  startAt: PropTypes.number.isRequired,
  shouldPlay: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default PreviewNode;
