import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Flex, Image, Button } from 'rebass';
import { useOnlineStatus } from '@/hooks/useOnlineStatus';
import NoInternet from '@/public/Images/NewDesignImages/NoInternet.png';
import { resumeUppyUpload } from '@/lib/helper';
import AnimatedDialog from './AnimatedDialog';

function InternetConnectivityTracker({ tryAgain }) {
  const isOnline = useOnlineStatus();
  const [base64Img, setBase64Img] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    fetch(NoInternet)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setBase64Img(base64data); // Base64 encoded image data
        };
      })
      .catch((error) => {
        console.error('Error fetching the image:', error);
      });
  }, []);

  if (!isOnline)
    return (
      <AnimatedDialog isOpen contentProps={{ width: '80vw', maxWidth: '700px' }}>
        <Flex pb={4} px={2} mt="40px" alignItems="center" width="100%" flexDirection="column">
          <Flex justifyContent="center" alignItems="center" flexDirection="column">
            <Text
              color="#16161D"
              fontSize="18px"
              fontWeight={400}
              maxWidth="350px"
              m="auto"
              textAlign="center"
              mt={[4, 0]}
              mb={2}
              lineHeight="130%"
              sx={{ letterSpacing: '-0.396px' }}
            >
              Oh no - looks like we lost your internet connection!
            </Text>
            <Image src={base64Img} maxWidth={260} />
            <Text
              maxWidth="350px"
              color="#16161D"
              fontSize="18px"
              fontWeight={400}
              m="auto"
              textAlign="center"
              mb={2}
              lineHeight="130%"
              sx={{ letterSpacing: '-0.396px' }}
            >
              If you <b>keep this window open and move to an area with better wifi</b>, we’ll try
              again.
            </Text>
            <Flex mt={5} justifyContent="center" width="100%">
              <Button
                variant="special"
                className={loading && 'pulse loading'}
                onClick={() => {
                  setLoading(true);
                  if (tryAgain) tryAgain();
                  else resumeUppyUpload();

                  setTimeout(() => {
                    setLoading(false);
                  }, 1500);
                }}
                width="100%"
                type="button"
              >
                {loading ? 'Loading' : 'Try uploading again'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </AnimatedDialog>
    );

  return null;
}
InternetConnectivityTracker.defaultProps = { tryAgain: () => {} };
InternetConnectivityTracker.propTypes = { tryAgain: PropTypes.func };

export default InternetConnectivityTracker;
