import React from 'react';
import { Text, Flex, Image, Box, Button } from 'rebass';
import VGBExampleImage1 from '@/public/Images/NewDesignImages/VGBExampleImage1.png';
import VGBExampleImage2 from '@/public/Images/NewDesignImages/VGBExampleImage2.png';
import AnimatedDialog from './app/AnimatedDialog';

function VGBExampleModal({ onDone, ...props }) {
  return (
    <AnimatedDialog contentProps={{ width: '90vw', maxWidth: '900px' }} {...props}>
      <Box m="auto" textAlign="center" px={3} pb={2}>
        <Text fontSize={['16px', '20px']} lineHeight="120%" m="auto" mb="20px" maxWidth={500}>
          😎
          <br />
          You can create beautiful table top cards and posters using our templates!
        </Text>
        <Flex
          pb={4}
          px={2}
          alignItems="center"
          sx={{ gap: '10px' }}
          width="100%"
          flexDirection={['column', 'row']}
        >
          <Image src={VGBExampleImage1} />
          <Image src={VGBExampleImage2} mt="-6.5px" />
        </Flex>
        <Text
          fontSize="16px"
          lineHeight="20.8px"
          textAlign="center"
          letterSpacing="-2.2%"
          fontStyle="italic"
          mx="auto"
          mb="20px"
          maxWidth={400}
        >
          *Printing not included
        </Text>
        <Button variant="special" onClick={onDone} minWidth={240}>
          Cool, got it
        </Button>
      </Box>
    </AnimatedDialog>
  );
}

VGBExampleModal.propTypes = {};
VGBExampleModal.defaultProps = {};

export default VGBExampleModal;
