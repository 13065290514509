import React from 'react';

function HomeIcon() {
  return (
    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7389 0.460648C11.3778 0.179784 10.8722 0.179784 10.5111 0.460648L0.386059 8.33565C0.142473 8.5251 0 8.81641 0 9.125V21.5C0 22.362 0.34241 23.1886 0.951903 23.7981C1.5614 24.4076 2.38805 24.75 3.25 24.75H7.75H14.5H19C19.862 24.75 20.6886 24.4076 21.2981 23.7981C21.9076 23.1886 22.25 22.362 22.25 21.5V9.125C22.25 8.81641 22.1075 8.5251 21.8639 8.33565L11.7389 0.460648ZM15.5 22.75H19C19.3315 22.75 19.6495 22.6183 19.8839 22.3839C20.1183 22.1495 20.25 21.8315 20.25 21.5V9.61408L11.125 2.51686L2 9.61408V21.5C2 21.8315 2.1317 22.1495 2.36612 22.3839C2.60054 22.6183 2.91848 22.75 3.25 22.75H6.75V12.5C6.75 11.9477 7.19772 11.5 7.75 11.5H14.5C15.0523 11.5 15.5 11.9477 15.5 12.5V22.75ZM8.75 22.75V13.5H13.5V22.75H8.75Z"
        fill="#606060"
        fillOpacity="1"
      />
    </svg>
  );
}

export default HomeIcon;
