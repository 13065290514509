import React from 'react';
import { Box, Button, Flex } from 'rebass';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import { Formik, Form } from 'formik';
import { FiCheckCircle } from 'react-icons/fi';
import { usePatchClip } from '@/hooks/api';
import { useClip } from '@/hooks/clip';
import TextField from '@/components/app/formik/TextField';
import { useUpdateClip } from '@/hooks/mutations';
import FormControlsWell from './FormControlsWell';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';
// eslint-disable-next-line import/no-cycle
import { EditClipActionButton } from './index';

function ClipOverview({ id, type, isFromMobile }) {
  const {
    query: { id: celebrationId },
  } = useRouter();
  const createConfirmation = useCreateConfirmation();
  const { width } = useWindowSize();
  const updateClip = useUpdateClip({ id, type });

  // const clip = useClip({ id, type });
  const clip = useClip({ id, type });
  const [patchClip] = usePatchClip({ id, type, celebrationId });
  return (
    <Box>
      <Formik
        initialValues={{
          name: clip.name || '',
          'soundtrack-volume':
            typeof clip['soundtrack-volume'] !== 'undefined'
              ? clip['soundtrack-volume']
              : (clip.video && 0.05) || 1,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.name !== clip.name) {
            await patchClip(values);
            createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
            updateClip(values);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormControlsWell mb={isFromMobile ? 1 : 3} p={2}>
              <Flex justifyContent="space-between" alignItems="center">
                <TextField
                  name="name"
                  label={
                    isFromMobile ? (
                      <div>
                        Contributor Name(s)
                        <br />
                        <span style={{ fontWeight: 500, fontSize: 14 }}>
                          {' '}
                          Will be used in credits at end of video
                        </span>
                      </div>
                    ) : (
                      'Contributor Name(s) — will be used in credits at end of video'
                    )
                  }
                  width={isFromMobile && '100%'}
                  pb={isFromMobile && 0}
                  tabIndex={-1}
                />
                {!isFromMobile && (
                  <Button
                    variant="special"
                    height="52px"
                    py="0px"
                    disabled={isSubmitting || clip.name === values.name}
                  >
                    Save
                  </Button>
                )}
              </Flex>
              {isFromMobile && (
                <EditClipActionButton
                  disabled={isSubmitting || clip.name === values.name}
                  width={width}
                  value="Save"
                />
              )}
              {/* <AutoSave /> */}
            </FormControlsWell>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ClipOverview;
