import React, { useState, useEffect } from 'react';
import { FiCopy, FiCheckCircle } from 'react-icons/fi';
import { useCreateConfirmation } from '@/components/app/ConfirmationOverlay';
import NavButton from './NavButton';
import EditClipDialogButton from '../EditClipDialogButton';

function DuplicateNavButton({ onDuplicate, isFromMobile, ...props }) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createConfirmation = useCreateConfirmation();

  useEffect(() => {
    if (showSuccess) {
      createConfirmation({ icon: FiCheckCircle, message: 'Clip Saved' });
    }
  }, [showSuccess]);

  return (
    <EditClipDialogButton
      title="Add duplicate clip to timeline?"
      description="This will add a second copy of this clip into your video."
      onConfirm={async () => {
        setIsSubmitting(true);
        await onDuplicate();
        setShowSuccess(true);
        setIsSubmitting(false);
      }}
      confirmLabel="Add duplicate clip"
      isFromMobile={isFromMobile}
    >
      {(onShow) => (
        <NavButton onClick={onShow} icon={FiCopy} {...props} disabled={isSubmitting}>
          Duplicate
        </NavButton>
      )}
    </EditClipDialogButton>
  );
}

export default DuplicateNavButton;
