import React from 'react';
import { Box, Heading, Flex, Text } from 'rebass';
import { useWindowSize } from 'react-use';
import { Formik } from 'formik';
import { Label } from '@rebass/forms';
import { FiCheckCircle } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { useEntity } from '@/lib/entities';
import Grid from '@/components/app/Grid';
import Clip from '@/components/Clip';
import AutoSave from '@/components/app/formik/AutoSave';
import { usePatchClip } from '@/hooks/api';
import { useCelebrationClips } from '@/hooks/celebration';
import { viewChangePixels } from '@/lib/constants';
import { useUpdateClip } from '@/hooks/mutations';
import TextField from '../app/formik/TextField';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';

function CreditEditor({ clipNumber, id, type, celebrationId }) {
  const clipDetail = useEntity({ id, type }) || {};

  const createConfirmation = useCreateConfirmation();
  const [patchClip] = usePatchClip({ id, type, celebrationId });
  const updateClip = useUpdateClip({ id, type });
  const { width } = useWindowSize();

  let clipWidth = (width / 100) * 30;
  clipWidth = clipWidth > 153 ? 153 : clipWidth;

  if (!clipDetail) return false;
  return (
    <Flex width="100%" mb={2} alignItems="center">
      <Formik
        initialValues={{ name: clipDetail ? clipDetail.name : '' }}
        // validationSchema={Yup.object().shape({ 'name': Yup.string().required() })}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.name !== clipDetail.name) {
            await patchClip(values);
            createConfirmation({ icon: FiCheckCircle, message: 'Clip Saved' });
            updateClip(values);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <>
            <Box mr="10px" sx={{ borderRadius: 'small', width: clipWidth }}>
              <Label mb={1}>{clipNumber}</Label>
              <Clip id={id} type={type} height={82} width={clipWidth} />
            </Box>
            <Box width={`calc(100% - ${clipWidth + 10}px)`}>
              <TextField
                disabled={isSubmitting}
                pt={0}
                pb={0}
                mt={5}
                name="name"
                placeholder={`Enter name(s) for ${clipNumber}`}
                tabIndex={width < viewChangePixels ? -1 : null}
              />
              {/* {isSubmitting && <Text className="loading"> Saving Changes</Text>} */}
              <AutoSave />
            </Box>
          </>
        )}
      </Formik>
    </Flex>
  );
}

function EditCredits({ isFromMobile }) {
  const {
    query: { id },
  } = useRouter();
  const { width } = useWindowSize();
  const clips = useCelebrationClips(id, { state: 'in_timeline' });

  function getColoumns() {
    if (width > 1800) {
      return '25% 25% 25% 25%';
    }
    if (width > 1100) {
      return '33% 33% 33%';
    }

    if (width > 700) {
      return '50% 50%';
    }

    return '100%';
  }

  return (
    <Box
      width="100%"
      sx={{ alignSelf: 'baseline', mt: isFromMobile && 2, mb: width < 500 && '60px' }}
    >
      <Box p={4} py={0}>
        <Heading variant="headings.h3" pb={1}>
          Edit Credits
        </Heading>
        <Text variant="headings.h6" pb={2}>
          These name(s) will be used in the credits at the end of the video
        </Text>
      </Box>
      {clips && clips.length > 0 ? (
        <Grid
          px={4}
          pb={4}
          gridTemplateColumns={getColoumns()}
          gridGap={1}
          sx={{ maxHeight: '70vh', overflowY: 'auto' }}
        >
          {clips.map((clip, index) => (
            <CreditEditor
              id={clip.id}
              type={clip.type}
              clipNumber={`Clip ${index + 1}`}
              celebrationId={id}
            />
          ))}
        </Grid>
      ) : (
        <Box p={4} mt={4} textAlign="center">
          <Text>Add video and pictures from your clipboard.</Text>
        </Box>
      )}
    </Box>
  );
}

export default EditCredits;
