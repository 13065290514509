import React from 'react';
import { Text, Flex, Box } from 'rebass';
import Tappable from 'react-tappable/lib/Tappable';
import { sendTrackingEvent } from '@/lib/helper';
import Rectangle from '../app/Svgs/Rectangle';
import Circle from '../app/Svgs/Circle';

function SideSwitch({ ml, switchSides, selectedSide }) {
  function onFrontPress() {
    if (selectedSide === 'back') {
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: 'Front',
          url: 'Front Side of Poster',
        },
      });
      switchSides('front');
    }
  }

  function onBackPress() {
    if (selectedSide === 'front') {
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: 'Back',
          url: 'Back Side of Poster',
        },
      });
      switchSides('back');
    }
  }
  return (
    <Flex alignItems="center" ml={ml}>
      <Tappable
        onClick={onFrontPress}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '4px',
          marginRight: '16px',
        }}
        onTap={onFrontPress}
      >
        <Flex
          sx={{
            height: '45px',
            width: '35px',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '2px',
            background:
              selectedSide === 'front'
                ? 'linear-gradient( 110deg,#ED2365 0%,#ED2365 0%,#C8368C 35%,#A846AF 65%,#6269F9 100%) 0% 0% no-repeat padding-box'
                : '',
          }}
        >
          <Rectangle
            fill={selectedSide === 'front' ? '#252627' : 'none'}
            stroke={selectedSide === 'front' ? 'none' : '#252627'}
          />
          <Box sx={{ position: 'absolute', mr: '-15px' }}>
            <Circle fill={selectedSide === 'front' ? '#fff' : '#252627'} />
          </Box>
        </Flex>
        <Text>Front</Text>
      </Tappable>
      <Tappable
        onClick={onBackPress}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '4px',
          marginRight: '20px',
        }}
        onTap={onBackPress}
      >
        <Flex
          sx={{
            height: '45px',
            width: '35px',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '2px',
            background:
              selectedSide === 'back'
                ? 'linear-gradient( 110deg,#ED2365 0%,#ED2365 0%,#C8368C 35%,#A846AF 65%,#6269F9 100%) 0% 0% no-repeat padding-box'
                : '',
          }}
        >
          <Rectangle
            fill={selectedSide === 'back' ? '#252627' : 'none'}
            stroke={selectedSide === 'back' ? 'none' : '#252627'}
          />
          <Box sx={{ position: 'absolute', mr: '15px' }}>
            <Circle fill={selectedSide === 'back' ? '#fff' : '#252627'} />
          </Box>
        </Flex>
        <Text>Back</Text>
      </Tappable>
    </Flex>
  );
}

export default SideSwitch;
