import React, { useRef } from 'react';
import { Flex, Text, Box } from 'rebass';
import useOnClickOutside from 'use-onclickoutside';
import { FiTrash2, FiPlusSquare } from 'react-icons/fi';
import Grid from '@/components/app/Grid';

function ImageEditingOptions({
  children,
  bg = 'rgba(0,0,0,.35)',
  sx = {},
  showEditor,
  setShowEditor,
  onEditingClick,
  onImageDeletion,
}) {
  const pickerRef = useRef(null);

  useOnClickOutside(pickerRef, () => {
    if (showEditor) {
      setShowEditor(false);
    }
  });

  return (
    <Grid ref={pickerRef}>
      <Box
        sx={{ gridArea: '1 / 1 / 2 / 2' }}
        onClick={() => {
          setShowEditor(true);
        }}
      >
        {children}
      </Box>
      {showEditor && (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          bg={bg}
          sx={{
            gridArea: '1 / 1 / 2 / 2',
            zIndex: 3,
            boxShadow: 'inset 0 0 50px rgba(0,0,0,.35)',
            ...sx,
          }}
        >
          <Flex
            sx={{
              boxShadow: '0px 6px 15px -11px rgba(0, 0, 0, 0.15)',
              borderRadius: '200px',
              background: '#FFF',
              py: 5,
              px: 7,
            }}
          >
            <Text
              size={250}
              color="black"
              as={FiPlusSquare}
              sx={{ cursor: 'pointer' }}
              onClick={onEditingClick}
            />
            {onImageDeletion && (
              <Text
                size={250}
                color="black"
                as={FiTrash2}
                ml={4}
                sx={{ cursor: 'pointer' }}
                onClick={onImageDeletion}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Grid>
  );
}

export default ImageEditingOptions;
