import React, { useCallback, useState } from 'react';
import { Flex, Button, Box, Text } from 'rebass';
import { Formik, Form } from 'formik';
import store from 'store';
import * as Yup from 'yup';
import { FiSettings } from 'react-icons/fi';
import { IoIosClose } from 'react-icons/io';
import { useRouter } from 'next/router';
import TextField from '@/components/app/formik/TextField';

function DevToolsForm({ onClose }) {
  return (
    <Box p={2} minWidth={360} maxWidth="calc(100vw - 16px)">
      <Flex
        justifyContent="space-between"
        pb={2}
        mb={4}
        sx={{ borderBottom: '1px solid', borderColor: 'border' }}
      >
        <Text>App Settings</Text>
        <Button type="button" onClick={onClose} variant="transparent">
          <Text size={24} as={IoIosClose} />
        </Button>
      </Flex>
      <Formik
        validationSchema={Yup.object().shape({
          token: Yup.string().required(),
        })}
        initialValues={{ token: store.get('token') }}
        onSubmit={({ token }) => store.set('token', token.replace(/"/g, ''))}
      >
        <Form>
          <TextField name="token" label="Add User Token" />
          <Flex justifyContent="flex-end">
            <Button type="submit">Update</Button>
          </Flex>
        </Form>
      </Formik>
    </Box>
  );
}

function DevToolsWidget() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleTools = useCallback(() => {
    setIsOpen((state) => !state);
  }, [setIsOpen]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg={isOpen ? 'white' : 'greys.5'}
      p={2}
      sx={{
        position: 'fixed',
        bottom: 12,
        left: 12,
        boxShadow: 'large',
        borderRadius: 'small',
        opacity: isOpen ? 1 : 0.7,
        transition: 'opacity 176ms ease',
        '&:hover': { opacity: 1 },
      }}
    >
      {!isOpen && (
        <Button type="button" onClick={toggleTools} variant="transparent">
          <Text color="border" size={24} as={FiSettings} />
        </Button>
      )}
      {isOpen && <DevToolsForm onClose={toggleTools} />}
    </Flex>
  );
}

function DevTools({ isEnabled }) {
  const { query } = useRouter();
  if (Object.keys(query || {}).includes('dt') && isEnabled) return <DevToolsWidget />;

  return null;
}

export default DevTools;
