import React, { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import store from 'store';
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js';
import Webcam from 'react-webcam';
import { Box, Flex, Heading, Text } from 'rebass';
import { v4 } from 'uuid';
import { useWindowSize } from 'react-use';
import { IoMdSettings } from 'react-icons/io';
import { FaArrowCircleDown, FaArrowCircleUp, FaSync } from 'react-icons/fa';
import Grid from '@/components/app/Grid';
import { ReactComponent as FireFoxEnableRecording } from '@/components/app/FireFoxEnableRecording.svg';
import { ReactComponent as EdgeAllowCameraAddressBar } from '@/components/app/Edge-instruction-1.svg';
import { ReactComponent as EdgeEnableRecording } from '@/components/app/EdgeEnableRecording.svg';
import { ReactComponent as FireFoxEnableVideoAndMicroPhone } from '@/components/app/FireFoxEnableVideoAndMicroPhone.svg';
import { ReactComponent as ProblemRecordingClickHere } from '@/components/app/ProblemRecordingClickHere.svg';
import { ReactComponent as Check } from '@/components/app/Check.svg';
import BufferingOverlay from '@/components/VideoPreview/BufferingOverlay';
import { useCheckIsIosUser } from '@/hooks/clip';
import { useLandScapeCheckHook } from '@/hooks/celebration';
import { useIsSafariUser } from '@/hooks/user';
import { LeftTilt } from '@/lib/constants';
import { checkIsIpadUser } from '@/lib/helper';
import Timer from './Timer';
import InternetSpeedTracker from '../InternetSpeedTracker/index';
import AnimatedDialog from '../app/AnimatedDialog';
import RecordButton from './RecordButton';
import ResponsiveReactPlayer from '../app/ResponsiveReactPlayer';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

let progressBar = 0;

function ModalParagraph({ children }) {
  return (
    <Text className="disabledVideoContentParagraph" my={16}>
      {children}
    </Text>
  );
}

function DisabledRecordingNotReadable({ noAudio, noVideo }) {
  if (noAudio)
    return (
      <Box className="disabledVideoContentContainer" m={32} mt={0}>
        <Heading variant="headings.h1">
          You don&apos;t have any audio source attached. Please plug in your Microphone to start
          recording.
        </Heading>
      </Box>
    );

  if (noVideo)
    return (
      <Box className="disabledVideoContentContainer" m={32} mt={0}>
        <Heading variant="headings.h1">
          You don&apos;t have any video source attached. Please attach camera to start recording.
        </Heading>
      </Box>
    );

  return (
    <Box className="disabledVideoContentContainer" m={32} mt={0}>
      <Heading variant="headings.h1">Webcam or Microphone is already in use by another app</Heading>
      <ModalParagraph>
        1. Close all the applications that are using Webcam or Microphone.
      </ModalParagraph>
      <ModalParagraph>2. Refresh the page and record your video </ModalParagraph>
    </Box>
  );
}

function DisabledForChrome() {
  return (
    <Box className="disabledVideoContentContainer" m={32} mt={0}>
      <Heading variant="headings.h1">Enable recording on Chrome</Heading>
      <Box width={['calc(100vw - 64px)', 640]} height={564} mt={3}>
        <ResponsiveReactPlayer
          url="https://player.vimeo.com/video/885842887?h=e51827b3d7"
          config={{
            file: {
              attributes: {
                controlsList: 'nofullscreen',
              },
            },
          }}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    </Box>
  );
}

function DisabledForFirefox() {
  return (
    <Box className="disabledVideoContentContainer" m={32} mt={0}>
      <Heading variant="headings.h1">Enable recording on Firefox</Heading>
      <ModalParagraph>1. Select the permission icons in the address bar</ModalParagraph>
      <FireFoxEnableRecording />
      <ModalParagraph>2. Click the x to allow the microphone and camera to be used </ModalParagraph>
      <FireFoxEnableVideoAndMicroPhone />
      <ModalParagraph>3. Refresh the page and record your video </ModalParagraph>
    </Box>
  );
}

function DisabledForEdge() {
  return (
    <Box className="disabledVideoContentContainer" m={32} mt={0}>
      <Heading variant="headings.h1">Enable recording on Edge</Heading>
      <ModalParagraph>1. Click on the lock icon in the address bar</ModalParagraph>
      <Flex justifyContent="center">
        <EdgeAllowCameraAddressBar />{' '}
      </Flex>
      <ModalParagraph>2. Change microphone and camera permission to allow </ModalParagraph>
      <ModalParagraph>3. Refresh the page and record your video </ModalParagraph>
      <Flex justifyContent="center">
        <EdgeEnableRecording />
      </Flex>
    </Box>
  );
}

function DisabledForSafari() {
  return (
    <Box className="disabledVideoContentContainer" m={32} mt={0}>
      <Heading variant="headings.h1">Enable recording on Safari</Heading>
      <Box width={['calc(100vw - 64px)', 640]} height={564} mt={3}>
        <ResponsiveReactPlayer
          url="https://player.vimeo.com/video/886025070?h=8c8c82a7f1"
          config={{
            file: {
              attributes: {
                controlsList: 'nofullscreen',
              },
            },
          }}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    </Box>
  );
}

function WebcamRecordVideo({
  onEnded,
  height,
  width,
  isFromMobile,
  template,
  subjectQuestion,
  onDismiss,
}) {
  const webcamRef = useRef(null);
  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  const { height: windowHeight } = useWindowSize();
  const idealConstraints = {
    width: 1920,
    height: 1080,
    frameRate: 24,
    facingMode: FACING_MODE_USER,
    aspectRatio: 1.777777778,
  };

  const [isLandscape, orientation, isBuffering] = useLandScapeCheckHook();

  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const [deviceId, setDeviceId] = useState(null);
  const [firstRendered, setFirstRenderd] = useState(null);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [audioDeviceId, setAudioDeviceId] = useState(null);
  const [audioDevices, setAudioDevices] = useState([]);
  const [showTimer, setShowTimer] = useState(false);
  const [dimensions, setDimensions] = useState({ width: '100%', height: '100%' });
  const [constraints, setConstraints] = useState(idealConstraints);
  const [audioConstraints, setAudioConstraints] = useState({});
  const [questionContainerHeight, setHeight] = useState(0);
  const [questionContainerWidth, setWidth] = useState(0);

  const [isCapturing, setIsCapturing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [notReadableError, setNotReadableError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [showScroll, setShowScroll] = useState(false);
  const mediaRecorderRef = useRef(null);
  const topicTopScrollerRef = useRef(null);
  const topicBottomScrollerRef = useRef(null);

  const iOSUser = useCheckIsIosUser();
  const isIpadUser = checkIsIpadUser();
  const isIOSUser = iOSUser || isIpadUser;
  const isSafariUser = useIsSafariUser();

  function SelectedMediaDeviceID(kind) {
    const tracks = window.localStream.getTracks();
    return tracks.filter((trac) => trac.kind === kind).map((trac) => trac.getSettings().deviceId);
  }

  const selectVideoDevice = async () => {
    const selectedVideoID = await SelectedMediaDeviceID('video');
    setDeviceId(selectedVideoID[0]);
    progressBar = 50;
  };

  const selectAudioDevice = async () => {
    const selectedAudioID = await SelectedMediaDeviceID('audio');
    setDeviceId(selectedAudioID[0]);
    setLoading(false);
    progressBar = 99;
  };

  const handleDevices = (mediaDevices) => {
    const devicesAttached = mediaDevices.filter(({ kind }) => kind === 'videoinput');
    if (
      devicesAttached.length !== devices.length ||
      devicesAttached.filter((dev) => !!dev.label).length !==
        devices.filter((dev) => !!dev.label).length
    ) {
      // const selectedVideoID = SelectedMediaDeviceID('video', true);
      if (devicesAttached.length > 0) {
        const frontCamera = devicesAttached.find((dev) =>
          dev.label.toLowerCase().includes('front')
        );
        if (isIOSUser && frontCamera) {
          setDeviceId(frontCamera.deviceId);
        } else if (store.get('selectedDeviceID')) setDeviceId(store.get('selectedDeviceID'));
        else {
          const filteredDevices = devicesAttached.filter(
            (device) => !device.label.toLowerCase().includes('iphone')
          );
          setDeviceId(filteredDevices[0]?.deviceId);
        }
      }
      setDevices(devicesAttached.sort((a, b) => a.label.localeCompare(b.label)));
    }
    const audioDevicesAttached = mediaDevices.filter(({ kind }) => kind === 'audioinput');
    if (
      audioDevicesAttached.length !== audioDevices.length ||
      audioDevicesAttached.filter((dev) => !!dev.label).length !==
        audioDevices.filter((dev) => !!dev.label).length
    ) {
      const selectedAudioID = SelectedMediaDeviceID('audio');
      if (audioDevicesAttached.length > 0) {
        if (store.get('selectedAudioDeviceID'))
          setAudioDeviceId(store.get('selectedAudioDeviceID'));
        else setAudioDeviceId(selectedAudioID[0]);
      }
      setAudioDevices(
        audioDevicesAttached
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((device) => {
            const clonedDevice = device.toJSON();
            const { label } = clonedDevice;
            const haveParenthesisText = label.match(/\(([^)]+)\)/);
            if (haveParenthesisText && haveParenthesisText.length > 1) {
              const updatedString = haveParenthesisText[1];
              clonedDevice.label = label.replace(`(${updatedString})`, '');
            }
            return clonedDevice;
          })
      );
    }
  };

  const handleFailedToLoad = (err) => {
    if (err.message.toLowerCase() === 'permission denied' || err.name === 'NotAllowedError') {
      setError(err.message || err.name);
      setOpenModal(true);
    }
    if (
      err.message.toLowerCase().includes('could not start audio source') ||
      err.name.toLowerCase().includes('notreadableerror')
    ) {
      setNotReadableError(err.message || err.name);
    }
    setLoading(false);
    store.set('sentryError', `${err} /Record Video Component`);
  };

  const containerType = useMemo(() => {
    return isIOSUser || isSafariUser ? 'mp4' : 'webm';
  }, [browserName, isIOSUser, isSafariUser]);

  const handleClick = useCallback(
    (devId) => {
      if (!firstRendered) setFirstRenderd(true);

      const updatedDeviceId = devices.find((dev) => dev.deviceId !== deviceId);
      if (isSafariUser && devices.length === 1)
        setFacingMode((prevState) =>
          prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
        );
      else {
        if (updatedDeviceId.label.toLowerCase().includes('front')) setFacingMode(FACING_MODE_USER);
        else setFacingMode(FACING_MODE_ENVIRONMENT);
        setDeviceId(devId);
      }
    },
    [firstRendered, isSafariUser, deviceId, devices]
  );

  const checkScrollTop = (e) => {
    const element = e.target;
    if (element.scrollTop > 50) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollTop = () => topicTopScrollerRef?.current?.scrollIntoView({ behavior: 'smooth' });

  const scrollBottom = () =>
    topicBottomScrollerRef?.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (isIOSUser && !isLandscape)
      setDimensions({ width: '100%', height: window?.innerHeight || windowHeight });
    else setDimensions({ width: '100%', height: '100%' });
  }, [isLandscape, isIOSUser]);

  useEffect(() => {
    if (deviceId) setConstraints({ ...constraints, deviceId });
  }, [deviceId]);

  useEffect(() => {
    if (audioDeviceId) setAudioConstraints({ deviceId: audioDeviceId });
  }, [audioDeviceId]);

  const closeStream = () => {
    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      window.localStream.getAudioTracks().forEach((track) => {
        track.stop();
      });
      window.localStream.getVideoTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const getAllMediaItems = () => {
    closeStream();
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        window.localStream = stream;
        if (!navigator.mediaDevices?.enumerateDevices) {
          selectVideoDevice();
          selectAudioDevice();
        } else {
          navigator.mediaDevices.enumerateDevices().then(handleDevices).catch(handleFailedToLoad);
        }
      })
      .catch((err) => {
        handleFailedToLoad(err);
      });
  };

  useEffect(() => {
    progressBar += 10;
    if (audioDevices.length > 0) progressBar = 99;
  }, [devices.length, audioDevices.length, deviceId, audioDeviceId]);
  progressBar += 1;

  if (devices.length > 0 && audioDevices.length > 0 && deviceId && audioDeviceId && loading) {
    setLoading(false);
  }
  const handleStartCaptureClick = useCallback(() => {
    try {
      setIsCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: `video/${containerType}`,
      });
      mediaRecorderRef.current.addEventListener('dataavailable', ({ data }) => {
        if (data.size > 0) {
          setRecordedChunks((prev) => [...prev, data]);
        }
      });
      mediaRecorderRef.current.start();
    } catch (err) {
      store.set(
        'sentryError',
        `${err} /Record Video Component in handleStartCaptureClick function`
      );
    }
  }, [containerType, webcamRef, setIsCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = useCallback(async () => {
    try {
      closeStream();
      mediaRecorderRef.current.stop();
    } catch (err) {
      store.set('sentryError', `${err} /Record Video Component in handleStopCaptureClick function`);
    }
    setIsCapturing(false);
  }, [facingMode, deviceId, mediaRecorderRef, setIsCapturing]);

  useEffect(() => {
    if (!isCapturing && recordedChunks.length) {
      const blob = new Blob(recordedChunks, { type: `video/${containerType}` });
      blob.name = `${v4()}.${containerType}`;
      onEnded(blob);
    }
  }, [recordedChunks, isCapturing, onEnded]);

  useEffect(() => {
    if (isIOSUser) {
      progressBar = 100;
      setLoading(false);
    } else getAllMediaItems();

    return async () => {
      progressBar = 0;
      closeStream();
    };
  }, [isIOSUser]);

  function findSome(top) {
    if (top === 'I prefer not to provide a suggested question.') return true;
    if (top === "I don't want to select an idea") return true;

    return false;
  }

  const isTopicSelectable =
    typeof subjectQuestion === 'string' ? true : (subjectQuestion || []).some(findSome);

  let marginBottom = 3;
  if (isIOSUser)
    if (isLandscape) marginBottom = '70px';
    else if (!isTopicSelectable) marginBottom = '100px';
    else marginBottom = '72px';
  else if (typeof subjectQuestion === 'object') marginBottom = `${questionContainerHeight + 10}px`;

  const isIOSLandscape = isIOSUser && isLandscape;

  const handleUserMedia = () => setIsDisabled(false);

  let objectFit = 'contain';
  if (isIpadUser) objectFit = 'revert';
  else if (isIOSUser && !firstRendered) objectFit = 'cover';

  if (constraints) {
    return (
      <Box
        sx={{
          position: 'relative',
          width: dimensions.width,
          alignSelf: isFromMobile && 'baseline',
        }}
        onClick={() => openSettingsModal && setOpenSettingsModal(false)}
      >
        <InternetSpeedTracker onDismiss={onDismiss} />
        <AnimatedDialog
          contentProps={{ width: '100%', maxWidth: '800px' }}
          isOpen={openModal}
          onDismiss={() => setOpenModal(false)}
        >
          {browserName === 'Chrome' && <DisabledForChrome />}
          {browserName?.includes('Safari') && <DisabledForSafari />}
          {browserName === 'Firefox' && <DisabledForFirefox />}
          {browserName === 'Edge' && <DisabledForEdge />}
        </AnimatedDialog>
        <AnimatedDialog
          contentProps={{ width: '100%', maxWidth: '800px' }}
          isOpen={notReadableError}
          onDismiss={() => setNotReadableError(null)}
        >
          <DisabledRecordingNotReadable
            noAudio={audioDevices.length === 0}
            noVideo={devices.length === 0}
          />
        </AnimatedDialog>
        <BufferingOverlay
          isBuffering={isBuffering}
          mainProgress={loading ? progressBar : 0}
          bg={isBuffering ? 'rgb(0, 0, 0)' : 'rgba(0, 0, 0, 0.5)'}
        >
          <>
            <Box mt={(template === 'question_and_answer' || !template) && -40} textAlign="center">
              {typeof subjectQuestion === 'string' && subjectQuestion && !loading && (
                <Text
                  display="inline-block"
                  color="purples.7"
                  p="25px"
                  fontWeight={500}
                  fontSize={24}
                  height={80}
                  mr="35px"
                  my={1}
                  className="move-in-from-right"
                >
                  {subjectQuestion}
                </Text>
              )}
              {showTimer && (
                <Timer
                  hideTimer={() => {
                    setShowTimer(false);
                    handleStartCaptureClick();
                  }}
                  isIOSLandscape={isIOSLandscape}
                />
              )}
              <Grid
                width={isIOSLandscape ? dimensions.width : width}
                maxWidth="100vw"
                maxHeight="100vh"
                height={isIOSUser ? dimensions.width : height}
              >
                {!loading && (
                  <Webcam
                    key={deviceId}
                    muted
                    audio
                    ref={webcamRef}
                    height={dimensions?.height}
                    width={dimensions?.width}
                    mirrored={facingMode === FACING_MODE_USER}
                    style={{
                      background: 'black',
                      objectFit,
                      gridArea: '1 / 1 / 2 / 2',
                    }}
                    videoConstraints={{
                      ...constraints,
                      deviceId,
                      facingMode,
                    }}
                    audioConstraints={audioConstraints}
                    onUserMedia={handleUserMedia}
                    onUserMediaError={handleFailedToLoad}
                  />
                )}
              </Grid>
              {!isTopicSelectable && (
                <Box
                  maxHeight={170}
                  overflowY="auto"
                  overflowX="hidden"
                  onScroll={checkScrollTop}
                  ref={(el) => {
                    if (!el) return;
                    if (el.getBoundingClientRect().height !== questionContainerHeight)
                      setHeight(el.getBoundingClientRect().height);
                    if (el.getBoundingClientRect().width !== questionContainerWidth)
                      setWidth(el.getBoundingClientRect().width);
                  }}
                >
                  {template === 'question_and_answer' &&
                  typeof subjectQuestion === 'object' &&
                  !loading &&
                  !error ? (
                    <ul>
                      <Box ref={topicTopScrollerRef} />
                      {subjectQuestion.map((top, index) => (
                        <li>
                          <Text
                            key={
                              typeof top === 'string'
                                ? top
                                : top?.['custom-topic'] || top?.description
                            }
                            display="inline-block"
                            color="purples.7"
                            p="10px"
                            fontWeight={500}
                            fontSize={24}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: questionContainerWidth,
                              width: '100%',
                              textAlign: 'left',
                              overflowY: 'hidden',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                            }}
                            className="move-in-from-right"
                            title={
                              typeof top === 'string'
                                ? top
                                : top?.['custom-topic'] || top?.description
                            }
                          >
                            {top.includes(')') ? '' : `${index + 1}) `}
                            {typeof top === 'string'
                              ? top
                              : top?.['custom-topic'] || top?.description}
                          </Text>
                        </li>
                      ))}
                      <Box ref={topicBottomScrollerRef} />
                      {subjectQuestion.length > 3 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            right: '50px',
                            bottom: '10px',
                          }}
                        >
                          {showScroll ? (
                            <FaArrowCircleUp
                              className="scrollTop"
                              onClick={scrollTop}
                              size={30}
                              style={{ display: 'flex' }}
                            />
                          ) : (
                            <FaArrowCircleDown
                              className="scrollTop"
                              onClick={scrollBottom}
                              size={30}
                              style={{ display: 'flex' }}
                            />
                          )}
                        </Box>
                      )}
                    </ul>
                  ) : null}
                </Box>
              )}
            </Box>
            {!error && (
              <Flex
                justifyContent="flex-start"
                alignItems="flex-end"
                sx={
                  isLandscape && orientation !== 0
                    ? {
                        position: 'fixed',
                        right: orientation === LeftTilt ? '70px' : 'auto',
                        left: orientation === LeftTilt ? 'auto' : '70px',
                        bottom: '-30px',
                        top: 'auto',
                      }
                    : {
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: 'max-content',
                        mr: 'auto',
                        ml: 3,
                        cursor: 'pointer',
                      }
                }
                mb={error ? 7 : marginBottom}
              >
                {isIOSUser
                  ? !isCapturing && (
                      <Box
                        onClick={() => {
                          if (!firstRendered) setFirstRenderd(true);

                          setFacingMode((prevState) =>
                            prevState === FACING_MODE_USER
                              ? FACING_MODE_ENVIRONMENT
                              : FACING_MODE_USER
                          );
                        }}
                      >
                        <FaSync color="white" size={24} />
                      </Box>
                    )
                  : (devices.length > 1 || audioDevices.length > 1) && (
                      <>
                        <Flex
                          alignItems="center"
                          sx={{
                            background: 'rgba(0, 0, 0, 0.8)',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            opacity: loading && 50,
                          }}
                          p={1}
                          px={2}
                          onClick={() => !loading && setOpenSettingsModal(!openSettingsModal)}
                        >
                          <IoMdSettings color="#FFFFFF" size={32} />
                          <Text color="#FFFFFF" ml={1}>
                            Settings
                          </Text>
                        </Flex>
                        <Box
                          sx={{
                            width: '300px',
                            position: 'absolute',
                            background: 'rgba(0, 0, 0, 0.8)',
                            borderRadius: '6px',
                            display: openSettingsModal ? 'block' : 'none',
                            p: 2,
                            bottom: 5,
                            zIndex: 1,
                          }}
                        >
                          {devices.length > 1 && (
                            <>
                              <Text lineHeight="120%" fontWeight={600} color="white">
                                Camera
                              </Text>
                              {devices.map((device, key) => (
                                <Flex
                                  my={1}
                                  key={device.deviceId}
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeviceId(device.deviceId);
                                    handleClick(device.deviceId);
                                    store.set('selectedDeviceID', device.deviceId);
                                    setOpenSettingsModal(false);
                                  }}
                                >
                                  <Box> {device.deviceId === deviceId && <Check />}</Box>
                                  <Text
                                    lineHeight="19.2px"
                                    fontWeight={400}
                                    fontStyle="normal"
                                    color="#FFFFFF"
                                    key={device.deviceId}
                                    ml={1}
                                    textAlign="right"
                                  >
                                    {device.label || `Device ${key + 1}`}
                                  </Text>
                                </Flex>
                              ))}
                              <Box sx={{ border: '1px solid rgba(255, 255, 255, 0.1)' }} my={1} />
                            </>
                          )}
                          {audioDevices.length > 1 && (
                            <>
                              <Text lineHeight="120%" fontWeight={600} color="white">
                                Microphone
                              </Text>
                              {audioDevices.map((device, key) => (
                                <Flex
                                  my={1}
                                  key={device.deviceId}
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setAudioDeviceId(device.deviceId);
                                    store.set('selectedAudioDeviceID', device.deviceId);
                                    setOpenSettingsModal(false);
                                  }}
                                >
                                  <Box> {device.deviceId === audioDeviceId && <Check />}</Box>
                                  <Text
                                    lineHeight="19.2px"
                                    fontWeight={400}
                                    fontStyle="normal"
                                    color="#FFFFFF"
                                    key={device.deviceId}
                                    ml={1}
                                    textAlign="right"
                                  >
                                    {device.label || `Device ${key + 1}`}
                                  </Text>
                                </Flex>
                              ))}
                            </>
                          )}
                        </Box>
                      </>
                    )}
              </Flex>
            )}

            <Flex
              justifyContent="center"
              alignItems="flex-end"
              sx={
                isLandscape && orientation !== 0
                  ? {
                      position: 'fixed',
                      alignItems: 'flex-start',
                      right: orientation === LeftTilt ? '60px' : 'auto',
                      left: orientation === LeftTilt ? 'auto' : '60px',
                      bottom: 'auto',
                      top: '40%',
                    }
                  : {
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      width: 'max-content',
                      m: 'auto',
                      opacity: showTimer ? 0 : loading && 50,
                    }
              }
              mb={error ? 7 : marginBottom}
            >
              <RecordButton
                isRecording={isCapturing}
                isDisabled={isDisabled}
                isIOSUser={isIOSUser}
                onClick={() => {
                  if (!loading) {
                    if (isCapturing) {
                      handleStopCaptureClick();
                    }
                    if (!isCapturing) {
                      setShowTimer(true);
                    }
                  }
                }}
              />
            </Flex>
          </>
        </BufferingOverlay>
        {error && (
          <Flex
            justifyContent="center"
            padding="16px"
            zIndex={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <ProblemRecordingClickHere />
          </Flex>
        )}
      </Box>
    );
  }

  return false;
}

WebcamRecordVideo.propTypes = {
  onEnded: PropTypes.func.isRequired,
  height: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string, PropTypes.number)),
  width: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string, PropTypes.number)),
    PropTypes.string,
  ]),
};
WebcamRecordVideo.defaultProps = { height: 360, width: 640 };

export default WebcamRecordVideo;
