import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from 'rebass';
import useImage from '@/hooks/image';

function RenderImage({
  photo,
  enabled,
  setIsPreviewOpenForChildren,
  handleClick = () => {},
  ...props
}) {
  const src = enabled ? useImage({ id: photo?.id, size: 2 }) : null;
  if (!enabled || !src) return null;
  const isAboveImageLayer = Object.keys(props?.['event-destination-layer']).length > 0;
  return (
    <Box
      sx={{
        cursor: isAboveImageLayer ? 'grab' : 'default',
      }}
      onClick={(e) =>
        isAboveImageLayer
          ? setIsPreviewOpenForChildren(props?.['event-destination-layer']?.id)
          : handleClick(e)
      }
    >
      <Image
        src={src}
        sx={{
          height: '100%',
          width: '100%',
        }}
        // loading="lazy"
      />
    </Box>
  );
}

RenderImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  photo: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  setIsPreviewOpenForChildren: PropTypes.func.isRequired,
};

export default memo(RenderImage);
