import React from 'react';
import { useRouter } from 'next/router';
import { Flex, Text, Box } from 'rebass';
import { FiLoader } from 'react-icons/fi';
// eslint-disable-next-line import/no-extraneous-dependencies
import { keyframes } from '@emotion/react';
import Grid from '@/components/app/Grid';
import {
  useCelebrationIdFromRoute,
  useIsLHSCelebrationTheme,
  useIsSombreCelebrationTheme,
} from '@/hooks/celebration';
import Progress from '../app/Progress';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

function BufferingOverlay({
  children,
  isBuffering = false,
  bg = 'rgba(0,0,0,.35)',
  mainProgress,
  showLoadingText = false,
  isFromPoster,
  sx = {},
}) {
  const {
    query: { id: celebId },
  } = useRouter();
  const id = useCelebrationIdFromRoute(celebId);

  const isSomberTheme = useIsSombreCelebrationTheme(id);
  const isFoundationTheme = useIsLHSCelebrationTheme(id);
  return (
    <Grid>
      <Box sx={{ gridArea: '1 / 1 / 2 / 2' }}>{children}</Box>
      {isBuffering && (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          bg={bg}
          sx={{
            gridArea: '1 / 1 / 2 / 2',
            zIndex: 3,
            boxShadow: 'inset 0 0 50px rgba(0,0,0,.35)',
            ...sx,
          }}
        >
          <Text
            as={FiLoader}
            color="greys.1"
            size={isFromPoster ? 720 : 72}
            sx={{
              animation: `${rotate} 4s linear infinite`,
              boxShadow: '1p 1px 8px rgba(0,0,0,.75)',
            }}
          />
          {showLoadingText && (
            <Text color="greys.1" className="loading">
              Our hamsters are hard at <br />
              work crafting your PDF,
              <br /> layer by layer 🐹
              <br />
              <br />
              This can take up to 3 minutes.
            </Text>
          )}
        </Flex>
      )}

      {mainProgress > 0 && mainProgress <= 100 && (
        <Box
          alignItems="center"
          justifyContent="center"
          bg={bg}
          sx={{ gridArea: '1 / 1 / 2 / 2', zIndex: 3, boxShadow: 'inset 0 0 50px rgba(0,0,0,.35)' }}
          p={2}
          mt={-80}
          width="100%"
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              padding: '32px',
              marginTop: '30%',
              bottom: '80px',
            }}
          >
            <Text
              textAlign="left"
              color="white"
              className={(isFoundationTheme || isSomberTheme) && 'loading'}
              pb={1}
              lineHeight="120%"
            >
              {isFoundationTheme || isSomberTheme ? 'Loading' : `Get ready to smile 😄`}
            </Text>
            <Progress percentComplete={mainProgress} mb={4} />
          </Box>
        </Box>
      )}
    </Grid>
  );
}

export default BufferingOverlay;
