import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Flex } from 'rebass';
import * as Yup from 'yup';
import { useUpdateClip } from '@/hooks/mutations';
import { useClip } from '@/hooks/clip';
import TextField from '../app/formik/TextField';
import FormControlsWell from './FormControlsWell';

function ImageTrimmer({ id, children }) {
  const clip = useClip({ id, type: 'celebration-image-clips' });
  const updateClip = useUpdateClip({ id: clip.id, type: 'celebration-image-clips' });

  return (
    <Formik
      initialValues={{ 'image-duration': clip['image-duration'] || 5 }}
      validationSchema={Yup.object().shape({ 'image-duration': Yup.number().required() })}
      onSubmit={updateClip}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormControlsWell mb={3} p={2}>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <TextField
                pb={0}
                name="image-duration"
                label="Image Duration"
                type="number"
                maxWidth={200}
              />
              <Button variant="special" height="52px" py="0px" disabled={isSubmitting}>
                Save
              </Button>
            </Flex>
          </FormControlsWell>
          {children}
        </Form>
      )}
    </Formik>
  );
}

export default ImageTrimmer;
