import React from 'react';

function Rectangle(props) {
  const { stroke, fill } = props;
  return (
    <svg width="30" height="39" viewBox="0 0 30 39" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="29" height="38" rx="1.5" stroke={stroke} />
    </svg>
  );
}

export default Rectangle;
