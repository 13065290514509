/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import store from 'store';
import eventsPlugin from 'store/plugins/events';
import PropTypes from 'prop-types';
import { Text, Box, Flex, Heading, Image } from 'rebass';
import { TimelineClips } from '@/components/VideoEditor/ClipsList';
import Grid from '@/components/app/Grid';
import { jsonAPIEntityParamsProps } from '@/lib/entities';
import useTimerTo from '@/hooks/timer';
import { getAudioDuration } from '@/hooks/celebration';
import confettiGroup from '@/public/Images/NewDesignImages/Confetti-Group.png';
import PlayIcon from '@/public/Images/NewDesignImages/PlayIcon.png';
import ClipPreview from './ClipPreview';
import PreviewControls from './PreviewControls';
import PreviewSoundTracks from './PreviewSoundTracks';
import { VideoPreviewContextProvider } from './VideoPreviewContext';
import BufferingOverlay from './BufferingOverlay';

const { Component } = React;

class WaterMark extends Component {
  constructor(props) {
    super(props);
    this.imgUrl = this.getImgUrl();
    this.id = 'watermark';
  }

  componentDidMount() {
    this.parent = this.watermark.parentNode;
    this.initAttribute(this.watermark);
    this.imgUrl = this.getImgUrl();
    this.parentObserver = this.observeParent();
    this.selfObserver = this.observeSelf(this.watermark);
  }

  componentWillUnmount() {
    this.parentObserver.disconnect();
    this.selfObserver.disconnect();
  }

  /* eslint-disable class-methods-use-this */
  getImgUrl = () => {
    const content = 'Draft!';

    const WIDTH = 150;
    const HEIGHT = 150;
    const RATIO = 0.7;

    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', WIDTH);
    canvas.setAttribute('height', HEIGHT);

    const ctx = canvas.getContext('2d');
    // style
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = '25px monospace';
    ctx.fillStyle = 'rgba(120, 120, 120, 0.4)';
    ctx.strokeStyle = 'rgba(120, 120, 120, 0.4)';
    ctx.lineWidth = 5;

    // rotate
    ctx.translate(WIDTH / 2, HEIGHT / 2);
    ctx.rotate(-(Math.PI / 180) * 30);
    ctx.translate(-WIDTH / 2, -HEIGHT / 2);

    // * Stamp
    ctx.beginPath();
    // ctx.arc(WIDTH / 2, HEIGHT / 2, (WIDTH * RATIO) / 2, 0, Math.PI * 2, true)
    ctx.moveTo((WIDTH * (1 - RATIO)) / 2, HEIGHT / 3);
    ctx.lineTo((WIDTH * (1 + RATIO)) / 2, HEIGHT / 2);
    // ctx.stroke()
    ctx.fillText('Draft!', WIDTH / 2, HEIGHT / 6);
    ctx.fillText(content, WIDTH / 2, (HEIGHT * 2) / 3);

    //* raw
    // ctx.fillText(content, WIDTH / 2, HEIGHT / 2)
    return canvas.toDataURL();
  };

  observeParent = () => {
    const options = {
      childList: true,
    };
    const callback = (mutationsList) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutationsList) {
        const removed = mutation.removedNodes[0];
        if (removed && removed.dataset.watermarkid === this.id) {
          this.selfObserver.disconnect();
          const { target } = mutation;
          this.insertClone(target);
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(this.parent, options);

    return observer;
  };

  observeSelf = (node) => {
    const options = {
      attributes: true,
      attributeOldValue: true,
    };
    const callback = (mutationsList, observer) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutationsList) {
        observer.disconnect();

        // let { oldValue, attributeName } = mutation
        // mutation.target.setAttribute(attributeName, oldValue)
        this.initAttribute(mutation.target);
        observer.observe(node, options);
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(node, options);

    return observer;
  };

  /* eslint-disable no-param-reassign, react/destructuring-assignment */
  initAttribute = (target) => {
    target.dataset.watermarkid = this.id;
    target.setAttribute(
      'style',
      `
        position: relative!important;
        transform: none!important;
        width: ${this.props.width}px!important;
        height: ${this.props.height}px!important;
        scale: 1!important;
        display: block!important;
        visibility: visible!important;
        opacity: 1!important;
        z-index: 2!important;
        pointer-events: none;
        background-repeat: repeat!important;
        background-image: url(${this.imgUrl})!important;
        background-size: auto!important;
      `
    );
  };

  insertClone = (target) => {
    const clonedWaterMark = this.watermark.cloneNode(true);
    this.selfObserver = this.observeSelf(clonedWaterMark);
    target.appendChild(clonedWaterMark);
  };

  render() {
    return (
      /* eslint-disable no-return-assign */
      <div id={this.id} data-watermarkid={this.id} ref={(element) => (this.watermark = element)} />
    );
  }
}

function RenderEmptyState() {
  return (
    <Flex
      width="100%"
      color="white"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      px={2}
      sx={{
        backgroundImage: `url("${confettiGroup}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Image src={PlayIcon} />
      <Heading mt={3} mb={2} color="purples.7" className="dm-sans">
        Preview pane
      </Heading>
      <Text className="dm-sans" color="purples.7" mb={3}>
        Here’s where your video preview will play after you’ve added clips to the timeline below.
      </Text>
    </Flex>
  );
}

function VideoPreview({
  hasFadeTransition,
  hasTimeline,
  clips,
  soundTracks,
  showTextClip,
  height,
  width,
  isForEdit,
  clipDuration,
  isForStickerEdit,
  isMobilePlay,
  showDownload,
  individualClip,
  loading,
  timelineClips,
  clipsLoading,
}) {
  const [shouldPlay, setShouldPlay] = useState(isMobilePlay);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isSlideChanged, setIsSlideChanged] = useState(false);

  const allClipsEndAt = useMemo(() => {
    if (!clips.length) return 0;
    const [lastClip] = clips.slice(-1);
    return lastClip.endAt;
  }, [clips]);

  const [{ seconds, endSeconds, isRunning }, dispatch] = useTimerTo(allClipsEndAt);

  const handleOnPlay = useCallback(() => {
    dispatch({ type: 'start' });
  }, [dispatch]);

  const handleOnPause = useCallback(() => {
    dispatch({ type: 'stop' });
  }, [dispatch]);

  const handlePlayClick = useCallback(() => {
    if (isForEdit) {
      store.set('PreviewClipPlaying', true);
    } else {
      store.set('ClipPlaying', true);
    }
    setShouldPlay(true);
  }, [setShouldPlay]);

  const handlePauseClick = useCallback(() => {
    setShouldPlay(false);
    if (isForEdit) {
      store.remove('PreviewClipPlaying');
    } else {
      store.remove('ClipPlaying');
    }
  }, [setShouldPlay]);

  const handleSliderChange = useCallback(
    (value) => {
      // if (!isRunning) {
      store.set('sliderChanged', true);
      if (isForEdit) {
        store.remove('PreviewClipPlaying');
      } else {
        store.remove('ClipPlaying');
      }
      dispatch({ type: 'stop' });
      dispatch({ type: 'setSeconds', seconds: value });
      store.set('currentSeconds', value);
      setShouldPlay(false);
      // }
    },
    [dispatch, isRunning]
  );

  const handleProgress = useCallback(
    (value) => {
      if (value === 0 || value) dispatch({ type: 'setSeconds', seconds: value });
    },
    [dispatch]
  );

  useEffect(() => {
    store.addPlugin(eventsPlugin);
    store.watch('sliderChanged', function (e) {
      setIsSlideChanged(!!e);
    });
  }, []);

  // access to the currently playing ref
  useEffect(() => {
    if (parseInt(seconds, 10) >= parseInt(endSeconds, 10)) {
      setTimeout(() => {
        setShouldPlay(false);
        if (isForEdit) {
          store.remove('PreviewClipPlaying');
        } else {
          store.remove('ClipPlaying');
        }
        dispatch({ type: 'setSeconds', seconds: 0 });
      }, 500);
    }
  }, [seconds, dispatch, endSeconds, setShouldPlay]);

  const [clipSoundtrackVolume, setClipSoundtrackVolume] = useState(0.05);

  const renderClips = useMemo(() => {
    if (clips && clips.length > 0)
      return clips.map((clip, index) => {
        const { volume, id, type, startAt, endAt } = clip;
        if (seconds >= startAt && seconds <= endAt) {
          return (
            <ClipPreview
              key={`preview-clip-${id}`}
              index={index}
              volume={volume <= 1 && typeof volume !== 'undefined' ? volume : 1}
              originalVolume={volume}
              currentTime={seconds}
              shouldPlay={shouldPlay}
              onProgress={handleProgress}
              onPlay={handleOnPlay}
              onPause={handleOnPause}
              clipDetail={individualClip ? { ...clip } : null}
              onEnded={() => {}}
              id={id}
              type={type}
              startAt={startAt}
              endAt={endAt}
              showTextClip={showTextClip}
              height={height}
              width={width}
              setClipSoundtrackVolume={setClipSoundtrackVolume}
              dispatch={dispatch}
              isBuffering={isBuffering}
              setBuffering={(val) => setIsBuffering(val)}
              isForEdit={isForEdit}
              handlePauseClick={handlePauseClick}
              handlePlayClick={handlePlayClick}
              isForStickerEdit={isForStickerEdit}
            />
          );
        }
        return null;
      });
    return <RenderEmptyState />;
  }, [
    clips,
    dispatch,
    handleOnPause,
    handleOnPlay,
    handleProgress,
    height,
    seconds,
    shouldPlay,
    showTextClip,
    width,
  ]);

  function getTracks() {
    return soundTracks
      .map((track) => ({ ...track, duration: getAudioDuration(track?.id, track?.type) }))
      .reduce((acc, el) => {
        if (acc.length === 0) {
          const endAt = el.duration;
          return [{ startAt: 0, endAt, ...el }];
        }

        const [{ endAt: lastEndAt }] = acc.slice(-1);
        const endAt = lastEndAt + el.duration;
        return [...acc, { startAt: lastEndAt, endAt, ...el }];
      }, []);
  }

  return (
    <>
      <VideoPreviewContextProvider
        hasFadeTransition={hasFadeTransition}
        currentSeconds={seconds}
        clips={clips}
        // isRunning={isRunning}
      >
        {/* {isMobilePlay &&  */}
        {clips && clips.length > 0 && !individualClip && (
          <Box mb={`-${height}px`}>
            <WaterMark height={height} width="100%" />
          </Box>
        )}
        {/* } */}
        <Box backgroundColor={individualClip && '#272929'} pb={isMobilePlay && 2}>
          <BufferingOverlay isBuffering={isBuffering || loading}>
            <Grid height={height} width={width} mx="auto">
              {renderClips}
            </Grid>
          </BufferingOverlay>
          <PreviewControls
            isPlaying={shouldPlay}
            onPlayClick={handlePlayClick}
            onPauseClick={handlePauseClick}
            currentSeconds={seconds}
            endSeconds={endSeconds}
            disabled={!clips || clips.length === 0 || isSlideChanged || loading}
            onSliderChange={handleSliderChange}
            isBuffering={isBuffering}
            showDownload={showDownload}
            clip={showDownload && clips.length > 0 ? clips[0] : null}
            individualClip={individualClip}
          />
          <PreviewSoundTracks
            clipSoundtrackVolume={clipSoundtrackVolume}
            tracks={getTracks()}
            isPlaying={shouldPlay}
            currentTime={seconds}
            isBuffering={isBuffering}
            isForEdit={isForEdit}
            clipDuration={clipDuration}
          />
        </Box>
      </VideoPreviewContextProvider>
      {hasTimeline && (
        <TimelineClips
          onClickClip={() => {
            setShouldPlay(false);
          }}
          timelineClips={timelineClips}
          clipsLoading={clipsLoading}
        />
      )}
    </>
  );
}
VideoPreview.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  hasTimeline: PropTypes.bool,
  clips: PropTypes.arrayOf(
    PropTypes.shape({
      startAt: PropTypes.number.isRequired,
      endAt: PropTypes.number.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      trimStartAt: PropTypes.number,
      trimEndAt: PropTypes.number,
    }).isRequired
  ),
  soundTracks: PropTypes.arrayOf(jsonAPIEntityParamsProps),
  showTextClip: PropTypes.bool,
  hasFadeTransition: PropTypes.bool,
};

VideoPreview.defaultProps = {
  showTextClip: true,
  height: 270,
  width: 480,
  soundTracks: [],
  clips: [],
  hasTimeline: false,
  hasFadeTransition: true,
};

export default VideoPreview;
