import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Text, Box } from 'rebass';
import { IoIosPause, IoIosPlay } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa';
import Grid from './Grid';
import { CircleButton } from '../VideoPreview/PreviewControls';
import { useFormattedSeconds } from '../FormattedTime';
import PreviewSlider from '../VideoPreview/PreviewSlider';

function FormattedSeconds({ seconds }) {
  const formatted = useFormattedSeconds(seconds);
  return (
    <Text fontSize={0} color="white">
      {formatted}
    </Text>
  );
}

function AudioPlayer({ src, id, addSong, uploading, isPlaying, setIsPlaying }) {
  const rafRef = useRef();
  const audioRef = useRef();
  const [canPlay, setCanPlay] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (isPlaying !== id) audioRef.current.pause();
  }, [isPlaying]);

  const handlePlayPause = useCallback(() => {
    if (isPlaying !== id) {
      audioRef.current.play();
      setIsPlaying(id);
    } else {
      audioRef.current.pause();
      setIsPlaying(null);
    }
  }, [isPlaying, setIsPlaying]);

  const handleScrub = useCallback(
    (position) => {
      audioRef.current.currentTime = position;
      setCurrentTime(position);
    },
    [setCurrentTime]
  );

  const handleEnded = useCallback(() => {
    audioRef.current.currentTime = 0;
    setCurrentTime(0);
    setIsPlaying(null);
  }, [setCurrentTime]);

  const updateTimer = () => {
    setCurrentTime(audioRef.current.currentTime);
    rafRef.current = requestAnimationFrame(updateTimer);
  };

  useEffect(() => {
    if (isPlaying) {
      rafRef.current = requestAnimationFrame(updateTimer);
    }
    if (!isPlaying || isPlaying !== id) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
    }
    return () => {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
    };
  }, [isPlaying]);

  return (
    <Box bg="purples.8" p={1} sx={{ borderRadius: 'small' }}>
      <audio
        src={src}
        ref={audioRef}
        onEnded={handleEnded}
        onCanPlay={() => setCanPlay(true)}
        onLoadedMetadata={(e) => {
          setDuration(e.target.duration);
        }}
      />
      <Grid gridTemplateColumns="max-content 80px 1fr max-content" gridGap={2} alignItems="center">
        <CircleButton onClick={handlePlayPause} disabled={!canPlay} bg="white">
          <Text color="purples.7" as={isPlaying === id ? IoIosPause : IoIosPlay} />
        </CircleButton>
        <Box>
          {duration && (
            <Grid gridTemplateColumns="1fr max-content 1fr" gridGap={1}>
              <FormattedSeconds seconds={currentTime} />
              <Text fontSize={0} color="white">
                /
              </Text>
              <FormattedSeconds seconds={duration} />
            </Grid>
          )}
        </Box>
        <PreviewSlider
          disabled={!duration}
          value={currentTime}
          min={0}
          max={duration}
          onAfterChange={handleScrub}
          bg="white"
        />
        <CircleButton onClick={addSong} disabled={uploading} bg="white">
          <Text color="purples.7" as={FaPlus} />
        </CircleButton>
      </Grid>
    </Box>
  );
}

export default AudioPlayer;
