import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js';
import store from 'store';
import ReactPlayer from 'react-player';
import { useEntity } from '@/lib/entities';
import { useVideoableClip } from '@/hooks/clip';

const sourcesList = [];

const SafariVideoNode = React.memo(
  ({
    nodeInRange,
    shouldPlay,
    currentTime,
    onPlay,
    onPause,
    onProgress,
    id,
    index,
    width,
    volume,
    startAt,
    endAt,
    notNormalized,
    normalizedVideo,
    setBuffering,
    isForEdit,
    originalVolume,
    clipDetail,
  }) => {
    const clip = clipDetail || useVideoableClip(id);
    const video = useEntity(clip.video);
    const player = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [isOpenWarning, setOpenWarning] = useState(false);

    const parser = new UAParser();
    const isIphoneUser = parser.getDevice().model?.toLowerCase()?.includes('iphone');

    useEffect(() => {
      if (player && player?.current?.player?.getInternalPlayer('hls')) {
        player.current.player.getInternalPlayer('hls').currentLevel = 0;
      }
    }, [player?.current?.player?.getInternalPlayer('hls')]);

    useEffect(() => {
      const myVideo = document.querySelector(
        `[id="${isForEdit ? `${id}-isForEdit` : id}"] > video`
      );
      let videoSource = null;
      const sourceExist = sourcesList.findIndex(
        (source) => source.mediaElement.mediaElement.src === myVideo.src
      );
      if (myVideo && nodeInRange && originalVolume > 1) {
        const boost = originalVolume / 2 + 1;
        if (sourceExist === -1) {
          window.AudioContext = window.AudioContext || window.webkitAudioContext;
          const audioCtx = new window.AudioContext();

          const gainNode = audioCtx?.createGain();
          const source = audioCtx?.createMediaElementSource(myVideo);
          gainNode.gain.value = boost; // double the volume
          sourcesList.push({ mediaElement: source, gainNode });
          videoSource = source;
          videoSource.connect(gainNode);
          gainNode.connect(audioCtx.destination);
        } else {
          const { gainNode } = sourcesList[sourceExist];

          gainNode.gain.value = boost;
          // gainNode.gain.linearRampToValueAtTime(boost * 2, currentTime);
        }
      }
      if (originalVolume <= 1 && sourceExist > -1) {
        const { gainNode } = sourcesList[sourceExist];

        gainNode.gain.value = 1;
        // gainNode.gain.linearRampToValueAtTime(1, currentTime);
      }
    }, [document.querySelector(`[id="${id}"] > video`), nodeInRange, originalVolume, volume]);

    const { 'trim-start': trimStartAt = 0, 'rotation-angle': rotation = 0 } = clip;

    // controlled play/pause
    useEffect(() => {
      // clip reached the end of playback
      if (shouldPlay && !nodeInRange) {
        player.current?.props.onPause();
      }
      // paused from button
      if (!shouldPlay && nodeInRange) {
        player.current?.props.onPause();
        onPause();
      }
      // play from button and this is the current node
      if (shouldPlay && nodeInRange) {
        setOpenWarning(false);
        player.current?.props.onPlay();
        onPlay();
      }
    }, [shouldPlay, nodeInRange, onPlay, onPause]);

    // slider scrubbing
    useEffect(() => {
      if (nodeInRange && player && player.current && !shouldPlay && isReady) {
        player?.current?.seekTo(currentTime + trimStartAt);
      }
      if (
        nodeInRange &&
        player &&
        player.current &&
        isReady &&
        trimStartAt > player.current?.getCurrentTime()
      ) {
        player?.current?.seekTo(trimStartAt, 'seconds');
      }
    }, [shouldPlay, currentTime, startAt, nodeInRange, trimStartAt, isReady, player]);

    return (
      <div className="video-wrapper">
        <ReactPlayer
          id={isForEdit ? `${id}-isForEdit` : id}
          ref={player}
          className="video-wrapper"
          onReady={() => {
            // if (store.get('sliderChanged'))
            store.remove('sliderChanged');
            setBuffering(false);
            setIsReady(true);
          }}
          playing={shouldPlay && nodeInRange}
          playsinline
          onPause={onPause}
          stopOnUnmount={false}
          onPlay={onPlay}
          volume={volume}
          onSeek={() => {
            if (store.get('currentSeconds')) store.remove('currentSeconds');
            if (store.get('sliderChanged')) store.remove('sliderChanged');
          }}
          progressInterval={0.5}
          onBuffer={() => setBuffering(true)}
          onBufferEnd={() => {
            setBuffering(false);
            setOpenWarning(false);
          }}
          onProgress={({ playedSeconds }) => {
            const rectPlayerDuartion = player?.current?.getDuration();
            if ((store.get('PreviewClipPlaying') || store.get('ClipPlaying')) && nodeInRange) {
              const currSec = store.get('currentSeconds');
              if (currSec) {
                onProgress(currSec);
                store.remove('currentSeconds');
              } else {
                const progress = playedSeconds + startAt - trimStartAt;
                if (progress > startAt) {
                  const diff = playedSeconds - currentTime;
                  if (diff > 1 && !trimStartAt) {
                    // Preventing scrubber from jumping ahead of scrubbed time.
                    player.current.seekTo(currentTime, 'seconds');
                  } else if (
                    rectPlayerDuartion < endAt &&
                    playedSeconds.toFixed(1) === rectPlayerDuartion.toFixed(1)
                  )
                    onProgress(endAt);
                  else onProgress(progress);
                }
              }
            }
          }}
          url={notNormalized ? video && video['hls-np'] : normalizedVideo?.['hls-np']}
          style={{
            height: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            objectPosition: 'center',
            transform: `rotate(${rotation}deg)`,
            width,
          }}
          light={
            isOpenWarning && isIphoneUser ? (
              <img src={video?.['preview-url']} alt="preview-url" />
            ) : undefined
          }
          onError={(e) => {
            if (
              (e?.message?.toLowerCase() ===
                'the request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.' ||
                e?.message?.toLowerCase() === 'the operation was aborted.') &&
              index >= 1
            ) {
              setOpenWarning(true);
              setBuffering(false);
            }
            store.set('sentryError', `${e} /SafariVideoNode Component React-player error`);
          }}
        />
      </div>
    );
  }
);
SafariVideoNode.propTypes = {
  currentTime: PropTypes.number.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  startAt: PropTypes.number.isRequired,
  shouldPlay: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  nodeInRange: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

SafariVideoNode.defaultProps = {
  width: 'inherit',
};
export default SafariVideoNode;
