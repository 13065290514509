import React from 'react';
import { Text } from 'rebass';

function CopyForTextNormalization() {
  return (
    <Text p={4} pt={0} fontSize="16px" lineHeight="150%">
      Sometimes people record their videos at different volumes.
      <br />
      <br />
      If you set the <b>Normalize Clip Volume</b> to <b>ON</b>, we will automagically balance your
      Contributors&apos; video clips while we process your merged video. Any music you add will be
      muted during video clips but will still be heard at the volume you select for pictures, cards
      and GIFs.
      <br />
      <br />
      If you prefer more fine-tuned control, leave this feature <b>OFF</b>, and adjust the volume of
      each clip yourself. In this case, music on videos, as well as all other clips, will be at the
      volume levels you choose.
    </Text>
  );
}

export default CopyForTextNormalization;
