import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import { Box, Text } from 'rebass';
import { Label } from '@rebass/forms';
import { FiVolume, FiVolume1, FiVolume2 } from 'react-icons/fi';
import Grid from '@/components/app/Grid';
import RangeField from './RangeField';

function VolumeField({ name, label, placeholder, isFromMobile, ...props }) {
  const [{ value }] = useField(name);
  const formattedValue = useMemo(() => {
    return Math.floor(value * 100);
  }, [value]);
  const icon = useMemo(() => {
    switch (value) {
      case 0:
        return FiVolume;
      case 1:
        return FiVolume2;
      default:
        return FiVolume1;
    }
  }, [value]);

  return (
    <Box {...props}>
      {label && (
        <Label htmlFor={name} pb={2}>
          {label}
        </Label>
      )}
      <Grid gridTemplateColumns="32px 32px 1fr" gridGap={2}>
        <Text as={icon} size={20} sx={{ justifySelf: 'center' }} />
        <Text textAlign="center" sx={{ justifySelf: 'center' }}>
          {formattedValue}
        </Text>
        <RangeField
          isFromMobile={isFromMobile}
          name={name}
          min={0}
          max={label === 'Clip Volume' ? 3 : 1}
          step={0.01}
        />
      </Grid>
      <ErrorMessage name={name}>
        {(message) => (
          <Text color="error" p={1} pt={2} fontSize={1}>
            {message}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
}

VolumeField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  formattedValue: PropTypes.func,
};

VolumeField.defaultProps = { formattedValue: null, placeholder: '', label: '' };

export default VolumeField;
