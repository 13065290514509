import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import { Box, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import TextareaAutosize from 'react-textarea-autosize';
import { capitalizeFirstLetter } from '@/lib/helper';

function TextAreaField({ name, fontSize, label, minHeight, placeholder, ...props }) {
  const [field, meta] = useField(name);
  const labelFontSize = useMemo(() => Math.max(0, fontSize - 1), [fontSize]);
  return (
    <Box pb={5} {...props}>
      {label && (
        <Label htmlFor={name} fontSize={labelFontSize} pb={fontSize}>
          {label}
        </Label>
      )}
      <Input
        as={TextareaAutosize}
        id={name}
        name={name}
        placeholder={placeholder}
        {...field}
        value={field.value || ''}
        minHeight={minHeight}
        sx={{
          fontFamily: 'body',
          borderColor: meta.touched && meta.error ? 'error' : 'border',
        }}
      />
      <ErrorMessage name={name}>
        {(message) => (
          <Text color="error" p={1} pt={fontSize} fontSize={1}>
            {capitalizeFirstLetter(message)}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
}

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

TextAreaField.defaultProps = { placeholder: '', fontSize: 2, label: '' };

export default TextAreaField;
