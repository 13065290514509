/* eslint-disable import/no-cycle */
import React, { useEffect, useState, memo } from 'react';
import { useMobileCheckHook } from '@/hooks/celebration';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import ImageLayer from './ImageLayer';
// import { fontOptions } from '@/components/EditClip/TextClipFonts';
import TextLayer from './TextLayer';

function RenderLayer({
  templateDetails,
  celebrationId,
  selectedSide,
  onClickOutside,
  'celebration-printable': celebrationPrintable,
  isPreviewOpenForChildren = false,
  allSupportedFontsList,
  zoom,
  isCursorInsideEditor,
  setIsEditing,
  setIsDeleIconOpen,
  ...props
}) {
  const [isCurrentLayerSelected, setIsCurrentLayerSelected] = useState(false);
  const [selectedLayer] = useLayerSelection();
  const isFromMobile = useMobileCheckHook();

  useEffect(() => {
    if (props && selectedLayer?.name) setIsCurrentLayerSelected(selectedLayer?.name === props.name);
    else setIsCurrentLayerSelected(false);
  }, [selectedLayer?.name]);

  if (!props.enabled) return null;
  if (props.text !== null) {
    return (
      <TextLayer
        isCurrentLayerSelected={isCurrentLayerSelected}
        onClickOutside={onClickOutside}
        zoom={zoom}
        isCursorInsideEditor={isCursorInsideEditor}
        allSupportedFontsList={allSupportedFontsList}
        setIsEditing={setIsEditing}
        setIsDeleIconOpen={setIsDeleIconOpen}
        {...props}
      />
    );
  }

  return (
    <ImageLayer
      isCurrentLayerSelected={isCurrentLayerSelected}
      templateId={templateDetails?.id}
      celebrationId={celebrationId}
      selectedSide={selectedSide}
      isFromMobile={isFromMobile}
      isPreviewOpenForChildren={isPreviewOpenForChildren}
      {...props}
    />
  );
}

RenderLayer.defaultProps = {
  selectedLayer: null,
  isPreviewOpenForChildren: null,
};

export default memo(RenderLayer);
