import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import { Box, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import { capitalizeFirstLetter } from '@/lib/helper';

function TextField({
  name,
  tabIndex,
  fontSize,
  label,
  placeholder,
  disabled = false,
  type,
  labelSx,
  justifyContent = 'center',
  bg = 'lightGreys.3',
  ...props
}) {
  const [field, meta] = useField(name);
  const labelFontSize = useMemo(() => Math.max(0, fontSize - 1), [fontSize]);
  return (
    <Box pb={4} {...props} maxWidth="500px" m="auto">
      <Label
        htmlFor={name}
        justifyContent={justifyContent}
        pb={1}
        sx={{ fontSize: labelFontSize, ...labelSx }}
      >
        {capitalizeFirstLetter(label)}
      </Label>
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        className={meta.touched && meta.error && 'error'}
        disabled={disabled}
        {...field}
        tabIndex={tabIndex}
        sx={{
          borderColor: meta.touched && meta.error ? 'error' : '#D9D9D9',
          bg,
          cursor: disabled && 'not-allowed',
        }}
      />

      <ErrorMessage name={name}>
        {(message) => (
          <Text color="error" p={1} fontSize={1} textAlign="left">
            {capitalizeFirstLetter(message)}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

TextField.defaultProps = { placeholder: '', fontSize: 2, type: 'input' };

export default TextField;
