import React from 'react';
import PropTypes from 'prop-types';
import VideoTrimmer from './VideoTrimmer';

function TrimControl({ clip, isFromMobile }) {
  return <VideoTrimmer id={clip.id} isFromMobile={isFromMobile} />;
}

TrimControl.propTypes = {
  isFromMobile: PropTypes.bool.isRequired,
};

export default TrimControl;
