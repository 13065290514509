/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
// import Grid from '@/components/app/Grid';
import { useWindowSize } from 'react-use';
import store from 'store';
import { Box, Heading, Text } from 'rebass';
import Clip from '@/components/Clip';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import EditClip from '@/components/EditClip';
import { videoGuestBookTemplateId } from '@/lib/constants';
import { mobileModalCSS } from '../Printables/ReusableComponents';
import SortableClips from './SortableClips';
import { useGetSelectedTemplate } from '../CelebrationForm/hooks';
import { LoadingBg, Spinner } from '../app/Loading';

function SubmissionClipsList({ isFromMobile, clips, clipBoardHeight, clipsLoading }) {
  const selectedTemplate = useGetSelectedTemplate();
  const isFromVideoGuestBook = selectedTemplate?.id === videoGuestBookTemplateId;
  const [showEdit, setShowEdit] = useState(null);

  const { width, height } = useWindowSize();

  const clipWidth = (width / 100) * 25;
  const clipHeight = clipWidth * (9 / 16);

  if (!clips.length && !isFromMobile)
    return (
      <Box
        textAlign="center"
        sx={{
          borderColor: 'white',
          borderStyle: 'dashed',
          borderRadius: '20px',
          textAlign: 'center',
        }}
        p={2}
      >
        <Heading className="dm-sans" color="white" fontWeight={500}>
          This is the Clipboard
        </Heading>
        <Text className="dm-sans" color="white" fontWeight={400} lineHeight={1.2}>
          This is just a holding bin for clips. They will not be in your main video until you add
          them to your timeline. That&apos;s where you will also be able to edit them.
        </Text>
      </Box>
    );

  const onStartDrag = (ev, clip, isFromTouch) => {
    if (clip.uploadStatus === 'finished' || clip.uploadStatus === false) {
      store.set('isDragging', true);
      if (isFromTouch) {
        const touch = ev.changedTouches[0];
        if (touch.screenY < height - clipBoardHeight)
          store.set('Submission clip', JSON.stringify({ id: clip.id, type: clip.type }));
      } else
        ev.dataTransfer.setData(
          'Submission clip',
          JSON.stringify({ id: clip.id, type: clip.type })
        );
    }
  };

  return (
    <Box
      pt={isFromMobile ? 1 : 3}
      overflowX={isFromMobile && clipsLoading ? 'auto' : 'hidden'}
      sx={{ position: 'relative' }}
    >
      {clipsLoading && (
        <LoadingBg
          sx={{
            position: isFromMobile && !clips.length ? 'relative' : 'absolute',
            display: 'flex',
            top: 0,
            left: 0,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minHeight: isFromMobile ? clipHeight : '130px',
            width: '100%',
            zIndex: 5,
          }}
        >
          <Spinner style={isFromMobile && { width: 50, height: 50 }} />
        </LoadingBg>
      )}
      <SortableClips
        clips={clips}
        style={{
          display: isFromMobile ? 'flex' : 'grid',
          gridGap: 15,
          paddingBottom: isFromMobile && '10px',
          gridTemplateColumns: !isFromMobile && 'repeat(auto-fit, minmax(170px,  170px))',
        }}
        handle={isFromMobile && '.handle'}
      >
        {(list) =>
          list.map(
            (clip, index) =>
              clip && (
                <Clip
                  id={clip.id}
                  isFromVideoGuestBook={isFromVideoGuestBook}
                  type={clip.type}
                  height={isFromMobile ? clipHeight : 130}
                  width={isFromMobile ? clipWidth : 180}
                  minWidth={isFromMobile && clipWidth}
                  key={clip.id}
                  onClick={() => setShowEdit(clip)}
                  onDragStart={(ev, isFromTouch) => onStartDrag(ev, clip, isFromTouch)}
                  showName
                  isFromMobile={isFromMobile}
                  index={index}
                  lastClip={isFromMobile && index === list.length - 1}
                />
              )
          )
        }
      </SortableClips>
      <AnimatedDialog
        isOpen={!!showEdit}
        onDismiss={() => {
          if (store.get('PreviewClipPlaying')) store.remove('PreviewClipPlaying');
          setShowEdit(null);
        }}
        title="Add to Timeline Dialog"
        contentCSS={isFromMobile && mobileModalCSS()}
        contentProps={{
          width: '100%',
          maxWidth: !isFromMobile && 900,
        }}
        mt={isFromMobile ? 0 : undefined}
        isFullScreenModal={isFromMobile}
        isFromMobile={isFromMobile}
      >
        <EditClip
          id={showEdit && showEdit.id}
          type={showEdit && showEdit.type}
          onDismiss={() => setShowEdit(null)}
          isFromMobile={isFromMobile}
          moveToTimeline
        />
      </AnimatedDialog>
    </Box>
  );
}

export default SubmissionClipsList;
