import React, { useState } from 'react';
import { Formik } from 'formik';
import useSWR from 'swr';
import { Box, Heading } from 'rebass';
import store from 'store';
import TextField from '@/components/app/formik/TextField';
import FileDropperField from '@/components/app/formik/FileDropperField';
import { useAddClipToEntities } from '@/hooks/clip';
import { checkIfIdExist } from '@/lib/helper';
import SubmitClipDialog from '../ContributorRecord/SubmitClipDialog';
import InternetSpeedTracker from '../InternetSpeedTracker/index';

function UploadClipFile({
  type,
  onFinished,
  isFromVideoGuestBookInvitation,
  celebrationId,
  questionId,
  isFromMobile,
  template,
  userAddedDetails,
  showLabel = true,
}) {
  const { mutate } = useSWR(
    checkIfIdExist(celebrationId) && !isFromVideoGuestBookInvitation
      ? `/v3/celebrations/${celebrationId}?shallow=true`
      : null
  );
  const [file, setFile] = useState(null);
  const [userAddedName, setUserAddedName] = useState(
    userAddedDetails ? `${userAddedDetails['first-name']} ${userAddedDetails['last-name']}` : null
  );
  const showName = !isFromVideoGuestBookInvitation || type.toLowerCase() === 'add media';
  const [addCliptoEntity] = useAddClipToEntities();

  function onSubmit(values) {
    try {
      if (Object.keys(values).length) {
        if (showName) setUserAddedName(values.name || null);
        setFile({ ...values, name: values.name || values.file[0].name });
      }
    } catch (error) {
      console.log(error);
      store.set('sentryError', `${error} /UploadClipFile Component after add-file function`);
    }
  }

  if (type && file)
    return (
      <SubmitClipDialog
        type={isFromVideoGuestBookInvitation ? null : type}
        fileName={file.name}
        celebrationId={celebrationId}
        questionId={questionId}
        files={file.file}
        userAddedName={userAddedName}
        userAddedDetails={userAddedDetails}
        isFromVideoGuestBookInvitation={isFromVideoGuestBookInvitation}
        onDone={(uploadedFiles) => {
          mutate();
          onFinished(isFromVideoGuestBookInvitation ? uploadedFiles : null);
          /* eslint-disable no-param-reassign */
          uploadedFiles.map(({ type: clipType, id }) =>
            addCliptoEntity(clipType, id, celebrationId)
          );
          setFile(null);
        }}
      />
    );

  return (
    <Box
      p={isFromMobile ? 2 : 4}
      width={isFromMobile ? '100%' : 'auto'}
      alignSelf={isFromMobile && 'baseline'}
      pt={isFromMobile && 0}
      maxWidth={480}
    >
      <InternetSpeedTracker onDismiss={onFinished} />
      {!isFromVideoGuestBookInvitation && showLabel && (
        <Heading variant="headings.h4" pb={2}>
          Add {type} clip
        </Heading>
      )}
      <Formik initialValues={{}} enableReinitialize onSubmit={onSubmit}>
        <Box>
          {showName && !template && <TextField name="name" label="name" justifyContent="left" />}
          <FileDropperField
            accept={isFromVideoGuestBookInvitation && !template ? 'image/*,video/*' : `${type}/*`}
            label={`${type}`}
            multiple
            name="file"
            placeholder={
              isFromMobile
                ? `Tap to upload ${type}s from device`
                : `Drop a media file or click to upload`
            }
          />
        </Box>
      </Formik>
    </Box>
  );
}

export default UploadClipFile;
