import React, { useState, useEffect } from 'react';

import { Image } from 'rebass';

export const useObjectURL = (file) => {
  const [fileSrc, setFileSrc] = useState(undefined);

  useEffect(() => {
    if (!fileSrc && file && typeof file === 'object') {
      setFileSrc(URL.createObjectURL(file));
    }
    return () => {
      if (fileSrc) {
        URL.revokeObjectURL(fileSrc);
      }
    };
  }, [file, setFileSrc, fileSrc]);

  if (!file) return undefined;

  return fileSrc;
};

function ImageFromFileOrSrc({ file, src, ...props }) {
  const fileSrc = useObjectURL(file);
  const image = fileSrc || src;
  return <Image src={image} {...props} />;
}

export default ImageFromFileOrSrc;
