import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Box, Text, Button } from 'rebass';
import { useWindowSize } from 'react-use';
// import { motion } from 'framer-motion';
import produce from 'immer';
import { IoIosClose } from 'react-icons/io';
import Moveable from 'react-moveable';
import { getAspectRatio } from '@/lib/helper';
import defaultValues from './defaultValues';

function getStringBetween(str, char1, char2) {
  return str.split(char1).slice(1).join('').split(char2).slice(0, -1).join('');
}
/* eslint-disable no-param-reassign */
const EditableTextClip = React.forwardRef(
  ({ isFromMobile, onDelete, currentValue, index, onSelectIndex, selectedIndex }, ref) => {
    const { values, setValues } = useFormikContext();
    const textRef = useRef();
    const { width: screenWidth } = useWindowSize();
    const vidHeight = getAspectRatio(screenWidth);

    const updateValues = (properties) => {
      setValues(
        values.map((val, i) => {
          if (index === i)
            return produce(currentValue, (draft) => {
              Object.keys(properties).forEach((key) => {
                // eslint-disable-next-line no-param-reassign
                draft[key] = properties[key];
              });
            });
          return val;
        })
      );
    };

    useEffect(() => {
      if (selectedIndex === index) {
        textRef.current.focus();
        textRef.current.style.beforeRate = currentValue?.['rotation-angle'] || 0;
        if (currentValue.text === defaultValues.text) {
          updateValues({ text: '' });
        }
      }
    }, [selectedIndex]);

    useEffect(() => {
      if (currentValue.width === undefined) {
        const textAreaWidth = textRef?.current?.getBoundingClientRect()?.width;
        const textAreaHeight = textRef?.current?.getBoundingClientRect()?.height;
        updateValues({
          width: textAreaWidth,
          height: textAreaHeight,
        });
      }
    }, [
      textRef?.current?.getBoundingClientRect()?.width,
      textRef?.current?.getBoundingClientRect()?.height,
    ]);

    const handleDeleteDefaultText = (e) => {
      if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        textRef.current.focus();
      }
      onSelectIndex(index);
    };
    const height = isFromMobile ? vidHeight + 100 : 394;
    const width = isFromMobile ? '100%' : 700;

    const [mainTarget, setTarget] = useState();

    useEffect(() => {
      setTarget(document.querySelector(`.target${index}`));
    }, []);

    return (
      <Box
        sx={{ position: 'relative' }}
        height={height}
        width={width}
        overflow="hidden"
        margin="auto"
      >
        <Box
          sx={{ position: 'relative' }}
          height={height}
          width={width}
          overflow="hidden"
          ref={ref}
          onClick={(e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left; // x position within the element.
            const y = e.clientY - rect.top; // y position within the element.
            onSelectIndex(
              values.findIndex((textcli) => {
                const textcliBoxPositionWidthMax = textcli.x + textcli.width;
                const textcliBoxPositionHeightMax = textcli.y + textcli.height;
                return (
                  x <= textcliBoxPositionWidthMax &&
                  x >= textcli.x &&
                  y <= textcliBoxPositionHeightMax &&
                  y >= textcli.y
                );
              })
            );
          }}
        >
          <Text
            ref={textRef}
            className={`target${index}`}
            bg={currentValue['background-color']}
            color={currentValue['text-color']}
            fontFamily={currentValue.font}
            fontSize={Number(currentValue['font-size'])}
            width={currentValue?.width || 'max-content'}
            overflow="hidden"
            textAlign="center"
            height={currentValue?.height}
            sx={{
              transform:
                currentValue?.transform ||
                `translate(${currentValue?.x}px, ${currentValue?.y}px) rotate(${
                  currentValue?.['rotation-angle'] || 0
                }deg)`,
            }}
            p={1}
            contentEditable
            onBlur={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateValues({
                text: e.target.innerText,
              });
            }}
            onClick={handleDeleteDefaultText}
            dangerouslySetInnerHTML={{ __html: currentValue.text }}
          />
          {selectedIndex === index && (
            <>
              <Button
                onClick={(e) => {
                  onDelete(e, { ...currentValue, index });
                  setValues(
                    values.filter((_, i) => {
                      return index !== i;
                    })
                  );
                }}
                variant="transparent"
                color="white"
                bg="greys.5"
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  // margin: '-20px -20px auto auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  transform: currentValue?.transform.includes('rotate')
                    ? currentValue?.transform
                    : `${currentValue?.transform} rotate(${
                        currentValue['rotation-angle'] || 0
                      }deg)`,
                }}
              >
                <Text as={IoIosClose} size={24} />
              </Button>
              <Moveable
                target={mainTarget}
                container={null}
                origin
                snappable
                bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: 'css' }}
                /* Resize event edges */
                edge={false}
                onClick={handleDeleteDefaultText}
                /* draggable */
                edgeDraggable
                draggable
                throttleDrag={1}
                startDragRotate={0}
                onDrag={({ target, transform }) => {
                  target.style.transform = transform;
                }}
                onDragEnd={({ lastEvent }) => {
                  if (lastEvent) {
                    const axis = getStringBetween(lastEvent.transform, 'translate(', 'px)')
                      .split(',')
                      .map((str) => +str.trim().replace('px', ''));
                    const originalAxis = getStringBetween(lastEvent.transform, 'matrix3d(', ')')
                      .split(',')
                      .map((str) => +str.trim().replace('px', ''))
                      .filter((_, i) => i === 12 || i === 13);
                    updateValues({
                      x: (originalAxis[0] || 0) + axis[0],
                      y: (originalAxis[1] || 0) + axis[1],
                      transform: lastEvent.transform,
                    });
                  }
                }}
                /* When resize or scale, keeps a ratio of the width, height. */
                keepRatio={false}
                /* resizable */
                /* Only one of resizable, scalable, warpable can be used. */
                resizable
                throttleResize={0}
                onResize={(e) => {
                  e.target.style.width = `${e.width}px`;
                  e.target.style.height = `${e.height}px`;
                  e.target.style.transform = e.drag.transform;
                }}
                onResizeEnd={(e) => {
                  updateValues({
                    width: e.lastEvent.width,
                    height: e.lastEvent.height,
                  });
                }}
                /* rotatable */
                rotatable
                throttleRotate={0}
                onRotate={({ target, beforeRotate, transform }) => {
                  target.style.transform = transform;
                  target.style.beforeRate = beforeRotate;
                  updateValues({ transform, 'rotation-angle': beforeRotate });
                }}
              />
            </>
          )}
        </Box>
      </Box>
    );
  }
);

EditableTextClip.defaultProps = { height: 270, width: 480 };
EditableTextClip.propTypes = { height: PropTypes.number, width: PropTypes.number };

export default EditableTextClip;
