import { useEntity } from '@/lib/entities';

export const useSoundTrack = (id) => useEntity({ id, type: 'sound-tracks' });
export const useSoundTrackPlayable = (id) => {
  const track = useSoundTrack(id);
  return useEntity(track?.playable);
};
export const useSoundTrackAudio = (id) => {
  const track = useSoundTrack(id);
  const playable = useEntity(track?.playable) || {};
  const audioFromPlayable = useEntity(playable.audio || { type: 'audios', id: '' });
  // songs have audio related to the playable, user upload playables ARE the audio
  return audioFromPlayable || playable;
};

export const useSoundTrackTitle = (id) => {
  const track = useSoundTrack(id);
  const playable = useSoundTrackPlayable(id);
  if (track || playable) {
    return track?.title || playable?.title || playable?.filename;
  }

  return 'Error Uploading song';
};

export const useSoundTrackArtist = (id) => {
  const track = useSoundTrack(id);
  const playable = useSoundTrackPlayable(id);
  if (track || playable) return track?.artist || playable?.artist;

  return '';
};

export const useSoundTrackLockVersion = (id) => {
  const track = useSoundTrack(id);
  const playable = useSoundTrackPlayable(id);
  if (track || playable) return track?.['lock-version'] || playable?.['lock-version'];

  return '';
};
