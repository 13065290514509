import React, { useState, useEffect } from 'react';
import { Flex, Text, Button } from 'rebass';
import { AiFillWarning } from 'react-icons/ai';
import store from 'store';
import AnimatedDialog from '../app/AnimatedDialog';

const useInterval = (handler, interval) => {
  const [intervalId, setIntervalId] = useState();
  useEffect(() => {
    const id = setInterval(handler, interval);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);
  return () => clearInterval(intervalId);
};

// eslint-disable-next-line react/prop-types
function InternetSpeedTracker({ onDismiss }) {
  const [isFastInternet, setIsFastInternet] = useState(true);
  // eslint-disable-next-line no-use-before-define
  const stopInterval = useInterval(handleInterval, 3000);

  function handleInterval() {
    const arrTimes = [];
    let i = 0; // start
    const timesToTest = 5;
    const tThreshold = 350; // ms
    const testImage = 'http://www.google.com/images/phd/px.gif'; // small image in your server
    const dummyImage = new Image();
    let isConnectedFast = false;

    /** test and average time took to download image from server, called recursively timesToTest times */
    function testLatency(cb) {
      const tStart = new Date().getTime();
      if (i < timesToTest - 1) {
        dummyImage.src = `${testImage}?t=${tStart}`;
        dummyImage.onload = function () {
          const tEnd = new Date().getTime();
          const tTimeTook = tEnd - tStart;
          arrTimes[i] = tTimeTook;
          testLatency(cb);
          i += 1;
        };
      } else {
        /** calculate average of array items then callback */
        const sum = arrTimes.reduce(function (a, b) {
          return a + b;
        });
        const avg = sum / arrTimes.length;
        cb(avg);
      }
    }

    testLatency(function (avg) {
      isConnectedFast = avg <= tThreshold;
      if (store.get('clearInterval')) {
        setIsFastInternet(true);
        stopInterval();
      } else setIsFastInternet(isConnectedFast);
    });
  }

  useEffect(() => {
    store.remove('clearInterval');
    return () => stopInterval();
  }, []);

  if (isFastInternet) return null;
  return (
    <AnimatedDialog isOpen={!isFastInternet} contentProps={{ width: '80vw', maxWidth: '700px' }}>
      <Flex pb={4} px={2} alignItems="center" width="100%" flexDirection="column">
        <Text size={64} mb={2} as={AiFillWarning} color="#FFC626" />
        <Flex justifyContent="center" alignItems="center" flexDirection="column">
          <Text textAlign="center">
            We’re detecting a slow internet connection. This may cause uploads to fail.
          </Text>
          <Flex mt={5} justifyContent="space-between" width="100%">
            <Button
              onClick={() => {
                store.set('clearInterval', true);
                setIsFastInternet(true);
                stopInterval();
              }}
              width="100%"
              type="button"
              variant="outline"
            >
              Try Anyway
            </Button>
            <Button
              width="100%"
              type="button"
              ml={2}
              onClick={() => {
                onDismiss();
                stopInterval();
              }}
            >
              Try Again Later
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </AnimatedDialog>
  );
}

export default InternetSpeedTracker;
