/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Text, Flex, Box, Button } from 'rebass';
import store from 'store';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// import { fontOptions } from '@/components/EditClip/TextClipFonts';
import { FiCheckCircle } from 'react-icons/fi';
import { IoMdArrowBack } from 'react-icons/io';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { isTouchDevice } from '@/lib/helper';
import { useMobileCheckHook } from '@/hooks/celebration';
import { ax } from '@/lib/api';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import { useCreateConfirmation } from './app/ConfirmationOverlay';
import { Tour } from './Tour';
import SideSwitch from './poster/SideSwitch';
import ZoomSlider from './VideoPreview/ZoomSlider';
import RenderLayer from './PosterEditor/RenderLayer';
import SystemLayer from './PosterEditor/SystemLayer';
import FormikTextEditor from './PosterEditor/FormikTextEditor';
import RenderEditiorForMobile from './PosterEditor/RenderEditiorForMobile';

// let allSupportedFontsList = [];
let isCursorInsideEditor = false;

const tourMobilePosterConfig = [
  {
    selector: '[data-tut="TEXT_EDITOR_MOBILE"]',
    // eslint-disable-next-line react/prop-types
    content: ({ close }) => (
      <Flex flexDirection="column">
        <Text fontWeight="700" fontSize="20px" lineHeight="21px" color="white" mb={1}>
          Tap text to edit
        </Text>
        <Text fontWeight="400" fontSize="16px" lineHeight="21px" color="white">
          Add your own text by tapping on text boxes.
        </Text>
        <Button mt={1} bg="purples.7" onClick={close} ml="auto">
          GOT IT
        </Button>
      </Flex>
    ),
    style: {
      backgroundColor: '#6369EF',
      left: 0,
    },
    stepInteraction: false,
  },
];

const tourPosterConfig = [
  {
    selector: '[data-tut="TEXT_EDITOR"]',
    // eslint-disable-next-line react/prop-types
    content: ({ goTo }) => (
      <Flex flexDirection="column">
        <Text fontWeight="700" fontSize="20px" lineHeight="21px" color="white" mb={1}>
          Click on text to edit it
        </Text>
        <Text fontWeight="400" fontSize="16px" lineHeight="21px" color="white">
          Add your own text by clicking on text boxes.
        </Text>
        <Button mt={1} bg="purples.7" onClick={() => goTo(1)} ml="auto">
          GOT IT
        </Button>
      </Flex>
    ),
    style: {
      backgroundColor: '#6369EF',
      left: '450px',
      top: '200px',
    },
    stepInteraction: false,
  },
  {
    selector: '[data-tut="CHANGE_COLOUR"]',
    // eslint-disable-next-line react/prop-types
    content: ({ close }) => (
      <Flex flexDirection="column">
        <Text fontWeight="700" fontSize="20px" lineHeight="21px" color="white" mb={1}>
          Customize the text
        </Text>
        <Text fontWeight=" 400" fontSize="16px" lineHeight="21px" color="white">
          You can change the colour, size, and typeface by using the tools here.
        </Text>
        <Button mt={1} bg="purples.7" onClick={close} ml="auto">
          GOT IT
        </Button>
      </Flex>
    ),
    style: {
      backgroundColor: '#6369EF',
    },
    stepInteraction: false,
  },
];

function EmptyEditorState({ isFromMobileScreen = false, height = '100%' }) {
  return (
    <Flex
      sx={{ border: '5px dashed #000000', maxWidth: 480, m: 'auto' }}
      height={height}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text
        sx={{
          fontFamily: 'Alata',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '42px',
          lineHeight: '53px',
          /* or 126% */
          textAlign: 'center',
          letterSpacing: '0.1em',
          texttransform: 'uppercase',
          color: '#000000',
        }}
      >
        {isFromMobileScreen
          ? 'Choose a template by clicking on plus icon below'
          : 'Choose a template to get started!'}
      </Text>
      {!isFromMobileScreen && <Text as={IoMdArrowBack} color="#000000" fontSize={140} />}
    </Flex>
  );
}

function InlineEditing({
  templateDetails,
  celebrationId,
  isLoading,
  revalidatePrintables,
  editorValue,
  switchSides,
  selectedSide,
  zoom,
  setZoom,
  RenderTagSearch,
  printableLayers,
  allSupportedFontsList,
  isFromMobileScreen,
  RenderFooter,
  containerHeight,
  setMobileFooterHeight,
}) {
  const [selectedLayer, setSelectedLayer] = useLayerSelection();
  const isFromMobile = useMobileCheckHook();

  const [showEditor, setShowEditor] = useState(null);
  const [isDeleteIconOpen, setIsDeleIconOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [fontFamilyOptions, setFontFamilyOptions] = useState([]);
  const [userLayersList, setUserLayersList] = useState([]);
  const [layersList, setLayersList] = useState([]);

  const [isPreviewOpenForChildren, setIsPreviewOpenForChildren] = useState(null);
  const [parentHeight, setParentHeight] = useState(null);

  const [showPosterTour, setShowPosterTour] = useState(false);
  const [posterSectionWidth, setPosterSectionWidth] = useState(0);
  const reactpinchzoomRef = useRef(null);

  useEffect(() => {
    if (printableLayers && printableLayers.length > 0) {
      setUserLayersList(
        printableLayers
          .filter((lay) => lay && lay.kind === 'user' && lay.enabled)
          .sort((a, b) => b.height - a.height)
      );
      setLayersList(
        printableLayers
          .filter((lay) => lay && lay.kind === 'system' && lay.enabled)
          .sort((a, b) => b.height - a.height)
          .map((lay) => ({ ...lay, 'font-name': isFromMobile ? 'Akshar' : lay['font-name'] }))
      );
    }
  }, [printableLayers]);

  useEffect(() => {
    if (posterSectionWidth && templateDetails?.width) {
      const widthZoom = (posterSectionWidth / templateDetails.width) * 100;
      const heightZoom = (parentHeight / templateDetails.height) * 100;

      const zoomPercentage = Math.min(widthZoom, heightZoom);
      setZoom(zoomPercentage - (isFromMobile ? 1 : 0));
    }
  }, [
    isFromMobile,
    posterSectionWidth,
    parentHeight,
    templateDetails?.width,
    templateDetails?.height,
  ]);

  useEffect(() => {
    if (selectedLayer?.text || selectedLayer?.text === '') {
      setShowEditor(selectedLayer.name);
      if (selectedLayer.supportsBold || selectedLayer.supportsItalic) {
        setFontFamilyOptions(
          allSupportedFontsList.filter((fon) => {
            if (selectedLayer.supportsBold) return fon.supportsBold;
            if (selectedLayer.supportsItalic) return fon.supportsItalic;
            return false;
          })
        );
      } else setFontFamilyOptions(allSupportedFontsList);
    }
    if (!selectedLayer && isEditing) setIsEditing(false);
    if (!selectedLayer) setIsPreviewOpenForChildren(null);
    if (isDeleteIconOpen) setIsDeleIconOpen(false);
  }, [selectedLayer]);

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const toggleInnerCreatePostTour = () => {
    if (showPosterTour) {
      setSelectedLayer(null);
      // setShowEditor(null);
    }
    setShowPosterTour(!showPosterTour);
  };

  useEffect(() => {
    if (templateDetails && !store.get('posterEditorGuidedTour') && !showPosterTour) {
      store.set('posterEditorGuidedTour', true);
      setShowPosterTour(true);
    }

    if (!showEditor) {
      isCursorInsideEditor = false; // This might need to be handled differently
    }
  }, [showEditor]);

  useEffect(() => {
    setSelectedLayer(null);
    setShowEditor(null);
    setIsPreviewOpenForChildren(null);
  }, [selectedSide]);

  useEffect(() => {
    if (editorValue && userLayersList.length > 0) {
      const editorValueLayer = userLayersList.find((o) => o.name === editorValue);
      if (editorValueLayer) {
        const { 'celebration-printable': print, ...layer } = editorValueLayer;
        setSelectedLayer(layer);
      }
    }
  }, [editorValue]);

  async function updatePrintableLayerText({ id, ...attributes }, values) {
    const { data } = await ax().patch(
      `/v3/celebrations/${celebrationId}/celebration_printables/${templateDetails?.id}/celebration_printable_text_layers/${id}`,
      { data: { attributes } }
    );
    if (values) {
      // this is to prevent the updating layer when user clicks outside a text box
      const updatedRecord = data?.data?.attributes;
      const { supportsBold, supportsItalic } = allSupportedFontsList.find(
        (list) => list.label === updatedRecord['font-name']
      );
      setSelectedLayer({ ...updatedRecord, supportsBold, supportsItalic });
      setUserLayersList((userLayers) =>
        userLayers.map((lis) => {
          if (lis.id === updatedRecord.id)
            return {
              ...lis,
              ...updatedRecord,
              supportsBold,
              supportsItalic,
            };
          return lis;
        })
      );
    }
    revalidatePrintables();
  }
  const createConfirmation = useCreateConfirmation();

  const deleteTextLayer = async () => {
    return Promise.resolve().then(async () => {
      const idsToBeDeleted = printableLayers
        .filter((lis) => lis.name.includes('flip'))
        .map(({ id }) => id);

      await Promise.all(
        idsToBeDeleted.map((layerId) =>
          ax().delete(
            `/v3/celebrations/${celebrationId}/celebration_printables/${templateDetails?.id}/celebration_printable_layers/${layerId}`
          )
        )
      );
      createConfirmation({ icon: FiCheckCircle, message: 'Changes Saved' });
      revalidatePrintables();
      setSelectedLayer(null);
      return true;
    });
  };

  const initialValues = useMemo(() => {
    const iniitObject = {
      font: '',
      'text-color': '',
      // 'background-color': textClip['background-color'] || celebration?.['font-background-colour'],
      'font-size': '',
      'font-weight': '',
      'font-style': 'normal',
      'font-decoration': 'none',
      'font-alignment': 'center',
    };

    if (selectedLayer?.text || selectedLayer?.text === '') {
      iniitObject.font = selectedLayer['font-name'] || '';
      iniitObject['text-color'] = selectedLayer['font-colour'] || '';
      // 'background-color'= selectedLayer['background-color'] || celebration?.['font-background-colour'];
      iniitObject['font-size'] = selectedLayer['font-size']
        ? selectedLayer['font-size'].replaceAll('px', '')
        : '';
      iniitObject['font-alignment'] = selectedLayer['font-alignment'] || '';

      if (selectedLayer.supportsBold)
        iniitObject['font-weight'] = selectedLayer['font-weight'] || '';
      else delete iniitObject['font-weight'];

      if (selectedLayer.supportsItalic)
        iniitObject['font-style'] = selectedLayer['font-style'] || 'normal';
      else delete iniitObject['font-style'];

      iniitObject['font-decoration'] = selectedLayer['font-decoration'] || '';
    }

    return iniitObject;
  }, [selectedLayer]);

  const handleSave = async (values, params) => {
    const layerToUpdate = params?.updatedLayer || selectedLayer;
    let styles = {};
    let isFontWeightChanged = false;
    let isFontStyleChanged = false;
    if (values) {
      isFontWeightChanged = values['font-weight'] !== layerToUpdate?.['font-weight'];
      isFontStyleChanged = values['font-style'] !== layerToUpdate?.['font-style'];
      styles = {
        'font-name': values.font ? values.font.replaceAll(' !important', '') : '',
        'font-colour': values['text-color'],
        // 'background-color': values['background-color'] || celebration?.['font-background-colour'],
        'font-size': values['font-size'].toString(),
        'font-weight':
          isFontStyleChanged && values['font-style'] === 'italic'
            ? ''
            : values['font-weight'] || '',
        'font-style':
          isFontWeightChanged && values['font-weight'] === 'bold'
            ? 'normal'
            : values['font-style'] || 'normal',
        'font-decoration': values['font-decoration'] || 'none',
        'font-alignment': values['font-alignment'],
      };
    }

    if (layerToUpdate) {
      if (styles?.['font-name'] !== layerToUpdate?.['font-name']) {
        const selectedFontDetails = allSupportedFontsList.find(
          (list) => list.label === (styles['font-name'] || layerToUpdate?.['font-name'])
        );

        if (selectedFontDetails) {
          const { supportsBold, supportsItalic } = selectedFontDetails;
          if (!supportsItalic) styles['font-style'] = 'normal';
          if (!supportsBold) styles['font-weight'] = '';
        }
      }
      delete layerToUpdate.supportsBold;
      delete layerToUpdate.supportsItalic;
      delete layerToUpdate.index;
      delete layerToUpdate.selectedLayer;
      updatePrintableLayerText({ ...layerToUpdate, ...styles }, values);
    }
    // revalidatePrintables()
  };

  useEffect(() => {
    if (reactpinchzoomRef.current) {
      const { zoomToElement } = reactpinchzoomRef.current;
      zoomToElement('templateContainer');
    }
  }, [reactpinchzoomRef?.current]);

  const onClickOutside = (e, updatedLayer) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleIconOpen(false);
    if (showEditor) {
      setShowEditor(null);
      setSelectedLayer(null);
    }
    if (updatedLayer && !showPosterTour) {
      handleSave(null, { updatedLayer });
    }
  };

  function getMl() {
    if (isFromMobile) return 1;

    if (RenderTagSearch()) return 1;

    return 126;
  }

  const memoizedUserLayersList = useMemo(
    () =>
      userLayersList.map((layer, index) => (
        <RenderLayer
          key={layer['updated-at']}
          isPreviewOpenForChildren={isPreviewOpenForChildren}
          {...layer}
          zoom={zoom}
          index={index}
          setIsEditing={setIsEditing}
          templateDetails={templateDetails}
          celebrationId={celebrationId}
          selectedSide={selectedSide}
          allSupportedFontsList={allSupportedFontsList}
          onClickOutside={onClickOutside}
          isCursorInsideEditor={isCursorInsideEditor}
          setIsDeleIconOpen={setIsDeleIconOpen}
        />
      )),
    [userLayersList, isPreviewOpenForChildren]
  );

  const memoizedLayersList = useMemo(
    () =>
      layersList.map((attr, index) => (
        <SystemLayer
          zoom={zoom}
          index={index}
          onFlipDeletion={deleteTextLayer}
          isDeleteIconOpen={isDeleteIconOpen}
          setIsDeleIconOpen={setIsDeleIconOpen}
          setIsPreviewOpenForChildren={setIsPreviewOpenForChildren}
          {...attr}
        />
      )),
    [layersList, isDeleteIconOpen, setIsDeleIconOpen]
  );

  return (
    <Box sx={{ position: 'relative', zIndex: 1 }}>
      {isFromMobile ? (
        <RenderEditiorForMobile
          setMobileFooterHeight={setMobileFooterHeight}
          showEditor={showEditor}
          RenderFooter={RenderFooter}
          handleSave={handleSave}
          fontFamilyOptions={fontFamilyOptions}
          isCursorInsideEditor={isCursorInsideEditor}
        />
      ) : (
        <Flex
          justifyContent={RenderTagSearch() ? 'space-between' : 'flex-end'}
          alignItems="center"
          sx={{
            position: 'fixed',
            height: '65px',
            mt: '-65px',
            ml: getMl(),
            zIndex: 99,
            left: 0,
            width: isFromMobile ? '100%' : `calc(100% - 126px)`,
          }}
        >
          {RenderTagSearch()}
          <Flex justifyContent="flex-end" alignItems="center">
            {templateDetails && (
              <FormikTextEditor
                showEditor={showEditor}
                initialValues={initialValues}
                handleSave={handleSave}
                options={fontFamilyOptions}
                isCursorInsideEditor={isCursorInsideEditor}
              />
            )}
            {!isTouchDevice() && templateDetails && (
              <ZoomSlider
                id="zoom_control"
                type="range"
                zoom={zoom}
                onChange={(e) => setZoom(e.target.value)}
                max={100}
                min={10}
                step="1"
              />
            )}
            <SideSwitch
              ml={!templateDetails && 'auto'}
              switchSides={switchSides}
              selectedSide={selectedSide}
            />
          </Flex>
        </Flex>
      )}
      <Box
        data-tut={isFromMobile ? 'TEXT_EDITOR_MOBILE' : 'TEXT_EDITOR'}
        height={containerHeight}
        width="100%"
        m="auto"
        className="posterContainer"
        onClick={onClickOutside}
        overflow="auto"
        py={1}
        sx={{ position: 'relative' }}
        ref={(el) => {
          if (!el) return;
          setPosterSectionWidth(el.getBoundingClientRect().width);
          setParentHeight(el.getBoundingClientRect().height);
        }}
      >
        <TransformWrapper
          ref={reactpinchzoomRef}
          centerZoomedOut
          centerOnInit
          disabled={!isTouchDevice() || isEditing}
          limitToBounds
        >
          <TransformComponent>
            {templateDetails ? (
              <Flex
                flexDirection="column"
                justifyContent="center"
                sx={{
                  zoom: `${zoom}% `,
                  MozTransform: `scale(${zoom / 100})`,
                  MozTransformOrigin: '10% 0',
                  position: 'relative',
                  backgroundColor: templateDetails?.['background-colour'],
                  boxShadow: '0px 7px 36px 5px rgba(0, 0, 0, 0.25)',
                  width: `${templateDetails?.width}px`,
                  height: `${templateDetails?.height}px`,
                  left: 0,
                  right: 0,
                  m: 'auto',
                  touchAction: 'pan-x pan-y pinch-zoom',
                }}
                id="templateContainer"
              >
                {!isLoading && memoizedUserLayersList}

                {memoizedLayersList}
              </Flex>
            ) : (
              <EmptyEditorState isFromMobileScreen={isFromMobileScreen} height={containerHeight} />
            )}
          </TransformComponent>
        </TransformWrapper>
      </Box>
      {RenderFooter && (
        <Flex
          width={`${posterSectionWidth}px`}
          sx={{
            position: 'fixed',
            bottom: 0,
            py: 1,
          }}
          bg="white"
        >
          {RenderFooter(onClickOutside)}
        </Flex>
      )}
      <Tour
        onRequestClose={toggleInnerCreatePostTour}
        steps={isFromMobile ? tourMobilePosterConfig : tourPosterConfig}
        disableInteraction
        isOpen={showPosterTour || false}
        maskClassName="mask"
        className="helper"
        rounded={8}
        startAt={0}
        accentColor="purples.9"
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        maskSpace={8}
        showNavigation={false}
        showNumber={false}
        showCloseButton={false}
        showButtons={false}
      />
    </Box>
  );
}

export default InlineEditing;
