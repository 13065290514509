import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import store from 'store';
import {
  useCelebration,
  useCelebrationClips,
  useCelebrationIdFromRoute,
} from '@/hooks/celebration';
import {
  usePatchClip,
  usePatchResource,
  useDeleteClip,
  useCelebrationResourceRequest,
  useResourceRequest,
} from '@/hooks/api';
import { ax } from '@/lib/api';
import { useUpdateEntity } from '@/lib/entities';
import { useEditingSuiteSelection } from '@/components/VideoEditor/EditingSuiteContext';
import { useClipTextClip } from './clip';

export const useUpdateClip = ({ id, type }) => {
  const updateEntity = useUpdateEntity({ id, type });
  const [patchClip] = usePatchClip({ id, type });

  return useCallback(
    async (attributes = {}) => {
      updateEntity(attributes);

      await patchClip(attributes);
    },
    [patchClip, updateEntity]
  );
};

export const useDeleteCanceledClip = (celebId) => {
  const celebrationId = useCelebrationIdFromRoute(celebId);
  return useCallback(
    async (mediaId = null) => {
      if (mediaId) {
        const { data } = await ax().get(`/v2/media/${mediaId}`);
        const { 'owner-id': id } = data.data.attributes;
        await ax().delete(`/v3/celebrations/${celebrationId}/celebration_clips/${id}`);
        store.remove('uploadingID');
      }
    },
    [celebrationId]
  );
};

export const useRenderClipOrder = ({ state, celebrationId }) => {
  const clips = useCelebrationClips(celebrationId, {
    state: state === 'in_timeline' ? 'in_clipboard' : 'in_timeline',
  });

  const [updateClipList] = useResourceRequest(
    'patch',
    `/v3/celebrations/${celebrationId}/celebration_clips/reorder`
  );
  return useCallback(async () => {
    store.remove('isDragging');
    store.remove('clipMoving');
  }, [updateClipList, clips]);
};

export const useMoveClip = ({ id, type, state, celebrationId }) => {
  const clips = useCelebrationClips(celebrationId, { state });
  const { mutate: revalidateCelebration } = useSWRConfig();

  const rerenderClips = useRenderClipOrder({ id, state, celebrationId });
  const updateEntity = useUpdateEntity({ id, type });
  const [patchClip] = usePatchClip({ id, type, celebrationId });

  return useCallback(async () => {
    let position = 1;
    if (clips.length) {
      position = Math.max(...clips.map((o) => o.position)) + 1;
    }

    const attributes = { position, state };

    updateEntity(attributes);
    await patchClip(attributes);
    revalidateCelebration(`/v3/celebrations/${celebrationId}?shallow=true`);
    rerenderClips({
      id,
      position,
    });
  }, [updateEntity, patchClip, clips, state]);
};

export const useDeleteAndRemoveClip = ({ id, type, celebrationId }) => {
  const updateEntity = useUpdateEntity({ id, type });
  const updateCelebrationEntity = useUpdateEntity({ type: 'celebrations', id: celebrationId });

  const [deleteClip, { loading, error }] = useDeleteClip({ id, type, celebrationId });
  const celebration = useCelebration(celebrationId);

  const fn = useCallback(
    async (updateCelebration = false) => {
      updateEntity({ state: 'deleted' });
      await deleteClip();

      if (updateCelebration === true) {
        const updatedVisibleClips = celebration['visible-clips'].filter((clip) => clip.id !== id);

        updateCelebrationEntity({
          'visible-clips': [...updatedVisibleClips],
          'visible-clips-count': updatedVisibleClips.length,
        });
      }
      // mutate()
    },
    [deleteClip, updateCelebrationEntity, updateEntity, id, celebration?.['visible-clips']]
  );

  return [fn, { loading, error }];
};

export const useDuplicateClip = ({ id, celebrationId }) => {
  const { mutate: revalidateCelebration } = useSWRConfig();

  const [duplicate] = usePatchResource(
    `/v3/celebrations/${celebrationId}/celebration_clips/${id}/duplicate`
  );
  const [, setDuplicateClipPosition] = useEditingSuiteSelection();
  return useCallback(async () => {
    const clipResponse = await duplicate();
    const { attributes } = clipResponse.data.data;
    setDuplicateClipPosition(attributes?.position);
    revalidateCelebration(`/v3/celebrations/${celebrationId}?shallow=true`);
  }, [duplicate, revalidateCelebration]);
};

export const useDeleteClipTextClip = ({ id, type }) => {
  const textClip = useClipTextClip({ id });
  const updateEntity = useUpdateEntity({ id, type });

  const [deleteTextClip, { data, loading, error }] = useCelebrationResourceRequest(
    'delete',
    `celebration_text_clips/${textClip && textClip.id}`
  );

  const fn = useCallback(async () => {
    updateEntity({ 'text-clips': [] });
    await deleteTextClip();
  }, [deleteTextClip, updateEntity]);

  return [fn, { loading, error, data }];
};
