import React from 'react';
import ButtonWithIcon from '@/components/ButtonWithIcon';

function NavButton({ selected, ...props }) {
  return (
    <ButtonWithIcon
      iconProps={{ color: selected ? 'greys.1' : 'purples.7', mr: 1 }}
      p={2}
      type="button"
      variant={selected ? 'primary' : 'muted-outline'}
      fontSize={0}
      sx={{
        '&:focus': { outline: 'none' },
        ...props.disabledStyleProps,
      }}
      {...props}
    />
  );
}

export default NavButton;
