import React from 'react';
import PropTypes from 'prop-types';
import { Image, Flex, Text } from 'rebass';
import { FiUser } from 'react-icons/fi';

function Avatar({ src, initials, height, width, size, borderRadius, opacity = 1, ...props }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height={height || size}
      width={width || size}
      sx={{
        boxShadow: 'medium',
        border: '2px solid',
        borderColor: 'greys.2',
        bg: 'greys.2',
        borderRadius: borderRadius || '50%',
        overflow: 'hidden',
        opacity,
      }}
      {...props}
    >
      {src && <Image size={size} src={src} sx={{ objectFit: 'cover', zIndex: 3 }} />}
      {!src && initials && <Text color="greys.5">{initials}</Text>}
      {!src && !initials && <Text size={size / 3} color="greys.5" as={FiUser} />}
    </Flex>
  );
}

Avatar.propTypes = { src: PropTypes.string, initials: PropTypes.string, size: PropTypes.number };
Avatar.defaultProps = { src: '', initials: '', size: 48 };

export default Avatar;
