/* eslint-disable import/no-cycle */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { FaCircle } from 'react-icons/fa';
import { Box, Text, Flex, Button } from 'rebass';
import { Label } from '@rebass/forms';
import { SketchPicker } from 'react-color';
import useOnClickOutside from 'use-onclickoutside';
import { useSubmitCelebration } from '@/components/CelebrationForm/hooks';
import { useDebouncedValue } from '@/hooks/celebration';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import ShowBottomSheet from '../ShowBottomSheet';

function Swatch({ color }) {
  return (
    <Box
      bg={color}
      height={24}
      width={24}
      sx={{ border: '2px solid', borderColor: 'rgba(255, 255, 255, .5)' }}
    />
  );
}

function RenderSketcher(props) {
  return (
    <SketchPicker
      presetColors={[
        '#ffffff',
        '#D0021B',
        '#F5A623',
        '#F8E71C',
        '#8B572A',
        '#7ED321',
        '#417505',
        '#BD10E0',
        '#9013FE',
        '#4A90E2',
        '#50E3C2',
        '#B8E986',
        '#000000',
        '#4A4A4A',
        '#9B9B9B',
        'transparent',
      ]}
      {...props}
    />
  );
}

function withStateHOC(Component) {
  function WithState(props) {
    const { name, valueIndex, celebrationAttrName, isFromSubmitForm, isFromMobileScreen } = props;
    if (isFromMobileScreen) {
      const [selectedLayer, setSelectedLayer] = useLayerSelection();
      const onChange = (c) => {
        setSelectedLayer((layer) => ({ ...layer, 'font-colour': c.hex }));
      };

      return <Component {...props} color={selectedLayer['font-colour']} onChange={onChange} />;
    }

    const [field, , helper] = useField(valueIndex !== undefined ? `${valueIndex}.${name}` : name);
    const formik = useFormikContext();
    const [color, setColor] = useState(field.value);

    const submitCelebration = useSubmitCelebration();

    const debouncedValue = useDebouncedValue(color, 500);

    const onChange = async (c) => {
      helper.setValue(c);
      if (celebrationAttrName) await submitCelebration({ [celebrationAttrName]: c });
      if (isFromSubmitForm) formik.submitForm();
    };

    useEffect(() => {
      if (debouncedValue !== field.value) {
        // helper.setValue(debouncedValue);
        // formik.submitForm();
        onChange(debouncedValue);
      }
    }, [debouncedValue]);

    return <Component {...props} onChange={(c) => setColor(c.hex)} color={color} />;
  }
  WithState.displayName = `withState(${Component.displayName || Component.name})`;
  return WithState;
}

function ColorField({
  name,
  fontSize,
  celebrationAttrName,
  label,
  isFromSubmitForm,
  placeholder,
  valueIndex,
  isFromMobileScreen,
  onChange,
  color,
  ...props
}) {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const buttonRef = useRef(null);

  const labelFontSize = useMemo(() => Math.max(0, fontSize - 1), [fontSize]);

  useOnClickOutside(pickerRef, () => {
    if (showPicker) {
      setShowPicker(false);
    }
  });

  function onClick() {
    setShowPicker((show) => !show);
  }

  return (
    <Box {...props}>
      {label && !isFromSubmitForm && (
        <Label htmlFor={name} fontSize={labelFontSize} pb={labelFontSize === 14 ? 1 : 2}>
          {label}
        </Label>
      )}
      <Box sx={{ position: 'relative' }}>
        {isFromMobileScreen ? (
          <Box>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={onClick}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: 'black' /* Set the background color of the circle */,
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                }}
              >
                <FaCircle color={color || '#414143'} size={20} />
              </Flex>
              <Text className="posterEditiorNavText">Color</Text>
            </Flex>
            <ShowBottomSheet
              heading="Color"
              isOpen={showPicker}
              ref={pickerRef}
              height="max-content"
              onClose={onClick}
            >
              <Flex justifyContent="center">
                <RenderSketcher maxWidth="500px" color={color} onChange={onChange} />
              </Flex>
            </ShowBottomSheet>
          </Box>
        ) : (
          <Box>
            <Box
              ref={pickerRef}
              style={{
                display: showPicker ? 'block' : 'none',
                position: 'absolute',
                zIndex: 2,
                transform: !isFromSubmitForm && 'translateY(calc(-100% - 24px))',
              }}
            >
              <RenderSketcher color={color} onChange={onChange} />
            </Box>
            <Button
              type="button"
              variant="muted-outline"
              ref={buttonRef}
              p={1}
              py={12}
              bg="white"
              onClick={() => {
                setShowPicker((show) => !show);
              }}
              width={props.width}
            >
              <Flex alignItems="center">
                <Swatch color={color} />
                <Label ml={2}>{color.toUpperCase()}</Label>
              </Flex>
            </Button>
          </Box>
        )}
      </Box>
      {!isFromMobileScreen && (
        <ErrorMessage name={name}>
          {(message) => (
            <Text color="error" p={1} pt={fontSize} fontSize={1}>
              {message}
            </Text>
          )}
        </ErrorMessage>
      )}
    </Box>
  );
}

ColorField.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

ColorField.defaultProps = { placeholder: '', fontSize: 2 };

export default withStateHOC(ColorField);
