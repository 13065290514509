import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'rebass';
// import { useWindowSize } from 'react-use';
// import { viewChangePixels } from '@/lib/constants';
// import { getAspectRatio } from '@/lib/helper';

function StickerClipImage({ stickerClips, zIndex = undefined }) {
  // const image = useCanvasImageClip({ id });
  if (stickerClips.length === 0) return false;

  // const { width: screenWidth } = useWindowSize();
  // const isFromMobile = screenWidth < viewChangePixels;
  // const vidHeight = getAspectRatio(screenWidth);

  return stickerClips.map((image) => {
    const { src, width: stickerWidth, height: stickerHeight, x, y } = image;
    // const imageWidth = isFromMobile ? screenWidth : 700;
    // const imageHeight = isFromMobile ? vidHeight + 100 : 394;
    const imageWidth = 700;
    const imageHeight = 394;
    return (
      <Image
        key={src}
        src={src}
        sx={{
          position: 'absolute',
          width: `${(stickerWidth / imageWidth) * 100}%`,
          height: `${(stickerHeight / imageHeight) * 100}%`,
          left: `${(x / imageWidth) * 100}%`,
          top: `${(y / imageHeight) * 100}%`,
          zIndex: zIndex || 10,
        }}
      />
    );
  });
  // return <Image src={image['asset-url']} {...props} />;
}

StickerClipImage.defaultProps = { size: 1 };
StickerClipImage.propTypes = { size: PropTypes.number };

export default StickerClipImage;
