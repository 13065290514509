import React, { useEffect, useRef, useState } from 'react';
import store from 'store';
import { useRouter } from 'next/router';
import { Box, Heading, Button, Link } from 'rebass';
import { getMetadata } from 'video-metadata-thumbnails';
import Uppy from '@uppy/core';
import Axios from 'axios';
import { StatusBar, useUppy } from '@uppy/react';
import { useCurrentMemberName } from '@/hooks/member';
import Progress from '@/components/app/Progress';
import { UploadInProgressTrigger } from '@/lib/constants';
import {
  getMediaIdFromUploadKey,
  setFileType,
  setFileName,
  retryUppyUpload,
  getIDFromKey,
  sendTrackingEvent,
  clearLocalStorageItems,
} from '@/lib/helper';
import { ax, postEventDetails } from '@/lib/api';
import { useCelebration, useCelebrationUploadErrorEmailMailto } from '@/hooks/celebration';
import { useEntity } from '@/lib/entities';
import { useIsSafariUser } from '@/hooks/user';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';

import { useGETClipSWR } from '@/hooks/api';
import { useCurrentMemberId } from '@/hooks/auth';
import ProgressTracker from '../app/ProgressTracker';
import UppyComponent from '../UppyComponent';
import { useCreateNewAccount } from '../Auth/index';
import { useVideoUploadContext } from './VideoUploadContext';

const baseURL = process.env.NEXT_PUBLIC_API_BASE.replace('/api', '/');

let filteredFiles = [];
let uploadedFiles = [];
let count = 0;

function getRandomMessage() {
  const messages = [
    'Sweet, next question…',
    'That’s awesome!',
    'We bet they’ll really enjoy that one!',
    'Great answer!',
    'Interesting response!',
    'Nice!',
    'Excellent!',
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}

function RenderUploader({
  children,
  celebrationId,
  mainProgress,
  uploadError,
  tryAgain,
  clipsUploading,
  files = [],
}) {
  const memberId = useCurrentMemberId();
  const [message, setMessage] = useState('');

  const uploadFailedMailto = useCelebrationUploadErrorEmailMailto(celebrationId, memberId);

  useEffect(() => {
    const selectedMessage = getRandomMessage();
    setMessage(selectedMessage);
  }, []);

  const celebration = useCelebration(celebrationId);
  const templateDetails = useEntity(celebration?.['celebration-template']);

  if (uploadError) {
    return (
      <Box p={4} pt={0} maxWidth="100vw">
        <Heading variant="headings.h1" fontSize={4} pb={4}>
          Oops. That didn’t work 🙁.
        </Heading>
        <Heading variant="headings.h6" fontSize={4}>
          Would you please try again? If it fails a second time, please email us at{' '}
          <Button as={Link} variant="link" target="_blank" href={uploadFailedMailto(uploadError)}>
            help@celebrate.buzz
          </Button>{' '}
          and we will help you with this issue.
        </Heading>
        {children}
        <Button as="primary" mt={4} onClick={tryAgain}>
          Try Again
        </Button>
      </Box>
    );
  }

  return (
    <Box p={4} pt={0} maxWidth="100vw">
      <Heading variant="headings.h1" fontSize={4} pb={4}>
        Uploading{files.length > 1 ? ` clip ${clipsUploading} of ${files.length}` : '.'} <br />
        Please don&rsquo;t close this screen until upload is complete.
      </Heading>
      {templateDetails?.['invitation-show-awesome-message'] && (
        <Heading variant="headings.h6" fontSize={4}>
          {templateDetails?.kind === 'question_and_answer' ? message : 'This will be so awesome!'}
        </Heading>
      )}
      <ProgressTracker progress={mainProgress} />
      <Progress percentComplete={mainProgress} />
      {children}
    </Box>
  );
}

function SubmitClipDialog({
  files,
  type,
  onDone,
  isFromVideoGuestBookInvitation,
  questionId,
  celebrationId,
  userAddedDetails,
  userAddedName,
  fileName = null,
  isRecordVideoSubmitted,
}) {
  const requestedRef = useRef(false);
  const name = fileName || useCurrentMemberName();

  const { asPath } = useRouter();
  const isPublic = asPath.includes('/invitations/');

  const [clipsUploading, setClipsUploading] = useState(0);
  const [, setMediacurrentlyUploading] = useVideoUploadContext();

  const [uploadError, setuploadError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainProgress, setProgress] = useState(0);
  const [uploadingMediaId, setUploadingMediaId] = useState(null);
  const [failedUploadFile, setFailedUploadFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(null);

  const { data: updatedData } = useGETClipSWR(isPublic ? null : uploadingFile, celebrationId);

  useEffect(() => {
    if (updatedData) {
      const {
        data: {
          data: { type: updatedDataType, id },
        },
      } = updatedData;
      uploadedFiles.push({ type: updatedDataType, id, celebrationId });
    }
  }, [updatedData]);

  useEffect(() => {
    const interval = setInterval(async function () {
      // method to be executed;
      if (uploadingMediaId) await ax().patch(`/v3/media/${uploadingMediaId}/touch`);
    }, UploadInProgressTrigger);
    if (!uploadingMediaId) return clearInterval(interval);

    return () => clearInterval(interval);
  }, [uploadingMediaId]);

  const isSafariUser = useIsSafariUser();

  const createNewUser = useCreateNewAccount();

  const uppy = useUppy(() => {
    let allowedFileTypes = ['image/*', 'video/*'];
    if (isSafariUser) allowedFileTypes = null;
    else if (type) allowedFileTypes = [`${type}/*`];
    return new Uppy({
      meta: {},
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: null,
        allowedFileTypes,
      },
      autoProceed: true,
    });
  });

  async function createMultipartUpload(file) {
    /* eslint-disable no-param-reassign */
    file.type = setFileType(file.type);
    const originalFileName = file.name.split('->')[0];
    const filename = setFileName(originalFileName, file.type);

    let token = store.get('token');
    if (!token) {
      token = await createNewUser(userAddedDetails);
    } else if (token.includes('token=')) token = token.replace('token=', '');

    const mainName = userAddedDetails
      ? `${userAddedDetails['first-name']} ${userAddedDetails['last-name']}`
      : name;

    const clipAttributes = {
      filename,
      metadata: { name: filename, type: file.type },
      type: file.type,
      token,
      celebration_id: celebrationId,
    };
    if (questionId) clipAttributes.celebration_celebration_topic_id = questionId;

    if (fileName && !isFromVideoGuestBookInvitation) clipAttributes.position_beginning = true;

    clipAttributes.source = 'video_editor';
    if (isPublic) {
      clipAttributes.source = 'invitation';
      clipAttributes.clip_name = fileName ? originalFileName : mainName;
    }
    if (!isPublic && userAddedName) clipAttributes.clip_name = userAddedName;
    try {
      const { duration } = await getMetadata(file.data);
      clipAttributes.duration = duration * 1000;
    } catch (err) {
      store.set('sentryError', `${err} /SubmitClipDialog Component and in GetMetaData function`);
    }

    const tokenResponse = await Axios.post(
      `${baseURL}s3/multipart${window.location.search}`,
      clipAttributes,
      {
        headers: {
          'X-LIFETALES-CLIENT': 'web-celebrate-client',
          'X-CELEBRATE-REFERER': window?.location?.href,
        },
      }
    );
    const { status, data: multipartData, error } = tokenResponse;
    if (status === 200) {
      getIDFromKey(multipartData.key);
      const mediaId = getMediaIdFromUploadKey(multipartData.key);
      setUploadingMediaId(mediaId);
      const {
        data: {
          data: { attributes },
        },
      } = await ax().get(`/v2/media/${mediaId}`);
      if (isFromVideoGuestBookInvitation)
        uploadedFiles.push({
          id: attributes['owner-id'],
          type: `celebration_${attributes.type.toLowerCase()}_clips`,
          celebrationId,
        });
      setUploadingFile({
        id: attributes['owner-id'],
        type: `celebration-${attributes.type.toLowerCase()}-clips`,
      });

      if (isRecordVideoSubmitted && setMediacurrentlyUploading) {
        setMediacurrentlyUploading({
          id: attributes['owner-id'],
          type: `celebration-${attributes.type.toLowerCase()}-clips`,
        });
      }

      sendTrackingEvent(`${file.type.split('/')[0]}_upload_started`);
      store.set('file-type', file.type.split('/')[0]);
      return multipartData;
    }

    store.remove('Media Uploading');
    store.set('sentryError', `${error} /SubmitClipDialog Component After Multipart Request`);
    return null;
  }

  async function uploadSuccess(file) {
    sendTrackingEvent(`${file.meta.type.split('/')[0]}_upload_completed`);
    setUploadingFile(null);
    setUploadingMediaId(null);
    clearLocalStorageItems();
    if (isPublic) {
      postEventDetails(`celebration_invitation_thankyou`, {
        attributes: {
          'subject-type': 'Celebration',
          'subject-id': celebrationId,
        },
      });
    }
    filteredFiles = filteredFiles.filter((fi) => fi.name !== file.data.name);
    if (filteredFiles.length === 0) {
      setClipsUploading([]);
      setLoading(false);
      onDone(uploadedFiles);
      uploadedFiles = [];
      store.remove('Media Uploading');
      uppy.close();
    } else {
      try {
        setProgress(0);
        setClipsUploading(files.length - filteredFiles.length + 1);
        let mainName = name;
        if (userAddedDetails)
          mainName = `${userAddedDetails['first-name']} ${userAddedDetails['last-name']}`;

        if (fileName) mainName = filteredFiles[0].name;
        count += 1;
        uppy.addFile({
          name: `${mainName || filteredFiles[0].name}->${count}`,
          type: filteredFiles[0].type,
          data: filteredFiles[0],
        });
      } catch (error) {
        store.set(
          'sentryError',
          `${error} /SubmitClipDialog Component and in upload-success function`
        );
      }
    }
  }

  async function uploadProgress(file, progress) {
    const currProgress = (progress.bytesUploaded / progress.bytesTotal) * 100;
    setProgress(currProgress);
    sendTrackingEvent(`${file.meta.type.split('/')[0]}_part_uploaded`, {
      context: `upload progrees ${currProgress}`,
    });
  }

  async function uppyUploadError(file, error) {
    setUploadingMediaId(null);
    setUploadingFile(null);
    setuploadError(error);
    setLoading(false);
    setFailedUploadFile(file);
  }

  async function addFileToUppy() {
    try {
      requestedRef.current = true;
      filteredFiles = files;
      const mainName = userAddedDetails
        ? `${userAddedDetails['first-name']} ${userAddedDetails['last-name']}`
        : name;
      setLoading(true);
      setClipsUploading(1);
      uppy.addFile({
        name: mainName || '',
        type: files[0].type,
        data: files[0],
      });
    } catch (error) {
      store.set(
        'sentryError',
        `${error} /SubmitClipDialog Component and in addFileToUppy function`
      );
    }
  }

  useEffect(() => {
    if (!requestedRef.current && (name || userAddedDetails)) {
      addFileToUppy();
    }
  }, [name, userAddedDetails]);

  useEffect(() => {
    if (loading) store.set('Media Uploading', true);
    else store.remove('Media Uploading');
  }, [loading]);

  const tryAgain = () => {
    setLoading(true);
    setuploadError(null);
    uppy.cancelAll();
    retryUppyUpload();
    sendTrackingEvent(`${failedUploadFile.type.split('/')[0]}_upload_retry`);
    uppy.addFile({
      name: failedUploadFile.name,
      type: failedUploadFile.type,
      data: failedUploadFile.data,
    });
  };

  return (
    <Box>
      <UppyComponent
        uppy={uppy}
        createMultipartUpload={createMultipartUpload}
        uploadSuccess={uploadSuccess}
        uploadProgress={uploadProgress}
        uploadError={uppyUploadError}
        tryAgain={uploadError ? tryAgain : undefined}
      >
        <RenderUploader
          celebrationId={celebrationId}
          uploadError={uploadError}
          mainProgress={mainProgress}
          clipsUploading={clipsUploading}
          files={files}
          tryAgain={tryAgain}
        >
          <StatusBar uppy={uppy} hideUploadButton hideAfterFinish={false} />
        </RenderUploader>
      </UppyComponent>
    </Box>
  );
}

export default SubmitClipDialog;
