import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Box, Button, Flex, Text, Heading } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useOnClickOutside from 'use-onclickoutside';
import { uniq, uniqBy } from 'lodash';
import { FiCheckCircle } from 'react-icons/fi';
import { MdSave, MdClear } from 'react-icons/md';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import { Label, Input } from '@rebass/forms';
import Grid from '@/components/app/Grid';
import TextField from '@/components/app/formik/TextField';
import UnorderedList from '@/components/UnorderedList';
// import { FaInfoCircle } from 'react-icons/fa';
import { useRelatedEntity } from '@/lib/entities';
import { celebrateDomainAddress, CELEBRATE_SMTP_SECURE_TOKEN } from '@/lib/constants';
import {
  useCelebration,
  useCelebrationInRoute,
  useMobileCheckHook,
  usePublicCelebrationVideoURL,
} from '@/hooks/celebration';
import { useCurrentMemberName, useCurrentMemberEmail } from '@/hooks/member';
import { ax } from '@/lib/api';
import { usePatchClip } from '@/hooks/api';
// eslint-disable-next-line import/no-cycle
import { FinalVideoPreviewPage } from '@/pages/dashboard/[id]/final-video';
import Avatar from '../app/Avatar';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';
import TextAreaField from '../app/formik/TextAreaField';

import AnimatedDialog from '../app/AnimatedDialog';
import SubmitButton from '../app/formik/SubmitButton';
import CheckBox from '../app/CheckBox';
import { useCheckIfQnAIdea, useCheckIfVideoGuestBook } from '../CelebrationForm/hooks';

function SendEmailForm({ emails, from, subject, Body, onDismiss }) {
  const createConfirmation = useCreateConfirmation();
  const [toEmails, setToEmails] = useState(emails);
  const [error, setError] = useState(null);
  const [fieldError, setFieldError] = useState(null);

  const handleSubmit = useCallback(
    async (attributes, { setSubmitting }) => {
      try {
        if (toEmails.length === 0) {
          setFieldError('Please add valid address');
          return false;
        }
        setError(null);
        const { From, Body: updatedBody } = attributes;
        setSubmitting(true);
        const messages = await Promise.all(
          toEmails.map((toEmail) =>
            window.Email.send({
              SecureToken: CELEBRATE_SMTP_SECURE_TOKEN,
              port: 465,
              secure: true,
              To: toEmail,
              From: celebrateDomainAddress,
              ReplyAddress: From,
              Subject: subject,
              Body: updatedBody.replaceAll('\n', '<br/>'),
              IsBodyHtml: true,
            })
          )
        );
        const emailsResponse = messages.map((message, index) => {
          if (message.toLowerCase() === 'ok') return 'success';
          return toEmails[index];
        });
        if (emailsResponse.every((resp) => resp === 'success')) {
          createConfirmation({ icon: FiCheckCircle, message: 'Email Sent' });
          onDismiss();
        } else {
          const failedEmails = emailsResponse.filter((resp) => resp !== 'success');
          setError(failedEmails.join(', '));
        }
        setSubmitting(false);
      } catch (err) {
        setError(err);
      }
      return null;
    },
    [toEmails]
  );

  function isInList(email) {
    return toEmails.includes(email);
  }

  function isEmail(email) {
    /* eslint-disable no-useless-escape */
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  function isValid(email) {
    let newFieldError = null;

    if (isInList(email)) {
      newFieldError = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      newFieldError = `${email} is not a valid email address.`;
    }

    if (!email) {
      newFieldError = `Please ener valid email`;
    }
    if (newFieldError) {
      setFieldError(newFieldError);
      return false;
    }
    setFieldError(null);
    return true;
  }

  return (
    <Formik
      initialValues={{ To: '', From: from || '', Body }}
      validationSchema={Yup.object().shape({
        From: Yup.string().email().required(),
        Body: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting, handleChange, setFieldValue }) => (
        <Form style={{ margin: '20px' }}>
          <Box pb={4}>
            <Label pb={1}>To*</Label>
            {toEmails.map((item) => (
              <div className="tag-item" key={item}>
                <span className="span">{item}</span>
                <button
                  type="button"
                  className="button"
                  onClick={() => setToEmails(toEmails.filter((e) => e !== item))}
                >
                  &times;
                </button>
              </div>
            ))}
            {/* <TextField name="To" placeholder="Type or paste email addresses and press `Enter`..." pb={2} /> */}
            <Input
              name="To"
              placeholder="Type or paste email addresses and press `Enter`..."
              autoFocus={false}
              tabIndex={-1}
              value={values.To}
              sx={{ borderColor: fieldError ? 'error' : 'border' }}
              onChange={handleChange}
              onKeyDown={(e) => {
                const { value } = e.target;
                if (['Enter', 'Tab', ','].includes(e.key)) {
                  e.preventDefault();
                  if (isValid(value)) {
                    setFieldValue('To', '');
                    setToEmails([...toEmails, value]);
                  }
                } else {
                  setFieldValue('To', value);
                }
              }}
            />
            <Text color="error" p={1} fontSize={1}>
              {fieldError}
            </Text>
          </Box>
          <TextField name="From" label="From*" pb={1} />
          <TextAreaField pt={4} label="Body*" name="Body" />
          {error && (
            <Box bg="pinks.1" p={2} mb={2}>
              <Heading variant="headings.h5" color="pinks.4">
                Failed to send email to {error}
              </Heading>
            </Box>
          )}
          <SubmitButton
            variant="primary"
            minWidth="30%"
            disabled={toEmails.length === 0 || isSubmitting || fieldError}
          >
            Send Email
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

export function RenderForm({
  email,
  name,
  artistName = null,
  isFromMobile,
  handleSubmit,
  onDismiss,
}) {
  return (
    <Formik
      initialValues={{ email: email || '', name: name || '', artist: artistName || '' }}
      validationSchema={
        artistName !== null
          ? Yup.object().shape({
              name: Yup.string(),
              artist: Yup.string(),
            })
          : Yup.object().shape({
              email: Yup.string().email().required(),
              name: Yup.string().required(),
            })
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form style={{ margin: '0px 0px 20px 0px' }}>
          <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            bg="lightGreys.3"
            sx={{
              borderRadius: '8px',
              p: 2,
              flexDirection: isFromMobile && 'column',
            }}
          >
            {name && (
              <TextField
                width={
                  isFromMobile || (email === undefined && artistName === null) ? '100%' : '39%'
                }
                name="name"
                label="Name"
                pb={0}
              />
            )}
            {email !== undefined && (
              <TextField
                width={isFromMobile || !name ? '100%' : '39%'}
                name="email"
                label="Email"
                pb={0}
              />
            )}
            {artistName !== null && (
              <TextField
                width={isFromMobile ? '100%' : '39%'}
                name="artist"
                label="Artist Name"
                pb={0}
              />
            )}
            <Flex
              justifyContent={isFromMobile ? 'center' : 'flex-end'}
              mt={isFromMobile && 1}
              ml={!isFromMobile && 1}
            >
              <SubmitButton
                variant="primary"
                disabled={isSubmitting}
                height={52}
                p={1}
                mr={1}
                bg="pinks.4"
              >
                <Text as={MdSave} size={36} />
              </SubmitButton>
              {onDismiss && (
                <Button
                  justifySelf="end"
                  type="button"
                  onClick={onDismiss}
                  disabled={isSubmitting}
                  bg="purples.7"
                  p={1}
                  height={52}
                >
                  <Text as={MdClear} size={36} />
                </Button>
              )}
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

function RenderRecepient({ id, name, email, thumbnail, type, active, onSelect, onRefresh }) {
  const createConfirmation = useCreateConfirmation();

  const [isEditing, setIsEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    query: { id: celebrationId },
  } = useRouter();
  const clipType = type
    .replace(/\.?([A-Z]+)/g, function (x, y) {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, '');
  const [patchClip] = usePatchClip({ id, type: `${clipType}s`, celebrationId });
  const isFromMobile = useMobileCheckHook();

  const initials = useMemo(
    () =>
      name
        ? name
            .split(' ')
            .map((word) => word[0])
            .join('')
        : '',
    [name]
  );

  const handleSubmit = useCallback(async (attributes, { setSubmitting }) => {
    try {
      const { data, status } = await patchClip({
        'contributor-email': attributes.email,
        'contributor-name': attributes.name,
      });
      createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
      setSubmitting(false);
      if (status === 200) {
        onRefresh({
          id: data.data.id,
          name: data.data.attributes['contributor-name'],
          email: data.data.attributes['contributor-email'],
        });
        setIsEdit(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Box mb={2}>
      <Grid
        gridTemplateColumns={isFromMobile ? '1fr' : '1fr max-content'}
        gridGap={isFromMobile ? 1 : 3}
        p={1}
        style={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#E9E9E9',
          borderRadius: '10px',
          backgroundColor: 'greys.0',
        }}
      >
        <Flex py={1} alignItems="center" mr={1} sx={{ wordWrap: 'break-word' }}>
          <CheckBox
            active={active}
            disabled={!email || isSubmitting}
            onClick={async () => {
              if (email) {
                setIsSubmitting(true);
                const { status } = await patchClip({ notify: !active });
                if (status === 200 || status === 201) {
                  setIsSubmitting(false);
                  onSelect(id);
                }
              } else
                createConfirmation({ message: 'Please add email address', delay: 1, duration: 4 });
            }}
          />
          <Avatar
            opacity={active ? 1 : 0.5}
            src={thumbnail}
            initials={initials}
            size={100}
            height={64}
            minWidth={90}
            borderRadius="10px"
            mr={1}
          />
          <Box opacity={active ? 1 : 0.5}>
            <Text fontSize={20} lineHeight="24px" fontWeight={600}>
              {name} {!email && '⚠️'}
            </Text>
            <Text
              fontSize={16}
              lineHeight="24px"
              fontWeight={400}
              color="pinks.4"
              sx={{
                cursor: !email ? 'pointer' : 'default',
              }}
              onClick={() => !email && setIsEdit(true)}
            >
              {email || 'Add email address'}
            </Text>
          </Box>
        </Flex>
        <Button
          variant="primary"
          opacity={active ? 1 : 0.5}
          fontSize={16}
          lineHeight="24px"
          fontWeight={400}
          bg="pinks.4"
          px={isFromMobile ? 1 : 2}
          height={52}
          alignSelf="center"
          py={1}
          onClick={() => setIsEdit(true)}
        >
          EDIT
        </Button>
      </Grid>
      {isEditing && (
        <RenderForm
          isFromMobile={isFromMobile}
          celebrationId={celebrationId}
          email={email}
          name={name}
          handleSubmit={handleSubmit}
          onDismiss={() => setIsEdit(false)}
        />
      )}
    </Box>
  );
}

const celebrationVideoEmailMailto = ({ emails, videoLink, subject }) => {
  const title = `The video for ${subject}`;

  const body = `Thanks again for your contribution to the video for ${subject}! Here’s a link to the final video: ${videoLink}`;

  const mailto = `mailto:${emails}?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
    body
  )}`;
  return mailto;
};

function CelebrationLinks({ focusTimer }) {
  const isQnATemplate = useCheckIfQnAIdea();
  const isVideoGuestBook = useCheckIfVideoGuestBook();
  const celebration = useCelebrationInRoute();
  const { width } = useWindowSize();
  const [isOpen, setOpen] = useState(false);
  const [isOpenWarning, setOpenWarning] = useState(false);
  const [isSendEmail, setSendEmail] = useState(false);

  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [allProcessedClips, setAllProcessedClips] = useState([]);
  const [allContributorsClips, setAllContributorsClips] = useState([]);
  const name = useCurrentMemberName();
  const currentUserEmail = useCurrentMemberEmail();
  const isFromMobile = useMobileCheckHook();
  const id = celebration?.id;
  const { 'for-whom': subject } = useCelebration(id) || {};
  const publicVideoLink = usePublicCelebrationVideoURL(celebration?.slug);

  useOnClickOutside(ref, () => {
    if (isOpenWarning) {
      setOpenWarning(false);
    }
  });

  async function GetData() {
    setLoading(true);
    const { data, status } = await ax().get(`/v3/celebrations/${id}/contributors`);
    if (status === 200) {
      const mainClips = data.data.filter((c) => c.attributes.type === 'CelebrationVideoClip');
      const contributorClips = [];
      const producerClips = [];

      mainClips.forEach((cli) => {
        if (cli.attributes['author-id'] === celebration?.creator?.id) {
          producerClips.push({
            id: cli.id,
            name: cli.attributes['contributor-name'],
            email: cli.attributes['contributor-email'],
            author: cli.attributes['author-id'],
            active: cli.attributes.notify && cli.attributes['contributor-email'],
            type: cli.attributes.type,
            thumbnail:
              cli.attributes['giphy-gif-url'] ||
              cli.attributes['media-thumbnail'] ||
              cli.attributes['media-asset-url'],
          });
        } else {
          contributorClips.push({
            id: cli.id,
            name: cli.attributes['contributor-name'],
            email: cli.attributes['contributor-email'],
            author: cli.attributes['author-id'],
            active: cli.attributes.notify && cli.attributes['contributor-email'],
            type: cli.attributes.type,
            thumbnail:
              cli.attributes['giphy-gif-url'] ||
              cli.attributes['media-thumbnail'] ||
              cli.attributes['media-asset-url'],
          });
        }
      });

      setAllContributorsClips(uniqBy(contributorClips, 'email'));
      setAllProcessedClips(producerClips);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (id) GetData();
  }, [id]);

  const video = useRelatedEntity({ id, type: 'celebrations', relation: 'final-video' });
  const filteredProducersClips = allProcessedClips.filter((author) => author.active);
  const filteredContributorsClips = allContributorsClips.filter((author) => author.active);

  return (
    <Box pb={isFromMobile ? 2 : 4}>
      <AnimatedDialog
        contentProps={{
          width: '90vw',
          maxWidth: 640,
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
        isOpen={isOpen || isSendEmail}
        onDismiss={() => {
          setOpen(null);
          setSendEmail(false);
        }}
      >
        <Box flex={1}>
          {isSendEmail ? (
            <Box>
              <SendEmailForm
                emails={uniq(
                  [...filteredContributorsClips, ...filteredProducersClips]
                    .filter((e) => !!e.email)
                    .map((cli) => cli.email.toLowerCase())
                )}
                from={currentUserEmail}
                subject={subject}
                Body={`Thanks again for your contribution to the video for ${subject}.
                  
Here’s a link to the final video!
                  
${publicVideoLink}
                  
Enjoy!
                  
${name}`}
                onDismiss={() => setSendEmail(false)}
              />
            </Box>
          ) : (
            <Box textAlign="left" p={3} pt={0}>
              <Heading variant="headings.h2" color="pinks.4">
                {isOpen}
              </Heading>
              {isOpen === 'Sharing Your Video' ? (
                <Text
                  pt={2}
                  color="lightBody"
                  fontSize={1}
                  fontWeight="normal"
                  lineHeight="28.15px"
                >
                  Celebrate does not automatically distribute any links to the video; our experience
                  is that the video creators prefer to make this decision.
                  <br />
                  <br />
                  There are three ways you can share this video:
                  <br />
                  <UnorderedList
                    items={[
                      `Download the video and share it.`,
                      `Copy the video link by pressing the button, copy the link into a text or email message, then send that text/email to the gift recipient and anybody else of your choosing.`,
                      'Use the list of contributors below to open an email with the link attached, ready to send to everyone you selected.',
                    ]}
                  />
                </Text>
              ) : (
                <Text
                  pt={2}
                  color="lightBody"
                  fontSize={1}
                  fontWeight="normal"
                  lineHeight="28.15px"
                >
                  You can add or subtract people by checking or unchecking each box. Then tap the
                  email video link button below. When you do that, a pre-filled email will pop up
                  with:
                  <br />
                  <UnorderedList
                    items={[
                      'the checked email addresses in the "To" field',
                      'a "from" address',
                      'our suggested text and',
                      'a link to the video.',
                    ]}
                  />
                  <br />
                  You can then send &quot;as is&quot; or edit it before sending.
                  <br />
                  You can also share with people by copying the link up above and sending it via any
                  messaging app, email, social media or carrier pigeon 😄
                </Text>
              )}
            </Box>
          )}
        </Box>
      </AnimatedDialog>
      {/* <Box
        sx={
          isVideoGuestBook && {
            background: '#FFFFFF',
            border: '1px solid rgba(0,0,0,0.1)',
            borderRadius: '6px',
            padding: '16px',
          }
        }
      > */}
      <Flex />
      <Flex pb={2} flexDirection="column">
        <Heading variant="headings.h5" fontSize={20} pb={2}>
          Share and Download
        </Heading>
        <Text lineHeight="150%" fontStyle="normal">
          You can download the video to your hard drive after it&apos;s been processed.
          <br />
          <br />
          You also have the option of sharing a link to the video with friends and family. The
          choice is yours. We do not automatically share it with anyone.
          <br />
          <br />
          <b>Sharing:</b> After processing, you&apos;ll see a button for Copy Link. Tap it and paste
          the link into your email or messaging app.
          <br />
          <br />
        </Text>
      </Flex>
      {(celebration?.state === 'completed' ||
        celebration?.state === 'creating_video' ||
        celebration?.state === 'submitted_to_create_video') && (
        <FinalVideoPreviewPage focusTimer={focusTimer} />
      )}
      {isVideoGuestBook === false && isQnATemplate === false && (
        <Flex pb={2} flexDirection="column">
          <Heading variant="headings.h5" fontSize={20} pb={2} mt={2}>
            Sharing Requests
          </Heading>
          <Text mb={2}>
            Contributors who said they wanted to see the final video have provided their email
            addresses. <br />
            <br />
            But the option is still yours.
            <br />
            <br />
            We&apos;ve made it really easy for you. Check the boxes beside the people you&apos;d
            like to receive an email. You can also add or edit a Contributor&apos;s email address if
            you wish.
            <br />
            <br />
            When you&apos;re ready, tap the <b>Email Video Link</b> button below.
            <span onClick={() => setOpen('Sharing with contributors')} className="learnMore">
              Learn More
            </span>
            <br />
            {/* <br />
                Add whomever you want to this list and we will send the video to their emails. */}
          </Text>
          <Box mb={2}>
            {allContributorsClips.length > 0 && (
              <Box
                sx={{
                  background: '#FFFFFF',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  borderRadius: '6px',
                  p: 2,
                }}
              >
                {allContributorsClips.length > 0 && (
                  <Flex alignItems="center" sx={{ borderBottom: '1px solid #CBD5E0', pb: 2 }}>
                    {/* <Text size={36} mr={10} sx={{ borderRadius: '6px' }} p="4px" backgroundColor="#E1E1FA" color="purples.7" as={MdPeople} /> */}
                    <Heading
                      className="gradientHeading"
                      color="rgb(39, 41, 41)"
                      variant="headings.h3"
                      fontSize="24px !important"
                    >
                      Contributor Uploads
                    </Heading>
                  </Flex>
                )}

                {allContributorsClips.map((author) => (
                  <RenderRecepient
                    {...author}
                    onSelect={(clip) => {
                      setAllContributorsClips(
                        allContributorsClips.map((clonedClip) => {
                          const cli = clonedClip;
                          if (cli.id === clip) {
                            cli.active = !cli.active;
                          }
                          return cli;
                        })
                      );
                    }}
                    onRefresh={(clip) => {
                      setAllContributorsClips(
                        allContributorsClips.map((clonedClip) => {
                          const cli = clonedClip;
                          if (cli.id === clip.id) {
                            cli.name = clip.name;
                            cli.email = clip.email;
                          }
                          return cli;
                        })
                      );
                    }}
                  />
                ))}

                {/* {allProcessedClips.length > 0 && (
                      <Flex
                        alignItems="center"
                        sx={{
                          borderBottom: '1px solid #CBD5E0',
                          pb: 2,
                          mb: 2,
                          mt: allContributorsClips.length > 0 && 4,
                        }}
                      >
                        <Heading
                          className="gradientHeading"
                          color="rgb(39, 41, 41)"
                          variant="headings.h3"
                          fontSize="24px !important"
                        >
                          My Uploads
                        </Heading>
                      </Flex>
                    )}
                    {allProcessedClips.map((author) => (
                      <RenderRecepient
                        {...author}
                        onSelect={(clip) => {
                          setAllProcessedClips(
                            allProcessedClips.map((cli) => {
                              if (cli.id === clip) {
                                cli.active = !cli.active;
                              }
                              return cli;
                            })
                          );
                        }}
                        onRefresh={(clip) => {
                          setAllProcessedClips(
                            allProcessedClips.map((cli) => {
                              if (cli.id === clip.id) {
                                cli.name = clip.name;
                                cli.email = clip.email;
                              }
                              return cli;
                            })
                          );
                        }}
                      />
                    ))} */}
              </Box>
            )}
          </Box>

          <Box
            ref={ref}
            py={1}
            mt="-100px"
            bg="white"
            ml="0px"
            maxWidth={250}
            sx={{
              position: 'absolute',
              display: isOpenWarning ? 'block' : 'none',
              boxShadow: 'medium',
              borderRadius: 'medium',
              borderSize: '3px',
              borderStyle: 'solid',
              borderColor: 'greys.2',
              zIndex: 10,
            }}
          >
            <Flex py={1} px={1} textAlign="center" alignItems="center">
              {/* <Text size={48} mr={2} as={AiOutlineWarning} /> */}
              <Text>
                You will be able to email the video link after the video has been processed
              </Text>
            </Flex>
          </Box>
          <Button
            // variant="special"
            backgroundColor="#7B33FB"
            width={width < 500 ? '80vw' : '250px'}
            // mb={isFromMobile && 5}
            disabled={
              [...filteredContributorsClips, ...filteredProducersClips].length === 0 || loading
            }
            className={loading && 'loading'}
            fontWeight="normal"
            sx={{
              display: isQnATemplate || isVideoGuestBook ? 'none' : 'block',
              opacity: !video || celebration?.state !== 'completed' ? 0.5 : 1,
              cursor: !video || celebration?.state !== 'completed' ? 'not-allowed' : 'pointer',
              borderRadius: '40px',
            }}
            onMouseEnter={() =>
              !video || celebration?.state !== 'completed' ? setOpenWarning(true) : null
            }
            onMouseLeave={() =>
              !video || celebration?.state !== 'completed' ? setOpenWarning(false) : null
            }
            onClick={async () => {
              if (!video || celebration?.state !== 'completed') setOpenWarning(true);
              else if (isFromMobile)
                window.location.href = celebrationVideoEmailMailto({
                  emails: uniq(
                    [...filteredContributorsClips, ...filteredProducersClips]
                      .filter((e) => !!e.email)
                      .map((cli) => cli.email.toLowerCase())
                  ).join(','),
                  subject,
                  name,
                  videoLink: publicVideoLink,
                });
              else setSendEmail(true);
            }}
          >
            {loading ? 'Loading' : 'Email Video Link'}
          </Button>
        </Flex>
      )}
      {/* </Box> */}
    </Box>
  );
}

export default CelebrationLinks;
