import React from 'react';

// eslint-disable-next-line react/prop-types
function ItalicImage({ fill = '#414143' }) {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0V3H6.21L2.79 11H0V14H8V11H5.79L9.21 3H12V0H4Z" fill={fill} />
    </svg>
  );
}

export default ItalicImage;
