import useImage from 'use-image';
import React, { useRef } from 'react';
import { Image as KonvaImage, Group, Transformer } from 'react-konva';
import { useLongPress } from 'react-use';
import Cancel from '@/components/app/cancel.svg';

function IndividualSticker({
  image,
  onDelete,
  onDragEnd,
  onWidthChange,
  isSelected,
  onSelect,
  containerWidth,
  containerHeight,
}) {
  const imageRef = useRef(null);
  const trRef = React.useRef();
  // const [stickerWidth, setStickerWidth] = useState(image.width)
  const [stickerImage] = useImage(image.src, 'Anonymous');
  const [deleteImage] = useImage(Cancel, 'Anonymous');

  const onLongPress = () => {};

  // eslint-disable-next-line no-param-reassign
  image.resetButtonRef.current = () => {};
  const longPressEvent = useLongPress(onLongPress, { delay: 200 });

  const stickerWidth = image.width;
  const stickerHeight = image.height;
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([imageRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    // if (image.width === 548) {
    //   onDelete()
    // }
  }, [isSelected]);

  return (
    <Group
      draggable
      x={image.x}
      y={image.y}
      onDragEnd={(event) => {
        onDragEnd(event);
      }}
      onClick={onSelect}
      onTap={onSelect}
    >
      <KonvaImage
        ref={imageRef}
        width={stickerWidth}
        height={stickerHeight}
        image={stickerImage}
        {...longPressEvent}
      />
      {/* {isSelected && (
        )} */}
      {isSelected && (
        <>
          <KonvaImage
            onTouchStart={onDelete}
            onClick={onDelete}
            image={deleteImage}
            width={25}
            height={30}
            offsetX={-image.width}
            offsetY={5}
          />
          <Transformer
            ref={trRef}
            rotateEnabled={false}
            borderDash={[2, 2]}
            padding={-5}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (
                newBox.width < 50 ||
                newBox.height < 50 ||
                newBox.width > containerWidth - 50 ||
                newBox.height > containerHeight - 50
              ) {
                return oldBox;
              }
              onWidthChange(newBox);
              return newBox;
            }}
            enabledAnchors={['bottom-left', 'bottom-right', 'middle-right', 'middle-left']}
          />
        </>
      )}
    </Group>
  );
}

export default IndividualSticker;
