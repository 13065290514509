import React, { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ReactSortable } from 'react-sortablejs';
import isEqual from 'fast-deep-equal';
import store from 'store';
import { useResourceRequest } from '@/hooks/api';
import { EntityCacheContext } from '@/lib/entities';

const getClipsRequestData = (clips) =>
  clips.map(
    (clip, index) =>
      clip && {
        ...clip,
        id: clip.id,
        position: index + 1,
      }
  );

const checkStatusChanged = (clips, list) =>
  clips.map((clip) => clip.uploadStatus).join('') !==
  list.map((clip) => clip.uploadStatus).join('');

function SoundSortableClips({ clips, children, ...props }) {
  const {
    query: { id },
  } = useRouter();
  const [list, setList] = useState([]);
  const { updateEntities } = useContext(EntityCacheContext);

  const [updateClipList] = useResourceRequest(
    'patch',
    `/v3/celebrations/${id}/celebration_clips/reorder`
  );

  useEffect(() => {
    setList(clips);
  }, [clips]);

  const handleSetList = (next) => {
    const nextRequestData = getClipsRequestData(next);
    if (!isEqual(nextRequestData, next) && !store.get('clipMoving') && !store.get('isDragging')) {
      /* eslint-disable no-param-reassign */
      updateEntities((draft) => {
        nextRequestData.forEach((clip, index) => {
          if (clip) {
            if (draft[clip.type] && draft[clip.type][clip.id]) {
              draft[clip.type][clip.id].position = index + 1;
            }
          }
        });
      });
      setList(nextRequestData);
      updateClipList(null, { celebration_clips: nextRequestData });
    }
  };

  useEffect(() => {
    if (
      (clips.length !== list.length || checkStatusChanged(clips, list)) &&
      !store.get('clipMoving') &&
      !store.get('isDragging')
    ) {
      const nextList = clips.map((clip, index) => ({
        ...clip,
        selected: false,
        chosen: false,
        position: index + 1,
      }));
      setList(nextList);
    }
  }, [clips, list]);

  return (
    <ReactSortable
      delayOnTouchStart
      delay={3}
      animation={150}
      list={list}
      setList={handleSetList}
      {...props}
    >
      {children(list)}
    </ReactSortable>
  );
}

SoundSortableClips.defaultProps = { clips: [] };

export default SoundSortableClips;
