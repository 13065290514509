/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { Box, Text, Flex } from 'rebass';
import { useRouter } from 'next/router';
import { Label } from '@rebass/forms';
import Select from 'react-select';
import { useTheme } from 'emotion-theming';
import { useSubmitCelebration } from '@/components/CelebrationForm/hooks';
import { ReactComponent as FontFamily } from '@/components/app/FontFamily.svg';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import ShowBottomSheet from '../ShowBottomSheet';

function FontSelector({
  name,
  placeholder,
  celebrationAttrName,
  label,
  defaultValue,
  options,
  selectProps,
  fontSize,
  value,
  isFromSubmitForm,
  labelSx,
  background,
  valueIndex,
  menuIsOpen,
  onChange,
  onBlur,
  ...props
}) {
  const { colors, fontSizes } = useTheme();

  const currentValue = options.find((op) => op.value === value);
  // const submitCelebration = useSubmitCelebration();
  // const formik = useFormikContext();

  return (
    <Box pb={5} {...props}>
      {label && !isFromSubmitForm && (
        <Label
          htmlFor={name}
          pb={fontSize === 14 ? 1 : 2}
          justifyContent="center"
          fontSize={fontSize}
          sx={labelSx}
        >
          {label}
        </Label>
      )}
      <Select
        label={label}
        id={name}
        menuIsOpen={menuIsOpen}
        fontSize={fontSizes[1]}
        options={options}
        placeholder={placeholder}
        // {...field}
        {...selectProps}
        styles={{
          option: (provided) => ({
            ...provided,
            fontSize: fontSizes[1],
            borderRadius: '8px',
            backgroundColor: '#fff',
            ':active': {},
            color: colors.black,
          }),
          control: (provided) => ({
            ...provided,
            fontSize: fontSizes[1],
            paddingTop: 8,
            paddingBottom: 8,
            background,
            textAlign: 'left',
            border: '1px solid #D9D9D9',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '8px',
            fontWeight: 400,
            lineHeight: '140%',
            letterSpacing: '-0.308px',
            '&:hover': {
              ...provided['&:hover'],
              border: '2px solid #7B33FB',
            },
          }),
          menu: (provided) => ({
            ...provided,
            color: colors.black, // font color
            zIndex: 15,
            paddingLeft: '8px',
            borderRadius: '8px',
            border: '1px solid #D9D9D9',
            backgroundColor: '#FFF',
          }),
          ...(selectProps.styles || {}),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral20: colors.border,
            primary: 'rgba(99, 105, 239, 0.1)',
            neutral90: colors.body,
            neutral0: colors.lightGreys[3],
          },
        })}
        value={currentValue}
        onChange={onChange}
        onBlur={onBlur}
      />
      {onBlur && (
        <ErrorMessage name={name}>
          {(message) =>
            value ? null : (
              <Text color="error" p={1} pt={2} fontSize={1}>
                {message}
              </Text>
            )
          }
        </ErrorMessage>
      )}
    </Box>
  );
}

function SelectField({ isFromMobileScreen, ...props }) {
  const {
    query: { id },
  } = useRouter();

  const [showPicker, setShowPicker] = useState(false);

  function handleClick() {
    setShowPicker((show) => !show);
  }

  if (isFromMobileScreen) {
    const [selectedLayer, setSelectedLayer] = useLayerSelection();

    const onChange = (opt) => {
      setSelectedLayer((layer) => ({ ...layer, 'font-name': opt.value }));
    };

    return (
      <Box>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          onClick={handleClick}
        >
          <FontFamily />
          <Text className="posterEditiorNavText">Font</Text>
        </Flex>
        <ShowBottomSheet heading="Font" isOpen={showPicker} onClose={handleClick} height="250px">
          <Flex justifyContent="center" mt={2} width="100%">
            <FontSelector
              value={selectedLayer['font-name']}
              onChange={onChange}
              menuIsOpen={showPicker}
              {...props}
            />
          </Flex>
        </ShowBottomSheet>
      </Box>
    );
  }
  const { valueIndex, celebrationAttrName, isFromSubmitForm, name } = props;
  const [field, , helper] = useField(valueIndex !== undefined ? `${valueIndex}.${name}` : name);

  const submitCelebration = useSubmitCelebration();
  const formik = useFormikContext();

  const onChange = async (opt) => {
    helper.setValue(opt.value);
    if (celebrationAttrName) await submitCelebration({ [celebrationAttrName]: opt.value }, id);
    if (isFromSubmitForm) formik.submitForm();
  };
  return (
    <FontSelector onBlur={helper.setTouched} value={field.value} onChange={onChange} {...props} />
  );
}

SelectField.defaultProps = {
  label: '',
  placeholder: 'Make a selection..',
  selectProps: {
    menuPlacement: 'top',
    styles: {},
  },
  fontSize: 2,
};
SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  selectProps: PropTypes.shape({ menuPlacement: PropTypes.string, styles: PropTypes.shape({}) }),
  fontSize: PropTypes.number,
};

export default SelectField;
