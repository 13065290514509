import { Label } from '@rebass/forms';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slider';
import { Box, Text } from 'rebass';

function RangeField({
  icon,
  step,
  name,
  label,
  max,
  min,
  formattedValue,
  placeholder,
  isFromMobile,
  ...props
}) {
  const [field, , helper] = useField(name);

  return (
    <Box pb={isFromMobile ? 2 : 5} {...props}>
      {label && (
        <Label htmlFor={name} pb={2}>
          {label}
        </Label>
      )}
      <Box flex="1" sx={{ '.slider': { height: 16 } }}>
        <Slider
          value={field.value}
          max={max}
          step={step}
          onChange={(value) => helper.setValue(value)}
          className="slider"
          renderThumb={(thumbProps) => (
            <Box
              {...thumbProps}
              sx={{
                bg: 'purples.5',
                height: 16,
                width: 16,
                borderRadius: '50%',
                transform: 'translateY(-50%)',
                top: '50%',
                zIndex: 6,
                transition: 'transform 304ms',
                outline: 'none',
                '&:hover, &:active': {
                  transform: 'translateY(-50%) scale(1.3)',
                },
              }}
            />
          )}
          renderTrack={(trackProps, { index }) => (
            <Box
              {...trackProps}
              bg={index % 2 ? 'greys.3' : 'purples.4'}
              height={8}
              sx={{
                borderRadius: 3,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: index,
              }}
            />
          )}
        />
      </Box>
      <ErrorMessage name={name}>
        {(message) => (
          <Text color="error" p={1} pt={2} fontSize={1}>
            {message}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
}

RangeField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  formattedValue: PropTypes.func,
};

RangeField.defaultProps = { formattedValue: null, placeholder: '', label: '' };

export default RangeField;
