import React, { useEffect, useState, useRef } from 'react';
import { Flex, Text, Box } from 'rebass';
import { useWindowSize } from 'react-use';
import { FiCheckCircle } from 'react-icons/fi';
import { useImmer } from 'use-immer';
import ReactPlayer from 'react-player';
import store from 'store';
import { useEntity } from '@/lib/entities';
import Grid from '@/components/app/Grid';
import VideoPreviewTime from '@/components/VideoPreview/VideoPreviewTime';
import { useClip } from '@/hooks/clip';
import { useUpdateClip } from '@/hooks/mutations';
import { useFrameNow } from '@/hooks/timer';
import { getAspectRatio } from '@/lib/helper';
import TrimSlider from './TrimSlider';
// import SubmitButton from '../app/formik/SubmitButton';
import FormControlsWell from './FormControlsWell';
import CirclePlayButton from '../app/CirclePlayButton';
import AsyncButton from '../app/AsyncButton';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';
import BufferingOverlay from '../VideoPreview/BufferingOverlay';

function VideoTrimmer({ id, isFromMobile }) {
  const [isBuffering, setIsBuffering] = useState(true);
  const [duration, setDuration] = useState(undefined);
  const [isPlaying, setIsPlaying] = useState(false);
  const frameNow = useFrameNow(isPlaying);
  const clip = useClip({ id, type: 'celebration-video-clips' });
  const [value, updateValue] = useImmer([
    clip['trim-start'],
    clip['trim-start'] || 0,
    clip['trim-end'],
  ]);
  const createConfirmation = useCreateConfirmation();

  const totalTime = value[2] - value[0];
  const currentOffsetTime = value[1] - value[0];

  const videoRef = useRef(null);
  const updateClip = useUpdateClip({ id, type: 'celebration-video-clips' });
  const video = useEntity(clip.video);

  const setCurrentTime = (time) => {
    updateValue((draft) => {
      draft.splice(1, 1, time);
    });
  };

  useEffect(() => {
    if (isPlaying && frameNow && videoRef.current) {
      if (videoRef.current.getCurrentTime() >= value[2]) {
        setIsPlaying(false);
      } else {
        setCurrentTime(videoRef.current.getCurrentTime());
      }
    }
  }, [frameNow]);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current?.props.onPlay();
    } else {
      videoRef.current?.props.onPause();
    }
  }, [isPlaying]);

  const handleSliderChange = (nextValue) => {
    updateValue((draft) => {
      nextValue.forEach((val, index) => {
        if (draft[index] !== val) {
          draft.splice(index, 1, val);
        }
      });
    });
    const [, valueTime] = nextValue;
    videoRef.current.seekTo(valueTime, 'seconds');
  };

  const isDirty = clip['trim-start'] !== value[0] || clip['trim-end'] !== value[2];

  const { width } = useWindowSize();
  const vidHeight = getAspectRatio(width);

  return (
    <Box maxWidth={isFromMobile ? '100%' : 700}>
      {/* {duration && ( */}
      <FormControlsWell mb={isFromMobile ? 0 : 2} p={2}>
        <Grid gridGap={4} gridTemplateColumns="max-content 1fr">
          <Flex justifyContent="center">
            <CirclePlayButton
              isPlaying={isPlaying}
              onClick={async () => {
                if (videoRef.current.getCurrentTime() >= value[2]) {
                  videoRef.current.seekTo(value[0], 'seconds');
                  setCurrentTime(value[0]);
                }
                setIsPlaying((state) => !state);
              }}
            />
          </Flex>
          <Box pt={2}>
            <TrimSlider
              value={value}
              min={0}
              max={duration || video?.duration}
              step={0.016}
              onChange={handleSliderChange}
              onClick={() => setIsPlaying(false)}
            />
          </Box>
        </Grid>
        <Grid alignItems="baseline" gridGap={4} gridTemplateColumns="max-content 1fr" pt={0}>
          <Flex mt={isFromMobile && 1}>
            <VideoPreviewTime color="purples.8" seconds={currentOffsetTime} />
            <Text color="purples.8" px={1} fontSize={0}>
              /
            </Text>
            <VideoPreviewTime color="purples.8" seconds={totalTime} />
          </Flex>
          <AsyncButton
            mt={!isFromMobile && 3}
            ml="auto"
            variant={isFromMobile ? 'transparent' : 'special'}
            justifySelf="end"
            disabled={!isDirty}
            onClick={() => {
              updateClip({ 'trim-start': value[0] || null, 'trim-end': value[2] });
              createConfirmation({ icon: FiCheckCircle, message: 'Saved Clip' });
            }}
            sx={
              isFromMobile && {
                fontWeight: 'bold',
                opacity: !isDirty && 0.5,
                cursor: !isDirty ? 'not-allowed' : 'pointer',
                position: 'fixed',
                right: 16,
                top: width < 500 ? 21 : 31,
                zIndex: '3',
                color: 'purples.7',
              }
            }
          >
            Save
          </AsyncButton>
        </Grid>
      </FormControlsWell>
      {/* )} */}
      <BufferingOverlay isBuffering={isBuffering}>
        <Box bg="purples.8">
          <ReactPlayer
            ref={videoRef}
            className="trim-video-wrapper"
            onBuffer={() => setIsBuffering(true)}
            onBufferEnd={() => setIsBuffering(false)}
            onReady={() => {
              setIsBuffering(false);
            }}
            playing={isPlaying}
            playsinline
            stopOnUnmount={false}
            // onPlay={onPlay}
            volume={!clip.volume || clip.volume > 1 ? 1 : clip.volume}
            // onBuffer={onPause}
            progressInterval={0.5}
            url={video.mp4}
            style={{
              height: isFromMobile ? `${vidHeight + 100} !important` : 700,
              width: isFromMobile ? '100%' : 394,
              objectFit: 'contain',
              objectPosition: 'center',
              transform: `rotate(0deg)`,
            }}
            onError={(e) => {
              store.set('sentryError', `${e} /VideoTrimmer Component React-player error`);
            }}
            onDuration={(e) => {
              videoRef.current.seekTo(e, 'seconds');
              setDuration(e);
            }}
          />
        </Box>
      </BufferingOverlay>
    </Box>
  );
}

export default VideoTrimmer;
