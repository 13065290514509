import React from 'react';

function EditCelebration() {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2636 8.46535L18.0726 12.2773H21.144C21.312 12.2743 21.4771 12.3218 21.6179 12.4135C21.7588 12.5052 21.8689 12.637 21.9341 12.7919C21.9993 12.9468 22.0166 13.1177 21.9838 13.2826C21.951 13.4474 21.8696 13.5986 21.75 13.7168L18.9241 16.2313L20.4863 19.821C20.5552 19.9854 20.5707 20.1673 20.5307 20.3409C20.4906 20.5146 20.3969 20.6713 20.263 20.7889C20.129 20.9064 19.9615 20.9789 19.784 20.9961C19.6066 21.0132 19.4283 20.9742 19.2743 20.8845L15.4974 18.7601L11.7205 20.8845C11.5665 20.9742 11.3882 21.0132 11.2108 20.9961C11.0334 20.9789 10.8658 20.9064 10.7319 20.7889C10.5979 20.6713 10.5042 20.5146 10.4642 20.3409C10.4241 20.1673 10.4396 19.9854 10.5085 19.821L12.0707 16.2313L9.24998 13.7168C9.13043 13.5986 9.04899 13.4474 9.01618 13.2826C8.98338 13.1177 9.00069 12.9468 9.06591 12.7919C9.13112 12.637 9.24123 12.5052 9.38206 12.4135C9.52288 12.3218 9.68795 12.2743 9.85599 12.2773H12.9274L14.7364 8.46535C14.8087 8.32517 14.9182 8.20759 15.0529 8.1255C15.1876 8.04342 15.3423 8 15.5 8C15.6577 8 15.8124 8.04342 15.9471 8.1255C16.0818 8.20759 16.1913 8.32517 16.2636 8.46535Z"
        stroke="#606060"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 15C1 16.8385 1.37505 18.659 2.10375 20.3576C2.83244 22.0561 3.9005 23.5995 5.24695 24.8995C6.5934 26.1995 8.19187 27.2307 9.95109 27.9343C11.7103 28.6379 13.5958 29 15.5 29C17.4042 29 19.2897 28.6379 21.0489 27.9343C22.8081 27.2307 24.4066 26.1995 25.7531 24.8995C27.0995 23.5995 28.1676 22.0561 28.8963 20.3576C29.6249 18.659 30 16.8385 30 15C30 13.1615 29.6249 11.341 28.8963 9.64243C28.1676 7.94387 27.0995 6.40053 25.7531 5.1005C24.4066 3.80048 22.8081 2.76925 21.0489 2.06569C19.2897 1.36212 17.4042 1 15.5 1C13.5958 1 11.7103 1.36212 9.95109 2.06569C8.19187 2.76925 6.5934 3.80048 5.24695 5.1005C3.9005 6.40053 2.83244 7.94387 2.10375 9.64243C1.37505 11.341 1 13.1615 1 15Z"
        stroke="#606060"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditCelebration;
