/* eslint-disable import/no-cycle */
import React, { useEffect, useState, memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button } from 'rebass';
// import * as html2canvas from 'html2canvas';
import useSWR from 'swr';
import { FiCheckCircle } from 'react-icons/fi';
import { useEntity, useRemoveEntity } from '@/lib/entities';
import { ax, fetcher, postPrivateEventDetails } from '@/lib/api';
import useCreateMedium from '@/hooks/medium';
import { renderPositionForVGBImage } from '@/lib/helper';
import { useLayerSelection } from '@/pages/dashboard/[id]/poster';
import BufferingOverlay from '../VideoPreview/BufferingOverlay';
import AnimatedDialog from '../app/AnimatedDialog';
import AvatarEditor, { scalePosition } from '../Invitations/SubjectAvatarForm/AvatarEditor';
import { useCreateConfirmation } from '../app/ConfirmationOverlay';
import { cssHandling } from '../Printables/ReusableComponents';
import RenderImageLayer from './RenderImageLayer';
import ConfirmDialog from '../app/ConfirmDialog';
import ImageEditingOptions from './ImageEditingOptions';
import { LoadingBg } from '../app/Loading';
import RotatingText from '../app/RotatingText';

function ImageUploader({
  src,
  scale,
  width,
  height,
  photoScaling,
  onImageSelection,
  setPhotoScalling,
  setScale,
  setFile,
  isFromMobile,
}) {
  const [isEditing, setIsEditing] = useState(!!src);
  const [file, setNewFile] = useState(src);

  return (
    <Box maxWidth="450px" m="auto" mb={['80px', 3]} textAlign="center">
      <AvatarEditor
        onDismiss={isEditing || !src ? null : () => setIsEditing(true)}
        src={isEditing ? file : null}
        setFile={isEditing ? null : setNewFile}
        posterAttributes={{
          position: photoScaling,
          scale,
          aspectRatio: { width, height },
        }}
        onAccept={(val) => {
          const { scale: newScaleValue, position } = val;
          setPhotoScalling({ x: position?.x, y: position?.y });
          setScale(+newScaleValue);
          if (isEditing) {
            onImageSelection(val);
          } else if (file && file.type.includes('image') && !isEditing) {
            onImageSelection(val, file);
            setFile(file);
          }
        }}
      />
      {isEditing && (
        <Button
          width={['80%', '100%']}
          type="button"
          variant="special"
          onClick={() => setIsEditing(false)}
          sx={{
            mt: isFromMobile && 1,
            borderRadius: `6px`,
          }}
        >
          Change Image
        </Button>
      )}
    </Box>
  );
}

function ImageLayer({
  templateId,
  isCurrentLayerSelected,
  isFromMobile,
  celebrationId,
  selectedSide,
  isPreviewOpenForChildren,
  ...props
}) {
  const [file, setFile] = useState(null);
  const onDrop = async (val) => {
    if (val && val[0].type.includes('image')) {
      setFile(val[0]);
    } else {
      // setDropError(`Please add a image file`);
    }
  };
  const { mutate: revalidatePrintables } = useSWR(
    celebrationId ? `/v3/celebrations/${celebrationId}/celebration_printables` : null
  );
  const { left, top, width, height, enabled } = props;
  const imageSrc = enabled ? useEntity({ type: 'images', id: props?.photo?.id }) : null;
  const preview = enabled ? useEntity({ type: 'images', id: props?.['canvas-image']?.id }) : null;
  const [selectedLayer, setSelectedLayer] = useLayerSelection();
  const [createMedium] = useCreateMedium({ type: 'Image' });
  const createConfirmation = useCreateConfirmation();
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [url, setUrl] = useState(null);
  const [src, setSrc] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [scale, setScale] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [photoScaling, setPhotoScalling] = useState(null);
  const uploadStatus = preview?.state;

  useSWR(
    refreshInterval ? `/v3/celebrations/${celebrationId}/celebration_printables` : null,
    fetcher,
    { refreshInterval }
  );

  useEffect(() => {
    if (
      uploadStatus === 'pending_upload' ||
      uploadStatus === 'uploaded' ||
      uploadStatus === 'processing'
    ) {
      setRefreshInterval(3000);
    }
    // TODO: works for happy path. needs error handling
    if (refreshInterval !== null && uploadStatus === 'finished') {
      setRefreshInterval(0);
    }
  }, [uploadStatus, setRefreshInterval]);

  const removeImageEntityReference = useRemoveEntity({
    type: 'images',
    id: props?.photo?.id,
  });

  const removeCanvasEntityReference = useRemoveEntity({
    type: 'images',
    id: props?.['canvas-image']?.id,
  });

  useEffect(() => {
    setUrl(
      previewFile || preview?.['image-versions']?.[isFromMobile ? 9 : 4]?.url || preview?.tmpURL
    );
  }, [preview, previewFile]);

  useEffect(() => {
    setSrc(imageSrc?.['image-versions']?.[4]?.url || imageSrc?.tmpURL);
  }, [imageSrc]);

  useEffect(() => {
    if (props) {
      if (width && left !== null && top !== null)
        setPhotoScalling({
          x: left / (width || 1),
          y: top / (height || 1),
        });
      setScale(props?.scale || 1);
    }
  }, []);

  /* eslint-disable no-unsafe-optional-chaining */
  const onImageSelection = async (val, selectedFile) => {
    const { scale: valScale, position, croppedPostion, croppedBlob = null } = val;
    const attributes = {
      'crop-left': `${croppedPostion?.x}%`,
      'crop-top': `${croppedPostion?.y}%`,
      left: position?.x * selectedLayer?.width,
      top: position?.y * selectedLayer?.height,
      scale: +valScale,
      'crop-width': selectedLayer?.['crop-width'],
      'crop-height': selectedLayer?.['crop-height'],
    };
    if (croppedPostion.width || croppedPostion.height) {
      attributes['crop-width'] = `${croppedPostion.width}%`;
      attributes['crop-height'] = `${croppedPostion.height}%`;
    }
    const photo = selectedLayer?.photo ? { ...selectedLayer?.photo } : {};
    setSubmitting(true);

    if (croppedBlob) {
      const blob = new Blob([croppedBlob], { type: 'image/png' });
      blob.name = 'canvas-clip-image.png';
      setPreviewFile(URL.createObjectURL(blob));
      const imageCanvasClipResponse = await createMedium({
        file: blob,
        attributes: { 'owner-type': 'CelebrationPrintableLayer', 'owner-id': selectedLayer.id },
      });
      const { id: textCanvasClipId } = imageCanvasClipResponse.data.data;
      attributes.canvas_image_id = textCanvasClipId;
    }
    if (selectedFile && selectedLayer) {
      const response = await fetch(URL.createObjectURL(selectedFile));
      const responseblob = await response.blob();
      const blob = new Blob([responseblob], { type: responseblob.type });
      blob.name = selectedFile.name;
      const {
        data: {
          data: { id, type },
        },
      } = await createMedium({
        file: blob,
        attributes: { 'owner-type': 'CelebrationPrintableLayer', 'owner-id': selectedLayer.id },
      });
      photo.id = id;
      photo.type = type;
      postPrivateEventDetails(`celebration_tapped_upload_printable_${selectedSide}`, {
        'subject-type': 'Celebration',
        'subject-id': celebrationId,
      });
      attributes.photo_id = id;
    }
    await ax().patch(
      `/v3/celebrations/${celebrationId}/celebration_printables/${templateId}/celebration_printable_image_layers/${selectedLayer.id}`,
      {
        data: {
          attributes,
        },
      }
    );
    revalidatePrintables();
    setSubmitting(false);
    setSelectedLayer(null);
    setShowEditor(false);
    createConfirmation({ icon: FiCheckCircle, message: 'Changes Saved' });
  };

  const onImageDeletion = async () => {
    setSubmitting(true);
    await ax().delete(`/v2/media/${props?.photo?.id}`);
    await ax().delete(`/v2/media/${props?.['canvas-image']?.id}`);
    await ax().patch(
      `/v3/celebrations/${celebrationId}/celebration_printables/${templateId}/celebration_printable_image_layers/${props?.id}`,
      {
        data: {
          attributes: { photo_id: null },
        },
      }
    );
    setSubmitting(false);
    setSelectedLayer(null);
    setShowEditor(false);
    removeImageEntityReference();
    removeCanvasEntityReference();
    createConfirmation({ icon: FiCheckCircle, message: 'Changes Saved' });
    setUrl(null);
    setSrc(null);
    revalidatePrintables();
  };

  useEffect(() => {
    if (isPreviewOpenForChildren === props?.id) {
      if (src) setFile(src);
      setSelectedLayer(props);
    }
  }, [isPreviewOpenForChildren, src]);

  function onImageReady() {
    setLoading(false);
  }

  return (
    <Box
      width={`${props.width}px`}
      height={`${props.height}px`}
      sx={{
        position: 'absolute',
        top: `${props.ypos}px`,
        left: `${props.xpos}px`,
        zIndex: props['render-order'],
        writingMode: 'horizontal-tb',
        touchAction: 'pan-x pan-y pinch-zoom',
      }}
    >
      <AnimatedDialog
        contentProps={{ width: '100vw', maxWidth: 540 }}
        contentCSS={isFromMobile ? cssHandling(true) : ''}
        isOpen={isCurrentLayerSelected}
        hideAutoDismiss
        onDismiss={() => {
          if (isSubmitting) setShowConfirmClose(true);
          else setSelectedLayer(null);
          setShowEditor(false);
        }}
      >
        <ConfirmDialog
          isOpen={showConfirmClose}
          onConfirm={() => {
            setShowConfirmClose(false);
          }}
          onDismiss={() => setShowConfirmClose(false)}
          title="Cancel upload?"
          body="Your file(s) will not be uploaded."
          cancelTitle="Nevermind"
          confirmTitle="Cancel upload"
          confirmVariant="destructive"
        />
        <BufferingOverlay isBuffering={isSubmitting} sx={{ mb: 2 }}>
          <ImageUploader
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            src={file}
            width={width}
            height={height}
            photoScaling={photoScaling}
            scale={scale}
            onImageSelection={onImageSelection}
            setPhotoScalling={setPhotoScalling}
            setScale={setScale}
            isSubmitting={isSubmitting}
            setFile={setFile}
            isFromMobile={isFromMobile}
          />
        </BufferingOverlay>
      </AnimatedDialog>
      {!preview?.tmpURL &&
      (uploadStatus === 'processing' ||
        uploadStatus === 'pending_upload' ||
        uploadStatus === 'uploaded') ? (
        <LoadingBg
          sx={{
            pointerEvents: 'none',
            gridArea: '1 / 1 / 2 / 2',
            height: `${props.height}px`,
            borderRadius: 'medium',
          }}
        >
          <RotatingText
            className="loading"
            fontSize="150px"
            color="white"
            textAlign="center"
            pt="25%"
          />
        </LoadingBg>
      ) : (
        <BufferingOverlay
          isFromPoster
          isBuffering={isSubmitting || loading}
          sx={{
            height: `${props.height}px`,
            width: `${props.width}px`,
          }}
        >
          <ImageEditingOptions
            showEditor={showEditor}
            setShowEditor={setShowEditor}
            onImageDeletion={imageSrc || preview ? onImageDeletion : undefined}
            onEditingClick={() => {
              if (src) setFile(src);
              setSelectedLayer(props);
            }}
          >
            <RenderImageLayer
              image={url}
              secondUrl={src}
              height={props.height}
              width={props.width}
              color={[0, 0, 0, 0]}
              position={url ? renderPositionForVGBImage(photoScaling) : null}
              scale={url ? (scalePosition(scale) * 3) / 100 : 1}
              onImageReady={onImageReady}
            />
          </ImageEditingOptions>
        </BufferingOverlay>
      )}
    </Box>
  );
}

export default memo(ImageLayer);
